/* eslint-disable react/jsx-pascal-case */
import { useCallback, useContext, useMemo } from "react";
import { createBinder, getAssetUrl } from "utils/utils";
import { CharacterPage, CharacterPageProps } from "./CharacterPage";
import { GameContext, PlayersContext, StoryContext, UserContext } from "utils/contexts";
import { useParams } from "react-router-dom";
import NDYY_CharacterPage from "lib/stories/NDYY/NDYY_CharacterPage";
import { socket } from "utils/socketConnector";
import YLSX_CharacterPage from "lib/stories/YLSX/YLSX_CharacterPage";
import SSQW_CharacterPage from "lib/stories/SSQW/SSQW_CharacterPage";
import ZTFR_CharacterPage from "lib/stories/ZTFR/ZTFR_CharacterPage";
import EXSL_CharacterPage from "lib/stories/EXSL/EXSL_CharacterPage";
import YLW_CharacterPage from "lib/stories/YLW/YLW_CharacterPage";
import MHZY_CharacterPage from "lib/stories/MHZY/MHZY_CharacterPage";
import WQYF_CharacterPage from "lib/stories/WQYF/WQYF_CharacterPage";
import XLJ_CharacterPage from "lib/stories/XLJ/XLJ_CharacterPage";
import YSZM_CharacterPage from "lib/stories/YSZM/YSZM_CharacterPage";

export type CharacterPageBinderManagedProps = 'isCreator' | 'currentCharacter' | 'isReady' | 'isAllReady' | 'backgroundUrl' | 'storyLayout' | 'remainingPlayers' | 'onReadyButtonClicked' | 'onNextButtonClicked' | 'showReadyButton';

export interface CharacterPageBinderProps extends Omit<CharacterPageProps, CharacterPageBinderManagedProps> { }

const useCharacterPageBinder = (props: CharacterPageBinderProps): CharacterPageProps => {

  const { user } = useContext(UserContext);
  const gameAudit = useContext(GameContext);
  const storyDetail = useContext(StoryContext);
  const players = useContext(PlayersContext);
  const { gameId } = useParams();

  const currentCharacter = gameAudit?.players?.[user?.userId ?? '']?.character;
  const isReady = gameAudit?.players?.[user?.userId ?? '']?.isReady;

  const isAudience = useMemo(() => !Object.keys(gameAudit?.players ?? {}).includes(user?.userId ?? ''), [gameAudit?.players, user?.userId]);

  const onSelectCharacter = useCallback((character: string) => {
    if (!isReady && !Object.entries(gameAudit?.players ?? {}).some(([playerId, playerInfo]) => playerInfo.character === character && playerId !== user?.userId) && !isAudience) {
      socket?.emit('playerSelectCharacter', user?.userId, gameId, character);
    }
  }, [gameAudit?.players, gameId, isAudience, isReady, user?.userId]);

  const storyLayout = useMemo(() => {
      switch (gameAudit?.storyId) {
        case 'NDYY':
          return <NDYY_CharacterPage characterList={storyDetail?.characterList ?? {}} onCharacterClicked={onSelectCharacter} />;
        case 'YLSX':
          return <YLSX_CharacterPage characterList={storyDetail?.characterList ?? {}} onCharacterClicked={onSelectCharacter} />;
        case 'SSQW':
          return <SSQW_CharacterPage characterList={storyDetail?.characterList ?? {}} onCharacterClicked={onSelectCharacter} />;
        case 'ZTFR':
          return <ZTFR_CharacterPage characterList={storyDetail?.characterList ?? {}} onCharacterClicked={onSelectCharacter} />;
        case 'EXSL':
          return <EXSL_CharacterPage characterList={storyDetail?.characterList ?? {}} onCharacterClicked={onSelectCharacter} />;
        case 'YLW':
          return <YLW_CharacterPage characterList={storyDetail?.characterList ?? {}} onCharacterClicked={onSelectCharacter} />;
        case 'MHZY':
          return <MHZY_CharacterPage characterList={storyDetail?.characterList ?? {}} onCharacterClicked={onSelectCharacter} />;
        case 'WQYF':
          return <WQYF_CharacterPage characterList={storyDetail?.characterList ?? {}} onCharacterClicked={onSelectCharacter} />;
        case 'XLJ':
          return <XLJ_CharacterPage characterList={storyDetail?.characterList ?? {}} onCharacterClicked={onSelectCharacter} />;
        case 'YSZM':
          return <YSZM_CharacterPage characterList={storyDetail?.characterList ?? {}} onCharacterClicked={onSelectCharacter} />;
        default:
          return null;
      }
  }, [gameAudit?.storyId, onSelectCharacter, storyDetail?.characterList]);

  const remainingPlayers = useMemo(() => Object.fromEntries(Object.entries(players).filter(([playerId]) => gameAudit?.players?.[playerId] && !gameAudit?.players?.[playerId].character)), [gameAudit?.players, players]);
  const isAllReady = useMemo(() => Object.values(gameAudit?.players ?? {}).every((player) => player.isReady),[gameAudit?.players]);

  const onReadyButtonClicked = useCallback(() => {
    if (currentCharacter) {
      socket?.emit('playerReady', user?.userId, gameId);
    }
  }, [currentCharacter, gameId, user?.userId]);

  const onNextButtonClicked = useCallback(() => {
    if (isAllReady) {
      socket?.emit('moveToStage', gameId, 'READING1');
    }
  }, [gameId, isAllReady]);

  const managedProps: Pick<CharacterPageProps, CharacterPageBinderManagedProps> = {
    isCreator: user?.userId === gameAudit?.creator,
    currentCharacter,
    isReady,
    isAllReady,
    backgroundUrl: getAssetUrl(`/${gameAudit?.storyId}/background.webp`),
    storyLayout,
    remainingPlayers,
    onReadyButtonClicked,
    onNextButtonClicked,
    showReadyButton: !isAudience,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const CharacterPageBinder = createBinder(CharacterPage, useCharacterPageBinder);

export default CharacterPageBinder;