import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';
import { FadeInAndOutKeyframes } from 'utils/keyframes';
import useSound from 'use-sound';
import BackgroundMusic from 'assets/music/baiye.mp3';
import { useTimeout } from 'utils/hooks';

const ContentContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-family: STFangSong;
  font-size: 32px;
  text-indent: 2em;
  text-align: justify;
`;

const Info = styled.div`
  width: 40vw;
  height: 100vh;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 10%;
  top: 0;
  bottom: 0;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  text-align: justify;
  line-height: 1.5;
`;

const Info1 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 10s ease-in-out both 2s;
`;

const Info2 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 13s;
`;

const Info3 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 26s;
`;

const Info4 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 39s;
`;

const InfoImage = styled.img`
  margin-bottom: 50px;
`;

const PosterIamge = styled.img`
  height: 60vh;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 15%;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 1.5s ease-in-out both;
`;

const Subtitle = styled.div`
  font-size: 36px;
  font-family: STZhongSong;
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 120px;
  margin: auto;
  text-align: center;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.4), 0 6px 20px rgba(0, 0, 0, 0.6);
`;


export interface SSQW_EpiloguePageProps extends CommonUIProps { }

export const SSQW_EpiloguePage: FC<SSQW_EpiloguePageProps> = function ({
  ...props
}) {

  const [, { stop, sound }] = useSound(BackgroundMusic, {
    volume: 0.05,
    autoplay: 1,
    interrupt: true,
  });
  const [playLastVoliceLine] = useSound(getAssetUrl('/SSQW/aq_lastvoice.mp3'), { volume: 0.8 });
  const [showPoster, setShowPoster] = useState<boolean>(false);
  const [subtitleText, setSubtitleText] = useState<string>();

  useEffect(() => {
    return () => stop();
  }, [stop]);

  useTimeout(() => {
    playLastVoliceLine();
    setSubtitleText('怀武，等千海把我妹妹接来，我就去找你，一起开一家酒楼');
  }, 53500);

  useTimeout(() => {
    setSubtitleText('我做你的掌柜，从此余生，与美酒香鱼作伴可好？');
  }, 60700);

  useTimeout(() => {
    sound.fade(0.05, 0.15, 500);
  }, 67700);

  return (
    <ContentContainer {...props}>
      <Info1>
        <InfoImage src={getAssetUrl('/SSQW/jeanne.jpg')} height={600} />
        <p>罗明纱小时候看到的手抄本，其实讲述的是著名法国女英雄圣女贞德，被英格兰当局判有异端和女巫罪，处以火刑的故事。</p>
      </Info1>
      <Info2>
        <InfoImage src={getAssetUrl('/SSQW/phosphorus.jpg')} height={600} />
        <p>
          淡黄色的蜡状固体是白磷（Phosphorus），在空气中会发生氧化反应并自燃。据说，这种物质是在德国人布朗特·汉宁在用尿液提炼哲人石（Philosopher's Stone）的时候发现并命名的。
        </p>
      </Info2>
      <Info3>
        <InfoImage src={getAssetUrl('/SSQW/phenolphthalien.jpg')} height={600} />
        <p>非诺夫他林（Phenolphthalein）就是酚酞，遇到碱水（比如膨松剂）会变成红色，这也是陶山德与韩仁陆表演的咒符魔术原理。</p>
      </Info3>
      <Info4 onAnimationEnd={() => setShowPoster(true)}>王怀武的真实身份，韩仁陆碰到的记者“安宁”，以及西卜博士的其他故事，都在其他豪门惊情其他故事有所出现。感兴趣的玩家，可以继续体验《惊魂醉阳楼》、《傀儡的记忆》、《雾起云浮》等剧本。</Info4>
      <PosterIamge src={getAssetUrl('/SSQW/poster.webp')} style={{ opacity: showPoster ? '1' : '0' }} />
      <Subtitle>{subtitleText}</Subtitle>
    </ContentContainer>
  );
};

export default SSQW_EpiloguePage;
