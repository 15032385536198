import styled from '@emotion/styled';
import { Divider, Stack } from '@mui/material';
import { FC } from 'react';
import { CommonUIProps } from 'utils/types';
import DevloperImg from 'assets/developer.webp';
import ArtistImg from 'assets/artist.webp';
import TranslatorImg from 'assets/translator.webp';
import RouterPath from 'router/RouterPath';
import LogoWhite from 'assets/logo_white.png';
import LogoWhiteLarge from 'assets/logo1024_white.png';
import { useNavigate } from 'react-router-dom';
import PageFooter from './PageFooter';

const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
`;

const PageHeader = styled.div`
  padding-top: 16px;
  padding-bottom: 48px;
  background-color: #274472;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const TopBar = styled(Stack)`
  width: 100%;
  color: white;
  font-size: 20px;
  margin-left: 5vw;
`;

const LogoStack = styled(Stack)`
  width: 200px;
  cursor: pointer;
`;

const LargeLogo = styled.img`
  width: 640px;
  height: 640px;
`;

const Title = styled.div`
  font-size: 56px;
  color: white;
`;

const Subtitle = styled.div`
  font-size: 24px;
  color: #D4D4D4;
`;

const LogoImg = styled.img`
  width: 36px;
  height: 36px;
`;

const SectionStack = styled(Stack)`
  padding: 36px;
`;

const Section = styled(Stack)`
  background-color: white;
`;

const Spacer = styled.div`
  width: 120px;
`;

const RecruitImage = styled.img`
  width: 600px;
  height: 400px;
`;

const RecruitTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RecruitTextTitle = styled.div`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 24px;
  line-height: 1.5;
`;

const RecruitTextSubtitle = styled.div`
  font-size: 18px;
  color: #4D4D4D;
  line-height: 2;
`;

export interface RecruitPageProps extends CommonUIProps {

}

export const RecruitPage: FC<RecruitPageProps> = function ({
  ...props
}) {

  const navigate = useNavigate();

  return (
    <PageContainer {...props}>
      <PageHeader>
        <TopBar>
          <LogoStack direction="row" alignItems="center" spacing={1} onClick={() => navigate(RouterPath.Home)}>
            <LogoImg src={LogoWhite} />
            <span style={{ fontWeight: 'bold', marginBottom: '4px' }}>Agency of Time</span>
          </LogoStack>
        </TopBar>
        <LargeLogo src={LogoWhiteLarge} />
        <Title>如果您也一样热爱剧本杀，欢迎加入我们</Title>
        <Subtitle>一起打造好玩又有趣的线上剧本游戏</Subtitle>
      </PageHeader>
      <SectionStack direction="column" justifyContent="center" spacing={6}>
        <Section direction="row" alignItems="center" justifyContent="center" spacing={24}>
          <Spacer />
          <RecruitTextWrapper>
            <RecruitTextTitle>程序开发<br />&emsp;Application Developer</RecruitTextTitle>
            <RecruitTextSubtitle>
              · 主要负责平台和游戏流程的开发和运维<br />
              · 需要熟练掌握JS语言、前端React框架或后端Node框架<br />
              · 有任意AIGC工具使用经验<br />
              · <span style={{ fontWeight: 'bold' }}>热爱剧本杀！</span><br />
              · 感兴趣请联系 <span style={{ textDecoration: 'underline' }}>agency.of.time@gmail.com</span><br />
            </RecruitTextSubtitle>
          </RecruitTextWrapper>
          <RecruitImage src={DevloperImg} />
        </Section>
        <Divider />
        <Section direction="row" alignItems="center" justifyContent="center" spacing={24}>
          <RecruitImage src={ArtistImg} />
          <RecruitTextWrapper>
            <RecruitTextTitle>美术与创意设计<br /> &emsp;Art & Creative Design</RecruitTextTitle>
            <RecruitTextSubtitle>
              · 主要负责平台UI/UX设计，美术和游戏内容的创意设计<br />
              · 需要熟悉网页与移动端应用的视觉与交互设计、人物立绘美术或插画美术<br />
              · 有丰富的AIGC工具使用经验<br />
              · <span style={{ fontWeight: 'bold' }}>热爱剧本杀！</span><br />
              · 感兴趣请联系 <span style={{ textDecoration: 'underline' }}>agency.of.time@gmail.com</span><br />
            </RecruitTextSubtitle>
          </RecruitTextWrapper>
          <Spacer />
        </Section>
        <Divider />
        <Section direction="row" alignItems="center" justifyContent="center" spacing={24}>
          <Spacer />
          <RecruitTextWrapper>
            <RecruitTextTitle>多语言翻译<br /> &emsp;Localization Specialist</RecruitTextTitle>
            <RecruitTextSubtitle>
              · 主要负责将剧本故事翻译成其他语言<br />
              · 需要熟悉对应语种的文字译制工作<br />
              · 有文本类AIGC工具的使用经验<br />
              · <span style={{ fontWeight: 'bold' }}>热爱剧本杀！</span><br />
              · 感兴趣请联系 <span style={{ textDecoration: 'underline' }}>agency.of.time@gmail.com</span><br />
            </RecruitTextSubtitle>
          </RecruitTextWrapper>
          <RecruitImage src={TranslatorImg} />
        </Section>
      </SectionStack>
      <PageFooter />
    </PageContainer>
  );
};

export default RecruitPage;
