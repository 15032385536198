import { Stack } from '@mui/material';
import Logo from 'assets/logo1024_white.png'
import { CommonUIProps } from 'utils/types';
import { FC } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const skew = keyframes`
  0% {
    -webkit-transform: skew(0deg, 0deg);
    transform: skew(0deg, 0deg);
  }
  40% {
    -webkit-transform: skew(0deg, 0deg);
    transform: skew(0deg, 0deg);
  }
  60% {
    -webkit-transform: skew(8deg, 8deg);
    transform: skew(8deg, 0deg);
  }
  80% {
    -webkit-transform: skew(-4deg);
    transform: skew(-4deg);
  }
  90% {
    -webkit-transform: skew(2deg);
    transform: skew(2deg);
  }
  100% {
    -webkit-transform: skew(0deg, 0deg);
    transform: skew(0deg, 0deg);
  }
`;

const particleAnimation1 = keyframes`
  0% {
    top: 5px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
  50% {
    top: 5px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
  80% {
    top: -25px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 1;
  }
  100% {
    top: -25px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
`;

const particleAnimation2 = keyframes`
  0% {
    top: 5px;
    right: 38px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
  50% {
    top: 5px;
    right: 38px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
  80% {
    top: -5px;
    right: 60px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 1;
  }
  100% {
    top: -5px;
    right: 60px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
`;

const particleAnimation3 = keyframes`
  0% {
    right: 30px;
    top: 8px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
  50% {
    right: 30px;
    top: 8px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
  80% {
    right: 10px;
    top: 13px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 1;
  }
  100% {
    right: 10px;
    top: 13px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
`;

const PageContainer = styled(Stack)`
  width: 100dvw;
  height: 100dvh;
  background-color: black;
`;

const LogoWrapper = styled(Stack)`
  position: relative;
`;

const AnimatedLogo = styled.div`
  animation: ${skew} 0.55s ease-in-out infinite both;
  transform-origin: bottom left;
`;

const AnimatedParticle1 = styled.div`
  width: 3px;
  height: 3px;
  background-color: white;
  position: absolute;
  right: 35px;
  top: 5px;
  animation: ${particleAnimation1} ease-in 0.55s infinite;
`;

const AnimatedParticle2 = styled.div`
  width: 2px;
  height: 2px;
  background-color: white;
  position: absolute;
  right: 38px;
  top: 5px;
  animation: ${particleAnimation2} ease-in 0.55s infinite;
`;

const AnimatedParticle3 = styled.div`
  width: 2px;
  height: 2px;
  background-color: white;
  position: absolute;
  right: 30px;
  top: 8px;
  animation: ${particleAnimation3} ease-in 0.55s infinite;
`;

const LogoImage = styled.img`
  width: 320px;
  height: 320px;
`;

const ConstructingText = styled.div`
  margin-left: 45px;
  margin-right: 45px;
  text-align: center;
  color: white;
  font-size: 20px;
  line-height: 32px;
`;

export interface ConstructingPageProps extends CommonUIProps { }

export const ConstructingPage: FC<ConstructingPageProps> = function ({ ...props }) {
  return (
    <PageContainer justifyContent="center" alignItems="center" spacing={4} {...props}>
      <LogoWrapper justifyContent="center" alignItems="center">
        <AnimatedLogo>
          <LogoImage src={Logo} />
        </AnimatedLogo>
        <AnimatedParticle1 />
        <AnimatedParticle2 />
        <AnimatedParticle3 />
      </LogoWrapper>
      <ConstructingText>
        移动端仍在开发中
        <br />
        请先使用电脑或平板访问本网站吧！
      </ConstructingText>
    </PageContainer>
  );
};

export default ConstructingPage;
