export interface StoryDataProps {
  name: string;
  rating: number;
  females: number;
  males: number;
  tags: string[];
  length: number;
  difficulty: string;
  abstract: string;
}

export const StoryData: Record<string, StoryDataProps> = {
  NDYY: {
    name: '孽岛疑云',
    rating: 4.5,
    females: 3,
    males: 2,
    tags: ['豪门惊情', '上海以东', '灵魂', '杀人魔'],
    length: 3,
    difficulty: '中等',
    abstract:
      '\t时值清宣统皇帝逊位后，中华改国体为共和，袁世凯就任大总统。西方列强忙于欧陆战场时，仍不忘扩张在中国的殖民势力，这一年，上海法租界的面积扩展至15150亩（约10，095，990平方米）。在上海之东的大洋上，零星散布着许多岛屿，其中一座因与陆地不远，如同宗外旁支，得名“孽岛”（孽：宗法制度下指家庭的旁支、支庶）。\n\n\t“孽岛”的居民大多数住在岛南的村落里，生活贫苦，依靠渡船往来陆地。岛北海边有一座二层建筑——这里原本是西洋人的疗养院，装有独立的发电装置，后来被国人买下，改建成“海砂馆”。\n\n\t如今的馆主人贺先生极少与岛上居民往来，就连往返上海都使用自家的船只和雇员——不但如此，他还在“海砂馆”每个窗户外都安装了防盗的铁栏，栏杆之间的空隙很窄，就算孩童也无法探出身子。\n\n\t岛南的人很少接近这里，今天却有许多戴着“面具”的客人乘船至此，应邀前往一向少有访客的“海砂馆”……',
  },
  YLSX: {
    name: '岳麓山下',
    rating: 4,
    females: 3,
    males: 3,
    tags: ['豪门惊情', '湖南长沙', '香菜', '鸭汤'],
    length: 3.5,
    difficulty: '中等',
    abstract:
      '\t湘江西岸的“岳麓山”下，坐落着有千年历史的“岳麓书院”，静静地见证朝代更替，兴衰荣辱。\n\n\t1902年，英国凭借不平等条约，要求商船随意出入长沙——此后数年间，英、日等国不断扩张在长沙的势力，洋商及其代理们更是任意兴建金融及商业机构——1905年，英国商人“贝纳锡”违反禁令，擅自在长沙城内西长街开设商行，激起本地士绅的反对，引发“华洋杂处交涉”事件，却无法阻止洋人继续“圈地”。\n\n\t在比邻湘江的“易家湾”里有一户人家，世代经商，这一代的继承人易温在长沙创办“湘福轮船公司”，借长沙“开埠”后的混乱局面，不断投机，增购商船，后来搬入湘江西岸的一座三层建筑——因为能从楼上西侧窗口远眺“岳麓山”，遂改名“麓下馆”……',
  },
  SSQW: {
    name: '嗜睡蔷薇',
    rating: 4.5,
    females: 3,
    males: 3,
    tags: ['豪门惊情', '浙江宁波', '女巫', '催眠'],
    length: 3,
    difficulty: '中等',
    abstract:
      '\t浙江省内有一座横跨余姚、鄞州、奉化、嵊州的“四明山”——此山东西狭长，据《剡录·山水志》载：“四明山境四周八百余里……东为惊浪之山，西拒奔牛之垄，南则驱羊之势，北起走蛇之峭。”山中古林茂密，其中金钱松、银杏、枫香等树众多，加上此时已过“白露”，抬眼望去，峰峦间尽是绚烂秋色。\n\n\t在一处只有前路的陡峭山壁上，赫然矗立着一座古旧的西洋建筑，这是七十年前——第一次鸦片战争时，全境陷落的宁波被写入《南京条约》后，强制开埠，由英吉利人所建——几十年后被宁波商人“罗家”的先人买下，起名“蔷薇馆”，摘下高悬空中的“米字旗”，做了别墅。\n\n\t年代如此久远，“蔷薇馆”中自然少不了故事……从今天上午开始，“四明山”上就乌云密布，晦暗不明，地气上蒸而闷热，山林中的鸟兽仿佛有了预感，纷纷回巢返穴——这一切都征兆着一场即将到来的狂风暴雨……',
  },
  ZTFR: {
    name: '紫藤夫人',
    rating: 4.5,
    females: 3,
    males: 3,
    tags: ['豪门惊情', '广东宝殊镇', '宝藏', '太平'],
    length: 4.5,
    difficulty: '困难',
    abstract:
      '\t清宣统皇帝逊位后，“北洋政府”定都北京，袁世凯就任大总统。西方列强忙于欧陆战场，无暇东顾，中华似要迎来一番全新光景。\n\n\t在南方的广东省，有一条1903年建成通车的“广三铁路”，连通广州、佛山，一直到“三水河口站”，从这里下车，再渡过“西江”向西，便进入一片郁郁群山——其中有一座隐匿在山林中的“宝殊镇”。镇口距离“西江”的渡口有将近2小时的徒步旅程，只有一条进出镇子的大路，镇内人口集中在镇中心居住，其中以“葛家”家业最大——家主葛继先做过镇里的“保长”，生下两个女儿，大女儿葛月曼远嫁天津，留下小女儿葛月萝，招了外乡人朱陵山做养老女婿。\n\n\t三个月前，葛继先被土匪绑架，只找回了一具被割去右耳、满口牙齿尽落、双手两足折断的尸体，惨不忍睹……葛月萝悲痛欲绝，迁怒全宅奴仆，把这些人都赶出“葛家”……\n\n\t当时还没有人想到，这仅仅是“宝殊镇”惨剧的开端——这一切，都要从三天前9月1日说起……',
  },
  EXSL: {
    name: '鄂西山灵',
    rating: 4.5,
    females: 3,
    males: 3,
    tags: ['豪门惊情', '湖北恩施', '巫师', '童谣'],
    length: 4,
    difficulty: '较难',
    abstract:
      '\t早在清末，就有人提出要修筑一条连接湖北、四川两省，贯通长江中上游地区的“川汉铁路”，此后民间集资成立铁路公司，在湖北境内沿途勘察。\n\n\t清宣统二年（1910年），一名教授来到鄂西的“姊陵坪”考察地貌，那里的管理者是拥有大片土地的“巴康寨”寨主以及世代供奉“山灵”、相传可以掌控生死的“巫师”。\n\n\t民国三年（1914年）6月，詹天佑担任北洋政府交通部“汉粤川铁路督办”后，组建测量队，分头对湖北至四川的路段进行复勘。8月，一位年轻的专家带助手来到“姊陵坪”考察，不知道这一次，又将是怎样的结果……',
  },
  YLW: {
    name: '月落洼',
    rating: 4.5,
    females: 2,
    males: 3,
    tags: ['豪门惊情', '浙江嘉兴', '科学', '魔神'],
    length: 4,
    difficulty: '极难',
    abstract:
      '\t在江苏与浙江两省的交界处，有一座西塘古镇，地势平坦，周围河流遍布，水路交错，生长着许多古树，蔚然成林——往嘉兴的方向走去，偶尔能看到一些不高的丘陵，隐于林中。数十年前，有一块天降陨石落在古镇西南的一处茂林里，冲击成洼，附近的水流注入后——形成湖面——传说此乃月中蟾宫的司时之石，误落凡间，因此得名“月落洼”。\n\n\t之后有一位姓安的学者在“月落洼”附近修建“时珞庄”，带家眷来此定居，此后他常往返上海，眼下正担任着“圣约翰大学”神学院“灵魂存在”课程的临时老师……',
  },
  MHZY: {
    name: '冥海缒幽',
    rating: 4,
    females: 2,
    males: 3,
    tags: ['豪门惊情', '山东青岛', '深海', '触手'],
    length: 4,
    difficulty: '困难',
    abstract:
      '\t昏暗的大海深处，新型潜艇“铁鲸号”内的诸人在经历了数日水下旅程后均感到身体不适——在失去了阳光照射的海底，似乎有什么东西在船舱外不断窥视着这群来自陆地的不速之客……\n\n\t潜艇采用前置操作台，艇内的每间船舱上方都连接着维系呼吸的“通风管道”，从“储气舱”运输空气——潜艇主人樊宗楚信誓旦旦地保证，每次上浮储满“储气舱”后，里面的空气都足够他和船长、船员、乘客在内共6人在深海潜行一周的使用。',
  },
  WQYF: {
    name: '雾起云浮',
    rating: 4.5,
    females: 3,
    males: 2,
    tags: ['豪门惊情', '湖北武汉', '孤儿院', '复生'],
    length: 4.5,
    difficulty: '极难',
    abstract:
      '\t两江交汇的武汉境内江河纵横，作为“九省通衢”，向来商贾云集——清咸丰八年（1858年），在第二次鸦片战争时，汉口被写入《天津条约》开埠，从此就有了各路洋人到此画地为租界，广修楼宇。\n\n\t在汉口的“英租界”以北，有一处木质结构的“云浮馆”，原本是洋人的别墅，后来转卖给以贩茶起家、改做纺织印染的“柯记纱厂”老板……',
  },
  XLJ: {
    name: '先灵祭',
    rating: 4.5,
    females: 3,
    males: 3,
    tags: ['豪门惊情', '湖北恩施', '祭祀', '征兆'],
    length: 4.5,
    difficulty: '极难',
    abstract:
      '\t在颚湘交界的群山之中，有一处位于“鸦川”西岸的“祓赐垭”，周围遍布重峦深沼，其中住民恪守先人风俗，由世袭的“祓仆”担任祭司，一生至少要祭祀一次山林古泽，名曰“先灵祭”。\n\n\t最近半个世纪的世代巨变，同样影响到“祓赐垭”，期间不断有人走出故乡……许多年前，一位“祓仆”离开这里另起门户，建立起一座“巴康寨”，将祖坟迁出，留下他的弟弟继续在此担任“祓仆”。',
  },
  YSZM: {
    name: '仪式之门',
    rating: 4,
    females: 2,
    males: 3,
    tags: ['豪门惊情', '印加帝国', '预言', '转世'],
    length: 4,
    difficulty: '困难',
    abstract:
      '\t在约公元1200年，以“太阳神之子”自居的曼科·卡帕克率领部落来到位于今天南美洲秘鲁的库斯科，插下象征神权的金杖，创建被后人称作“印加帝国”的文明……初代“印加”曼科·卡帕克去世后，由他和姊妹兼王后的玛玛·奥克略·瓦科所生的长子继承王位，继续扩展王国版图，直到公元1492年，哥伦布到达美洲……',
  },
};
