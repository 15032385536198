/* eslint-disable react/jsx-pascal-case */
import styled from '@emotion/styled';
import { LocationButton } from 'lib/game/components/LocationButton';
import GameLock from 'lib/game/components/GameLock';
import { FC, LegacyRef, ReactNode, useContext, useState } from 'react';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';
import ConfirmDialog from 'lib/common/ConfirmDialog';
import BagImg from 'assets/bag.webp';
import { GameContext } from 'utils/contexts';
import { useRandomInterval } from 'utils/hooks';

const MapContainer = styled.div`
  width: 56vw;
  height: 100dvh;
  position: absolute;
  margin: auto;
  left: 22vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Map = styled.img`
  width: 100%;
`;

const GameTime = styled.div`
  position: absolute;
  top: 40px;
  right: 60px;
  text-align: center;
  font-family: 'STZhongSong';
  font-size: 40px;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  color: white;
`;

const MapInfoWrapper = styled.div`
  position: absolute;
  top: 15vh;
  right: 8vh;
  color: white;
  width: 320px;
  font-family: 'STZhongSong';
  font-size: 32px;
  text-align: center;
`;

const MapInfo = styled.img`
  width: 320px;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 8px;
`;

const MapAnnotate = styled.div`
  width: 15vw;
  position: absolute;
  top: 13%;
  right: 0;
  color: white;
  font-family: 'STZhongSong';
  font-size: 26px;
  line-height: 1.5;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  text-align: justify;
`;

const MapAnnotate2 = styled.div`
  width: 10vw;
  position: absolute;
  top: 60%;
  right: 5vw;
  color: white;
  font-family: 'STZhongSong';
  font-size: 26px;
  line-height: 1.5;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  text-align: justify;
`;

const Belongings = styled(LocationButton)`
  position: absolute;
  top: 40dvh;
  right: 8vh;
  color: white;
  width: 260px;
  padding: 16px;
  text-align: center;
  font-family: 'STZhongSong';
  font-size: 32px;
  border-radius: 16px;
  border: 5px solid transparent;

  &:hover {
    border-color: #7eb2ff;
  }
`;

const BelongingsImg = styled.img`
  width: 100%;
`;

const Kitchen = styled(LocationButton)`
  width: 14.7%;
  height: 22.7%;
  left: 1.0%;
  top: 50.5%;
`;

const ServantRoom = styled(LocationButton)`
  width: 17.9%;
  height: 13.5%;
  left: 16.1%;
  top: 50.5%;
`;

const Library = styled(LocationButton)`
  width: 30.2%;
  height: 13.5%;
  left: 34.4%;
  top: 50.5%;
  border-width: 5px 0 5px 5px;

  &:before {
    content: '';
    position: absolute;
    width: 30%;
    height: 105%;
    top: -5px;
    left: 95%;
    border: inherit;
    border-width: 5px 5px 0 0;
    border-radius: 5px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 25%;
    height: 131.5%;
    top: 100%;
    left: calc(100% - 5px);
    border: inherit;
    border-width: 0 5px 5px 5px;
    border-radius: 5px;
  }
`;

const DinningRoom = styled(LocationButton)`
  width: 29.8%;
  height: 17.3%;
  left: 34.4%;
  top: 64.5%;
`;

const StorageRoom = styled(LocationButton)`
  width: 10.3%;
  height: 17.3%;
  left: 1.0%;
  top: 73.7%;
`;

const Remnant = styled(LocationButton)`
  width: 10.4%;
  height: 8.0%;
  left: 77.4%;
  top: 74.5%;
`;

const Outside = styled(LocationButton)`
  width: 10.0%;
  left: 100%;
  top: 80.5%;
  padding: 16px;
  padding-bottom: 48px;
  text-align: center;
  font-family: 'STZhongSong';
  font-size: 28px;
  color: white;
`;

const OutsideImg = styled.img`
  width: 100%;
`;

const Hallway = styled(LocationButton)`
  width: 14.4%;
  height: 8.0%;
  left: 32.5%;
  top: 16.9%;
`;

const Room1 = styled(LocationButton)`
  width: 16.8%;
  height: 11.6%;
  left: 2%;
  top: 2.1%;
`;

const Room2 = styled(LocationButton)`
  width: 11.6%;
  height: 11.6%;
  left: 19.2%;
  top: 2.1%;
`;

const Room3 = styled(LocationButton)`
  width: 21.7%;
  height: 11.6%;
  left: 31.1%;
  top: 2.1%;
`;

const Room4 = styled(LocationButton)`
  width: 16.3%;
  height: 11.6%;
  left: 53.2%;
  top: 2.1%;
`;

const Room5 = styled(LocationButton)`
  width: 16.8%;
  height: 11.5%;
  left: 2%;
  top: 28.8%;
`;

const Room6 = styled(LocationButton)`
  width: 11.6%;
  height: 11.5%;
  left: 19.2%;
  top: 28.8%;
`;

const Room7 = styled(LocationButton)`
  width: 21.7%;
  height: 11.5%;
  left: 31.1%;
  top: 28.8%;
`;

const Room8 = styled(LocationButton)`
  width: 16.3%;
  height: 11.5%;
  left: 53.2%;
  top: 28.8%;
`;

const Stairs = styled.div<{ active: boolean }>`
  position: absolute;
  width: 4%;
  height: 7.65%;
  top: 50%;
  left: 95%;
  cursor: ${(props) => props.active ? 'pointer' : 'auto'};

  border-radius: 5px;
  border: 5px solid transparent;

  &:hover {
    border-color: ${(props) => props.active ? '#7eb2ff' : 'transparent'};
  }
`;

const StairsImg = styled.img`
  width: 100%;
`;

const AnimationText = styled.img`
  width: 120px;
  filter: drop-shadow(0 0 0.75rem rgb(255, 217, 0));
  opacity: 0;
  transition: opacity 5s ease-in-out;
`;

const Text1 = styled(AnimationText)`
  position: absolute;
  left: 5vw;
  bottom: 10dvh;
`;

const Text2 = styled(AnimationText)`
  position: absolute;
  right: 10vw;
  bottom: 25dvh;
`;

const Text3 = styled(AnimationText)`
  position: absolute;
  left: 12vw;
  bottom: 55dvh;
`;

const Text4 = styled(AnimationText)`
  position: absolute;
  right: 30vw;
  bottom: 90dvh;
`;


export interface WQYF_InvestigatePageProps extends CommonUIProps {
  showMap: boolean;
  numRemainingClues: Record<string, number>;
  locationHasNewClue: string[];
  onLocationButtonClicked: (location: string) => void;
  pickClueDialog: ReactNode;
  showPickClueDialog: boolean;
  setShowPickClueDialog: (_: boolean) => void;
  lockWobbles: Record<string, boolean>;
  lockedRooms: Record<string, boolean>;
  onLockAnimationEnd: (location: string) => void;
  showBasementEntrance: boolean;
  showConfirmDialog: boolean;
  handleConfirmDialogClose: () => void;
  onConfirmEnterBasement: () => void;
  basementEntered: boolean;
  bgm2MusicRef: LegacyRef<HTMLAudioElement>;
  onBgm2MusicEnded: () => void;
}

export const WQYF_InvestigatePage: FC<WQYF_InvestigatePageProps> = function ({
  showMap,
  numRemainingClues,
  locationHasNewClue,
  onLocationButtonClicked,
  pickClueDialog,
  showPickClueDialog,
  setShowPickClueDialog,
  lockWobbles,
  lockedRooms,
  onLockAnimationEnd,
  showBasementEntrance,
  showConfirmDialog,
  handleConfirmDialogClose,
  onConfirmEnterBasement,
  basementEntered,
  bgm2MusicRef,
  onBgm2MusicEnded,
  ...props
}) {

  const gameStage = useContext(GameContext)?.stage ?? '';

  const [startTextTransition, setStartTextTransition] = useState<boolean>(false);
  const [animationIndex, setAnimationIndex] = useState<number>(0);

  useRandomInterval(() => {
    if (['READING3', 'INVESTIGATING3', 'VOTING', 'ESCAPING', 'REVEALING'].includes(gameStage)) {
      setStartTextTransition(true);
    }
  }, 15 * 1000, 30 * 1000);

  const onTextTransitionEnd = () => {
    if (startTextTransition) {
      setStartTextTransition(false);
      setAnimationIndex((prev) => (prev + 1) % 4);
    }
  }

  return (
    <div {...props}>
      {showMap &&
        <>
          <MapContainer>
            <div style={{ width: '100%', position: 'relative' }}>
              <Map src={getAssetUrl('/WQYF/map.webp')} draggable={false} />
              {['INVESTIGATING1', 'DISCUSSING1', 'INVESTIGATING2', 'DISCUSSING2', 'INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameStage) && <>
                <Kitchen badge={numRemainingClues['厨房']} badgeStyle={{ top: '50%', left: '-2%' }} hasNewClue={locationHasNewClue.includes('厨房')} onClick={() => onLocationButtonClicked('厨房')} />
                <ServantRoom badge={numRemainingClues['仆人房']} badgeStyle={{ top: '33%', left: '45%' }} hasNewClue={locationHasNewClue.includes('仆人房')} onClick={() => onLocationButtonClicked('仆人房')} />
                <Library badge={numRemainingClues['书库']} badgeStyle={{ top: '50%', left: '85%' }} hasNewClue={locationHasNewClue.includes('书库')} onClick={() => onLocationButtonClicked('书库')} />
                <DinningRoom badge={numRemainingClues['餐厅']} badgeStyle={{ top: '65%', left: '2%' }} hasNewClue={locationHasNewClue.includes('餐厅')} onClick={() => onLocationButtonClicked('餐厅')} />
                <StorageRoom badge={numRemainingClues['杂物房']} badgeStyle={{ top: '50%' }} hasNewClue={locationHasNewClue.includes('杂物房')} onClick={() => onLocationButtonClicked('杂物房')} />
                <Remnant badge={numRemainingClues['废墟']} badgeStyle={{ top: '30%' }} hasNewClue={locationHasNewClue.includes('废墟')} onClick={() => onLocationButtonClicked('废墟')} />
                <Outside badge={numRemainingClues['外面']} badgeStyle={{ bottom: '4%' }} hasNewClue={locationHasNewClue.includes('外面')} onClick={() => onLocationButtonClicked('外面')}>
                  <OutsideImg src={getAssetUrl('/WQYF/stone.webp')} />
                  云浮馆外
                </Outside>
              </>}
              {['INVESTIGATING2', 'DISCUSSING2', 'INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameStage) && <>
                <Hallway badge={numRemainingClues['走廊']} badgeStyle={{ top: '30%', left: '45%' }} hasNewClue={locationHasNewClue.includes('走廊')} onClick={() => onLocationButtonClicked('走廊')} />
                <Room1 badge={numRemainingClues['房间一']} badgeStyle={{ top: '60%' }} hasNewClue={locationHasNewClue.includes('房间一')} onClick={() => onLocationButtonClicked('房间一')} />
                <Room2 badge={numRemainingClues['房间二']} badgeStyle={{ top: '60%' }} hasNewClue={locationHasNewClue.includes('房间二')} onClick={() => onLocationButtonClicked('房间二')} />
                <Room3 badge={numRemainingClues['房间三']} badgeStyle={{ top: '60%' }} hasNewClue={locationHasNewClue.includes('房间三')} onClick={() => onLocationButtonClicked('房间三')} />
                <Room4 badge={numRemainingClues['房间四']} badgeStyle={{ top: '60%', left: '20%' }} hasNewClue={locationHasNewClue.includes('房间四')} onClick={() => onLocationButtonClicked('房间四')} />
                <Room5 badge={numRemainingClues['房间五']} badgeStyle={{ top: '7%' }} hasNewClue={locationHasNewClue.includes('房间五')} onClick={() => onLocationButtonClicked('房间五')} />
                <Room6 badge={numRemainingClues['房间六']} badgeStyle={{ top: '7%' }} hasNewClue={locationHasNewClue.includes('房间六')} onClick={() => onLocationButtonClicked('房间六')} />
                <Room7 badge={numRemainingClues['房间七']} badgeStyle={{ top: '7%' }} hasNewClue={locationHasNewClue.includes('房间七')} onClick={() => onLocationButtonClicked('房间七')} />
                <Room8 badge={numRemainingClues['房间八']} badgeStyle={{ top: '7%', left: '20%' }} hasNewClue={locationHasNewClue.includes('房间八')} onClick={() => onLocationButtonClicked('房间八')} />
              </>}
              {showBasementEntrance && <Stairs active={!basementEntered} onClick={() => onLocationButtonClicked('地下室')}>
                <StairsImg src={getAssetUrl('/WQYF/stairs.webp')} />
                {lockedRooms['地下室'] && <GameLock wobble={lockWobbles['地下室']} style={{ top: '35%', left: '15%' }} onAnimationEnd={() => onLockAnimationEnd('地下室')} />}
              </Stairs>}
              <MapAnnotate>
                注：“楼梯间”及东侧被炸毁，屋顶已经烧光，二层只剩下半截没有烧完的墙壁（约50厘米高），万幸的是火都灭了，地面上都是混合着雨水的残渣。
              </MapAnnotate>
              <MapAnnotate2>
                注：“楼梯间”及东侧被炸毁，只留下废墟，一层其他房间尚算完好。
              </MapAnnotate2>
            </div>
          </MapContainer>
          {['INVESTIGATING1', 'DISCUSSING1', 'INVESTIGATING2', 'DISCUSSING2', 'INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameStage) && <>
            <Belongings badge={numRemainingClues['随身物品']} badgeStyle={{ bottom: '6%', left: '60%' }} hasNewClue={locationHasNewClue.includes('随身物品')} onClick={() => onLocationButtonClicked('随身物品')}>
              <BelongingsImg src={BagImg} />
              随身物品
            </Belongings>
          </>}
          <GameTime>
            云浮馆时间 <br />
            <span style={{ fontFamily: 'STHeiTi', fontWeight: 'bold' }}>？？：？？</span>
          </GameTime>
          <MapInfoWrapper>
            <MapInfo src={getAssetUrl('/WQYF/mapinfo.webp')} />
          </MapInfoWrapper>
        </>
      }
      <Text1 style={{ opacity: (startTextTransition && (animationIndex === 0)) ? 1 : 0}} src={getAssetUrl('/WQYF/ling.webp')} onTransitionEnd={onTextTransitionEnd} />
      <Text2 style={{ opacity: (startTextTransition && (animationIndex === 1)) ? 1 : 0}} src={getAssetUrl('/WQYF/lu.webp')} onTransitionEnd={onTextTransitionEnd} />
      <Text3 style={{ opacity: (startTextTransition && (animationIndex === 2)) ? 1 : 0}} src={getAssetUrl('/WQYF/wu.webp')} onTransitionEnd={onTextTransitionEnd} />
      <Text4 style={{ opacity: (startTextTransition && (animationIndex === 3)) ? 1 : 0}} src={getAssetUrl('/WQYF/yun.webp')} onTransitionEnd={onTextTransitionEnd} />
      {pickClueDialog}
      <ConfirmDialog open={showConfirmDialog} onClose={handleConfirmDialogClose} title="确定进入地下室吗？" content="所有人同意后，游戏会进入下一个阶段。" onConfirmButtonClicked={onConfirmEnterBasement} />
      <audio ref={bgm2MusicRef} src={getAssetUrl('/WQYF/bgm2.mp3')} onEnded={onBgm2MusicEnded} />
    </div>
  );
};

export default WQYF_InvestigatePage;
