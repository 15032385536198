import { createBinder } from "utils/utils";
import { TruthPage, TruthPageProps } from "./TruthPage";
import { useEffect, useState } from "react";
import clientApi from "utils/clientApi";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

export type TruthPageBinderManagedProps = 'pdfData';

export interface TruthPageBinderProps extends Omit<TruthPageProps, TruthPageBinderManagedProps> { }

const useTruthPageBinder = (props: TruthPageBinderProps): TruthPageProps => {

    const { gameId } = useParams();
    const [pdfData, setPdfData] = useState<ArrayBuffer>();

    useEffect(() => {
        if (gameId) {
            clientApi().getTruthForGame(gameId, { responseType: 'arraybuffer' }).then((result) => {
                setPdfData(result.data);
            }).catch((error: Error) => {
                enqueueSnackbar('权限不足或游戏还未结束', { variant: 'error' })
                console.error(error);
            });
        }
    }, [gameId]);

    const managedProps: Pick<TruthPageProps, TruthPageBinderManagedProps> = {
        pdfData,
    };

    return {
        ...props,
        ...managedProps,
    };
};

export const TruthPageBinder = createBinder(TruthPage, useTruthPageBinder);

export default TruthPageBinder;