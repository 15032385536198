import styled from '@emotion/styled';
import { DialogProps, css } from '@mui/material';
import { FullDialog } from 'lib/common/FullDialog';
import { FC, useEffect, useState } from 'react';
import useSound from 'use-sound';
import { useInterval, useTimeout } from 'utils/hooks';
import TypingSound from 'assets/sound/typing.mp3';
import { getAssetUrl } from 'utils/utils';
import { EarthquakeKeyframes } from 'utils/keyframes';
import { SerializedStyles } from '@emotion/react';

const TextWrapper = styled.div`
  width: 100vw;
  height: 400px;
  border: white solid;
  border-width: 3px 0 3px 0;
  background-color: #090909;
  color: white;
  font-family: 'STZhongSong';
  text-align: center;
  transition: opacity 1s ease-in-out 2s;
`;

const Title = styled.div`
  font-size: 32px;
  margin-top: 15px;
`;

const Text = styled.div`
  font-size: 36px;
  margin-top: 20px;
  line-height: 2em;
  margin-left: 1em;
`;

export interface MHZY_StageTransitionProps extends DialogProps {
  setBackgroundAnimation?: (animation?: SerializedStyles) => void;
}

const MHZY_StageTransition1: FC<MHZY_StageTransitionProps> = function ({
  setBackgroundAnimation,
  ...props
}) {

  const text1 = '参与首航的成员们依次登上潜艇，从长梯进入铁鲸号。';
  const text2 = '樊宗楚在船长室内向大家解释航行中的规定，而张助理检查一番后，将上方的入口关闭。';
  const text3 = '随着机器启动的轰鸣声，铁鲸号逐渐驶入海中，观景窗外的海水越来越深，越来越暗……';

  const [animatedText1, setAnimatedText1] = useState<string>('');
  const [animatedText2, setAnimatedText2] = useState<string>('');
  const [animatedText3, setAnimatedText3] = useState<string>('');

  const typingSpeed = 120;
  const linePause = 500;

  const [playTypingSound, { stop }] = useSound(TypingSound, { volume: 0.3, playbackRate: 0.85, loop: true });
  const [playSubmarineSound] = useSound(getAssetUrl('/MHZY/transition1.mp3'));

  useInterval(() => {
    setAnimatedText1(text1.slice(0, animatedText1.length + 1));
  }, props.open ? (animatedText1.length === text1.length ? null : animatedText1.length === 0 ? 2000 : typingSpeed) : null);

  useInterval(() => {
    setAnimatedText2(text2.slice(0, animatedText2.length + 1));
  }, props.open ? (animatedText2.length === text2.length ? null : animatedText2.length === 0 ? 2500 + text1.length * typingSpeed + linePause : typingSpeed) : null);

  useInterval(() => {
    if (animatedText3.length === 0) {
      playTypingSound();
    }
    setAnimatedText3(text3.slice(0, animatedText3.length + 1));
  }, props.open ? (animatedText3.length === text3.length ? null : animatedText3.length === 0 ? 2500 + (text1.length + text2.length) * typingSpeed + linePause * 2  + 10000 : typingSpeed) : null);

  useTimeout(
    () => {
      playTypingSound();
    },
    props.open ? 1600 : null
  );

  useTimeout(
    () => {
      stop();
    },
    props.open ? 2000 + typingSpeed * (text1.length + text2.length) + linePause * 2 : null
  );

  useTimeout(
    () => {
      playSubmarineSound();
    },
    props.open ? 2000 + typingSpeed * (text1.length + text2.length) + linePause * 2 + 1000 : null
  );


  useEffect(() => {
    if (animatedText3 === text3) {
      stop();
    }
  }, [animatedText3, stop]);

  return (
    <FullDialog disableEscapeKeyDown {...props}>
      <TextWrapper style={{ opacity: animatedText3.length === text3.length ? '0' : '1' }} onTransitionEnd={() => props.onClose?.({}, 'escapeKeyDown')}>
        <Title>
          “毗海村”码头
          <br />
          10月18日
        </Title>
        <Text>
          {animatedText1}
          <br />
          {animatedText2}
          <br />
          {animatedText3}
          <br />
        </Text>
      </TextWrapper>
    </FullDialog>
  );
};

const MHZY_StageTransition2: FC<MHZY_StageTransitionProps> = function ({
  setBackgroundAnimation,
  ...props
}) {

  const text1 = '于跃洋似乎突然想起了什么，叫大家快去找船长，神色很难看。';
  const text2 = '众人来到船长室却空无一人，于跃洋似乎等不及了，直接走上操舵台操作起来。';
  const text3 = '随着潜艇一颤，船身开始向右后方倾斜，仿佛有什么东西把铁鲸号拽住，吸在了海底！';

  const [animatedText1, setAnimatedText1] = useState<string>('');
  const [animatedText2, setAnimatedText2] = useState<string>('');
  const [animatedText3, setAnimatedText3] = useState<string>('');

  const typingSpeed = 120;
  const linePause = 500;

  const [playTypingSound, { stop }] = useSound(TypingSound, { volume: 0.3, playbackRate: 0.85, loop: true });
  const [playCrashSound] = useSound(getAssetUrl('/MHZY/transition2.mp3'));

  useInterval(() => {
    setAnimatedText1(text1.slice(0, animatedText1.length + 1));
  }, props.open ? (animatedText1.length === text1.length ? null : animatedText1.length === 0 ? 2000 : typingSpeed) : null);

  useInterval(() => {
    setAnimatedText2(text2.slice(0, animatedText2.length + 1));
  }, props.open ? (animatedText2.length === text2.length ? null : animatedText2.length === 0 ? 2500 + text1.length * typingSpeed + linePause : typingSpeed) : null);

  useInterval(() => {
    if (animatedText3.length === 0) {
      playTypingSound();
    }
    setAnimatedText3(text3.slice(0, animatedText3.length + 1));
  }, props.open ? (animatedText3.length === text3.length ? null : animatedText3.length === 0 ? 2500 + (text1.length + text2.length) * typingSpeed + linePause * 2 + 3000 : typingSpeed) : null);

  useTimeout(
    () => {
      playTypingSound();
    },
    props.open ? 1600 : null
  );

  useTimeout(
    () => {
      stop();
    },
    props.open ? 2000 + typingSpeed * (text1.length + text2.length) + linePause * 2 : null
  );

  useTimeout(
    () => {
      playCrashSound();
      setBackgroundAnimation?.(css`${EarthquakeKeyframes} 1s ease-out 4`);
    },
    props.open ? 2000 + typingSpeed * (text1.length + text2.length) + linePause * 2 + 1000 : null
  );


  useEffect(() => {
    if (animatedText3 === text3) {
      stop();
    }
  }, [animatedText3, stop]);

  return (
    <FullDialog disableEscapeKeyDown {...props}>
      <TextWrapper style={{ opacity: animatedText3.length === text3.length ? '0' : '1' }} onTransitionEnd={() => props.onClose?.({}, 'escapeKeyDown')}>
        <Title>
          “铁鲸号”内部
          <br />
          10月23日
        </Title>
        <Text>
          {animatedText1}
          <br />
          {animatedText2}
          <br />
          {animatedText3}
          <br />
        </Text>
      </TextWrapper>
    </FullDialog>
  );
};

export { MHZY_StageTransition1, MHZY_StageTransition2 };