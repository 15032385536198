import { createBinder, getAssetUrl } from "utils/utils";
import { MHZY_InvestigatePage, MHZY_InvestigatePageProps } from "./MHZY_InvestigatePage";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GameContext, UserContext, VolumeContext } from "utils/contexts";
import { socket } from "utils/socketConnector";
import PickClueDialogBinder from "lib/game/components/PickClueDialogBinder";
import { MHZY_ForbiddenRooms } from "./MHZY_StaticData";
import useSound from "use-sound";
import { useParams } from "react-router-dom";
import { useInterval, useRandomInterval } from "utils/hooks";
import { css } from "@emotion/react";
import { EarthquakeKeyframes } from "utils/keyframes";

export type MHZY_InvestigatePageBinderManagedProps = 'showMap' | 'gameTime' | 'numRemainingClues' | 'locationHasNewClue'
  | 'onLocationButtonClicked' | 'pickClueDialog' | 'showReportButton' | 'onReportButtonClicked'
  | 'showConfirmDialog' | 'handleConfirmDialogClose' | 'onConfirmInvite'
  | 'showStageTransition1' | 'showStageTransition2' | 'onStageTransitionEnd'
  | 'escapeTimer' | 'showConfirmEscapeDialog' | 'handleConfirmEscapeDialogClose' | 'onConfirmEscape' | 'escapeLocations' | 'showEscapeResults' | 'onEscapeReultsClose';

export interface MHZY_InvestigatePageBinderProps extends Omit<MHZY_InvestigatePageProps, MHZY_InvestigatePageBinderManagedProps> {
  showPickClueDialog: boolean;
  setShowPickClueDialog: (_: boolean) => void;
  setControllerBackground: (backgroundUrl?: string) => void;
}

const useMHZY_InvestigatePageBinder = (props: MHZY_InvestigatePageBinderProps): MHZY_InvestigatePageProps => {

  const { user } = useContext(UserContext);
  const { gameId } = useParams();
  const volume = useContext(VolumeContext);
  const gameAudit = useContext(GameContext);
  const [newClues, setNewClues] = useState<Record<string, string[]>>({});
  const { showPickClueDialog, setShowPickClueDialog } = props;
  const [currentLocation, setCurrentLocation] = useState<string>();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const [showStageTransition1, setShowStageTransition1] = useState<boolean>(false);
  const [showStageTransition2, setShowStageTransition2] = useState<boolean>(false);

  const [music1Play, { stop: music1Stop }] = useSound(getAssetUrl('/MHZY/bgm1.mp3'), {
    volume: volume / 100,
    loop: true,
    autoplay: ['READING1', 'INTRODUCING', 'INVESTIGATING1', 'REVEALING'].includes(gameAudit?.stage ?? ''),
    interrupt: true,
  });

  const [music2Play, { stop: music2Stop }] = useSound(getAssetUrl('/MHZY/bgm2.mp3'), {
    volume: volume / 100,
    loop: true,
    autoplay: !['READING1', 'INTRODUCING', 'INVESTIGATING1', 'ESCAPING', 'REVEALING'].includes(gameAudit?.stage ?? ''),
    interrupt: true,
  });

  const [playSonar, { stop: sonarStop }] = useSound(getAssetUrl('/MHZY/sonar.mp3'), {
    autoplay: !['READING1', 'INTRODUCING', 'INVESTIGATING1', 'ESCAPING', 'REVEALING'].includes(gameAudit?.stage ?? ''),
    interrupt: true,
  });

  const [playAlarmSound, { stop: alarmStop }] = useSound(getAssetUrl('/MHZY/alarm.mp3'), { volume: 0.3, loop: true, autoplay: gameAudit?.stage === 'ESCAPING', interrupt: true });
  const [playStrikeSound] = useSound(getAssetUrl('/MHZY/transition2.mp3'));

  useEffect(() => {
    return () => music1Stop();
  }, [music1Stop]);

  useEffect(() => {
    return () => music2Stop();
  }, [music2Stop]);

  useEffect(() => {
    return () => sonarStop();
  }, [sonarStop]);

  useEffect(() => {
    if (['READING2', 'INVESTIGATING2', 'DISCUSSING2'].includes(gameAudit?.stage ?? '') && !showStageTransition1) {
      props.setControllerBackground(getAssetUrl('/MHZY/background.webp'));
    }
  }, [gameAudit?.stage, props, showStageTransition1]);

  useRandomInterval(() => {
    if (!['READING1', 'INTRODUCING', 'INVESTIGATING1', 'ESCAPING', 'REVEALING'].includes(gameAudit?.stage ?? '')) {
      playSonar();
    }
  }, 60 * 1000 * 2, 60 * 1000 * 5);

  const newCharacters = useMemo(() => {
    switch (gameAudit?.stage) {
      case 'READING2':
      case 'INVESTIGATING2':
      case 'DISCUSSING2':
      case 'READING3':
      case 'INVESTIGATING3':
      case 'VOTING':
      case 'ESCAPING':
      case 'REVEALING':
        return { 王赶海: '潜水员' };
      default:
        return {};
    }
  }, [gameAudit?.stage]);

  const numRemainingClues = useMemo(() =>
    Object.fromEntries(
      Object.entries(gameAudit?.rooms ?? {})
        .map(([room, roomInfo]) => [room, Object.values(roomInfo.clues).reduce(((numClues, clueInfo) => numClues + (clueInfo.isFound ? 0 : 1)), 0)]))
    , [gameAudit?.rooms]);

  const unavailableClues = useMemo(() => gameAudit?.rooms['船长室']?.clues['clue13']?.isPublic ? undefined : ['clue22'], [gameAudit?.rooms]);

  const allowToInvestigate = useMemo(() => {
    const forbiddenRoom = MHZY_ForbiddenRooms[gameAudit?.players?.[user?.userId ?? '']?.character ?? ''];

    if (gameAudit?.players?.[user?.userId ?? '']?.character === '孙孟真') {
      if (!['发电室', '船员仓'].includes(currentLocation ?? '')) {
        return true;
      }
      return Object.keys(gameAudit?.rooms ?? {}).every((room) => {
        if (['发电室', '船员仓'].includes(room)) {
          return true;
        }
        return Object.values(gameAudit?.rooms[room]?.clues ?? {}).every((clue) => clue.isFound);
      })
    } else {
      if (forbiddenRoom) {
        if (forbiddenRoom !== currentLocation) {
          return true;
        }
        return Object.keys(gameAudit?.rooms ?? {}).every((room) => {
          if (room === forbiddenRoom) {
            return true;
          }
          return Object.values(gameAudit?.rooms[room]?.clues ?? {}).every((clue) => clue.isFound);
        })
      }
    }
    return true;
  }, [currentLocation, gameAudit?.players, gameAudit?.rooms, user?.userId]);

  useEffect(() => {

    const onClueFound = (clue: string, location: string) => {
      setNewClues((prev) => ({
        ...prev,
        [location]: prev[location] ? [...prev[location], clue] : [clue]
      }));
    }

    const onCluePublished = (clue: string, location: string, character: string) => {
      if (character !== gameAudit?.players?.[user?.userId ?? '']?.character) {
        setNewClues((prev) => ({
          ...prev,
          [location]: prev[location] ? [...prev[location], clue] : [clue]
        }));
      }
    }

    const onStageTransition = (newStage: string) => {
      if (newStage === 'READING2') {
        music1Stop();
        setShowStageTransition1(true);
      }
      if (newStage === 'READING3') {
        music2Stop();
        setShowStageTransition2(true);
        props.setControllerBackground(undefined);
      }
      if (newStage === 'ESCAPING') {
        playAlarmSound();
        music2Stop();
        setEscapeTimer(180);
      }
      if (newStage === 'REVEALING') {
        music1Play();
        alarmStop();
        sonarStop();
        setShowEscapeResults(true);
      }
    }

    socket?.on('clueFound', onClueFound);
    socket?.on('cluePublished', onCluePublished);
    socket?.on('stageTransition', onStageTransition);

    return () => {
      socket?.off('clueFound', onClueFound);
      socket?.off('cluePublished', onCluePublished);
      socket?.off('stageTransition', onStageTransition);
    }
  }, [alarmStop, gameAudit?.players, music1Play, music1Stop, music2Stop, playAlarmSound, props, sonarStop, user?.userId]);

  const onLocationButtonClicked = useCallback((location: string) => {
    if (gameAudit?.stage === 'ESCAPING') {
      if (!gameAudit?.players?.[user?.userId ?? ''].votedClue) {
        setEscapeLocation(location);
        setShowConfirmEscapeDialog(true);
      }
    } else {
      setCurrentLocation(location);
      setShowPickClueDialog(true);
    }
  }, [gameAudit?.players, gameAudit?.stage, setShowPickClueDialog, user?.userId]);

  const handlePickClueDialogClose = useCallback(() => {
    setShowPickClueDialog(false);
    if (currentLocation) {
      const temp = { ...newClues };
      delete temp[currentLocation];
      setNewClues(temp);
    }
  }, [currentLocation, newClues, setShowPickClueDialog]);

  const onConfirmInvite = useCallback(() => {
    setShowConfirmDialog(false);
    if (gameAudit?.players?.[user?.userId ?? ''].character === '于跃洋') {
      socket?.emit('moveToStage', gameId, 'READING2');
    }
  }, [gameAudit?.players, gameId, user?.userId]);

  const handleStageTransitionEnd = useCallback((_: Event, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === 'escapeKeyDown') {
      music2Play();
      setShowStageTransition1(false);
      setShowStageTransition2(false);
    }
  }, [music2Play]);

  const [escapeTimer, setEscapeTimer] = useState<number>(Math.max(0, Math.floor(180 - (new Date().getTime() - (gameAudit?.gameTime ?? 0)) / 1000)));
  const [escapeLocation, setEscapeLocation] = useState<string>();

  useInterval(() => {
    setEscapeTimer((prev) => prev - 1);
  }, escapeTimer > 0 ? 1000 : null);

  useInterval(() => {
    playStrikeSound();
    props.setBackgroundAnimation(css`${EarthquakeKeyframes} 1s ease-out 4`);
  }, gameAudit?.stage === 'ESCAPING' ? 60000 : null)

  const [showConfirmEscapeDialog, setShowConfirmEscapeDialog] = useState<boolean>(false);

  const onConfirmEscape = useCallback(() => {
    socket?.emit('MHZY:escape', user?.userId, gameId, escapeLocation);
    setShowConfirmEscapeDialog(false);
  }, [escapeLocation, gameId, user?.userId]);

  const escapeLocations = useMemo(() => {
    const temp: Record<string, string[]> = {};
    if (gameAudit?.stage === 'ESCAPING') {
      Object.values(gameAudit?.players ?? {}).forEach((player) => {
        if (player.votedClue) {
          temp[player.votedClue] = [...(temp[player.votedClue] ?? []), player.character ?? ''];
        }
      })
    }
    return temp;
  }, [gameAudit?.players, gameAudit?.stage]);

  const [showEscapeResults, setShowEscapeResults] = useState<boolean>(false);

  const managedProps: Pick<MHZY_InvestigatePageProps, MHZY_InvestigatePageBinderManagedProps> = {
    showMap: ['INVESTIGATING2', 'DISCUSSING2', 'INVESTIGATING3', 'VOTING', 'ESCAPING', 'REVEALING'].includes(gameAudit?.stage ?? ''),
    gameTime: ['READING1', 'INTRODUCING', 'INVESTIGATING1'].includes(gameAudit?.stage ?? '') ? '12:15' : '13:15',
    numRemainingClues,
    locationHasNewClue: useMemo(() => Object.keys(newClues), [newClues]),
    onLocationButtonClicked,
    pickClueDialog: useMemo(() => <PickClueDialogBinder location={currentLocation} newClues={newClues[currentLocation ?? '']} newCharacters={newCharacters} open={showPickClueDialog} onClose={handlePickClueDialogClose} allowToInvestigate={allowToInvestigate} unavailableClues={unavailableClues} />, [allowToInvestigate, currentLocation, handlePickClueDialogClose, newCharacters, newClues, showPickClueDialog, unavailableClues]),
    showReportButton: gameAudit?.stage === 'INVESTIGATING1' && gameAudit?.players?.[user?.userId ?? '']?.character === '于跃洋',
    onReportButtonClicked: () => setShowConfirmDialog(true),
    showConfirmDialog,
    handleConfirmDialogClose: () => setShowConfirmDialog(false),
    onConfirmInvite,
    showConfirmEscapeDialog,
    handleConfirmEscapeDialogClose: () => setShowConfirmEscapeDialog(false),
    onConfirmEscape,
    showStageTransition1,
    showStageTransition2,
    onStageTransitionEnd: handleStageTransitionEnd,
    escapeTimer,
    escapeLocations,
    showEscapeResults,
    onEscapeReultsClose: () => setShowEscapeResults(false),
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const MHZY_InvestigatePageBinder = createBinder(MHZY_InvestigatePage, useMHZY_InvestigatePageBinder);

export default MHZY_InvestigatePageBinder;