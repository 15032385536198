import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';
import { FadeInAndOutKeyframes } from 'utils/keyframes';
import useSound from 'use-sound';
import BackgroundMusic from 'assets/music/what_is_a_youth.mp3';

const ContentContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-family: STFangSong;
  font-size: 32px;
  text-indent: 2em;
  text-align: justify;
`;

const Info = styled.div`
  width: 40vw;
  height: 100vh;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 10%;
  top: 0;
  bottom: 0;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  text-align: justify;
  line-height: 1.5;
`;

const Info1 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 16s ease-in-out both 2s;
`;

const Info2 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 19s;
`;

const Info3 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 18s ease-in-out both 32s;
`;

const Info4 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 51s;
`;

const InfoImage = styled.img`
  margin-bottom: 50px;
`;

const PosterIamge = styled.img`
  height: 60vh;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 15%;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 1.5s ease-in-out both;
`;


export interface ZTFR_EpiloguePageProps extends CommonUIProps { }

export const ZTFR_EpiloguePage: FC<ZTFR_EpiloguePageProps> = function ({
  ...props
}) {
  const [, { stop }] = useSound(BackgroundMusic, {
    volume: 0.2,
    autoplay: 1,
    interrupt: true,
  });
  const [showPoster, setShowPoster] = useState<boolean>(false);

  useEffect(() => {
    return () => stop();
  }, [stop])

  return (
    <ContentContainer {...props}>
      <Info1>
        <InfoImage src={getAssetUrl('/ZTFR/RandJ.webp')} height={500} />
        <p>《罗密欧与朱丽叶》是威廉·莎士比亚的著名悲剧作品。小说中的朱丽叶为和罗密欧私奔而服下假毒药，结果罗密欧未能及时得知消息，错误选择自杀殉情。在清末民初时期，莎士比亚也被译作过“狭斯丕尔”。</p>
      </Info1>
      <Info2>
        <InfoImage src={getAssetUrl('/ZTFR/opium.png')} height={600} />
        <p>
        张医师所携带的止痛剂实际上是“鸦片”（Opium）。除去本身的致幻、成瘾等副作用外，鸦片所含有的吗啡成分还具有抑制神经的效果，可以镇痛，甚至一度被误认为是可治百病的“灵丹妙药”。
        </p>
      </Info2>
      <Info3>
        <InfoImage src={getAssetUrl('/ZTFR/datura_stramonium.webp')} height={500} />
        <p>葛月萝所服用的假死药主要成分是曼陀罗中的“东莨菪碱”，是一种能够抑制大脑皮质的生物碱，较大剂量时会麻痹中枢。此花有着悠久的用药历史，曾被记录于《扁鹊心书》、《本草纲目》等书中，也是“蒙汗药”、“麻沸散”等名药的主要原料。在西方，它还被制作成传说中的“吐真剂”……</p>
      </Info3>
      <Info4 onAnimationEnd={() => setShowPoster(true)}>紫藤夫人实际上是一副油画，上面写有天国宝藏地图的解法。葛月曼并不知道此事，在与张医师私奔时将其拿走，最后托亲戚卖给了山西泽州府的一位姓“赵”的大富商。而在剧本《丹水山庄》中，玩家们会发现赵富商也在自己的府内不幸身亡……</Info4>
      <PosterIamge src={getAssetUrl('/ZTFR/poster.webp')} style={{ opacity: showPoster ? '1' : '0' }} />
    </ContentContainer>
  );
};

export default ZTFR_EpiloguePage;
