import styled from '@emotion/styled';
import { FC } from 'react';
import { Badge, Button, ButtonProps } from '@mui/material';
import { HeartbeatKeyframes, PulseKeyframes } from 'utils/keyframes';
import ClickSound from 'assets/sound/click.mp3';
import useSound from 'use-sound';

const IButton = styled(({ buttonColor, animate, ...props }: ButtonProps & { buttonColor?: string, animate: boolean }) => (
  <Button {...props} />
))`
  width: 120px;
  height: 60px;
  border-radius: 8px;
  pointer-events: auto;
  color: white;
  background-color: ${(props) => props.buttonColor};
  text-align: center;
  font-family: STFangSong;
  font-size: 28px;
  line-height: 20px;
  font-style: normal;
  white-space: nowrap;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  &:hover {
    background-color: ${(props) => props.buttonColor + 'AA'};
  }

  &.Mui-disabled {
    background-color: #274472AA;
    color: #9B9B9B;
  }

  &.MuiButton-root {
    text-transform: none;
  }
  
  animation: ${(props) => props.animate ? HeartbeatKeyframes : ''} 1.5s infinite ease-in-out, ${(props) => props.animate ? PulseKeyframes : ''} 2s infinite ease;
`;

export interface GameButtonProps extends ButtonProps {
  badge?: number;
  buttonColor?: string;
  animate?: boolean;
  handleClick: () => void;
}

export const GameButton: FC<GameButtonProps> = function ({
  badge,
  children,
  buttonColor,
  animate = false,
  handleClick,
  ...props
}) {

  const [playClickSound] = useSound(ClickSound, { playbackRate: 1.2, volume: 0.3, interrupt: true });

  return (
    <Badge badgeContent={badge} color="error">
      <IButton
        variant="contained"
        buttonColor={buttonColor}
        animate={animate}
        onClick={() => {
          playClickSound();
          handleClick();
        }}
        {...props}
      >
        {children}
      </IButton>
    </Badge>
  );
};
