import ConstructingPage from 'lib/mobile/ConstructingPage';
import { useLocation } from 'react-router-dom';
import { Fragment, PropsWithChildren } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CommonUIProps } from 'utils/types';
import { createBinder } from 'utils/utils';

export interface ResponsiveBinderProps extends CommonUIProps {}

const isNotUseResponsiveUrls = new Set(['wechat-redirect', 'oauth-callback']);

const useResponsiveBinder = (props: ResponsiveBinderProps): PropsWithChildren => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const location = useLocation();
  const pathname = location.pathname.slice(1);
  const isNotUseResponsive = isNotUseResponsiveUrls.has(pathname);
  return {
    children: isMobile && !isNotUseResponsive ? <ConstructingPage /> : props.children,
  };
};

export const ResponsiveBinder = createBinder(Fragment, useResponsiveBinder);

export default ResponsiveBinder;
