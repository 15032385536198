import { createBinder, getAssetUrl } from "utils/utils";
import { EXSL_InvestigatePage, EXSL_InvestigatePageProps } from "./EXSL_InvestigatePage";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GameContext, UserContext, VolumeContext } from "utils/contexts";
import { socket } from "utils/socketConnector";
import PickClueDialogBinder from "lib/game/components/PickClueDialogBinder";
import { EXSL_ForbiddenRooms } from "./EXSL_StaticData";
import useSound from "use-sound";
import { useParams } from "react-router-dom";

export type EXSL_InvestigatePageBinderManagedProps = 'showMap' | 'gameTime' | 'numRemainingClues' | 'locationHasNewClue'
  | 'onLocationButtonClicked' | 'pickClueDialog' | 'isShamanHouseInvestigated' | 'showInviteButton' | 'onInviteButtonClicked'
  | 'showConfirmDialog' | 'handleConfirmDialogClose' | 'onConfirmInvite'
  | 'showStageTransition1' | 'showStageTransition2' | 'onStageTransitionEnd';

export interface EXSL_InvestigatePageBinderProps extends Omit<EXSL_InvestigatePageProps, EXSL_InvestigatePageBinderManagedProps> {
  showPickClueDialog: boolean;
  setShowPickClueDialog: (_: boolean) => void;
}

const useEXSL_InvestigatePageBinder = (props: EXSL_InvestigatePageBinderProps): EXSL_InvestigatePageProps => {

  const { user } = useContext(UserContext);
  const { gameId } = useParams();
  const volume = useContext(VolumeContext);
  const gameAudit = useContext(GameContext);
  const [newClues, setNewClues] = useState<Record<string, string[]>>({});
  const { showPickClueDialog, setShowPickClueDialog } = props;
  const [currentLocation, setCurrentLocation] = useState<string>();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const [showStageTransition1, setShowStageTransition1] = useState<boolean>(false);
  const [showStageTransition2, setShowStageTransition2] = useState<boolean>(false);

  const [musicPlay, { stop: musicStop }] = useSound(getAssetUrl('/EXSL/bgm.mp3'), {
    volume: volume / 100,
    loop: true,
    autoplay: true,
    interrupt: true,
  });

  useEffect(() => {
    return () => musicStop();
  }, [musicStop]);

  const numRemainingClues = useMemo(() =>
    Object.fromEntries(
      Object.entries(gameAudit?.rooms ?? {})
        .map(([room, roomInfo]) => [room, Object.values(roomInfo.clues).reduce(((numClues, clueInfo) => numClues + (clueInfo.isFound ? 0 : 1)), 0)]))
    , [gameAudit?.rooms]);

  const allowToInvestigate = useMemo(() => {
    const forbiddenRoom = EXSL_ForbiddenRooms[gameAudit?.players?.[user?.userId ?? '']?.character ?? ''];
    if (forbiddenRoom) {
      if (forbiddenRoom !== currentLocation) {
        return true;
      }
      return Object.keys(gameAudit?.rooms ?? {}).every((room) => {
        if (room === forbiddenRoom) {
          return true;
        }
        return Object.values(gameAudit?.rooms[room]?.clues ?? {}).every((clue) => clue.isFound);
      })
    }
    return true;
  }, [currentLocation, gameAudit?.players, gameAudit?.rooms, user?.userId]);

  useEffect(() => {

    const onClueFound = (clue: string, location: string) => {
      setNewClues((prev) => ({
        ...prev,
        [location]: prev[location] ? [...prev[location], clue] : [clue]
      }));
    }

    const onCluePublished = (clue: string, location: string, character: string) => {
      if (character !== gameAudit?.players?.[user?.userId ?? '']?.character) {
        setNewClues((prev) => ({
          ...prev,
          [location]: prev[location] ? [...prev[location], clue] : [clue]
        }));
      }
    }

    const onStageTransition = (newStage: string) => {
      if (newStage === 'READING2') {
        musicStop();
        setShowStageTransition1(true);
      }
      if (newStage === 'READING3') {
        musicStop();
        setShowStageTransition2(true);
      }
    }

    socket?.on('clueFound', onClueFound);
    socket?.on('cluePublished', onCluePublished);
    socket?.on('stageTransition', onStageTransition);

    return () => {
      socket?.off('clueFound', onClueFound);
      socket?.off('cluePublished', onCluePublished);
      socket?.off('stageTransition', onStageTransition);
    }
  }, [gameAudit?.players, musicStop, user?.userId]);

  const onLocationButtonClicked = useCallback((location: string) => {
    setCurrentLocation(location);
    setShowPickClueDialog(true);
  }, [setShowPickClueDialog]);

  const handlePickClueDialogClose = useCallback(() => {
    setShowPickClueDialog(false);
    if (currentLocation) {
      const temp = { ...newClues };
      delete temp[currentLocation];
      setNewClues(temp);
    }
  }, [currentLocation, newClues, setShowPickClueDialog]);

  const onConfirmInvite = useCallback(() => {
    setShowConfirmDialog(false);
    if (gameAudit?.players?.[user?.userId ?? ''].character === '隼') {
      socket?.emit('moveToStage', gameId, 'READING2');
    }
  }, [gameAudit?.players, gameId, user?.userId]);

  const handleStageTransitionEnd = useCallback((_: Event, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === 'escapeKeyDown') {
      musicPlay();
      setShowStageTransition1(false);
      setShowStageTransition2(false);
    }
  }, [musicPlay]);

  const managedProps: Pick<EXSL_InvestigatePageProps, EXSL_InvestigatePageBinderManagedProps> = {
    showMap: !!gameAudit?.stage && gameAudit.stage.slice(0, -1) !== 'READING' && gameAudit.stage !== 'INTRODUCING',
    gameTime: ['READING1', 'INTRODUCING', 'INVESTIGATING1'].includes(gameAudit?.stage ?? '') ? '12:15' : '13:15',
    numRemainingClues,
    locationHasNewClue: useMemo(() => Object.keys(newClues), [newClues]),
    onLocationButtonClicked,
    pickClueDialog: useMemo(() => <PickClueDialogBinder location={currentLocation} newClues={newClues[currentLocation ?? '']} open={showPickClueDialog} onClose={handlePickClueDialogClose} allowToInvestigate={allowToInvestigate} />, [allowToInvestigate, currentLocation, handlePickClueDialogClose, newClues, showPickClueDialog]),
    isShamanHouseInvestigated: numRemainingClues['巫师家'] === 0,
    showInviteButton: gameAudit?.stage === 'INVESTIGATING1' && gameAudit?.players?.[user?.userId ?? '']?.character === '隼',
    onInviteButtonClicked: () => setShowConfirmDialog(true),
    showConfirmDialog,
    handleConfirmDialogClose: () => setShowConfirmDialog(false),
    onConfirmInvite,
    showStageTransition1,
    showStageTransition2,
    onStageTransitionEnd: handleStageTransitionEnd,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const EXSL_InvestigatePageBinder = createBinder(EXSL_InvestigatePage, useEXSL_InvestigatePageBinder);

export default EXSL_InvestigatePageBinder;