import { useState, ChangeEvent, useMemo, useContext, useCallback } from "react";
import { createBinder } from "utils/utils";
import { HomePage, HomePageProps } from "./HomePage";
import LoginDialogBinder from "./LoginDialogBinder";
import AvatarPopoverBinder from "./AvatarPopoverBInder";
import CreateGameDialogBinder from "./CreateGameDialogBinder";
import clientApi from "utils/clientApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import RouterPath from "router/RouterPath";
import { UserContext } from "utils/contexts";
import SubscriptionDialogBinder from "./SubscriptionDialogBinder";
import SubscriptionSuccessDialogBinder from "./SubscriptionSuccessDialogBinder";
import MyCasesDialogBinder from "./MyCasesDialogBinder";
import { StoryData } from "utils/storyData";
import { validateNickname } from "utils/validations";
import localStorageService from "utils/localStorage";
import { enqueueSnackbar } from "notistack";
import WelcomeDialog from "./WelcomeDialog";
import { UserRes } from "openapi";

export type HomePageBinderManagedProps = 'user' | 'errorMsg' | 'gameId' | 'onLoginButtonClicked' | 'onGameIdInputChange'
  | 'onJoinGameButtonClicked' | 'onCreateGameButtonClicked' | 'loginDialog' | 'createGameDialog' | 'avatarPopover'
  | 'subscriptionDialog' | 'subscriptionSuccessDialog' | 'onSubscriptionButtonClicked'
  | 'myCasesDialog' | 'onMyCasesButtonClicked'
  | 'newNickname' | 'handleNewNicknameChange' | 'showEditNicknameDialog' | 'handleEditNicknameDialogClose' | 'onConfirmEditNickname'
  | 'newAvatar' | 'handleNewAvatarChange' | 'showEditAvatarDialog' | 'handleEditAvatarDialogClose' | 'onConfirmEditAvatar' | 'avatars'
  | 'welcomeDialog';

export interface HomePageBinderProps extends Omit<HomePageProps, HomePageBinderManagedProps> { }

const useHomePageBinder = (props: HomePageBinderProps): HomePageProps => {

  const [searchParams, setSearchParams] = useSearchParams();

  const { user, setUser } = useContext(UserContext);
  const [gameId, setGameId] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>();

  const [showLoginDialog, setShowLoginDialog] = useState<boolean>(false);
  const [showCreateGameDialog, setShowCreateGameDialog] = useState<boolean>(false);
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState<boolean>(searchParams.get('payment') === 'cancel');
  const [showSubscriptionSuccessDialog, setShowSubscriptionSuccessDialog] = useState<boolean>(searchParams.get('payment') === 'success');
  const [showMyCasesDialog, setShowMyCasesDialog] = useState<boolean>(false);

  const [newNickname, setNewNickname] = useState<string | undefined>(user?.nickname);
  const [newAvatar, setNewAvatar] = useState<string | undefined>(user?.avatar);
  const [showEditNicknameDialog, setShowEditNicknameDialog] = useState<boolean>(false);
  const [showEditAvatarDialog, setShowEditAvatarDialog] = useState<boolean>(false);

  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const [showWelcomeDialog, setShowWelcomeDialog] = useState<boolean>(false);

  const navigate = useNavigate();

  const onJoinGameButtonClicked = () => {
    if (gameId === '') {
      setErrorMsg('案件编号不能为空');
    } else if (!user) {
      setShowLoginDialog(true);
    } else {
      navigate(`${RouterPath.Game}/${gameId}`);
    }
  };

  const onCreateGame = useCallback((storyId: string, isVIPGame?: boolean) => {
    if (!user) {
      setShowLoginDialog(true);
    }
    // else if (['PLAN_A', 'PLAN_B', 'PLAN_C'].includes(user.role) && user.gameToken <= 0) {
    //   enqueueSnackbar('剧本体验次数不足', { variant: 'error' });
    // }
    else {
      if (Object.keys(StoryData).includes(storyId)) {
        clientApi().createGame({ storyId, isVIPGame }).then((result) => {
          if (result.data.gameId) {
            navigate(`${RouterPath.Game}/${result.data.gameId}`)
          }
        }).catch((error: Error) => {
          console.error(error);
          setErrorMsg(error.message);
        })
      }
    }
  }, [navigate, user]);

  const handleSubscriptionDialogClose = useCallback(() => {
    setShowSubscriptionDialog(false);
    setSearchParams();
  }, [setSearchParams]);

  const handleSubscriptionSuccessDialogClose = useCallback(() => {
    setShowSubscriptionSuccessDialog(false);
    setSearchParams();
  }, [setSearchParams]);

  const handleEditNicknameDialogClose = useCallback(() => {
    setShowEditNicknameDialog(false);
    setNewNickname(user?.nickname);
    if (isNewUser) {
      setShowEditAvatarDialog(true);
    }
  }, [isNewUser, user?.nickname]);

  const onConfirmEditNickname = useCallback(() => {
    if (validateNickname(newNickname)) {
      clientApi().updateUserInfo({ nickname: newNickname}).then(() => {
        if (user && newNickname) {
          setUser({ ...user, nickname: newNickname });
          localStorageService.setUserNickname(newNickname);
        }
        handleEditNicknameDialogClose();
      }).catch((error) => {
        console.error(error);
      })
    } else {
      enqueueSnackbar('用户名不能超过12个字符', { variant: 'error' });
    } 
  }, [handleEditNicknameDialogClose, newNickname, setUser, user]);

  const handleEditAvatarDialogClose = useCallback(() => {
    setShowEditAvatarDialog(false);
    setNewAvatar(user?.avatar);
    if (isNewUser) {
      setShowWelcomeDialog(true);
    }
  }, [isNewUser, user?.avatar]);

  const onConfirmEditAvatar = useCallback(() => {
      clientApi().updateUserInfo({ avatar: newAvatar}).then(() => {
        if (user && newAvatar) {
          setUser({ ...user, avatar: newAvatar });
          localStorageService.setUserAvatar(newAvatar);
        }
        handleEditAvatarDialogClose();
      }).catch((error) => {
        console.error(error);
      })
  }, [handleEditAvatarDialogClose, newAvatar, setUser, user]);

  const onLoginSuccess = useCallback((_user: UserRes) => {
    setNewNickname(_user?.nickname);
    setNewAvatar(_user?.avatar);
    if (_user.newUser) {
      setIsNewUser(true);
      setShowEditNicknameDialog(true);
    }
  }, []);

  const handleWelcomeDialogClose = useCallback(() => {
    setShowWelcomeDialog(false);
    setIsNewUser(false);
  }, []);

  const managedProps: Pick<HomePageProps, HomePageBinderManagedProps> = {
    user,
    errorMsg,
    gameId,
    onLoginButtonClicked: () => setShowLoginDialog(true),
    onGameIdInputChange: (e: ChangeEvent<HTMLInputElement>) => setGameId(e.target.value),
    onJoinGameButtonClicked,
    onCreateGameButtonClicked: () => setShowCreateGameDialog(true),
    loginDialog: useMemo(() => (
      <LoginDialogBinder open={showLoginDialog} onClose={() => setShowLoginDialog(false)} onLoginSuccess={onLoginSuccess} />)
      , [onLoginSuccess, showLoginDialog]),
    createGameDialog: useMemo(() => (
      <CreateGameDialogBinder open={showCreateGameDialog} onClose={() => setShowCreateGameDialog(false)} handleCreateGame={onCreateGame} />
    ), [onCreateGame, showCreateGameDialog]),
    avatarPopover: useMemo(() => <AvatarPopoverBinder onSubscriptionButtonClicked={() => setShowSubscriptionDialog(true)} onEditNicknameButtonClicked={() => setShowEditNicknameDialog(true)} onEditAvatarButtonClicked={() => setShowEditAvatarDialog(true)} />, []),
    subscriptionDialog: useMemo(() => <SubscriptionDialogBinder open={showSubscriptionDialog} onClose={handleSubscriptionDialogClose} />, [handleSubscriptionDialogClose, showSubscriptionDialog]),
    subscriptionSuccessDialog: useMemo(() => <SubscriptionSuccessDialogBinder open={showSubscriptionSuccessDialog} onClose={handleSubscriptionSuccessDialogClose} />, [handleSubscriptionSuccessDialogClose, showSubscriptionSuccessDialog]),
    onSubscriptionButtonClicked: () => setShowSubscriptionDialog(true),
    myCasesDialog: useMemo(() => <MyCasesDialogBinder open={showMyCasesDialog} onClose={() => setShowMyCasesDialog(false)} />, [showMyCasesDialog]),
    onMyCasesButtonClicked: () => setShowMyCasesDialog(true),
    newNickname,
    avatars: ['avatar1', 'avatar2', 'avatar3', 'avatar4', 'avatar5', 'avatar6', 'avatar7', 'avatar8'],
    newAvatar,
    handleNewNicknameChange: (e: ChangeEvent<HTMLInputElement>) => setNewNickname(e.target.value),
    handleNewAvatarChange: (avatarChoice: string) => setNewAvatar(avatarChoice),
    showEditNicknameDialog,
    showEditAvatarDialog,
    handleEditNicknameDialogClose,
    handleEditAvatarDialogClose,
    onConfirmEditNickname,
    onConfirmEditAvatar,
    welcomeDialog: <WelcomeDialog open={showWelcomeDialog} onClose={handleWelcomeDialogClose} />,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const HomePageBinder = createBinder(HomePage, useHomePageBinder);

export default HomePageBinder;
