import { createBinder } from "utils/utils";
import { Fragment, PropsWithChildren, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { CommonUIProps } from "utils/types";
import { UserContext } from "utils/contexts";
import RouterPath from "router/RouterPath";

export interface LocationProps {
  state?: {
    from: Location;
  };
}

export interface RequireAuthBinderProps extends CommonUIProps {}

const useRequireAuthBinder = (props: RequireAuthBinderProps): PropsWithChildren => {
  const { user } = useContext(UserContext);
  const location = useLocation();
  if (!user) {
    // Redirect them to the login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return {
      children: <Navigate to={RouterPath.Home} state={{ from: location }} replace />,
    };
  }

  return {
    children: props.children,
  };
};

export const RequireAuthBinder = createBinder(Fragment, useRequireAuthBinder);

export default RequireAuthBinder;
