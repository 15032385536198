import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';
import { FadeInAndOutKeyframes } from 'utils/keyframes';
import useSound from 'use-sound';
import BackgroundMusic from 'assets/music/haidiliangwanli.mp3';

const ContentContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-family: STFangSong;
  font-size: 32px;
  text-indent: 2em;
  text-align: justify;
`;

const Info = styled.div`
  width: 40vw;
  height: 100vh;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 10%;
  top: 0;
  bottom: 0;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  text-align: justify;
  line-height: 1.5;
`;

const Info1 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 10s ease-in-out both 2s;
`;

const Info2 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 13s;
`;

const Info3 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 10s ease-in-out both 26s;
`;

const Info4 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 37s;
`;

const InfoImage = styled.img`
  margin-bottom: 50px;
`;

const PosterIamge = styled.img`
  height: 60vh;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 15%;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 1.5s ease-in-out both;
`;


export interface MHZY_EpiloguePageProps extends CommonUIProps { }

export const MHZY_EpiloguePage: FC<MHZY_EpiloguePageProps> = function ({
  ...props
}) {
  const [, { stop }] = useSound(BackgroundMusic, {
    volume: 0.5,
    autoplay: 1,
    interrupt: true,
    loop: true
  });
  const [showPoster, setShowPoster] = useState<boolean>(false);

  useEffect(() => {
    return () => stop();
  }, [stop])

  return (
    <ContentContainer {...props}>
      <Info1>
        <InfoImage src={getAssetUrl('/MHZY/haidiliangwanli.webp')} height={600} />
        <p>
          《海底两万里》是法国科幻小说作家凡尔纳的代表作之一，书中对深海冒险的精彩描述被多次改编成电影、动画片等，展示了大海的无穷魅力。世界上第一艘核动力潜艇就被命名为“鹦鹉螺号”，也是首艘水下航行到达北极点的潜水艇。
        </p>
      </Info1>
      <Info2>
        <InfoImage src={getAssetUrl('/MHZY/chinese_sub.jpg')} width={800} />
        <p>中国的潜艇技术直到1950年，在苏联的帮助下才真正开始发展。1952年，中国第一个潜艇基地在青岛建成。1957年，江南造船厂交付第一艘中国自主生产的“03型”常规动力潜艇。如今，中国海军潜艇部队已经成为亚洲规模最大的潜艇部队。</p>
      </Info2>
      <Info3>
        <InfoImage src={getAssetUrl('/MHZY/divesuit.webp')} height={600} />
        <p>早期的潜水服十分笨重，衣服材料无法隔绝冰冷的海水，还有供氧的软管限制活动范围，非常不便。</p>
      </Info3>
      <Info4 onAnimationEnd={() => setShowPoster(true)}>
        <InfoImage src={getAssetUrl('/MHZY/nautilus.jpg')} width={600} />
        <p>鹦鹉螺（Nautilus）是一种生活在深海的古老头足类软体动物，被誉为“活化石”。由于其美丽的外壳，人们过度捕捞又难以养殖，现在已被列入国际濒危野生动物。</p>
      </Info4>
      <PosterIamge src={getAssetUrl('/MHZY/poster.webp')} style={{ opacity: showPoster ? '1' : '0' }} />
    </ContentContainer>
  );
};

export default MHZY_EpiloguePage;
