import { getAssetUrl } from 'utils/utils';

export const getSSQWInvestigatePageBackgroundUrl = (stage: string) => {
  switch (stage) {
    case 'READING1':
    case 'INTRODUCING':
    case 'INVESTIGATING1':
      return [getAssetUrl('/SSQW/background1.webp')];
    case 'READING2':
    case 'INVESTIGATING2':
    case 'VOTING':
    case 'REVEALING':
      return [getAssetUrl('/SSQW/background1.webp'), getAssetUrl('/SSQW/background2.webp')];
    default:
      return undefined;
  }
};

export const SSQW_CharacterColors: Record<string, string> = {
  太太: '#F7418F',
  罗明纱: '#7469B6',
  罗千海: '#378CE7',
  小霁: '#B2B377',
  韩仁陆: '#BE7B72',
  王怀武: '#7F9F80',
};

export const SSQW_CharacterAbbr: Record<string, string> = {
  太太: '太',
  罗明纱: '纱',
  罗千海: '海',
  小霁: '霁',
  韩仁陆: '韩',
  王怀武: '武',
};

export const SSQW_VoteQuestions = ['是谁杀害了罗亨理？'];

export const SSQW_Scripts: Record<string, string> = {
  太太: '/SSQW/scripts/TT.pdf',
  罗明纱: '/SSQW/scripts/LMS.pdf',
  罗千海: '/SSQW/scripts/LQH.pdf',
  小霁: '/SSQW/scripts/XJ.pdf',
  韩仁陆: '/SSQW/scripts/HRL.pdf',
  王怀武: '/SSQW/scripts/WHW.pdf',
};

export const SSQW_ScriptMaxPage: Record<string, number> = {
  READING1: 9,
  INTRODUCING: 9,
  INVESTIGATING1: 9,
  READING2: 11,
  INVESTIGATING2: 11,
  VOTING: 11,
};

export const SSQW_TruthSummary: Record<string, string> = {
  背景故事:'1887年，罗亨理爱上性格外向的黄苏玫，不顾家中反对，执意带她住进蔷薇馆中，称为“蔷薇夫人”。后来在经商的过程中，罗亨理结识了专门研究炼金术和灵魂的西卜博士，其为研究向魔鬼献祭的方法，不惜残忍绑架杀害城中少女，不仅切割肢体，还在身上刻下拉丁文书写的咒符。陶山德作为招魂术大师，受邀前往蔷薇馆中表演，见到故乡人黄苏玫，彼此投缘。此时黄苏玫已对日渐沉迷炼金术的罗亨理心生恐惧，借机和陶山德逃走，回到武昌成亲。\n\n       后来陶山德和黄苏玫生下两个女儿陶若晴（阿晴）和陶若霁（小霁），而陶山德与前妻生的儿子陶孝先则去给西卜博士做了助手，学习化学知识。',
  '“招魂大师”山德先生': '由于残害少女的事情败露，罗亨理与西卜博士便买通黄苏玫的哥哥黄余振在官府做了伪证，将陶山德作为替罪羊抓捕入狱。后来虽然找不到证据，但是在罗亨理的贿赂下还是判了流刑。后来陶山德刑满出狱，宛如一个流浪汉，来黄家寻找妻女下落，却被罗亨理安排的杀手罗千海一枪打死在江边。',
  悲惨的阿晴: '失去丈夫的黄苏玫只能带着两个女儿住进了哥哥家里，同黄余振的女儿黄昭然一同生活。不久黄苏玫便郁郁而终，阿晴和小霁只得帮黄家打理药铺度日。阿晴卖药茶时与王怀武相识，很快坠入爱河，却因长相与母亲相似被罗亨理看上，要买回家中。得知此事的王怀武写了信约阿晴一起私奔，委托小霁将信交给阿晴，结果被黄昭然看见。此时罗亨理已经付了钱，黄昭然为了有钱能去学校读书，在抢夺信的过程中失手将小霁推入江中。\n\n       此后，阿晴被卖到了蔷薇馆别墅中，成为女仆。罗亨理的炼金术屡次失败，便动起了向魔鬼献祭的念头。他砍去阿晴的双足，刻下咒符，将人关在地下。阿晴十分痛苦，求老仆哑伯帮她自杀，哑伯见其可怜便在罗亨理准备完成仪式（献祭生命）的那天帮阿晴上吊。罗亨理知道后大发脾气，从此把所有绳子都藏了起来，还把阿晴的尸体处理过后锁在地下，用哲人石做了法阵。',
  睡不醒的白裙太太: '白裙太太的身份是真正的小霁。当年小霁被黄昭然推下江后奋力游回岸边，筋疲力尽的她被罗亨理发现，救起来后送往罗家，作为“炼金术”的备用品。在西卜博士的研究中，购买灵魂的方法即先赠送给女性贵重的物品，再询问对方是否愿意，如果对方给予了肯定的答案，便成功签订了契约。罗亨理如法炮制送了小霁一枚戒指，小霁不知情，高兴的答应后便告诉所有人自己成了太太，住进蔷薇馆中。\n\n       一次小霁在无意间发现了刻着蔷薇图案的钥匙，在地下找到了被关着的姐姐阿晴。她带着姐姐逃往外面时被罗亨理发现抓了回去，此后开始不断对小霁使用精神药物，宣称其得了嗜睡症，小霁也因此记忆逐渐模糊，分不清梦境与现实。',
  害怕女巫的黑衣贵妇: '罗明纱因为小时候读过的手抄本而学习到了对付女巫的“秘药”，怀疑“蔷薇夫人”是魅惑哥哥的女巫便偷偷对其使用，使黄苏玫受到了巨大惊吓，此后精神一直会突然失常。',
  有伤疤的商行经理: '罗千海原为罗家家仆，对罗亨理的命令义无反顾。城中被害少女的家属一直在追查真正的凶手，一日带着武器将罗亨理的马车团团围住讨要说法，罗亨理却骗罗千海说这些人是匪徒，罗千海便拼死掩护马车，因此落得残疾和脸上的疤痕。',
  爱读书的眼镜少女: '戴眼镜的少女真实身份是黄余振的女儿黄昭然，一直以为小霁已经死亡，便冒充成阿晴的妹妹小霁一直受罗千海照顾，获得了读书的机会。',
  忍辱负重的高瘦男仆:'韩仁陆的真实身份是陶山德的儿子陶孝先，在跟随西卜博士学习了一段时间后，利用化学和魔术成为了有名的催眠大师，后听说父亲入狱另有隐情，发誓要找到真相。',
  救亡图存的魁梧男仆:'王怀武的真实身份是官府的通缉犯，因参加辛亥革命立功成为军官，却在变为民国后参加讨袁起义而沦为叛军。',
  罗亨理之死:'罗千海受伤时，被作为女仆的阿晴不眠不休地精心照顾，从未感受过如此温暖的罗千海顿时心生好感，视阿晴为救命恩人。今天罗亨理打算再次启动献祭仪式，要求罗千海将轮椅上的白裙女人杀害——误以为白裙女人是阿晴的罗千海辗转反侧，最后还是下定决心保护心中唯一的那份温暖。\n\n       罗千海先将三楼阳台上的旗帜解开一端，另一端垂在书房的窗口。随后在罗亨理的饭中下药，在罗亨理熟睡后用拐杖将窗外的旗帜勾进来绑在罗亨理身上，再前往三楼将旗帜的另一端绑在雕像上，将雕像从阳台扔下，利用雕像的重量将身材高大的罗亨理吊进了三楼陈列室。随后他将罗亨理摆好姿势，让枪口对准太阳穴，最后利用白磷自燃击发火铳，完成了自己的不在场证明。'
};

export const getSSQWFooterText = (stage: string) => {
  switch (stage) {
    case 'READING1':
      return '阅读剧本阶段（阅读完请点击剧本左上角的“阅读完毕”）';
    case 'INTRODUCING':
      return '介绍阶段';
    case 'INVESTIGATING1':
      return '轮流调查阶段';
    case 'READING2':
      return '阅读第二幕剧本（阅读完请点击剧本左上角的“阅读完毕”）';
    case 'INVESTIGATING2':
      return '轮流调查阶段';
    case 'VOTING':
      return '投票阶段（可以继续交流，直到所有人投完票）';
    case 'REVEALING':
      return '复盘阶段';
    default:
      return undefined;
  }
};

export const SSQW_ForbiddenRooms: Record<string, string> = {
  太太: '主卧室',
  罗明纱: '客房（罗明纱）',
  罗千海: '客房（罗千海）',
  小霁: '客房（小霁）',
  韩仁陆: '男仆房',
  王怀武: '男仆房',
};
