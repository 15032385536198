import { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from '@mui/material';

export interface ConfirmDialogProps extends DialogProps {
  title: string;
  content?: string;
  onConfirmButtonClicked: () => void;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = function ({
  title,
  content,
  onConfirmButtonClicked,
  ...props
}) {
  return (
    <Dialog {...props}>
      <DialogTitle>{title}</DialogTitle>
      {content &&
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
      }
      <DialogActions>
        <Button onClick={onConfirmButtonClicked}>确认</Button>
        <Button onClick={() => props.onClose?.({}, 'escapeKeyDown')}>取消</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
