import styled from "@emotion/styled";
import { css } from '@emotion/react';
import { CSSProperties, HTMLAttributes } from "react";
import { HeartbeatKeyframes, ShockwaveKeyframes } from 'utils/keyframes';

const LocationBadge = styled(({ badge, animate = false, ...props }: { badge?: number | string, animate?: boolean } & HTMLAttributes<HTMLDivElement>) => (
  <div {...props}>
    {badge}
  </div>
))`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 26px;
  font-family: 黑体;
  background-color: #f14d4d;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  animation: ${(props) => props.animate ? css`${HeartbeatKeyframes} 1s ease-out infinite` : null};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 80%;
    animation: ${(props) => props.animate ? css`${ShockwaveKeyframes} 1s ease-out infinite` : null};
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    animation: ${(props) => props.animate ? css`${ShockwaveKeyframes} 1s ease-out infinite` : null};
  }
`;


export const LocationButton = styled(({ badge, badgeStyle, hasNewClue, isLocked = false, ...props }: { badge?: number | string; badgeStyle?: CSSProperties, hasNewClue?: boolean, isLocked?: boolean } & HTMLAttributes<HTMLDivElement>) => (
  <div {...props}>
    {(isLocked || badge === undefined) ? null : <LocationBadge badge={badge} style={badgeStyle} animate={hasNewClue} />}
    {props.children}
  </div>
))`
  position: absolute;
  border-radius: 5px;
  border: 5px solid transparent;
  cursor: pointer;

  &:hover {
    border-color: #7eb2ff;
  }
`;