/* eslint-disable react/jsx-pascal-case */
import styled from '@emotion/styled';
import { LocationButton } from 'lib/game/components/LocationButton';
import { FC, LegacyRef, ReactNode, useContext } from 'react';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';
import { GameContext } from 'utils/contexts';
import { ThemeButton } from 'lib/common/ThemeButton';
import ConfirmDialog from 'lib/common/ConfirmDialog';
import { Stack } from '@mui/material';
import { YLW_StageTransition } from './YLW_StageTransition';

const MapContainer = styled.div`
  width: 45vw;
  position: absolute;
  margin: auto;
  top: 5vh;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const Map = styled.img`
  width: 100%;
`;

const InviteButton = styled(ThemeButton)`
  background-color: #90ADC6;
  position: absolute;
  height: 36px;
  left: 19.7%;
  top: 14.2%;
  writing-mode: vertical-rl;
  height: 120px;
  min-width: 36px;
  width: 36px;

  &:hover {
    background-color: #90ADC6AA;
  }
`;

const GameStageInfo = styled.div`
  position: absolute;
  top: 40px;
  right: 60px;
  text-align: center;
  font-family: 'STZhongSong';
  font-size: 40px;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  color: white;
`;

const MapInfoWrapper = styled(Stack)`
  width: 25vw;
  position: absolute;
  top: 18vh;
  right: 1vw;
  color: white;
  font-size: 20px;
  font-family: 'STZhongSong';
  text-align: justify;
`;

const MapInfo = styled.img`
  width: 25vw;
  margin-left: 10px;
  margin-bottom: 10px;
`;

const MapText = styled.div`
  line-height: 1.5;
  font-size: 24px;
`;

const Room1 = styled(LocationButton)`
  width: 10.9%;
  height: 16.5%;
  left: 6.7%;
  top: 11.6%;
`;

const Room2 = styled(LocationButton)`
  width: 10.9%;
  height: 16.5%;
  left: 6.7%;
  top: 31.7%;
`;

const Room3 = styled(LocationButton)`
  width: 10.9%;
  height: 16.8%;
  left: 6.7%;
  top: 51.6%;
`;

const Room4 = styled(LocationButton)`
  width: 11.1%;
  height: 16.8%;
  right: 6.8%;
  top: 11.6%;
`;

const Room5 = styled(LocationButton)`
  width: 11.1%;
  height: 16.8%;
  right: 6.8%;
  top: 31.5%;
`;

const MaidRoom = styled(LocationButton)`
  width: 11.1%;
  height: 16.9%;
  right: 6.8%;
  top: 51.4%;
`;

const ClassRoom = styled(LocationButton)`
  width: 19.6%;
  height: 11.9%;
  left: 39.5%;
  top: 16.7%;
`;

const DiningRoom = styled(LocationButton)`
  width: 9.1%;
  height: 13.9%;
  left: 37.1%;
  top: 31.7%;
`;

const Kitchen = styled(LocationButton)`
  width: 9.1%;
  height: 13.9%;
  right: 37.1%;
  top: 31.7%;
`;

const ButlerRoom = styled(LocationButton)`
  width: 9.1%;
  height: 13.9%;
  left: 37.1%;
  top: 47.8%;
`;

const MasterRoom = styled(LocationButton)`
  width: 9.1%;
  height: 13.9%;
  right: 37.1%;
  top: 47.8%;
`;

const FrontDoor = styled(LocationButton)`
  width: 7.8%;
  height: 4.8%;
  left: 45.5%;
  top: 2.9%;
`;

const WestStorage = styled(LocationButton)`
  width: 9.1%;
  height: 13.9%;
  left: 34.6%;
  bottom: 9.6%;
`;

const EastStorage = styled(LocationButton)`
  width: 9.1%;
  height: 13.9%;
  right: 34.8%;
  bottom: 9.6%;
`;

const ConfinementRoom = styled(LocationButton)`
  width: 8.7%;
  height: 6.0%;
  left: 45.0%;
  bottom: 6.6%;
`;

const OutsideWrapper = styled(Stack)`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -190px;
`;

const Outside = styled(LocationButton)`
  position: relative;
  width: 260px;

  color: white;
  font-size: 42px;
  text-align: center;
  font-family: 'STZhongSong';
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  margin-top: 36px;
`;

const OutsideImage = styled.img`
  height: 180px;
`;

const getStageInfo = (stage: string): ReactNode => {
  switch (stage) {
    case 'INVESTIGATING1':
      return <>
        <span style={{ fontSize: '32px' }}>第一幕</span><br />
        时珞庄课堂<br />
        <span style={{ fontSize: '28px' }}>9月20日 7:30</span>
      </>
    case 'INVESTIGATING2':
    case 'VOTING':
      return <>
        <span style={{ fontSize: '32px' }}>第二幕</span><br />
        时珞庄餐厅<br />
        <span style={{ fontSize: '28px' }}>9月20日 10:00</span>
      </>
    default:
      return null;
  }
}

export interface YLW_InvestigatePageProps extends CommonUIProps {
  showMap: boolean;
  gameTime: string;
  numRemainingClues: Record<string, number>;
  locationHasNewClue: string[];
  onLocationButtonClicked: (location: string) => void;
  pickClueDialog: ReactNode;
  showPickClueDialog: boolean;
  showExploreDialog: boolean;
  setShowExploreDialog: (_: boolean) => void;
  setShowPickClueDialog: (_: boolean) => void;
  isHouseInvestigated: boolean;
  showInviteButton: boolean;
  onInviteButtonClicked: () => void;
  showConfirmDialog: boolean;
  handleConfirmDialogClose: () => void;
  onConfirmInvite: () => void;
  exploreDialog: ReactNode;
  onExploreButtonClicked: () => void;
  exploreMusicRef: LegacyRef<HTMLAudioElement>;
  onExploreMusicEnded: () => void;
  showStageTransition: boolean;
  onStageTransitionEnded: (_: Event, reason: "backdropClick" | "escapeKeyDown") => void;
}

export const YLW_InvestigatePage: FC<YLW_InvestigatePageProps> = function ({
  showMap,
  gameTime,
  numRemainingClues,
  locationHasNewClue,
  onLocationButtonClicked,
  pickClueDialog,
  showPickClueDialog,
  setShowPickClueDialog,
  showExploreDialog,
  setShowExploreDialog,
  isHouseInvestigated,
  showInviteButton,
  onInviteButtonClicked,
  showConfirmDialog,
  handleConfirmDialogClose,
  onConfirmInvite,
  exploreDialog,
  onExploreButtonClicked,
  exploreMusicRef,
  onExploreMusicEnded,
  showStageTransition,
  onStageTransitionEnded,
  ...props
}) {

  const gameStage = useContext(GameContext)?.stage ?? '';

  return (
    <div {...props}>
      {showMap &&
        <>
          <MapContainer>
            <div style={{ width: '100%', position: 'relative' }}>
              <Map src={getAssetUrl('/YLW/map.webp')} draggable={false} />
              {showInviteButton && <InviteButton onClick={onInviteButtonClicked}>送安梧回房</InviteButton>}
              {['INVESTIGATING2', 'VOTING', 'REVEALING'].includes(gameStage) && <>
                <Room1 badge={numRemainingClues['1号房']} badgeStyle={{ top: '37%', left: '110%' }} hasNewClue={locationHasNewClue.includes('1号房')} onClick={() => onLocationButtonClicked('1号房')} />
                <Room2 badge={numRemainingClues['2号房']} badgeStyle={{ top: '37%', left: '110%' }} hasNewClue={locationHasNewClue.includes('2号房')} onClick={() => onLocationButtonClicked('2号房')} />
                <Room3 badge={numRemainingClues['3号房']} badgeStyle={{ top: '37%', left: '110%' }} hasNewClue={locationHasNewClue.includes('3号房')} onClick={() => onLocationButtonClicked('3号房')} />
                <Room4 badge={numRemainingClues['4号房']} badgeStyle={{ top: '37%', left: '-145%' }} hasNewClue={locationHasNewClue.includes('4号房')} onClick={() => onLocationButtonClicked('4号房')} />
                <Room5 badge={numRemainingClues['5号房']} badgeStyle={{ top: '37%', left: '-145%' }} hasNewClue={locationHasNewClue.includes('5号房')} onClick={() => onLocationButtonClicked('5号房')} />
                <MaidRoom badge={numRemainingClues['女佣房']} badgeStyle={{ top: '37%', left: '-145%' }} hasNewClue={locationHasNewClue.includes('女佣房')} onClick={() => onLocationButtonClicked('女佣房')} />
                <ClassRoom badge={numRemainingClues['课堂']} badgeStyle={{ top: '-47px' }} hasNewClue={locationHasNewClue.includes('课堂')} onClick={() => onLocationButtonClicked('课堂')} />
                <DiningRoom badge={numRemainingClues['餐厅']} badgeStyle={{ top: '37%', left: '-150%' }} hasNewClue={locationHasNewClue.includes('餐厅')} onClick={() => onLocationButtonClicked('餐厅')} />
                <Kitchen badge={numRemainingClues['厨房']} badgeStyle={{ top: '37%', left: '108%' }} hasNewClue={locationHasNewClue.includes('厨房')} onClick={() => onLocationButtonClicked('厨房')} />
                <ButlerRoom badge={numRemainingClues['管家房']} badgeStyle={{ top: '37%', left: '-150%' }} hasNewClue={locationHasNewClue.includes('管家房')} onClick={() => onLocationButtonClicked('管家房')} />
                <MasterRoom badge={numRemainingClues['主人房']} badgeStyle={{ top: '37%', left: '108%' }} hasNewClue={locationHasNewClue.includes('主人房')} onClick={() => onLocationButtonClicked('主人房')} />
                <FrontDoor badge={numRemainingClues['正门']} badgeStyle={{ top: '3px', left: '105px' }} hasNewClue={locationHasNewClue.includes('正门')} onClick={() => onLocationButtonClicked('正门')} />
                <WestStorage badge={numRemainingClues['仓库（西）']} badgeStyle={{ top: '25%', left: '110%' }} hasNewClue={locationHasNewClue.includes('仓库（西）')} onClick={() => onLocationButtonClicked('仓库（西）')} />
                <EastStorage badge={numRemainingClues['仓库（东）']} badgeStyle={{ top: '25%', left: '-160%' }} hasNewClue={locationHasNewClue.includes('仓库（东）')} onClick={() => onLocationButtonClicked('仓库（东）')} />
                <ConfinementRoom badge={numRemainingClues['禁闭室']} badgeStyle={{ bottom: '-45px' }} hasNewClue={locationHasNewClue.includes('禁闭室')} onClick={() => onLocationButtonClicked('禁闭室')} />
              </>}
              {isHouseInvestigated && <OutsideWrapper direction="row" alignItems="center" justifyContent="center">
                <OutsideImage src={getAssetUrl('/YLW/outside.webp')} />
                <Outside onClick={onExploreButtonClicked}>
                  ↑↑↑<br />
                  探索时珞庄外
                </Outside>
              </OutsideWrapper>}
            </div>
          </MapContainer>
          <GameStageInfo>
            {getStageInfo(gameStage)}
          </GameStageInfo>
          <MapInfoWrapper direction="column" spacing={2}>
            <MapInfo src={getAssetUrl('/YLW/mapinfo.webp')} />
            <Stack direction="column" alignItems="center">
              <MapText>注：时珞庄里没有通电，晚上禁止外出。<br />除了“正门”，所有的屋门、院门都没有锁。</MapText>
            </Stack>
          </MapInfoWrapper>
        </>
      }
      {pickClueDialog}
      {exploreDialog}
      <ConfirmDialog open={showConfirmDialog} onClose={handleConfirmDialogClose} title="确定先送安梧回房休息吗？" content="在你送安悟回房之后，游戏会进入下一阶段。" onConfirmButtonClicked={onConfirmInvite} />
      <YLW_StageTransition open={showStageTransition} onClose={onStageTransitionEnded} />
      <audio ref={exploreMusicRef} src={getAssetUrl('/YLW/explore.mp3')} onEnded={onExploreMusicEnded} />
    </div >
  );
};

export default YLW_InvestigatePage;
