import styled from '@emotion/styled';
import { FC } from 'react';
import { DialogProps, Stack } from '@mui/material';
import { getAssetUrl } from 'utils/utils';
import { ThemeButton } from 'lib/common/ThemeButton';
import { FullDialog } from 'lib/common/FullDialog';
import { MemoryRes } from 'openapi';
import { FlipBackKeyframes } from 'utils/keyframes';
import FlipSound from 'assets/sound/flip.mp3';
import useSound from 'use-sound';

const ContentWrapper = styled(Stack)`
  width: 100vw;
  height: 100dvh;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
`;

const TitleBox = styled.div`
  width: 100vw;
  padding-top: 30px;
  color: white;
  font-size: 56px;
  font-family: '黑体';
  text-align: center;
`;

const MemoryContainer = styled.div`
  width: 100vw;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
`;

const MemoryCardContainer = styled.div<{ shouldFlip?: boolean, isHorizontal?: boolean }>`
  width: ${(props) => props.isHorizontal ? '656px' : 'calc(33.2vh + 6px)'};
  height: ${(props) => props.isHorizontal ? '426px' : 'calc(48vh + 6px)'};;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 30px;
  perspective: 1000px;
  position: relative;

  transform-style: preserve-3d;
  animation-duration: 0.8s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-delay: 0.5s;
  animation-name: ${(props) => props.shouldFlip ? FlipBackKeyframes : ''};
`;

const MemoryCard = styled.img<{ isTriggered?: boolean, isHorizontal?: boolean }>`
  width: ${(props) => props.isHorizontal ? '650px' : '33.2vh'};
  height: ${(props) => props.isHorizontal ? '420px' : '48vh'};;
  border: 3px solid white;
  border-radius: 20px;
  filter: ${(props) => props.isTriggered ? 'brightness(1.0)' : 'brightness(0.7)'};
  backface-visibility: hidden;
`;

const FlipCard = styled(MemoryCard)`
  position: absolute;
  left: 0;
  top: 0;
`;

const FlipCardFront = styled(MemoryCard)`
  transform: rotateY(-180deg);
`;

const MemoryText = styled.div<{ isTriggered: boolean }>`
  margin-top: 8px;
  margin-bottom: 30px;
  color: white;
  font-size: 32px;
  font-family: STFangSong;
  text-align: center;
  text-decoration: ${(props) => props.isTriggered ? 'line-through' : ''};
  text-decoration-color: ${(props) => props.isTriggered ? '#f14d4d' : ''};
  text-decoration-thickness: ${(props) => props.isTriggered ? '5px' : ''};
  position: relative;
`;

const Footer = styled.div`
  width: 120px;
  padding-bottom: 24px;
`;

const Nautilus = styled.img`
  position: absolute;
  margin: auto;
  left: -8px;
  right: 0;
  top: 0;
  height: 40px;
  z-index: -1;
`;

const Alch = styled.img`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  height: 40px;
  z-index: -1;
`;

const getMemoryText = (memory: string) => {
  switch (memory) {
    case '鹦鹉螺':
      return <>
        “&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;”
        <Nautilus src={getAssetUrl('/MHZY/nautilus.png')} />
      </>
    case '奇怪的符号':
      return <>
        “&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;”
        <Alch src={getAssetUrl('/WQYF/alch.png')} />
      </>
    default:
      return `“${memory}”`;
  }
}

export interface MemoryDialogProps extends DialogProps {
  currentCharacter?: string;
  memories?: Record<string, MemoryRes>;
  newMemories: string[];
  cardOrientation?: 'horizontal' | 'vertical';
}

export const MemoryDialog: FC<MemoryDialogProps> = function ({
  currentCharacter,
  memories,
  newMemories,
  cardOrientation = 'vertical',
  ...props
}) {

  const [playFlipSound] = useSound(FlipSound, { volume: 0.5 });

  return (
    <FullDialog {...props}>
      <ContentWrapper direction="column" alignItems="center">
        <TitleBox>{`${currentCharacter}的回忆`}</TitleBox>
        <MemoryContainer>
          {Object.entries(memories ?? {}).map(([memory, memoryInfo]) => (
            <Stack key={memory} direction="column" alignItems="center">
              <MemoryCardContainer isHorizontal={cardOrientation === 'horizontal'} shouldFlip={newMemories.includes(memory)} onAnimationStart={() => playFlipSound()}>
                {newMemories.includes(memory) ?
                  <>
                    <FlipCardFront isHorizontal={cardOrientation === 'horizontal'} src={getAssetUrl(memoryInfo.frontUrl)} isTriggered draggable={false} onContextMenu={(e) => e.preventDefault()} />
                    <FlipCard isHorizontal={cardOrientation === 'horizontal'} src={getAssetUrl(memoryInfo.backUrl)} draggable={false} onContextMenu={(e) => e.preventDefault()} />
                  </>
                  : <MemoryCard isHorizontal={cardOrientation === 'horizontal'} src={getAssetUrl(memoryInfo.isTriggered ? memoryInfo.frontUrl : memoryInfo.backUrl)} isTriggered={memoryInfo.isTriggered} draggable={false} onContextMenu={(e) => e.preventDefault()} />}
              </MemoryCardContainer>
              <MemoryText isTriggered={memoryInfo.isTriggered}>
                {getMemoryText(memory)}
              </MemoryText>
            </Stack>
          ))}
        </MemoryContainer>
        <Footer>
          <ThemeButton onClick={() => props.onClose?.({}, "escapeKeyDown")}>返回</ThemeButton>
        </Footer>
      </ContentWrapper>
    </FullDialog>
  );
};

export default MemoryDialog;
