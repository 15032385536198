import { useContext, useEffect, useState } from 'react';
import { SubscriptionSuccessDialog, SubscriptionSuccessDialogProps } from './SubscriptionSuccessDialog';
import { createBinder } from 'utils/utils';
import clientApi from 'utils/clientApi';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from 'utils/contexts';

export type SubscriptionSuccessDialogBinderManagedProps = 'isVerifying' | 'errorMsg';

export interface SubscriptionSuccessDialogBinderProps extends Omit<SubscriptionSuccessDialogProps, SubscriptionSuccessDialogBinderManagedProps> {

}

const useSubscriptionSuccessDialogBinder = (props: SubscriptionSuccessDialogBinderProps): SubscriptionSuccessDialogProps => {

  const { user, setUser, refreshUser } = useContext(UserContext);
  const [isVerifying, setIsVerifying] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>();
  const [searchParams,] = useSearchParams();
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    if (user && sessionId && isVerifying) {
      setIsVerifying(false);
      clientApi().processCheckoutSession({ sessionId }).then(() => {
        refreshUser();
      }).catch((error: Error) => {
        setErrorMsg(error.message);
      })
    }
  }, [isVerifying, refreshUser, sessionId, setUser, user]);

  const managedProps: Pick<SubscriptionSuccessDialogProps, SubscriptionSuccessDialogBinderManagedProps> = {
    isVerifying,
    errorMsg,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const SubscriptionSuccessDialogBinder = createBinder(SubscriptionSuccessDialog, useSubscriptionSuccessDialogBinder);

export default SubscriptionSuccessDialogBinder;
