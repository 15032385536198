import { useState, ChangeEvent, useCallback, useContext, useRef, useEffect } from 'react';
import { LoginDialog, LoginDialogProps, LoginFormTypeEnum } from './LoginDialog';
import clientApi from 'utils/clientApi';
import { createBinder, useCurrentRef } from 'utils/utils';
import { validateEmail, validateVerificationCode } from 'utils/validations';
import localStorageService from 'utils/localStorage';
import { UserRes } from 'openapi';
import { UserContext } from 'utils/contexts';
import { CredentialResponse } from '@react-oauth/google';
import { VERIFICATION_CODE_LENGTH } from 'utils/constants';
import { useInterval } from 'utils/hooks';
import { enqueueSnackbar } from 'notistack';

export type LoginDialogBinderManagedProps = 'loginFormType' | 'email' | 'nickname' | 'errorMsg'
  | 'onEmailInputChange' | 'onNicknameInputChange'
  | 'onCloseButtonClicked' | 'onLoginButtonClicked' | 'onDiscordLoginSuccess' | 'onDiscordLoginFailure'
  | 'onReturnToLoginButtonClicked'
  | 'onGoogleLoginSuccess' | 'onGoogleLoginError'
  | 'verificationCode' | 'onCodeInputBoxClicked' | 'setCodeInputRef' | 'onCodeChange' | 'onPaste' | 'onKeyDown' | 'onResendButtonClicked' | 'resendTime';

export interface LoginDialogBinderProps extends Omit<LoginDialogProps, LoginDialogBinderManagedProps> {
  onClose: () => void;
  onLoginSuccess: (_user: UserRes) => void;
}

const useLoginDialogBinder = (props: LoginDialogBinderProps): LoginDialogProps => {
  const { onClose } = props;

  const { setUser } = useContext(UserContext);
  const [email, setEmail] = useState<string>('');
  const [nickname, setNickname] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>();
  const [loginFormType, setLoginFormType] = useState<LoginFormTypeEnum>(LoginFormTypeEnum.Login);

  const clearInputs = useCallback(() => {
    setEmail('');
    setNickname('');
    setErrorMsg(undefined);
    setLoginFormType(LoginFormTypeEnum.Login);
    setVerificationCode(Array.from({ length: VERIFICATION_CODE_LENGTH }).map(() => ''));
  }, []);

  const onCloseButtonClicked = useCallback(() => {
    onClose();
  }, [onClose]);

  const onReturnToLoginButtonClicked = useCallback(() => {
    setLoginFormType(LoginFormTypeEnum.Login);
  }, []);

  const handleUserLogin = useCallback((userRes: UserRes) => {
    const { accessToken, refreshToken, ...userInfo } = userRes;
    localStorageService.setUserInfo(userInfo);
    localStorageService.setAccessToken(accessToken);
    localStorageService.setRefreshToken(refreshToken);
    setUser(userInfo);
    enqueueSnackbar('登陆成功!', { variant: 'success' });
    props.onLoginSuccess(userRes);
    onCloseButtonClicked();
    clearInputs();
  }, [clearInputs, onCloseButtonClicked, props, setUser]);

  const sendVerificationCode = useCallback(() => {
    clientApi().sendVerificationCode({ email }).then(() => {
      setLoginFormType(LoginFormTypeEnum.EmailVerification)
      setResendTime(60);
    }).catch((error: Error) => {
      setErrorMsg(error.message);
      console.error(error);
    })
  }, [email]);

  const onConfirmVerificationCode = useCallback((code: string) => {
    if (email && code.length === VERIFICATION_CODE_LENGTH) {
      clientApi().loginWithEmail({ email, code }).then((result) => {
        handleUserLogin(result.data);
      }).catch((error: Error) => {
        setErrorMsg(error.message);
        console.error(error);
      })
    }
  }, [email, handleUserLogin]);

  const onLoginButtonClicked = useCallback(() => {
    if (validateEmail(email)) {
      sendVerificationCode();
      setErrorMsg(undefined);
    } else {
      setErrorMsg('邮箱格式错误');
    }
  }, [email, sendVerificationCode]);

  const onGoogleLoginSuccess = useCallback((response: CredentialResponse) => {
    if (response.credential) {
      clientApi().loginWithGoogle({ googleToken: response.credential }).then((result) => {
        handleUserLogin(result.data);
      }).catch((error: Error) => {
        setErrorMsg(error.message);
      })
    }
  }, [handleUserLogin]);

  const onGoogleLoginError = useCallback(() => {
    setErrorMsg('Google Login failed.');
  }, []);

  const onDiscordLoginSuccess = useCallback((response: Record<string, any>) => {
    clientApi().loginWithDiscord({ discordToken: response['access_token'] }).then((result) => {
      handleUserLogin(result.data);
    }).catch((error: Error) => {
      setErrorMsg(error.message);
    });
  }, [handleUserLogin]);

  const onDiscordLoginFailure = useCallback((error: Error) => {
    setErrorMsg(error.message);
    console.error(error);
  }, []);


  const inputsRef = useCurrentRef<HTMLInputElement[]>([]);
  // Input subscript of current focus
  const currentFocusIndexRef = useRef(0);
  const [verificationCode, setVerificationCode] = useState<string[]>(Array.from({ length: VERIFICATION_CODE_LENGTH }).map(() => ''));
  const [resendTime, setResendTime] = useState<number>();

  useInterval(() => {
    if (resendTime && resendTime > 0) {
      setResendTime(resendTime - 1);
    } else if (resendTime === 0) {
      setResendTime(undefined);
    }
  }, resendTime ? 1000 : null)

  useEffect(() => {
    if (verificationCode.every((digit) => digit !== '')) {
      onConfirmVerificationCode(verificationCode.join(''));
    }
  }, [onConfirmVerificationCode, verificationCode]);

  // Focus on input with specified subscript
  const focusInput = useCallback(
    (i: number) => {
      if (i >= inputsRef.current.length) {
        return;
      }
      const input = inputsRef.current[i];
      if (!input) {
        return;
      }
      input.focus();
      currentFocusIndexRef.current = i;
    },
    [inputsRef, currentFocusIndexRef],
  );

  const focusNextInput = useCallback(() => {
    focusInput(Math.min(currentFocusIndexRef.current + 1, VERIFICATION_CODE_LENGTH - 1));
  }, [focusInput, currentFocusIndexRef]);

  const focusPrevInput = useCallback(() => {
    focusInput(Math.max(0, currentFocusIndexRef.current - 1));
  }, [focusInput, currentFocusIndexRef]);

  // Deal with delete button
  const handleOnDelete = useCallback(() => {
    const currentIndex = currentFocusIndexRef.current;
    const temp = [...verificationCode];
    if (verificationCode[currentIndex] === '') {
      focusPrevInput();
      if (currentIndex > 0) {
        temp[currentIndex - 1] = '';
        setVerificationCode(temp);
      }
    } else {
      temp[currentIndex] = '';
      setVerificationCode(temp);
    }
  }, [focusPrevInput, verificationCode, currentFocusIndexRef]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.code) {
        case 'ArrowLeft':
        case 'ArrowRight':
        case 'Space':
        case 'Home':
        case 'End':
          e.preventDefault();
          break;
        case 'Backspace':
          handleOnDelete();
          break;
        default:
          break;
      }
    },
    [handleOnDelete],
  );

  const onCodeInputBoxClicked = useCallback((index: number) => {
    focusInput(index);
  }, [focusInput]);

  const onCodeChange = useCallback(
    (el: ChangeEvent<HTMLInputElement>) => {
      const { value } = el.target;
      if (!validateVerificationCode(value)) {
        return;
      }
      const temp = [...verificationCode];
      temp[currentFocusIndexRef.current] = value;
      setVerificationCode(temp);
      focusNextInput();
    },
    [focusNextInput, verificationCode],
  );

  const onPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const value = e.clipboardData.getData('text/plain').slice(0, VERIFICATION_CODE_LENGTH);
      if (!validateVerificationCode(value)) {
        return;
      }
      const temp = [...verificationCode];
      Array.from(value).forEach((char, index) => {
        if (currentFocusIndexRef.current + index < VERIFICATION_CODE_LENGTH) {
          temp[currentFocusIndexRef.current + index] = char;
        }
      })
      setVerificationCode(temp);
      focusInput(Math.min(VERIFICATION_CODE_LENGTH - 1, value.length + currentFocusIndexRef.current));
    },
    [focusInput, verificationCode],
  );

  const setCodeInputRef = useCallback(
    (ref: HTMLInputElement, index: number) => {
      inputsRef.current[index] = ref;
    },
    [inputsRef],
  );

  const onResendButtonClicked = useCallback(() => {
    if (!resendTime && validateEmail(email)) {
      sendVerificationCode();
      setErrorMsg(undefined);
    }
  }, [email, resendTime, sendVerificationCode]);

  const managedProps: Pick<LoginDialogProps, LoginDialogBinderManagedProps> = {
    loginFormType,
    email,
    verificationCode,
    nickname,
    errorMsg,
    onEmailInputChange: (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value),
    onNicknameInputChange: (e: ChangeEvent<HTMLInputElement>) => setNickname(e.target.value),
    onLoginButtonClicked,
    onCloseButtonClicked,
    onReturnToLoginButtonClicked,
    onGoogleLoginSuccess,
    onGoogleLoginError,
    onDiscordLoginSuccess,
    onDiscordLoginFailure,
    onCodeInputBoxClicked,
    onPaste,
    onKeyDown,
    onCodeChange,
    setCodeInputRef,
    onResendButtonClicked,
    resendTime,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const LoginDialogBinder = createBinder(LoginDialog, useLoginDialogBinder);

export default LoginDialogBinder;
