import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import CommonAvatar from '../../common/CommonAvatar';
import { CommonUIProps } from 'utils/types';
import { UserThumbnail } from 'openapi';
import PlayerReady from 'assets/player_ready.png';
import { parseAvatarUrl } from 'utils/utils';

const AvatarWrapper = styled.div`
  position: relative;
`;

const PlayerAvatar = styled(CommonAvatar)`
  height: 80px;
  width: 80px;
`;

const PlayerReadyCover = styled.img`
  height: 80px;
  width: 80px;
  position: absolute;
  left: 0;
  top: 0;
`;

const PlayerNickname = styled.div`
  width: 100%;
  font-size: 20px;
  font-family: 微软雅黑;
  text-align: center;
  margin-top: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: white;
`;

export interface PlayerWrapperProps extends CommonUIProps {
  player: Partial<UserThumbnail>,
  cover?: 'ready',
}

export const PlayerWrapper = styled(({ player, cover, ...props }: PlayerWrapperProps) => (
  <Stack direction="column" alignItems="center" {...props}>
    <AvatarWrapper>
      <PlayerAvatar src={parseAvatarUrl(player.avatar)} />
      {cover === 'ready' && <PlayerReadyCover src={PlayerReady} />}
    </AvatarWrapper>
    <PlayerNickname>{player.nickname}</PlayerNickname>
  </Stack>
))`
  width: 160px;
  position: relative;
`;