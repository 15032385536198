import { useCallback, useContext, useEffect, useState } from 'react';
import { SubscriptionDialog, SubscriptionDialogProps } from './SubscriptionDialog';
import { createBinder } from 'utils/utils';
import { UserContext } from 'utils/contexts';
import { StripeItem, SubscriptionRes } from 'openapi';
import clientApi, { updateAccessToken } from 'utils/clientApi';
import localStorageService from 'utils/localStorage';

const StatusText: Record<string, string> = {
  'none': 'N/A',
  'active': '激活',
  'canceled': '已取消'
}

export type SubscriptionDialogBinderManagedProps = 'role' | 'subscriptionStatus' | 'periodEndDate' | 'onPurchaseButtonClicked' | 'showConfirmCancelSubscriptionDialog'
  | 'onConfirmCancelSubscriptionDialogClose' | 'onConfirmCancelSubscriptionButtonClicked'
  | 'showCancelSubscriptionSuccessDialog' | 'onCancelSubscriptionSuccessDialogClose' | 'subscriptionEndDate'
  | 'showConfirmDowngradeDialog' | 'onConfirmDowngradeDialogClose' | 'onConfirmDowngradeButtonClicked'
  | 'showDowngradeSuccessDialog' | 'onDowngradeSuccessDialogClose' | 'downgradeEffectiveDate' | 'showUpgradeSuccessDialog'
  | 'onUpgradeSuccessDialogClose' | 'upgradeBillDate' | 'showUncancelButton' | 'onUncancelButtonClicked'
  | 'quantity' | 'onQuantityChange';

export interface SubscriptionDialogBinderProps extends Omit<SubscriptionDialogProps, SubscriptionDialogBinderManagedProps> { }

const useSubscriptionDialogBinder = (props: SubscriptionDialogBinderProps): SubscriptionDialogProps => {

  const { user, setUser } = useContext(UserContext);
  const [showConfirmCancelSubscriptionDialog, setShowConfirmCancelSubscriptionDialog] = useState<boolean>(false);
  const [showCancelSubscriptionSuccessDialog, setShowCancelSubscriptionSuccessDialog] = useState<boolean>(false);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState<string>();

  const [showConfirmDowngradeDialog, setShowConfirmDowngradeDialog] = useState<boolean>(false);
  const [showDowngradeSuccessDialog, setShowDowngradeSuccessDialog] = useState<boolean>(false);
  const [showUpgradeSuccessDialog, setShowUpgradeSuccessDialog] = useState<boolean>(false);
  const [upgradeBillDate, setUpgradeBillDate] = useState<string>();
  const [downgradeEffectiveDate, setDowngradeEffectiveDate] = useState<string>();

  const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionRes>();

  const [quantity, setQuantity] = useState<number>(1);

  const onQuantityChange = useCallback((change: number) => {
    if (1 <= quantity + change && quantity + change <= 30) {
      setQuantity((prev) => prev + change);
    }
  }, [quantity]);

  useEffect(() => {
    if (user && !subscriptionInfo) {
      clientApi().getCurrentSubscription().then((result) => {
        setSubscriptionInfo(result.data);
      }).catch((error: unknown) => {
        console.error(error)
      })
    }
  }, [subscriptionInfo, user]);

  const onPurchaseButtonClicked = useCallback((item: StripeItem | 'PLAN_A') => {
    if (item === 'PLAN_A') {
      setShowConfirmCancelSubscriptionDialog(true);
    } else {
      if (user?.role === 'PLAN_A') {
        clientApi().createCheckoutSession({ item, quantity: item === 'GAME_TOKEN' ? quantity : 1 }).then((result) => {
          if (result.data) {
            window.location.href = result.data.url;
          }
        }).catch((error: unknown) => {
          console.error(error);
        })
      } else if (user?.role === 'PLAN_B' && item === 'PLAN_C') {
        clientApi().upgradeSubscription().then((result) => {
          const refreshToken = localStorageService.getRefreshToken();
          if (refreshToken) {
            clientApi().verifyRefreshToken({ refreshToken }).then((_result) => {
              localStorageService.setAccessToken(_result.data.accessToken);
              localStorageService.setRefreshToken(_result.data.refreshToken);
              updateAccessToken(_result.data.accessToken);
            })
          }
          setUser({ ...user, role: 'PLAN_C' });
          setUpgradeBillDate(new Date(result.data.nextBillDate * 1000).toISOString().slice(0, 10));
          setShowUpgradeSuccessDialog(true);
        }).catch((error: unknown) => {
          console.error(error);
        })
      } else if (user?.role === 'PLAN_C' && item === 'PLAN_B') {
        setShowConfirmDowngradeDialog(true);
      }
    }
  }, [quantity, setUser, user]);

  const onConfirmCancelSubscriptionButtonClicked = useCallback(() => {
    clientApi().cancelSubscription().then((result) => {
      setSubscriptionEndDate(new Date(result.data.endDate * 1000).toISOString().slice(0, 10));
      setShowCancelSubscriptionSuccessDialog(true);
      setShowConfirmCancelSubscriptionDialog(false);
    }).catch((error: unknown) => {
      console.error(error);
    })
  }, []);

  const onConfirmDowngradeButtonClicked = useCallback(() => {
    clientApi().downgradeSubscription().then((result) => {
      setDowngradeEffectiveDate(new Date(result.data.effectiveDate * 1000).toISOString().slice(0, 10));
      setShowDowngradeSuccessDialog(true);
      setShowConfirmDowngradeDialog(false);
    }).catch((error: unknown) => {
      console.error(error);
    })
  }, []);

  const onUncancelButtonClicked = useCallback(() => {
    clientApi().uncancelSubscription().then(() => {
      setSubscriptionInfo({ ...subscriptionInfo, status: 'active' })
    }).catch((error: unknown) => {
      console.error(error);
    })
  }, [subscriptionInfo]);

  const managedProps: Pick<SubscriptionDialogProps, SubscriptionDialogBinderManagedProps> = {
    role: user?.role,
    subscriptionStatus: StatusText[subscriptionInfo?.status ?? ''],
    periodEndDate: subscriptionInfo?.periodEndDate ? new Date(subscriptionInfo?.periodEndDate * 1000).toISOString().slice(0, 10) : undefined,
    onPurchaseButtonClicked,
    showConfirmCancelSubscriptionDialog,
    onConfirmCancelSubscriptionDialogClose: () => setShowConfirmCancelSubscriptionDialog(false),
    onConfirmCancelSubscriptionButtonClicked,
    showCancelSubscriptionSuccessDialog,
    onCancelSubscriptionSuccessDialogClose: () => setShowCancelSubscriptionSuccessDialog(false),
    subscriptionEndDate,
    showConfirmDowngradeDialog,
    onConfirmDowngradeDialogClose: () => setShowConfirmDowngradeDialog(false),
    onConfirmDowngradeButtonClicked,
    showDowngradeSuccessDialog,
    onDowngradeSuccessDialogClose: () => setShowDowngradeSuccessDialog(false),
    downgradeEffectiveDate,
    showUpgradeSuccessDialog,
    onUpgradeSuccessDialogClose: () => setShowUpgradeSuccessDialog(false),
    upgradeBillDate,
    showUncancelButton: subscriptionInfo?.status === 'canceled',
    onUncancelButtonClicked,
    quantity,
    onQuantityChange,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const SubscriptionDialogBinder = createBinder(SubscriptionDialog, useSubscriptionDialogBinder);

export default SubscriptionDialogBinder;
