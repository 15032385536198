import styled from '@emotion/styled';
import { FC, useContext } from 'react';
import { CommonUIProps } from 'utils/types';
import { CharacterRes } from 'openapi';
import { getAssetUrl, parseAvatarUrl } from 'utils/utils';
import { Avatar, Stack, Tooltip, TooltipProps } from '@mui/material';
import { GameContext, PlayersContext } from 'utils/contexts';
import PlayerReady from 'assets/player_ready.png';
import CommonAvatar from 'lib/common/CommonAvatar';


const GridContainer = styled.div`
  margin-top: 12px;
  margin-left: -8%;
  width: 1750px;
  display: grid;
  grid-template-columns: 150px 650px 150px 800px;
  grid-template-rows: 200px 200px 200px 200px 200px;
`;

const CharacterTooltip = styled((({ className, ...props }: TooltipProps) => (
  <Tooltip classes={{ popper: className }} {...props} />
)))`
  & .MuiTooltip-tooltip {
    max-width: 600px;
    background-color: #4A4A4AAA;
    border: 5px solid #7d7d7d;
    border-radius: 20px;
    color: white;
    font-family: 楷体;
  }
`;

const CharacterQuote = styled.div`
  font-size: 32px;
  margin: 15px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: justify;
`;

const CharacterGrid = styled.div`
  display: flex;
  cursor: pointer;
`;

const CharacterAvatar = styled(Avatar)`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
  height: 160px;
  width: 160px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const CharacterInfo = styled.div`
  font-family: STFangSong;
  margin-left: 20px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  text-shadow: 2px 2px 4px #000000;
`;

const CharacterName = styled.div`
  color: white;
  margin-top: 20px;
  font-size: 48px;
  text-align: left;
`;

const CharacterIntro = styled.div`
  font-size: 28px;
  color: #C0C0C0;
  text-align: left;
  margin-left: 4px;
  margin-top: 4px;
`;

const PlayerWrapper = styled(Stack)`
  width: 160px;
`;

const PlayerAvatarWrapper = styled.div`
  margin-top: 10px;
  position: relative;
`;

const PlayerAvatar = styled(CommonAvatar)`
  height: 80px;
  width: 80px;
`;

const PlayerReadyCover = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  width: 80px;
`;

const PlayerNickname = styled.div`
  width: 100%;
  color: white;
  font-size: 20px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export interface NDYY_CharacterPageProps extends CommonUIProps {
  characterList: Record<string, CharacterRes>;
  onCharacterClicked: (character: string) => void;
}

export const NDYY_CharacterPage: FC<NDYY_CharacterPageProps> = function ({
  characterList,
  onCharacterClicked,
  ...props
}) {

  const gameAudit = useContext(GameContext);
  const players = useContext(PlayersContext);

  return (
    <GridContainer {...props}>
      {Object.entries(characterList).map(([character, characterInfo], index) => (
        <CharacterTooltip key={character} placement="bottom-start" title={
          <CharacterQuote>
            {characterInfo.quote}
          </CharacterQuote>
        }>
          <CharacterGrid key={character} style={{ gridColumn: index % 2 === 0 ? '2 / span 2' : 4, gridRow: index + 1 }} onClick={() => onCharacterClicked(character)}>
            <CharacterAvatar src={getAssetUrl(characterInfo.avatar)} />
            <CharacterInfo>
              <CharacterName>{`${characterInfo.title}: ${character}`}</CharacterName>
              <CharacterIntro>{characterInfo.intro}</CharacterIntro>
            </CharacterInfo>
          </CharacterGrid>
        </CharacterTooltip>
      ))}
      {Object.entries(gameAudit?.players ?? {}).map(([playerId, playerInfo]) => {
        if (playerInfo.character) {
          const index = Object.keys(characterList).findIndex((character) => character === playerInfo.character);
          return (
            <PlayerWrapper key={playerId} alignItems="center" justifyContent="center" style={{ gridColumn: index % 2 === 0 ? 1 : 3, gridRow: index + 1 }}>
              <PlayerAvatarWrapper>
                <PlayerAvatar src={parseAvatarUrl(players[playerId]?.avatar)} />
                {playerInfo.isReady ? <PlayerReadyCover src={PlayerReady} /> : null }
              </PlayerAvatarWrapper>
              <PlayerNickname>{players[playerId]?.nickname}</PlayerNickname>
            </PlayerWrapper>
          )
        }
        return null;
      })}
    </GridContainer>
  );
};

export default NDYY_CharacterPage;
