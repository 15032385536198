import { createBinder } from "utils/utils";
import { GameToolbar, GameToolbarProps } from "./GameToolbar";
import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RouterPath from "router/RouterPath";
import StickerPopover from "./StickerPopover";
import { socket } from "utils/socketConnector";
import { UserContext } from "utils/contexts";

export type GameToolbarBinderManagedProps = 'showConfirmDialog' | 'onConfirmDialogClose' | 'onConfirmButtonClicked' | 'onHomeButtonClicked' | 'stickerPopover' | 'onStickerButtonClicked';

export interface GameToolbarBinderProps extends Omit<GameToolbarProps, GameToolbarBinderManagedProps> { }

const useGameToolbarBinder = (props: GameToolbarBinderProps): GameToolbarProps => {

  const { user } = useContext(UserContext);
  const { gameId } = useParams();

  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const navigate = useNavigate();

  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(null);
  const showPopover = Boolean(popoverAnchor);

  const onConfirmButtonClicked = useCallback(() => {
    navigate(RouterPath.Home);
  }, [navigate]);

  const handleStickerClicked = useCallback((stickerId: string) => {
    if (user?.userId && gameId) {
      socket?.emit('playerSendSticker', user?.userId, gameId, stickerId);
    }
  }, [gameId, user?.userId]);

  const managedProps: Pick<GameToolbarProps, GameToolbarBinderManagedProps> = {
    showConfirmDialog,
    onConfirmDialogClose: () => setShowConfirmDialog(false),
    onConfirmButtonClicked,
    onHomeButtonClicked: () => setShowConfirmDialog(true),
    onStickerButtonClicked: (event: React.MouseEvent<HTMLButtonElement>) => setPopoverAnchor(event.currentTarget),
    stickerPopover: useMemo(() => <StickerPopover anchorEl={popoverAnchor} showPopover={showPopover} handlePopoverClose={() => setPopoverAnchor(null)} onStickerClicked={handleStickerClicked} />, [handleStickerClicked, popoverAnchor, showPopover]),
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const GameToolbarBinder = createBinder(GameToolbar, useGameToolbarBinder);

export default GameToolbarBinder;