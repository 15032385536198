/* eslint-disable max-len */
import styled from '@emotion/styled';
import { Dialog } from '@mui/material';

export const FullDialog = styled(Dialog)`
  & .MuiPaper-root {
    max-width: 100vw;
    max-height: 100vh;
    margin: 0;
    background-color: transparent;
    box-shadow: none;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
`;