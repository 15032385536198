import styled from '@emotion/styled';
import { Divider } from '@mui/material';
import { PlayerWrapper } from 'lib/game/components/PlayerWrapper';
import { CharacterRes } from 'openapi';
import { FC, useContext, useState } from 'react';
import { GameContext, PlayersContext } from 'utils/contexts';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';

const ContentWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
`;

const CharacterGrid = styled.div`
  display: grid;
  grid-template-columns: 320px 200px 320px 200px 320px;
  grid-template-rows: 200px 200px 100px 200px 200px;
`;

const CharacterContainer = styled.div`
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: STFangSong;
`;

const CharacterAvatarWrapper = styled.div`
  position: relative;
`;

const CharacterAvatar = styled.img`
  height: 120px;
  width: 120px;
  cursor: pointer;
  border-radius: 60px;

  &:hover {
    box-shadow: 0 0 4px 6px #5885AF;
  }
`;

const CharacterTitle = styled.div`
  font-size: 28px;
  margin-top: 12px;
  color: #C9C9C9;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.4), 0 6px 20px rgba(0, 0, 0, 0.6);
`;

const CharacterName = styled.div`
  font-size: 36px;
  margin-top: 8px;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.4), 0 6px 20px rgba(0, 0, 0, 0.6);
  font-family: STZhongSong;
`;

const IntroGrid = styled.div`
  grid-column: 2 / 5;
  grid-row: 3;
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  text-indent: 2em;
  text-align: justify;
  font-family: 'STZhongSong';
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.4), 0 6px 20px rgba(0, 0, 0, 0.6);
  padding-left: 32px;
  padding-right: 32px;
`;

const CharacterIntro = styled.div`
  color: white;
  font-size: 32px;
  text-align: justify;
`;

const IntroDivider = styled(Divider)`
  border-color: #EFEFEFAA;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const PlayerContainer = styled.div`
  position: absolute;
  top: -24px;
`;

const getGridPosition = (index: number) => {
  switch (index) {
    case 0:
      return { gridColumn: 3, gridRow: 1 };
    case 1:
      return { gridColumn: 5, gridRow: 2 };
    case 2:
      return { gridColumn: 5, gridRow: 4 };
    case 3:
      return { gridColumn: 3, gridRow: 5 };
    case 4:
      return { gridColumn: 1, gridRow: 4 };
    case 5:
      return { gridColumn: 1, gridRow: 2 };
    default:
      return undefined;
  }
}

export interface ZTFR_CharacterPageProps extends CommonUIProps {
  characterList: Record<string, CharacterRes>;
  onCharacterClicked: (character: string) => void;
}

export const ZTFR_CharacterPage: FC<ZTFR_CharacterPageProps> = function ({
  characterList,
  onCharacterClicked,
}) {

  const gameAudit = useContext(GameContext);
  const players = useContext(PlayersContext);

  const [activeCharacter, setActiveCharacter] = useState<string>();

  return (
    <ContentWrapper>
      <CharacterGrid>
        {Object.entries(characterList ?? {}).map(([character, characterInfo]) => {
          if (characterInfo.index <= 6) {
            const playerId = Object.keys(gameAudit?.players ?? {}).find((id) => gameAudit?.players?.[id].character === character);
            const playerThumbnail = players[playerId ?? ''];
            return (
              <CharacterContainer key={character} style={getGridPosition(characterInfo.index - 1)}>
                <CharacterAvatarWrapper>
                  <CharacterAvatar
                    src={getAssetUrl(characterInfo.avatar)}
                    onMouseEnter={() => {
                      setActiveCharacter(character);
                    }}
                    onClick={() => onCharacterClicked(character)}
                  />
                  <PlayerContainer style={{ left: characterInfo.index > 3 ? '-160px' : '120px' }}>
                    {playerThumbnail && <PlayerWrapper player={playerThumbnail} cover={gameAudit?.players?.[playerId ?? ''].isReady ? 'ready' : undefined} />}
                  </PlayerContainer>
                </CharacterAvatarWrapper>
                <CharacterTitle>{`“${characterInfo.title}”`}</CharacterTitle>
                <CharacterName>{character}</CharacterName>
              </CharacterContainer>
            )
          }
          return null;
        })}
        <IntroGrid>
          <CharacterIntro>{characterList[activeCharacter ?? '']?.intro}</CharacterIntro>
          {activeCharacter && <IntroDivider variant="middle" />}
        </IntroGrid>
      </CharacterGrid>
    </ContentWrapper>
  );
};

export default ZTFR_CharacterPage;
