import { FC } from 'react';
import styled from '@emotion/styled';
import ErrorIcon from '@mui/icons-material/Error';
import { SnackbarProvider as Snackbar, SnackbarProviderProps, SnackbarOrigin, closeSnackbar } from 'notistack';
import { CommonIconButton } from './CommonIconButton';
import Close from '@mui/icons-material/Close';

const CustomSnackbar = styled(Snackbar)`

  &.notistack-MuiContent {
    background: #274472;
  }

  &.notistack-MuiContent-success {
    background: #2e7d32;
  }

  &.notistack-MuiContent-warning {
    background: #FF5F15;
  }

  &.notistack-MuiContent-error {
    background: #d32f2f;
    .MuiSvgIcon-root {
      color: white;
    }
  }
`;

const MAX_SNACK = 3;
const AUTO_HIDE_DURATION = 5000;
const ANCHOR_ORIGIN: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

const SnackbarProvider: FC<SnackbarProviderProps> = function ({ ...props }) {
  return (
    <CustomSnackbar
      maxSnack={MAX_SNACK}
      anchorOrigin={ANCHOR_ORIGIN}
      autoHideDuration={AUTO_HIDE_DURATION}
      iconVariant={{
        error: <ErrorIcon />,
      }}
      style={{ fontSize: 20 }}
      action={(snackbarId) => (
        <CommonIconButton icon={<Close htmlColor="white" />} onClick={() => closeSnackbar(snackbarId)} />
      )}
      {...props}
    />
  );
};

export default SnackbarProvider;
