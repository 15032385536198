import { useCallback, useContext, useMemo } from 'react';
import { ChoiceDialog, ChoiceDialogProps } from './ChoiceDialog';
import { createBinder } from 'utils/utils';
import { GameContext, UserContext } from 'utils/contexts';
import { socket } from 'utils/socketConnector';
import { useParams } from 'react-router-dom';

export type ChoiceDialogBinderManagedProps = 'currentCharacter' | 'choices' | 'onChoiceButtonClicked' | 'showConsentButton';

export interface ChoiceDialogBinderProps extends Omit<ChoiceDialogProps, ChoiceDialogBinderManagedProps> { }

const useChoiceDialogBinder = (props: ChoiceDialogBinderProps): ChoiceDialogProps => {

  const { user } = useContext(UserContext);
  const gameAudit = useContext(GameContext);
  const { gameId } = useParams();

  const choices = useMemo(() =>
    Object.fromEntries(
      Object.entries(gameAudit?.choices ?? {})
        .sort(([, choiceA], [, choiceB]) => choiceA.index - choiceB.index))
    , [gameAudit?.choices]
  );

  const isAudience = useMemo(() => !Object.keys(gameAudit?.players ?? {}).includes(user?.userId ?? ''), [gameAudit?.players, user?.userId]);

  const onChoiceButtonClicked = useCallback((choice: string) => {
    socket?.emit('playerConsentChoice', user?.userId, gameId, choice);
  }, [gameId, user?.userId]);

  const managedProps: Pick<ChoiceDialogProps, ChoiceDialogBinderManagedProps> = {
    currentCharacter: gameAudit?.players?.[user?.userId ?? '']?.character ?? '',
    choices,
    onChoiceButtonClicked,
    showConsentButton: !isAudience,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const ChoiceDialogBinder = createBinder(ChoiceDialog, useChoiceDialogBinder);

export default ChoiceDialogBinder;
