import styled from '@emotion/styled';
import { FC, useMemo } from 'react';
import { useTimeout } from 'utils/hooks';
import { Stickers } from 'utils/stickerManager';
import { CommonUIProps } from 'utils/types';

const BubbleWrapper = styled.div`
  height: 120px;
  display: flex;
  align-items: center;
`;

const Bubble = styled.div<{ show?: boolean }>`
  width: fit-content;
  padding-top: 4px;
  padding-bottom: 8px;
  border-radius: 20px;
  background-color: white;
  font-size: 26px;
  padding-left: 15px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  opacity: ${(props) => props.show ? '1' : '0'};
  transition: 0.5s ease-in;
  z-index: 1;
  margin-left: 32px;
  pointer-events: none;

  &:before {
    content: '';
    margin-left: -32px;
    margin-right: 10px;
    border-top: 20px solid transparent;
    border-right: 30px solid white;
    border-bottom: 20px solid transparent;
  }
`;

export interface ChatBubbleProps extends CommonUIProps {
  show: boolean;
  message?: string,
  hideAfter?: number,
  onClose: () => void;
}

export const ChatBubble: FC<ChatBubbleProps> = function ({
  show,
  message,
  hideAfter = null,
  onClose,
  ...props
}) {

  useTimeout(() => {
    onClose();
  }, show ? hideAfter : null);

  const command = message?.split(' ')[0];

  const messageContent = useMemo(() => {
    switch (command) {
      case '/s':
        return (
          <img alt={'sticker'} src={Stickers[message?.split(' ')[1] ?? '']} />
        );
      case '/m':
        const memoryString = message?.split(' ')[1];
        return (
          <>
          {memoryString?.split('-')[0]}
          <span style={{ color: 'red' }}>{memoryString?.split('-')[1]}</span>
          {memoryString?.split('-')[2]}
          </>
        )
      default:
        return message;
    }
  }, [command, message])

  return (
    <BubbleWrapper>
      <Bubble show={show} {...props}>
        {messageContent}
      </Bubble>
    </BubbleWrapper>
  );
};

export default ChatBubble;
