import { createBinder, getAssetUrl } from "utils/utils";
import { NDYY_InvestigatePage, NDYY_InvestigatePageProps } from "./NDYY_InvestigatePage";
import useSound from "use-sound";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GameContext, UserContext, VolumeContext } from "utils/contexts";
import VoteClueDialogBinder from "lib/game/components/VoteClueDialogBinder";
import { socket } from "utils/socketConnector";
import { useInterval, useTimeout } from "utils/hooks";
import ThunderSound from 'assets/sound/thunder.mp3';

export type NDYY_InvestigatePageBinderManagedProps = 'showMap' | 'lockWobbles' | 'lockedRooms' | 'numRemainingClues'
  | 'locationHasNewClue' | 'playerVotedLocations' | 'onLocationButtonClicked' | 'onLockAnimationEnd' | 'voteClueDialog'
  | 'showStageTransition' | 'onStageTransitionEnd' | 'gameTime' | 'raining';

export interface NDYY_InvestigatePageBinderProps extends Omit<NDYY_InvestigatePageProps, NDYY_InvestigatePageBinderManagedProps> { }

const useNDYY_InvestigatePageBinder = (props: NDYY_InvestigatePageBinderProps): NDYY_InvestigatePageProps => {

  const { user } = useContext(UserContext);
  const volume = useContext(VolumeContext);
  const gameAudit = useContext(GameContext);
  const lockedRooms = Object.keys(gameAudit?.rooms ?? {}).filter((room) => gameAudit?.rooms?.[room].isLocked);
  const numRemainingClues = useMemo(() =>
    Object.fromEntries(
      Object.entries(gameAudit?.rooms ?? {})
        .map(([room, roomInfo]) => [room, Object.values(roomInfo.clues).reduce(((numClues, clueInfo) => numClues + (clueInfo.isFound ? 0 : 1)), 0)]))
    , [gameAudit?.rooms]
  );
  const playerVotedLocations = useMemo(() =>
    Object.fromEntries(
      Object.values(gameAudit?.players ?? {})
        .map((playerInfo) => [
          playerInfo.character,
          Object.keys(gameAudit?.rooms ?? {}).find((room) => Object.keys(gameAudit?.rooms?.[room]?.clues ?? {}).includes(playerInfo.votedClue ?? ''))
        ]))
    , [gameAudit?.players, gameAudit?.rooms]
  );


  const [playMusic, { stop: musicStop }] = useSound(getAssetUrl('/NDYY/bgm.mp3'), {
    volume: volume / 100,
    loop: true,
    autoplay: true,
    interrupt: true,
  });

  const [lockWobbles, setLockWobbles] = useState<Record<string, boolean>>({ 地下走廊: false, 一号房: false, 二号房: false, 三号房: false, 四号房: false });
  const [currentLocation, setCurrentLocation] = useState<string>();
  const [showVoteClueDialog, setShowVoteClueDialog] = useState<boolean>(false);
  const [showStageTransition, setShowStageTransition] = useState<boolean>(false);

  const [newClues, setNewClues] = useState<Record<string, string[]>>({});

  const raining = gameAudit?.stage === 'READING2' || gameAudit?.stage === 'INVESTIGATING2' || gameAudit?.stage === 'VOTING' || gameAudit?.stage === 'REVEALING';
  const [thunderVolume, setThunderVolume] = useState<number>(0.4);
  const [playThunderSound, { stop: thunderStop }] = useSound(ThunderSound, {
    loop: true,
    volume: thunderVolume,
    autoplay: raining,
    interrupt: true,
  });

  useEffect(() => {
    if (raining) {
      playThunderSound();
    }
    return () => {
      musicStop();
      thunderStop();
    };
  }, [musicStop, playThunderSound, raining, thunderStop]);

  useInterval(() => {
    setThunderVolume(Math.max(thunderVolume * 0.6, 0.05));
  }, raining ? 78000 : null);

  useTimeout(() => {
    playMusic();
  }, raining ? 78000 : null);

  useEffect(() => {

    const onClueFound = (clue: string, location: string) => {
      setNewClues((prev) => ({
        ...prev,
        [location]: prev[location] ? [...prev[location], clue] : [clue]
      }));
    }

    const onStageTransition = (newStage: string) => {
      if (newStage === 'READING2') {
        setShowStageTransition(true);
      }
    }

    socket?.on('clueFound', onClueFound);
    socket?.on('stageTransition', onStageTransition);

    return () => {
      socket?.off('clueFound', onClueFound);
      socket?.off('stageTransition', onStageTransition);
    }
  }, [gameAudit?.players, user?.userId]);

  const [gameTime, setGameTime] = useState<number>(gameAudit?.gameTime ?? 1120);

  useInterval(() => {
    setGameTime(gameTime + 1);
  }, gameTime < (gameAudit?.gameTime ?? 1120) ? 50 : null);

  const onLocationButtonClicked = useCallback((location: string) => {
    if (['一号房', '二号房', '三号房', '四号房'].includes(location) && lockedRooms.includes('地下走廊')) {
      setLockWobbles((prev) => ({ ...prev, '地下走廊': true }));
    } else if (lockedRooms.includes(location)) {
      setLockWobbles((prev) => ({ ...prev, [location]: true }));
    } else {
      setCurrentLocation(location);
      setShowVoteClueDialog(true);
    }
  }, [lockedRooms]);

  const handleVoteClueDialogClose = useCallback(() => {
    setShowVoteClueDialog(false);
    if (currentLocation) {
      const temp = { ...newClues };
      delete temp[currentLocation];
      setNewClues(temp);
    }
  }, [currentLocation, newClues]);

  const handleStageTransitionEnd = useCallback((_: Event, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === 'escapeKeyDown') {
      setShowStageTransition(false);
    }
  }, []);

  const managedProps: Pick<NDYY_InvestigatePageProps, NDYY_InvestigatePageBinderManagedProps> = {
    showMap: !!gameAudit?.stage && gameAudit.stage.slice(0, -1) !== 'READING' && gameAudit.stage !== 'INTRODUCING',
    lockWobbles,
    lockedRooms,
    numRemainingClues,
    locationHasNewClue: useMemo(() => Object.keys(newClues), [newClues]),
    playerVotedLocations,
    onLocationButtonClicked,
    onLockAnimationEnd: (location: string) => setLockWobbles((prev) => ({ ...prev, [location]: false })),
    voteClueDialog: useMemo(() => <VoteClueDialogBinder location={currentLocation} newClues={newClues[currentLocation ?? '']} open={showVoteClueDialog} onClose={handleVoteClueDialogClose} />, [currentLocation, handleVoteClueDialogClose, newClues, showVoteClueDialog]),
    showStageTransition,
    onStageTransitionEnd: handleStageTransitionEnd,
    gameTime,
    raining,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const NDYY_InvestigatePageBinder = createBinder(NDYY_InvestigatePage, useNDYY_InvestigatePageBinder);

export default NDYY_InvestigatePageBinder;