import styled from '@emotion/styled';
import { FC } from 'react';
import { Stack, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import LogoWhite from 'assets/logo_white.png';
import LogoWhiteLarge from 'assets/logo1024_white.png';
import { ExpandMore } from '@mui/icons-material';
import FluidBox from 'lib/common/FluidBox';
import { CommonUIProps } from 'utils/types';
import RouterPath from 'router/RouterPath';
import { useNavigate } from 'react-router-dom';
import PageFooter from './PageFooter';


const PageContainer = styled(FluidBox)`
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
`;

const ContentWrapper = styled(Stack)`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 20px;
  z-index: 1;
  margin-top: 60px;
`;

const PageHeader = styled.div`
  padding-top: 16px;
  padding-bottom: 48px;
  background-color: #274472;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const TopBar = styled(Stack)`
  width: 100%;
  color: white;
  font-size: 20px;
  margin-left: 5vw;
`;

const LogoStack = styled(Stack)`
  width: 200px;
  cursor: pointer;
`;

const LargeLogo = styled.img`
  width: 640px;
  height: 640px;
`;

const Title = styled.div`
  font-size: 56px;
  color: white;
`;

const LogoImg = styled.img`
  width: 36px;
  height: 36px;
`;

const PageAccordion = styled(Accordion)`
  font-size: 18px;
  width: 800px;
  max-width: 90vw;

  &.MuiAccordion-root {
    background-color: #EFEFEF;
    border-radius: 8px;
    color: black;
    line-height: 1.5em;
  }
`;

const PageAccordionSummary = styled(AccordionSummary)`
  font-size: 20px;
  font-weight: bold;

  & .MuiAccordionSummary-content {
    color: black;
  }

  & .MuiSvgIcon-root {
    color: black;
  }
`;

export interface FAQPageProps extends CommonUIProps { }

export const FAQPage: FC<FAQPageProps> = function ({
  ...props
}) {

  const navigate = useNavigate();

  return (
    <PageContainer {...props}>
      <PageHeader>
        <TopBar>
          <LogoStack direction="row" alignItems="center" spacing={1} onClick={() => navigate(RouterPath.Home)}>
            <LogoImg src={LogoWhite} />
            <span style={{ fontWeight: 'bold', marginBottom: '4px' }}>Agency of Time</span>
          </LogoStack>
        </TopBar>
        <LargeLogo src={LogoWhiteLarge} />
        <Title>常见问题（FAQ）</Title>
      </PageHeader>
      <ContentWrapper justifyContent="center" spacing={4}>
        <PageAccordion>
          <PageAccordionSummary expandIcon={<ExpandMore />}>如何进行游戏？</PageAccordionSummary>
          <AccordionDetails>
            使用电脑或平板登录本网站即可。如果有DM组织，DM会提前创建好房间，输入对应的案件编号就可以进入房间；如果自己组局，一名玩家创建房间后将案件编号分享给其余玩家即可。由平台合作的DM或剧本杀店家组织的游戏不需要消耗剧本体验次数，玩家自己创建的房间会在游戏开始后扣除每人一次剧本体验次数（即剧本币）。
          </AccordionDetails>
        </PageAccordion>
        <PageAccordion>
          <PageAccordionSummary expandIcon={<ExpandMore />}>如何获得更多的剧本体验次数？</PageAccordionSummary>
          <AccordionDetails>
            所有用户每个月自动获得一次剧本体验次数。充值会员的用户，每个月有4次剧本体验次数；充值VIP的用户，每个月有无限次剧本体验次数。之后还会开放剧本体验券单独购买的项目，敬请期待。
          </AccordionDetails>
        </PageAccordion>
        <PageAccordion>
          <PageAccordionSummary expandIcon={<ExpandMore />}>什么是会员剧本和会员专属功能？</PageAccordionSummary>
          <AccordionDetails>
            内测期间，所有剧本和功能对全部用户开放。在平台正式上线后，部分剧本和小工具会转变成为“会员专属”，即充值会员的用户才可以体验和使用。
          </AccordionDetails>
        </PageAccordion>
        <PageAccordion>
          <PageAccordionSummary expandIcon={<ExpandMore />}>游戏过程中遇到问题怎么办？</PageAccordionSummary>
          <AccordionDetails>
            游戏界面的左下角有一个帮助中心，点击即可查看最基本的规则和流程介绍。如果有其他问题，可以随时通过Discord、小红书或微信群的方式咨询。
          </AccordionDetails>
        </PageAccordion>
        <PageAccordion>
          <PageAccordionSummary expandIcon={<ExpandMore />}>游戏提示无法连接到服务器怎么办？</PageAccordionSummary>
          <AccordionDetails>
            如果您是中国大陆的玩家，访问网站可能会出现高延迟、掉线的情况。请尝试使用加速器，或其他VPN工具进行游戏。
          </AccordionDetails>
        </PageAccordion>
        <PageAccordion>
          <PageAccordionSummary expandIcon={<ExpandMore />}>充值、付款与取消订阅</PageAccordionSummary>
          <AccordionDetails>
            任何在充值和付款过程中遇到的问题，都可以联系agency.of.time@gmail帮助您解决。您也可以随时取消会员服务的订阅，在主页的“会员订阅”中选择免费计划即可。您的所有会员服务会在该付款周期内继续保持，直到该周期结束，并且不会再被收取费用。
          </AccordionDetails>
        </PageAccordion>
      </ContentWrapper>
      <PageFooter />
    </PageContainer>
  );
};

export default FAQPage;
