import styled from "@emotion/styled";
import { WobbleHorTopKeyframes } from "utils/keyframes";
import LockSound from 'assets/sound/locked.mp3';
import useSound from 'use-sound';
import { CommonUIProps } from "utils/types";
import { FC } from "react";
import LockImage from 'assets/lock.webp';

const Lock = styled.img<{ wobble: boolean }>`
  width: 40px;
  position: absolute;
  margin: auto;
  animation-name: ${(props) => props.wobble ? WobbleHorTopKeyframes : null};
  animation-duration: 0.6s;
  animation-fill-mode: both;
`;

export interface GameLockProps extends CommonUIProps {
  wobble: boolean;
}

export const GameLock: FC<GameLockProps> = function ({
  wobble,
  ...props
}) {

  const [playLockSound] = useSound(LockSound);

  return <Lock src={LockImage} wobble={wobble} onAnimationStart={() => playLockSound()} {...props} />;
}

export default GameLock;