import { useState, useCallback, MouseEvent, useContext } from 'react';
import { AvatarPopover, AvatarPopoverProps } from './AvatarPopover';
import { createBinder} from 'utils/utils';
import { UserContext } from 'utils/contexts';

export type AvatarPopoverBinderManagedProps = 'avatarAnchorEl' | 'nickname' | 'avatar' | 'role' | 'showPopover' 
| 'handlePopoverOpen' | 'handlePopoverClose' | 'onLogoutButtonClicked';

export interface AvatarPopoverBinderProps extends Omit<AvatarPopoverProps, AvatarPopoverBinderManagedProps> { }

const useAvatarPopoverBinder = (props: AvatarPopoverBinderProps): AvatarPopoverProps => {

  const { user, setUser } = useContext(UserContext);

  const [avatarAnchorEl, setAvatarAnchorEl] = useState<HTMLImageElement | null>(null);
  const showPopover = Boolean(avatarAnchorEl);

  const handlePopoverOpen = useCallback((event: MouseEvent<HTMLElement>) => {
    setAvatarAnchorEl(event.currentTarget as HTMLImageElement);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAvatarAnchorEl(null);
  }, []);

  const onLogoutButtonClicked = useCallback(() => {
    setUser(undefined);
  }, [setUser]);


  const managedProps: Pick<AvatarPopoverProps, AvatarPopoverBinderManagedProps> = {
    avatarAnchorEl,
    nickname: user?.nickname,
    avatar: user?.avatar,
    role: user?.role,
    showPopover,
    handlePopoverOpen,
    handlePopoverClose,
    onLogoutButtonClicked,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const AvatarPopoverBinder = createBinder(AvatarPopover, useAvatarPopoverBinder);

export default AvatarPopoverBinder;
