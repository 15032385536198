import styled from '@emotion/styled';
import { FC } from 'react';
import { DialogProps, Stack } from '@mui/material';
import { getAssetUrl } from 'utils/utils';
import { ThemeButton } from 'lib/common/ThemeButton';
import { FullDialog } from 'lib/common/FullDialog';
import { ChoiceRes } from 'openapi';
import { NDYY_CharacterColors } from 'lib/stories/NDYY/NDYY_StaticData';
import CharacterVoice from './CharacterVoice';

const ContentWrapper = styled(Stack)`
  width: 100vw;
  height: 100dvh;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  padding-bottom: 20px;
`;

const ChoiceContainer = styled.div`
  width: 100vw;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`;

const ChoiceCardContainer = styled(Stack)`
  flex: 40%;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 30px;
`;

const ChoiceCard = styled.img`
  width: 650px;
  height: 420px;
  border: 3px solid white;
  border-radius: 20px;
`;

const HiddenChoice = styled.div`
  width: 650px;
  height: 420px;
  border: 3px solid white;
  border-radius: 20px;
  background-color: black;
  font-family: 'STZhongSong'; 
  display: flex;
  align-items: center;
  justify-content: center;
`;

const QuestionMark = styled.div`
  color: #f14d4d;
  font-size: 100px;
  font-weight: bold;
`;

const QuestionText = styled(Stack)`
  max-width: 400px;
  color: white;
  font-size: 32px;
  text-align: justify;
`;

const ConsentChip = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-conent: center;
  background-color: ${(props) => props.color};
  color: white;
  font-size: 18px;
  font-family: 微软雅黑;
  padding-left: 12px;
  padding-right: 12px;
  height: 32px;
  border-radius: 16px;
`;

const ChoiceText = styled.div<{ isDone: boolean }>`
  color: white;
  font-size: 32px;
  font-family: STFangSong;
  text-align: center;
  max-width: 650px;
  text-decoration: ${(props) => props.isDone ? 'line-through 5px #f14d4d' : ''};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const Footer = styled.div`
  width: 120px;
  height: 60px;
`;

export interface ChoiceDialogProps extends DialogProps {
  newChoices: string[],
  currentCharacter: string;
  choices?: Record<string, ChoiceRes>;
  onChoiceButtonClicked: (choice: string) => void;
  showConsentButton: boolean;
}

export const ChoiceDialog: FC<ChoiceDialogProps> = function ({
  newChoices,
  currentCharacter,
  choices,
  onChoiceButtonClicked,
  showConsentButton,
  ...props
}) {

  return (
    <FullDialog {...props}>
      <ContentWrapper direction="column" alignItems="center">
        <ChoiceContainer>
          {Object.entries(choices ?? {}).map(([choice, choiceInfo]) => (
            <ChoiceCardContainer key={choice} direction="column" alignItems="center" spacing={2}>
              {choiceInfo.status === 'DONE'
                ? <ChoiceCard src={getAssetUrl(choiceInfo.frontUrl)} />
                : <HiddenChoice>
                  {choiceInfo.status === 'HIDDEN'
                    ? <QuestionMark>?</QuestionMark>
                    : <QuestionText direction="column" alignItems="center" spacing={4}>
                      <span>{choiceInfo.question}</span>
                      <Stack direction="row" spacing={1}>
                        {Object.keys(choiceInfo.consents ?? {}).map((consent) => <ConsentChip key={choice + consent} color={NDYY_CharacterColors[consent]}>{consent}</ConsentChip>)}
                      </Stack>
                      {!Object.keys(choiceInfo.consents ?? {}).includes(currentCharacter) && showConsentButton && <ThemeButton width="80px" color="secondary" onClick={() => onChoiceButtonClicked(choice)}>同意</ThemeButton>}
                    </QuestionText>
                  }
                </HiddenChoice>
              }
              <ChoiceText isDone={choiceInfo.status === 'DONE'}>
                {choice}{choice === '抉择02' && choiceInfo.status === 'DONE' && <CharacterVoice voiceUrl={getAssetUrl('/NDYY/choice02.mp3')} autoPlay={newChoices.includes('抉择02')} />}
              </ChoiceText>
            </ChoiceCardContainer>
          ))}
        </ChoiceContainer>
        <Footer>
          <ThemeButton onClick={() => props.onClose?.({}, "escapeKeyDown")}>返回</ThemeButton>
        </Footer>
      </ContentWrapper>
    </FullDialog>
  );
};

export default ChoiceDialog;
