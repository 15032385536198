import styled from '@emotion/styled';
import { FC } from 'react';
import { DialogProps, Stack, Tab, Tabs } from '@mui/material';
import { FullDialog } from 'lib/common/FullDialog';
import CloseIcon from '@mui/icons-material/Close';
import { CommonIconButton } from 'lib/common/CommonIconButton';

const GuidanceWrapper = styled(Stack)`
  margin: auto;
  height: 72vh;
  width: 60vh;
  background-color: #0f0f0f;
  border-radius: 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
`;

const TabWrapper = styled.div`
  width: 40vh;
  margin-top: 12px;
  margin-left: 16px;
  margin-right: 16px;
  font-size: 24px;
`;

const ITab = styled(Tab)`
  font-size: 18px;
  border-radius: 8px;
`;

const ContentScroller = styled.div`
  width: 100%;
  height: calc(100% + 32px);
  padding-top: 32px;
  padding-bottom: 32px;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
    margin-top: 64px;
    margin-bottom: 32px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #274472;
    border-radius: 100px;
    border: 4px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #274472AA;
  }
`;

const CloseButton = styled(CommonIconButton)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const TabPanel = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  line-height: 2;
  text-indent: 2em;
  text-align: justify;
  white-space: pre-wrap;
  color: #D4D4D4;
`;

const Title = styled.div`
  font-weight: bold;
  color: white;
`;

export interface GuidanceDialogProps extends DialogProps {
  activeTab: number;
  onTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const GuidanceDialog: FC<GuidanceDialogProps> = function ({
  activeTab,
  onTabChange,
  ...props
}) {
  return (
    <FullDialog {...props}>
      <GuidanceWrapper direction="column">
        <CloseButton icon={<CloseIcon htmlColor="white" />} onClick={() => props.onClose?.({}, 'escapeKeyDown')} />
        <TabWrapper>
          <Tabs value={activeTab} onChange={onTabChange} textColor="inherit" indicatorColor="primary" visibleScrollbar>
            <ITab label="游玩须知" />
            <ITab label="规则说明" />
            <ITab label="流程指引" />
            <ITab label="遇到问题" />
          </Tabs>
        </TabWrapper>
        <ContentScroller>
          {activeTab === 0 &&
            <TabPanel>
              <Title>1、免责声明</Title>
              <p>
                “豪门惊情系列剧本”中全部人物、故事的版权属于作者所有（北京智乐源文化发展有限公司），本平台仅提供数字化剧本工具服务。所有游戏内容皆为虚构故事，不与任何现实中的人物、团体和事件构成任何联系。剧本杀是一种角色扮演类互动游戏，剧情中可能包含阴谋、凶案等要素，请不要试图模仿。如果您对以上原则持反对态度，请不要参与本游戏。
              </p>
              <br />
              <Title>2、艺术资源</Title>
              <p>
                本平台的全部艺术资源（例如图片、音乐、配音或视频等）皆为网络获取或AI工具生成。如有侵权，请联系平台邮箱（agency.of.time@gmail.com），我们会立即对资源进行删除。同时，请您不要下载、打印、复制、传播任何本平台上的艺术资源、剧本故事、游戏线索等内容，如此行为若对相关权利人造成了侵权，将由您承担全部后果及责任。
              </p>
              <br />
              <Title>3、零剧透</Title>
              <p>
                剧本杀的核心是对故事的还原、诡计的破解、凶案的推理。如果在游玩之前提前知晓了答案，可能会损失大量乐趣。所以为了其他玩家的游戏体验，请不要在游戏结束后将故事内容随意分享在网络上，或告诉其他没有游玩过的玩家。良好的剧本杀生态需要大家一同维护，感谢您的支持与理解！
              </p>
            </TabPanel>
          }
          {activeTab === 1 &&
            <TabPanel>
              <Title>1、本格原则</Title>
              <p>
                与推理文学中的本格派定义并不完全相同，在剧本杀语境中，本格推理特指该剧本的逻辑遵循现实世界的科学法则，没有怪力乱神的背景设定。与之相对的是变格推理，也就是故事中可能会存在玄幻、魔幻等元素，无法在真实世界中复现。
              </p>
              <br />
              <Title>2、明凶原则</Title>
              <p>
                豪门惊情系列剧本中，每起案件只有一名凶手，且不存在梦游杀人、幻觉杀人、无意识杀人等情况。
              </p>
              <br />
              <Title>3、说谎原则</Title>
              <p>
                在一场沉浸式的角色扮演游戏中，所有玩家都可以说谎。每个角色或多或少都有需要隐藏的秘密，如果在交流的时候，有玩家询问到您不希望透露的内容，您可以选择回避，辩解，或是编造虚构的经历。除了找到真凶以外，有的时候通过线索挖掘每个角色背后的故事，也是游戏乐趣的一部分！</p><p>如果您想做一个坦诚的人，可以选择不主动透露自己的秘密，但是只要别人明确猜中或找到实质性的证据后，便如实交代，以洗清嫌疑。</p><p>如果您倾向于一直守护秘密，也请注意不要过度说谎，或者编造与剧本故事完全不相干的内容。如此行为可能会干扰大家对整体案件的推理，别忘了，还原故事与找到真凶才是大家最重要的目标！
              </p>
              <br />
              <Title>4、沉浸原则</Title>
              <p>
                在游戏过程中，请尽量不要直接读剧本原文，而是用符合角色性格的方式和大家自然交流。如果可以，不要使用“我看下剧本”、“本里没写”等方式回答，替换成“我回忆一下”、“我不知道这件事情”等用语将会有助于提升大家的代入感。</p><p>另外由于不同的角色的视角不同，人物之间互相怀疑或出现信息矛盾，是非常正常的事情；甚至，有时剧本作者还会使用叙述性诡计故意对您进行误导。为了保证最佳的游戏体验，推理时请勿直接贴脸。
              </p>
              <br />
              <Title>5、开放原则</Title>
              <p>
                只要所有人都同意，您可以和您的玩伴自由制定任意规则。常见的有：必须公开所有线索、凶手绝对不能自曝、全程禁止私聊等。如设立了额外的规则，请在游戏开始前确保大家都了解清楚，以免出现不必要的误会。
              </p>
            </TabPanel>
          }
          {activeTab === 2 &&
            <TabPanel>
              <p>除非特别注明，大多数游戏流程由房主控制。</p>
              <Title>1、阅读阶段</Title>
              <p>玩家通过阅读剧本了解各自的故事。根据章节，剧本内容会自动更新。当所有人阅读完毕后，会进入下一阶段。</p>
              <Title>2、寒暄阶段</Title>
              <p>玩家相互之间介绍彼此，自由交流和提问。自我介绍时应该保证尽量简短，符合逻辑，切忌直接念本，把自己的故事从头到尾说一遍。剧本最后会明确说明“你的表现”以及“你的目的”，玩家应该根据各自的目标，有针对性的分享、讨论目前已知的内容。</p>
              <br />
              <Title>3、调查阶段</Title>
              <p>玩家调查、分享线索。不同的剧本中，可能要求不尽相同，例如投票调查、公共调查、私自调查、轮流调查等。如果是轮流调查，玩家按照角色顺序依次选择自己感兴趣的地点，查看其中的一条线索，然后选择是否公开（有些线索必须公开）。一般来说，玩家不可以调查属于自己的线索，这点会在剧本中明确说明。除了普通的地点线索外，还可能会有以下几种特殊线索：</p>
              <p> - <span style={{ fontWeight: 'bold', color: '#39918C' }}>回忆线索</span>：当回忆单词在线索中出现时，自动触发的线索。回忆线索不可公开，只能转述给他人。</p>
              <p> - <span style={{ fontWeight: 'bold', color: '#9DA993' }}>秘密线索</span>：当特定条件满足时，才可以调查的线索。如果是需要某件物品，则调查到该物品的玩家可以调查；如果该物品已被公开，则所有人都可以调查（需要自己的调查回合）。</p>
              <p> - <span style={{ fontWeight: 'bold', color: '#d32f2f' }}>上锁的区域</span>：有些房间、线索可能被锁住。您需要先找到对应的钥匙，才能解锁。</p>
              <p>当调查结束后，可以对线索进行集中讨论，当线索提及某一角色时，扮演该角色的玩家应该对其进行合理的解释。游戏通常有多个章节，因此阅读阶段和调查阶段会交替进行。</p>
              <br />
              <Title>4、投票阶段</Title>
              <p>当所有的线索都被调查完毕后，投票阶段便会开启。玩家仍然可以进行讨论，直到没有人再有疑问了为止。有些剧本会包含多起案件，您需要对每个主线案件进行投票，选出对应的真凶。</p>
              <Title>5、复盘阶段</Title>
              <p>当所有玩家投票结束后，便会揭晓故事的真相。此时玩家可以曝光自己所有的秘密，看看谁是今天的名侦探！</p>
            </TabPanel>
          }
          {activeTab === 3 &&
            <TabPanel>
              <p>如果您在游玩的过程中遇到任何问题，可以联系管理员协助您解决。</p>
              <p><span style={{ fontWeight: 'bold', color: '#8c9eff' }}>Discord</span>：fermioniclyu</p>
              <p><span style={{ fontWeight: 'bold', color: '#ff2743' }}>小红书</span>：AOT剧本杀（1025489621）</p>
              <p><span style={{ fontWeight: 'bold', color: '#1aad19' }}>微信</span>：fermioniclyu</p>
            </TabPanel>
          }
        </ContentScroller>
      </GuidanceWrapper>
    </FullDialog>
  );
};

export default GuidanceDialog;
