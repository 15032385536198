import styled from "@emotion/styled";
import { CommonUIProps } from "utils/types"
import Logo from 'assets/logo1024_white.png';
import { ThemeButton } from "lib/common/ThemeButton";
import { FC } from "react";

const PageContainer = styled.div`
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflowy: auto;
  background-color: black;
`;

const LogoImage = styled.img`
  height: 800px;
  width: 800px;
  margin-top: 60px;
`;

export interface NotFoundPageProps extends CommonUIProps { }

export const NotFoundPage: FC<NotFoundPageProps> = function ({ ...props }) {
  return (
    <PageContainer {...props}>
      <LogoImage src={Logo} />
      <div style={{ color: 'white', fontSize: '160px', textAlign: 'center', marginTop: '-120px' }}>
        404
        <div style={{ fontSize: '50px', marginTop: '-16px', marginBottom: '32px' }}>页面未找到</div>
      </div>
      <ThemeButton href="/" width="120px">返回主页</ThemeButton>
    </PageContainer>
  )
}

export default NotFoundPage;