import styled from '@emotion/styled';
import { CommonUIProps } from 'utils/types';

export interface FluidBoxProps extends CommonUIProps {}

export const FluidBox = styled.div<FluidBoxProps>`
  width: 100%;
  height: 100%;
`;

export default FluidBox;
