import styled from '@emotion/styled';
import { FC, ChangeEvent } from 'react';
import { Chip, DialogProps, FormControlLabel, Grid, Radio, Rating, Stack, Checkbox, Divider, Avatar, Skeleton } from '@mui/material';
import { CommonDialog } from 'lib/common/CommonDialog';
import { getStoryPosterUrl, parseAvatarUrl } from 'utils/utils';
import { ThemeButton } from 'lib/common/ThemeButton';
import { StoryData } from 'utils/storyData';
import ConfirmDialog from 'lib/common/ConfirmDialog';
import { StoryCommentRes } from 'openapi';

const ContentWrapper = styled(Stack)`
  height: 60dvh;
  width: 48vw;
`;

const StoryGrid = styled(Grid)`
  flex: 1 1 auto;
  overflow: auto;
  margin-bottom: 16px;

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: #E0E0E0;
    border-radius: 100px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #274472;
    border-radius: 100px;
    border: 4px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #274472AA;
  }
`;

const StoryChoiceWrapper = styled(Stack)`
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 16px;
`;

const StoryPoster = styled.img`
  width: 160px;
  border: 3px solid #D4D4D4;
  border-radius: 4px;
`;

const StoryInfoStack = styled(Stack)`
  width: 100%;
  margin-left: 8px;
  margin-right: 8px;
`;

const StoryName = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const StoryText = styled.div`
  font-size: 20px;
`;

const StoryTagStack = styled(Stack)`
  max-width: 100%;
`;

const DetailWrapper = styled(Stack)`
  height: calc(100% - 64px);
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
`;

const DetailLeftWrapper = styled(Stack)`
  height: 100%;
  width: 40%;
`;

const DetailRightWrapper = styled(Stack)`
  height: calc(100% - 32px);
  width: calc(60% - 32px);
  padding: 16px;
  padding-right: 32px;

  overflow: auto;

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: #E0E0E0;
    border-radius: 100px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #274472;
    border-radius: 100px;
    border: 4px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #274472AA;
  }
`;

const StoryTitle = styled.div`
  font-size: 32px;
  text-align: center;
  font-weight: bold;
`;

const StoryIntrodcution = styled.div`
  line-height: 1.5;
  text-align: justify;
  white-space: pre-wrap;
`;

const NoCommentBox = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;
  text-align: center;
  color: #9B9B9B;
`;

const CommentsWrapper = styled(Stack)`
  padding: 16px;
  background-color: #EFEFEF;
  border-radius: 8px;
`;

const CommentBox = styled(Stack)`

`;

const CommentHeader = styled(Stack)`
  height: 48px;
`;

const DetailPoster = styled.img`
  height: 30dvh;
  border: 3px solid #D4D4D4;
  border-radius: 4px;
  margin-bottom: 16px;
`;

const BottomStack = styled(Stack)`
  height: 64px;
`;

const BottomButton = styled(ThemeButton)`
  min-height: 48px;
`;

export enum CreateGameDialogState {
  BROWSE,
  DETAIL
}

export interface CreateGameDialogProps extends DialogProps {
  state: CreateGameDialogState;
  selectedStory: string;
  comments?: Record<string, StoryCommentRes>;
  handleCreateGame: (story: string, isVIPGame?: boolean) => void;
  onSelectedStoryChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onNextButtonClicked: () => void;
  onPreviousButtonClicked: () => void;
  onCreateGameButtonClicked: () => void;
  showVIPGameOption: boolean;
  isVIPGame: boolean;
  onToggleVIPGame: () => void;
  showConfirmDialog: boolean;
  onConfirmVIPGame: () => void;
  handleConfirmDialogClose: () => void;
}

export const CreateGameDialog: FC<CreateGameDialogProps> = function ({
  state,
  selectedStory,
  comments,
  handleCreateGame,
  onSelectedStoryChange,
  onNextButtonClicked,
  onPreviousButtonClicked,
  onCreateGameButtonClicked,
  showVIPGameOption,
  isVIPGame,
  onToggleVIPGame,
  showConfirmDialog,
  onConfirmVIPGame,
  handleConfirmDialogClose,
  ...props
}) {
  return (
    <CommonDialog title="创建房间" {...props}>
      <ContentWrapper direction="column" alignItems="center" spacing={2}>
        {state === CreateGameDialogState.BROWSE && <>
          <StoryGrid container>
            {Object.entries(StoryData).map(([storyId, storyInfo]) => (
              <Grid key={storyId} item xs={4} lg={3}>
                <StoryChoiceWrapper direction="column" alignItems="center" spacing={1}>
                  <StoryPoster src={getStoryPosterUrl(storyId)} />
                  <StoryInfoStack direction="row" justifyContent="center" spacing={2}>
                    <StoryName>{storyInfo.name}</StoryName>
                    <StoryText>{storyInfo.males + storyInfo.females}人</StoryText>
                  </StoryInfoStack>
                  <Radio checked={selectedStory === storyId} value={storyId} onChange={onSelectedStoryChange} disabled={[''].includes(storyId)} />
                </StoryChoiceWrapper>
              </Grid>
            ))}
          </StoryGrid>
          <BottomStack>
            <BottomButton width="140px" onClick={onNextButtonClicked}>下一步</BottomButton>
          </BottomStack>
        </>}
        {state === CreateGameDialogState.DETAIL && <>
          <DetailWrapper direction="row" justifyContent="space-between">
            <DetailLeftWrapper direction="column" alignItems="center" justifyContent="center" spacing={2}>
              <DetailPoster src={getStoryPosterUrl(selectedStory)} />
              <StoryTagStack direction="row" spacing={1}>
                {StoryData[selectedStory].tags.map((tag) => <Chip key={`${selectedStory}-${tag}`} label={tag} />)}
              </StoryTagStack>
              <span>{`人数：${StoryData[selectedStory].males + StoryData[selectedStory].females}人（${StoryData[selectedStory].males}男${StoryData[selectedStory].females}女）`}</span>
              <span>{`时长：${StoryData[selectedStory].length}小时+`}</span>
              <span>{`难度：${StoryData[selectedStory].difficulty}`}</span>
              <Rating precision={0.5} size="large" value={StoryData[selectedStory].rating} readOnly />
            </DetailLeftWrapper>
            <DetailRightWrapper direction="column" justifyContent="flex-start" spacing={2}>
              <StoryTitle>{`《${StoryData[selectedStory].name}》 故事简介`}</StoryTitle>
              <StoryIntrodcution>
                {StoryData[selectedStory].abstract}
              </StoryIntrodcution>
              <CommentsWrapper direction="column" divider={<Divider flexItem />} spacing={2}>
                {comments === undefined ? <Skeleton sx={{ bgcolor: '#EFEFEF' }}  variant="rounded" width="100%" height="64px" /> :
                  Object.keys(comments).length === 0 ? <NoCommentBox>这个剧本暂时还没有评论哦</NoCommentBox> :
                    Object.entries(comments).map(([commentId, comment]) =>
                      <CommentBox key={commentId} direction="column">
                        <CommentHeader direction="row" alignItems="center" justifyContent="space-between">
                          <Stack direction="row" alignItems="center" spacing={1}>
                            <Avatar src={parseAvatarUrl(comment.avatar)} />
                            <span>{comment.username}</span>
                            <Rating precision={0.5} size="small" value={comment.rating} readOnly />
                          </Stack>
                          {new Date(comment.createTime ?? 0).toISOString().slice(0, 10)}
                        </CommentHeader>
                        {comment.comment}
                      </CommentBox>
                    )}
              </CommentsWrapper>
            </DetailRightWrapper>
          </DetailWrapper>
          <BottomStack direction="row" alignItems="center" spacing={4}>
            <BottomButton width="140px" onClick={onPreviousButtonClicked}>上一步</BottomButton>
            <BottomButton width="140px" onClick={onCreateGameButtonClicked}>确认创建</BottomButton>
            {showVIPGameOption && <FormControlLabel control={<Checkbox checked={isVIPGame} onChange={onToggleVIPGame} />} label="升级为会员案件（好友加入无需消耗剧本币）" color="blue" />}
          </BottomStack>
        </>}
      </ContentWrapper>
      <ConfirmDialog open={showConfirmDialog} onClose={handleConfirmDialogClose} title="确定创建会员案件吗？" content="本月还剩1次可以让好友免费体验的会员案件。" onConfirmButtonClicked={onConfirmVIPGame} />
    </CommonDialog>
  );
};

export default CreateGameDialog;
