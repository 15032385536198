import styled from '@emotion/styled';
import { DialogProps } from '@mui/material';
import { FullDialog } from 'lib/common/FullDialog';
import { FC, useEffect, useState } from 'react';
import { useInterval, useTimeout } from 'utils/hooks';
import useSound from 'use-sound';
import TypingSound from 'assets/sound/typing.mp3';

const TransitionContainer = styled.div`
  width: 100vw;
  height: 400px;
  border: white solid;
  border-width: 3px 0 3px 0;
  background-color: #090909;
  color: white;
  font-family: 'STZhongSong';
  text-align: center;
  transition: opacity 1.5s ease-in-out 5s;
`;

const Title = styled.div`
  font-size: 32px;
  margin-top: 15px;
`;

const Text = styled.div`
  font-size: 40px;
  margin-top: 20px;
  line-height: 2em;
  margin-left: 1em;
`;

export interface NDYY_StageTransitionProps extends DialogProps { }

export const NDYY_StageTransition: FC<NDYY_StageTransitionProps> = function ({
  ...props
}) {

  const text1 = '警察依然没有到来。';
  const text2 = '吉叔告诉大家，外面下起了大雨，看情况还需要等待很久。';
  const text3 = '你坐在房间内，逐渐回忆起了一些事情……';

  const [animatedText1, setAnimatedText1] = useState<string>('');
  const [animatedText2, setAnimatedText2] = useState<string>('');
  const [animatedText3, setAnimatedText3] = useState<string>('');

  const [playTypingSound, { stop }] = useSound(TypingSound, { volume: 0.3, playbackRate: 1 });

  const typingSpeed = 120;
  const linePause = 500;

  useTimeout(
    () => {
      playTypingSound();
    },
    props.open ? 2000 : null
  );

  useInterval(() => {
    setAnimatedText1(text1.slice(0, animatedText1.length + 1));
  }, props.open ? (animatedText1.length === text1.length ? null : animatedText1.length === 0 ? 2500 : typingSpeed) : null);

  useInterval(() => {
    setAnimatedText2(text2.slice(0, animatedText2.length + 1));
  }, props.open ? (animatedText2.length === text2.length ? null : animatedText2.length === 0 ? 2500 + text1.length * typingSpeed + linePause : typingSpeed) : null);

  useInterval(() => {
    setAnimatedText3(text3.slice(0, animatedText3.length + 1));
  }, props.open ? (animatedText3.length === text3.length ? null : animatedText3.length === 0 ? 2500 + (text1.length + text2.length) * typingSpeed + linePause * 2: typingSpeed) : null);

  useEffect(() => {
    if (animatedText3 === text3) {
      stop();
    }
  }, [animatedText3, stop]);

  return (
    <FullDialog disableEscapeKeyDown {...props}>
      <TransitionContainer style={{ opacity: animatedText3 === text3 ? '0' : '1' }} onTransitionEnd={() => props.onClose?.({}, 'escapeKeyDown')}>
        <Title>
          海砂馆时间
          <br />
          22:20
        </Title>
        <Text>
          {animatedText1}
          <br />
          {animatedText2}
          <br />
          {animatedText3}
          <br />
        </Text>
      </TransitionContainer>
    </FullDialog>
  );
};

export default NDYY_StageTransition;
