import { useState, useCallback, useContext, useRef } from 'react';
import { FeedbackDialog, FeedbackDialogProps } from './FeedbackDialog';
import { createBinder } from 'utils/utils';
import { GameContext } from 'utils/contexts';
import { enqueueSnackbar } from 'notistack';
import clientApi from 'utils/clientApi';

export type FeedbackDialogBinderManagedProps = 'storyId' | 'rating' | 'onRatingChange' | 'feedbackInputRef' | 'onSubmitButtonClicked';

export interface FeedbackDialogBinderProps extends Omit<FeedbackDialogProps, FeedbackDialogBinderManagedProps> { }

const useFeedbackDialogBinder = (props: FeedbackDialogBinderProps): FeedbackDialogProps => {

  const gameAudit = useContext(GameContext);

  const [rating, setRating] = useState<number>(0);
  const feedbackInputRef = useRef<HTMLInputElement>(null);

  const onSubmitButtonClicked = useCallback(() => {
    if (gameAudit?.storyId) {
      clientApi().submitComment({ storyId: gameAudit.storyId, rating, comment: feedbackInputRef.current?.value ?? '' }).then(() => {
        props.onClose?.({}, 'escapeKeyDown');
        enqueueSnackbar('感谢您的反馈！', { variant: 'success' });
      }).catch((error: Error) => {
        console.error(error);
        enqueueSnackbar(error.message, { variant: 'error' });
        props.onClose?.({}, 'escapeKeyDown');
      })
    }
  }, [gameAudit?.storyId, props, rating]);

  const managedProps: Pick<FeedbackDialogProps, FeedbackDialogBinderManagedProps> = {
    storyId: gameAudit?.storyId ?? '',
    rating,
    feedbackInputRef,
    onRatingChange: (_, newValue) => setRating(newValue ?? 0),
    onSubmitButtonClicked,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const FeedbackDialogBinder = createBinder(FeedbackDialog, useFeedbackDialogBinder);

export default FeedbackDialogBinder;
