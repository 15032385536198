import { UserRes } from "openapi";

export type UserInfo = Omit<UserRes, 'accessToken' | 'refreshToken'>;

class LocalStorage {
  protected service: Storage = localStorage;

  setAccessToken(token: string): void {
    this.service.setItem('accessToken', token);
  }

  setRefreshToken(token: string): void {
    this.service.setItem('refreshToken', token);
  }

  setUserInfo(userInfo?: UserInfo): void {
    if (userInfo) {
      this.service.setItem('user', JSON.stringify(userInfo));
    } else {
      this.clearUser();
    }
  }

  setUserNickname(name?: string): void {
    if (name) {
      const userInfo = this.getUserInfo();
      if (userInfo) {
        userInfo.nickname = name;
        this.setUserInfo(userInfo);
      } else {
        this.clearUser();
      }
    }
  }

  setUserAvatar(avatar?: string): void {
    if (avatar) {
      const userInfo = this.getUserInfo();
      if (userInfo) {
        userInfo.avatar = avatar;
        this.setUserInfo(userInfo);
      } else {
        this.clearUser();
      }
    }
  }

  getAccessToken(): string | undefined {
    return this.service.getItem('accessToken') ?? undefined;
  }

  getRefreshToken(): string | undefined {
    return this.service.getItem('refreshToken') ?? undefined;
  }

  getUserInfo(): UserInfo | undefined {
    const user = this.service.getItem('user');
    if (user) {
      try {
        // TODO: need a stonger type check here
        return JSON.parse(user) as UserInfo;
      } catch {
        this.clearUser();
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  getUserNickname(): string | undefined {
    const user = this.service.getItem('user');
    if (user) {
      try {
        const userInfo = JSON.parse(user) as UserInfo;
        return userInfo.nickname;
      } catch {
        this.clearUser();
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  getAvatar(): string | undefined {
    const user = this.service.getItem('user');
    if (user) {
      try {
        const userInfo = JSON.parse(user) as UserInfo;
        return userInfo.avatar;
      } catch {
        this.clearUser();
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  clearToken(): void {
    this.service.removeItem('accessToken');
    this.service.removeItem('refreshToken');
  }

  clearUser(): void {
    this.service.removeItem('user');
    this.clearToken();
  }
}

const localStorageService = new LocalStorage();

export default localStorageService;
