import { createBinder, getAssetUrl } from "utils/utils";
import { ZTFR_InvestigatePage, ZTFR_InvestigatePageProps } from "./ZTFR_InvestigatePage";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GameContext, UserContext, VolumeContext } from "utils/contexts";
import { socket } from "utils/socketConnector";
import PickClueDialogBinder from "lib/game/components/PickClueDialogBinder";
import { ZTFR_ForbiddenRooms } from "./ZTFR_StaticData";
import useSound from "use-sound";

export type ZTFR_InvestigatePageBinderManagedProps = 'showMap' | 'gameTime' | 'numRemainingClues' | 'locationHasNewClue'
  | 'onLocationButtonClicked' | 'pickClueDialog';

export interface ZTFR_InvestigatePageBinderProps extends Omit<ZTFR_InvestigatePageProps, ZTFR_InvestigatePageBinderManagedProps> {
  showPickClueDialog: boolean;
  setShowPickClueDialog: (_: boolean) => void;
}

const useZTFR_InvestigatePageBinder = (props: ZTFR_InvestigatePageBinderProps): ZTFR_InvestigatePageProps => {

  const { user } = useContext(UserContext);
  const volume = useContext(VolumeContext);
  const gameAudit = useContext(GameContext);
  const [newClues, setNewClues] = useState<Record<string, string[]>>({});
  const { showPickClueDialog, setShowPickClueDialog } = props;
  const [currentLocation, setCurrentLocation] = useState<string>();

  const [, { stop: musicStop }] = useSound(getAssetUrl('/ZTFR/bgm.mp3'), {
    volume: volume / 100,
    loop: true,
    autoplay: true,
    interrupt: true,
  });

  useEffect(() => {
    return () => musicStop();
  }, [musicStop]);

  const numRemainingClues = useMemo(() =>
    Object.fromEntries(
      Object.entries(gameAudit?.rooms ?? {})
        .map(([room, roomInfo]) => [room, Object.values(roomInfo.clues).reduce(((numClues, clueInfo) => numClues + (clueInfo.isFound ? 0 : 1)), 0)]))
    , [gameAudit?.rooms]);

  const allowToInvestigate = useMemo(() => {
    const forbiddenRoom = ZTFR_ForbiddenRooms[gameAudit?.players?.[user?.userId ?? '']?.character ?? ''];
    if (forbiddenRoom) {
      if (forbiddenRoom !== currentLocation) {
        return true;
      }
      return Object.keys(gameAudit?.rooms ?? {}).every((room) => {
        if (room === forbiddenRoom) {
          return true;
        }
        return Object.values(gameAudit?.rooms[room]?.clues ?? {}).every((clue) => clue.isFound);
      })
    }
    return true;
  }, [currentLocation, gameAudit?.players, gameAudit?.rooms, user?.userId]);

  const unavailableClues = useMemo(() => gameAudit?.rooms['吕家酒馆']?.clues['clue36']?.isPublic ? undefined : ['clue44'], [gameAudit?.rooms]);

  const newCharacters = useMemo(() => {
    switch (gameAudit?.stage) {
      case 'READING2':
      case 'INVESTIGATING2':
      case 'DISCUSSING2':
        return { 葛月萝: '葛月曼', 薛达财: '薛玉帆', 吕松年: '吕陆升' };
      case 'READING3':
      case 'INVESTIGATING3':
      case 'DISCUSSING3':
        return { 葛月萝: '葛月曼', 薛达财: '薛玉帆', 吕松年: '吕陆升', 于彦诚: '于尚勇' };
      case 'READING4':
      case 'INVESTIGATING4':
      case 'VOTING':
      case 'REVEALING':
        return { 葛月萝: '葛月曼', 薛达财: '薛玉帆', 吕松年: '吕陆升', 于彦诚: '于尚勇', 张太太: '稚筠' };
      default:
        return {};
    }
  }, [gameAudit?.stage]);

  useEffect(() => {

    const onClueFound = (clue: string, location: string) => {
      setNewClues((prev) => ({
        ...prev,
        [location]: prev[location] ? [...prev[location], clue] : [clue]
      }));
    }

    const onCluePublished = (clue: string, location: string, character: string) => {
      if (character !== gameAudit?.players?.[user?.userId ?? '']?.character) {
        setNewClues((prev) => ({
          ...prev,
          [location]: prev[location] ? [...prev[location], clue] : [clue]
        }));
      }
    }

    const onStageTransition = (newStage: string) => {

    }

    socket?.on('clueFound', onClueFound);
    socket?.on('cluePublished', onCluePublished);
    socket?.on('stageTransition', onStageTransition);

    return () => {
      socket?.off('clueFound', onClueFound);
      socket?.off('cluePublished', onCluePublished);
      socket?.off('stageTransition', onStageTransition);
    }
  }, [gameAudit?.players, user?.userId]);

  const onLocationButtonClicked = useCallback((location: string) => {
    setCurrentLocation(location);
    setShowPickClueDialog(true);
  }, [setShowPickClueDialog]);

  const handlePickClueDialogClose = useCallback(() => {
    setShowPickClueDialog(false);
    if (currentLocation) {
      const temp = { ...newClues };
      delete temp[currentLocation];
      setNewClues(temp);
    }
  }, [currentLocation, newClues, setShowPickClueDialog]);

  const managedProps: Pick<ZTFR_InvestigatePageProps, ZTFR_InvestigatePageBinderManagedProps> = {
    showMap: !!gameAudit?.stage && gameAudit.stage.slice(0, -1) !== 'READING' && gameAudit.stage !== 'INTRODUCING',
    gameTime: ['READING1', 'INTRODUCING', 'INVESTIGATING1'].includes(gameAudit?.stage ?? '') ? '12:15' : '13:15',
    numRemainingClues,
    locationHasNewClue: useMemo(() => Object.keys(newClues), [newClues]),
    onLocationButtonClicked,
    pickClueDialog: useMemo(() => <PickClueDialogBinder location={currentLocation} newClues={newClues[currentLocation ?? '']} newCharacters={newCharacters} open={showPickClueDialog} onClose={handlePickClueDialogClose} allowToInvestigate={allowToInvestigate} unavailableClues={unavailableClues} />, [allowToInvestigate, currentLocation, handlePickClueDialogClose, newCharacters, newClues, showPickClueDialog, unavailableClues]),
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const ZTFR_InvestigatePageBinder = createBinder(ZTFR_InvestigatePage, useZTFR_InvestigatePageBinder);

export default ZTFR_InvestigatePageBinder;