import { getAssetUrl } from 'utils/utils';

export const getYLWInvestigatePageBackgroundUrl = (stage: string) => {
  switch (stage) {
    case 'READING1':
    case 'INTRODUCING':
    case 'INVESTIGATING1':
      return [getAssetUrl('/YLW/background1.webp')];
    case 'READING2':
    case 'INVESTIGATING2':
    case 'VOTING':
    case 'REVEALING':
      return [getAssetUrl('/YLW/background2.webp'), getAssetUrl('/YLW/background1.webp'), getAssetUrl('/YLW/background.webp')];
    default:
      return undefined;
  }
};

export const YLW_CharacterColors: Record<string, string> = {
  安梧: '#89af88',
  吾特: '#7ABA78',
  睿司: '#739da2',
  尔芙: '#dd8f5b',
  伍艾菲: '#c96398',
};

export const YLW_CharacterAbbr: Record<string, string> = {
  安梧: '安',
  吾特: '吾',
  睿司: '睿',
  尔芙: '芙',
  伍艾菲: '菲',
};

export const YLW_VoteQuestions = ['是谁杀害了安季奚？', '是谁杀害了“睿司”？', '是谁杀害了“尔芙”？', '是谁杀害了“吾特”？'];

export const YLW_Scripts: Record<string, string> = {
  安梧: '/YLW/scripts/AW.pdf',
  吾特: '/YLW/scripts/WT.pdf',
  睿司: '/YLW/scripts/RS.pdf',
  尔芙: '/YLW/scripts/EF.pdf',
  伍艾菲: '/YLW/scripts/WAF.pdf',
};

export const YLW_ScriptMaxPage: Record<string, number> = {
  READING1: 8,
  INTRODUCING: 8,
  INVESTIGATING1: 8,
  READING2: 11,
  INVESTIGATING2: 11,
};

export const YLW_TruthSummary: Record<string, string> = {
  背景故事:
    '安季奚年少时对“科学”着迷，想要找出影响人性格行为的真相，受到家中双胞胎下人“张氏兄弟”的启发，决定开始一个长达数十年的“并蒂莲”计划，英文名称Y.A.N.A（You Are Not Alone）。他计划将一些双胞胎们分开养大，观测其不同年龄阶段的行为变化，以实验的方式证明科学理论。\n\n       因为实验必须排除干扰，安季奚就来到人迹罕至的月落洼附近，修建了南北两座完全一样的“时珞庄”，让张氏兄弟分别作为两座时珞庄的管家，而一起带来的双胞胎孩子们则被当成实验对象，两边各住一个。\n\n       安季奚想要尽量给他们完全相同的成长环境，于是将北面“时珞庄”的日期提前了一个星期，每次先去北边上课，将和孩子们的互动记录下来，再去南边重演一次，两个老张随时随地配合。\n\n       玩家中的吾特和睿司来自北面的时珞庄，而玩家中的安梧、尔芙和伍艾菲则来自南面的时珞庄。',
  天赋异禀的孩子:
    '1896年，乳名将来的安季奚（Future An）醉酒后将张氏兄弟的妹妹强暴，因为女方出身低贱无法婚配，只得被接回老家，视作丑事。随后第二年生下一对双胞胎，起名“悔过”和“改过”。\n\n       1899年，安氏宗族中的一户人家因为贫困，把无力抚养的双胞胎男孩送给富有的安季奚。此时实验开始，安家的双胞胎都被起名安梧，作为1号实验对象；而老张的妹妹因为精神失常被送入精神病院，孩子只能被老张带来交给主人安季奚抚养，都被起名吾特，作为2号实验对象。\n\n       1900年，安季奚从人贩子手里以10两银子每个的高价买下两对被拐卖的双胞胎，收为养子和养女，起名睿司和尔芙，作为3号和4号实验对象。\n\n       1901年，安季奚回老家时，又见到一对双胞胎女孩，就花钱将她们领养，都起名伍艾菲，成为“表外甥女”，作为5号实验对象。',
  相遇月落洼:
    '南面的老张是哥哥，做事严格，经常打骂吾特；北面的老张是弟弟，爱喝酒，善于厨艺。由于哥哥经常要替安季奚做事，离开后就要弟弟去替他，所以两边的孩子都会偶尔看到“老张“喝醉后忘记锁门。\n\n       北面的三个男孩发现可以把”石礅“当成路标，来到月落洼玩耍；而南面的伍艾菲也发现了山后的”小路“可以到达月落洼。因为爱喝酒的弟弟只会在一个时珞庄里喝醉忘记锁门，因此这么多年来，两边从来没有相遇过。\n\n       后来睿司学会了撬锁，才能第一次在月落洼见到趁”老张“喝醉偷溜出来的伍艾菲，少男少女们之间懵懂的情感也因此开始萌芽。',
  失踪的安梧:
    '安季奚为了测试两边孩子的认知差别，经常给一组双胞胎看相同的书籍，之后记录两个人的读后感，分析其中的异同。\n\n       1914年5月26日，安季奚给两个安梧各买了一本《择胎魔神》（The Time Machine），却没想到两个安梧都会因此沉迷，想要去寻找能够时间旅行的方法。北面的安梧在树林里沿着石墩摸索前进，找到了小路，发现了南面的时珞庄，走进课堂看到日历，以为自己穿越了时间。于是，他留下了纸条并在墙上刻字，想要提醒过去的“自己”。\n\n       南边的老张发现后，将出逃的安梧抓了起来，关在月落洼旁别馆里，之后因为胡言乱语又被安季奚送去了上海的精神病院。\n\n       南边的安梧（玩家）收到提醒后，因为曾错失和伍艾菲表白的机会，因此也想通过时间旅行的方式回到过去。在收到北面的安梧提醒后，也选择逃了出去，结果被有经验了的老张抓回，又关到了别馆里。',
  全新实验: '南面的尔芙突然提出想去上海，这种年轻人的自我诉求让安季奚非常惊喜。他不禁思考，两个成长环境完全相同的尔芙如果分别处于时珞庄和上海，又会产生多大的差异？况且即使尔芙离开，庄内还有另一个女生实验对象伍艾菲，于是便答应了尔芙的请求。\n\n       安季奚唯一的失误，便是没考虑到尔芙的离开居然会对留下的伍艾菲造成远超出预计的影响，甚至后来还会因为自己控制不住的情欲，付出无法挽回的代价！',
  大黄狗之死: '尔芙因为喜欢吾特，看不惯老张对大黄比对外甥还好，就想惩罚一下老张的大黄；伍艾菲因为没见到睿司，白喂了这么久大黄，也想拿大黄出气——于是两人各下了二十分之一的杀虫药在狗食里，共同导致了大黄死亡。',
  所有的命案: '吾特爱上了伍艾菲，却撞见她和安季奚在餐厅拥吻！吾特质问伍艾菲，反被羞辱，一怒之下吾特拿起厨刀威胁伍艾菲，却在推搡中将伍艾菲割破了喉咙，当场死亡。\n\n       睿司看到心爱之人尸体，悲痛中跑出时珞庄想要寻找安梧所说的时光机，被吾特误以为要去找安季奚告发，所以将其残忍杀害。行凶之时又被前来寻找二人的尔芙撞见，吾特便一不做二不休，追上去用石头将尔芙也砸死。事已至此，吾特决定连安季奚也一并除掉，他相信伍艾菲一定不是自愿爱上的安季奚！\n\n       随后吾特看到了另一个自己（双胞胎兄弟），想出替换身份的方法，于是埋伏在庄外将另一个自己用腰带勒死，换了衣服后伪装成”过去的自己”。\n\n       吾特曾经在上一个9月20日看到过安季奚所有的行动，于是这次提前在绿色水杯中下了致死剂量的杀虫药。不知情的安季奚为了确保实验一致，仍然用绿色杯子喝水，最后毒发身亡。',
};

export const getYLWFooterText = (stage: string) => {
  switch (stage) {
    case 'READING1':
      return '阅读剧本阶段（阅读完请点击剧本左上角的“阅读完毕”）';
    case 'INTRODUCING':
      return '介绍阶段';
    case 'INVESTIGATING1':
      return '寒暄阶段（自由交谈，直到吾特提出送安梧回房休息）';
    case 'READING2':
      return '阅读第二幕剧本（阅读完请点击剧本左上角的“阅读完毕”）';
    case 'INVESTIGATING2':
      return '轮流调查阶段';
    case 'VOTING':
      return '投票阶段（可以继续交流，直到所有人投完票）';
    case 'REVEALING':
      return '复盘阶段';
    default:
      return undefined;
  }
};

export const YLW_ForbiddenRooms: Record<string, string> = {
  安梧: '1号房',
  吾特: '2号房',
  睿司: '3号房',
  尔芙: '4号房',
  伍艾菲: '5号房',
};
