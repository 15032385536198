/* eslint-disable react/jsx-pascal-case */
import styled from '@emotion/styled';
import { LocationButton } from 'lib/game/components/LocationButton';
import { FC, ReactNode, useContext } from 'react';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';
import { GameContext } from 'utils/contexts';
import { ThemeButton } from 'lib/common/ThemeButton';
import ConfirmDialog from 'lib/common/ConfirmDialog';
import { Stack } from '@mui/material';
import { EXSL_StageTransition1, EXSL_StageTransition2 } from './EXSL_StageTransition';

const MapContainer = styled.div`
  width: 60vw;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const Map = styled.img`
  width: 100%;
`;

const GameStageInfo = styled.div`
  position: absolute;
  top: 40px;
  right: 60px;
  text-align: center;
  font-family: 'STZhongSong';
  font-size: 40px;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  color: white;
`;

const BaKangZhai = styled(LocationButton)`
  width: 2.9%;
  height: 13.6%;
  left: 50.2%;
  top: 53.6%;
`;

const Station = styled(LocationButton)`
  width: 6.5%;
  height: 4.5%;
  right: 19.9%;
  top: 36%;
`;

const GraveYard = styled(LocationButton)`
  width: 6.5%;
  height: 4.5%;
  left: 25%;
  top: 32.5%;
`;

const TeaField = styled(LocationButton)`
  width: 6.5%;
  height: 4.5%;
  right: 34.9%;
  bottom: 9.8%;
`;

const FishMan = styled(LocationButton)`
  width: 5%;
  height: 5%;
  right: 34.9%;
  top: 51.7%;
`;

const ReedMarshes = styled(LocationButton)`
  width: 5%;
  height: 5.5%;
  right: 16.5%;
  bottom: 21.5%;
`;

const ShamanHouse = styled(LocationButton)`
  width: 8.4%;
  height: 4.5%;
  left: 43.2%;
  top: 47.9%;
`;

const WestYa = styled(LocationButton)`
  width: 15.4%;
  height: 4.5%;
  left: 8.9%;
  top: 20.8%;
`;

const EastYa = styled(LocationButton)`
  width: 15.4%;
  height: 4.5%;
  left: 31.6%;
  top: 3.7%;
`;

const OldCabin = styled(LocationButton)`
  width: 2.9%;
  height: 13.6%;
  left: 56.3%;
  top: 16.5%;
`;

const ZiLinTower = styled(LocationButton)`
  width: 2.9%;
  height: 13.6%;
  left: 49.1%;
  top: 6.8%;
`;

const InviteButton = styled(ThemeButton)`
  background-color: #FF6060;
  position: absolute;
  height: 36px;
  left: 37.8%;
  top: 66.6%;

  &:hover {
    background-color: #FF6060AA;
  }
`;

const getStageInfo = (stage: string): ReactNode => {
  switch (stage) {
    case 'INVESTIGATING1':
      return <>
        <span style={{ fontSize: '32px' }}>第一幕</span><br />
        山灵之祸<br />
        <span style={{ fontSize: '28px' }}>巴康寨 9:00</span>
      </>
    case 'INVESTIGATING2':
    case 'DISCUSSING2':
      return <>
        <span style={{ fontSize: '32px' }}>第二幕</span><br />
        鹃歌之椁<br />
        <span style={{ fontSize: '28px' }}>坟场 15:00</span>
      </>
    case 'INVESTIGATING3':
    case 'VOTING':
      return <>
        <span style={{ fontSize: '32px' }}>第三幕</span><br />
        巫师之殁<br />
        <span style={{ fontSize: '28px' }}>巫师家 18:00</span>
      </>
    default:
      return null;
  }
}

const MapInfoWrapper = styled(Stack)`
  position: absolute;
  top: 18vh;
  right: 4vw;
  color: white;
  font-size: 20px;
  width: 14vw;
  font-family: 'STZhongSong';
  text-align: justify;
`;

const HouseImage = styled.img`
  width: 12vw;
  margin-left: 10px;
  margin-bottom: 10px;
  border: 3px solid white;
  border-radius: 8px;
`;

export interface EXSL_InvestigatePageProps extends CommonUIProps {
  showMap: boolean;
  gameTime: string;
  numRemainingClues: Record<string, number>;
  locationHasNewClue: string[];
  onLocationButtonClicked: (location: string) => void;
  pickClueDialog: ReactNode;
  showPickClueDialog: boolean;
  setShowPickClueDialog: (_: boolean) => void;
  isShamanHouseInvestigated: boolean;
  showInviteButton: boolean;
  onInviteButtonClicked: () => void;
  showConfirmDialog: boolean;
  handleConfirmDialogClose: () => void;
  onConfirmInvite: () => void;
  showStageTransition1: boolean;
  showStageTransition2: boolean;
  onStageTransitionEnd: (_: Event, reason: "backdropClick" | "escapeKeyDown") => void;
}

export const EXSL_InvestigatePage: FC<EXSL_InvestigatePageProps> = function ({
  showMap,
  gameTime,
  numRemainingClues,
  locationHasNewClue,
  onLocationButtonClicked,
  pickClueDialog,
  showPickClueDialog,
  setShowPickClueDialog,
  isShamanHouseInvestigated,
  showInviteButton,
  onInviteButtonClicked,
  showConfirmDialog,
  handleConfirmDialogClose,
  onConfirmInvite,
  showStageTransition1,
  showStageTransition2,
  onStageTransitionEnd,
  ...props
}) {

  const gameStage = useContext(GameContext)?.stage ?? '';

  return (
    <div {...props}>
      {showMap &&
        <>
          <MapContainer>
            <div style={{ width: '100%', position: 'relative' }}>
              <Map src={getAssetUrl('/EXSL/map.webp')} draggable={false} />
              {showInviteButton && <InviteButton width="72px" onClick={onInviteButtonClicked}>邀请</InviteButton>}
              {['INVESTIGATING2', 'DISCUSSING2', 'INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameStage) && <>
                <BaKangZhai badge={numRemainingClues['巴康寨']} badgeStyle={{ top: '37%', left: '-100px' }} hasNewClue={locationHasNewClue.includes('巴康寨')} onClick={() => onLocationButtonClicked('巴康寨')} />
                <Station badge={numRemainingClues['驿站']} badgeStyle={{ top: '5px', left: '-150px' }} hasNewClue={locationHasNewClue.includes('驿站')} onClick={() => onLocationButtonClicked('驿站')} />
                <GraveYard badge={numRemainingClues['坟场']} badgeStyle={{ top: '5px', left: '-150px' }} hasNewClue={locationHasNewClue.includes('坟场')} onClick={() => onLocationButtonClicked('坟场')} />
                <TeaField badge={numRemainingClues['茶田']} badgeStyle={{ top: '5px', left: '-150px' }} hasNewClue={locationHasNewClue.includes('茶田')} onClick={() => onLocationButtonClicked('茶田')} />
                <FishMan badge={numRemainingClues['渔家']} badgeStyle={{ top: '8px' }} hasNewClue={locationHasNewClue.includes('渔家')} onClick={() => onLocationButtonClicked('渔家')} />
                <ReedMarshes badge={numRemainingClues['芦苇荡']} badgeStyle={{ top: '10px' }} hasNewClue={locationHasNewClue.includes('芦苇荡')} onClick={() => onLocationButtonClicked('芦苇荡')} />
              </>}

              {['INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameStage) && <>
                <ShamanHouse badge={numRemainingClues['巫师家']} badgeStyle={{ top: '-45px' }} hasNewClue={locationHasNewClue.includes('巫师家')} onClick={() => onLocationButtonClicked('巫师家')} />
                {isShamanHouseInvestigated && <>
                  <WestYa badge={numRemainingClues['西垭']} badgeStyle={{ top: '-45px' }} hasNewClue={locationHasNewClue.includes('西垭')} onClick={() => onLocationButtonClicked('西垭')} />
                  <EastYa badge={numRemainingClues['东垭']} badgeStyle={{ bottom: '-45px' }} hasNewClue={locationHasNewClue.includes('东垭')} onClick={() => onLocationButtonClicked('东垭')} />
                  <OldCabin badge={numRemainingClues['旧木屋']} badgeStyle={{ top: '37%', right: '-100px' }} hasNewClue={locationHasNewClue.includes('旧木屋')} onClick={() => onLocationButtonClicked('旧木屋')} />
                  <ZiLinTower badge={numRemainingClues['姊陵塔']} badgeStyle={{ top: '37%', right: '-100px' }} hasNewClue={locationHasNewClue.includes('姊陵塔')} onClick={() => onLocationButtonClicked('姊陵塔')} />
                </>
                }
              </>}
            </div>
          </MapContainer>
          <GameStageInfo>
            {getStageInfo(gameStage)}
          </GameStageInfo>
          <MapInfoWrapper  direction="column" spacing={2}>
            <Stack direction="column" alignItems="center">
              <HouseImage src={getAssetUrl('/EXSL/diaojiaolou.webp')} />
              <span style={{ fontSize: '24px' }}>注：吊脚楼外观</span>
            </Stack>
            <span>
              步行路程：<br /><br />
              从“巴康寨”内到“旧木屋”要经过石桥再沿河走，约1小时；<br /><br />
              “旧木屋”到“东垭”沿河走，约半小时；<br /><br />
              “东垭”到“西垭”要经过木桥，约半小时；<br /><br />
              “西垭”到“巴康寨”要从北面的山间绕过坟场，约1小时。
            </span>
          </MapInfoWrapper>
        </>
      }
      {pickClueDialog}
      <ConfirmDialog open={showConfirmDialog} onClose={handleConfirmDialogClose} title="确定邀请众人前往巴家吗？" content="在你邀请之后，游戏会进入下一阶段。" onConfirmButtonClicked={onConfirmInvite} />
      <EXSL_StageTransition1 open={showStageTransition1} onClose={onStageTransitionEnd} />
      <EXSL_StageTransition2 open={showStageTransition2} onClose={onStageTransitionEnd} />
    </div>
  );
};

export default EXSL_InvestigatePage;
