import axios, { AxiosError } from 'axios';
import { DefaultApi, RefreshTokenRes } from 'openapi';
import { FC, useCallback, useContext, useEffect } from 'react';
import createAuthRefreshInterceptor, { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';
import localStorageService from './localStorage';
import { UserContext } from './contexts';

const BASE_URL = process.env['REACT_APP_AXIOS_SERVER_URL'];
const axiosInstance = axios.create({ baseURL: BASE_URL });

let apiInstance: DefaultApi;

const clientApi = (): DefaultApi => {
  if (apiInstance) {
    return apiInstance;
  }
  apiInstance = new DefaultApi(undefined, BASE_URL, axiosInstance);
  return apiInstance;
};

export const updateAccessToken = (accessToken: string) => {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
}

// This component must be included inside Router and store provider
export const SetupInterceptorsComponent: FC = function () {
  const { user, setUser } = useContext(UserContext);

  // Function that will be called to refresh authorization
  const refreshAuthLogic = useCallback(
    async (error: AxiosError) => {
      const refreshToken = localStorageService.getRefreshToken();
      // Verify refresh token and resend the request if:
      // 1. refresh token is available
      if (refreshToken) {
        return axios.post(`${BASE_URL}/auth/refresh`,{ refreshToken }, { skipAuthRefresh: true } as AxiosAuthRefreshRequestConfig)
          .then((res) => {
            if (res.status === 200) {
              const response: RefreshTokenRes = res.data;
              // Save new access token and refresh token
              localStorageService.setAccessToken(response.accessToken);
              localStorageService.setRefreshToken(response.refreshToken);

              // Change the authorization header
              updateAccessToken(response.accessToken);

              return Promise.resolve();
            }
            // Refresh token failed or refresh token expired, clean up login information
            setUser(undefined);
            throw error;
          })
          .catch((error_: unknown) => {
            // Refresh token failed, clean up login information
            setUser(undefined);
            throw error_;
          });
      }
      setUser(undefined);
      throw error;
    },
    [setUser]
  );

  useEffect(() => {
    axiosInstance.interceptors.request.use(
      (config) => {
        const token = localStorageService.getAccessToken();
        if (token) {
          return {
            ...config,
            headers: {
              ...config.headers,
              Authorization: `Bearer ${token}`,
            },
          };
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Instantiate the interceptor
    createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {
      statusCodes: [401],
      pauseInstanceWhileRefreshing: false,
      interceptNetworkError: true,
      retryInstance: axiosInstance,
    });
  }, [user, refreshAuthLogic]);
  return null;
};

export default clientApi;
