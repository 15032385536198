import styled from '@emotion/styled';
import { FC } from 'react';
import { Grid, Popover } from '@mui/material';
import { CommonUIProps } from 'utils/types';
import { Stickers } from 'utils/stickerManager';

const IPopover = styled(Popover)`
  .MuiPaper-root {
    background-color: white;
    border-radius: 20px;

    &::-webkit-scrollbar {
      width: 16px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #274472;
      border-radius: 100px;
      border: 4px solid transparent;
      background-clip: content-box;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background-color: #274472AA;
    }
  }
`;

const ContentWrapper = styled(Grid)`
  width: 420px;
  height: 500px;
  padding: 12px;
`;

const StickerWrapper = styled.div`
  height: 120px;
  width: 120px;
  border-radius: 12px;
  cursor: pointer;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;

  &:hover {
    border: 2px solid #B4B4B4;
  }
`;

const StickerImg = styled.img`

`;


export interface StickerPopoverProps extends CommonUIProps {
  anchorEl: HTMLButtonElement | null,
  showPopover: boolean,
  handlePopoverClose: () => void,
  onStickerClicked: (stickerId: string) => void;
}

export const StickerPopover: FC<StickerPopoverProps> = function ({
  anchorEl,
  showPopover,
  handlePopoverClose,
  onStickerClicked,
  ...props
}) {

  return (
    <IPopover
      open={showPopover}
      onClose={handlePopoverClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      {...props}
    >
      <ContentWrapper container>
        {Object.entries(Stickers).map(([stickerId, stickerSrc]) =>
          <Grid key={stickerId} item xs={4} style={{ textAlign: 'center' }}>
            <StickerWrapper onClick={() => onStickerClicked(stickerId)}>
              <StickerImg src={stickerSrc} />
            </StickerWrapper>
          </Grid>)}
      </ContentWrapper>
    </IPopover>
  );
};

export default StickerPopover;
