import styled from '@emotion/styled';
import { FC, useEffect, useMemo, useState } from 'react';
import { DialogProps, Stack, Tooltip, TooltipProps } from '@mui/material';
import { getAssetUrl } from 'utils/utils';
import { ThemeButton } from 'lib/common/ThemeButton';
import { FullDialog } from 'lib/common/FullDialog';
import { FlipBackKeyframes, FlipKeyframes, ZZZKeyframes } from 'utils/keyframes';
import { ClueRes } from 'openapi';
import FlipSound from 'assets/sound/flip.mp3';
import useSound from 'use-sound';
import StarIcon from '@mui/icons-material/Star';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { ClipColor, Clips } from './ClueClipDialogBinder';
import { CommonIconButton } from 'lib/common/CommonIconButton';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import Mist from 'assets/mist.webp';

const ContentWrapper = styled(Stack)`
  width: 100vw;
  height: 100dvh;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
`;

const TitleBox = styled.div`
  width: 100vw;
  padding-top: 30px;
  color: white;
  font-size: 56px;
  font-family: '黑体';
  text-align: center;
`;

const ClueContainer = styled.div`
  width: 100vw;
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`;

const ClueCardContainer = styled.div<{ shouldFlip?: boolean }>`
  width: calc(33.2vh + 6px);
  height: calc(48vh + 6px);
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 8px;
  position: relative;
`;

const FlipAnimationWrapper = styled.div<{ flip?: boolean, animate?: boolean }>`
  transform-style: preserve-3d;
  animation-duration: 0.8s;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-name: ${(props) => props.animate === true ? FlipKeyframes : (props.animate === false ? FlipBackKeyframes : null)};
  transform: rotateY(${props => props.flip ? '180deg' : '0deg'});
  perspective: 1000px;
`;

const ClueCard = styled.img<{ clickable?: boolean, isFound?: boolean, isPublic?: boolean }>`
  width: 33.2vh;
  height: 48vh;
  border: 3px solid white;
  border-radius: 20px;
  backface-visibility: hidden;
  cursor: ${(props) => (props.isFound || !props.clickable) ? 'auto' : 'pointer'};
  filter: ${(props) => props.isPublic ? 'brightness(1.0)' : ((props.isFound || !props.clickable) ? 'brightness(0.7)' : 'brightness(0.9)')};

  &:hover {
    filter: ${(props) => props.isPublic ? 'brightness(1.0)' : ((props.isFound || !props.clickable) ? 'brightness(0.7)' : 'brightness(1.1)')};
  }
`;

const FlipCard = styled(ClueCard)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const FlipCardFront = styled(ClueCard)`
  transform: rotateY(-180deg);
`;

const ClueText = styled.div`
  height: 35px;
  margin-top: 12px;
  font-size: 24px;
  color: white;
  font-family: '微软雅黑';
  text-align: center;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.4), 0 6px 20px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
`;

const Footer = styled.div`
  width: 120px;
  padding-bottom: 24px;
`;

const SleepyZ = styled.div`
  animation-name: ${ZZZKeyframes};
  animation-duration: 3s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
  color: rgba(0,171,208);
  font-weight: bold;
  position: absolute;
  z-index: 100;
  transform: translateY(100%);
  font-family: 'Concert One', cursive;
  top: 0;
`;

const MistCover = styled.img`
  position: absolute;
  width: 38vh;
  left: -2.4vh;
  top: -2.4vh;
  z-index: 5;
`;

const FlipButtonWrapper = styled.div`
  height: 40px;
`;

const FlipButton = styled(CommonIconButton)`
  width: 40px;
  transition: transform 0.8s ease-in-out;
`;

export const ClipBadge = styled.div<{ color?: string }>`
  width: 36px;
  height: 48px;
  border-radius: 3px;
  padding-top: 5px;
  font-family: 'Mircrosoft Yahei';
  font-size: 24px;
  color: white;
  text-align: center;
  position: absolute;
  top: -16px;
  left: 25px;
  background-color: #ffe6a7;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;

  &:before {
    background-color: inherit;
    content: '';
    height: 26px;
    width: 26px;
    position: absolute;
    top: 33px;
    left: 5.25px;
    border-radius: 3px;
    transform: rotate(45deg);
  }
`;

export const ClipTooltip = styled((({ className, ...props }: TooltipProps) => (
  <Tooltip enterTouchDelay={0} leaveDelay={300} classes={{ popper: className }} {...props} />
)))`
  & .MuiTooltip-tooltip {
    max-width: 600px;
    background-color: white;
    border-radius: 16px;
  }

  & .MuiTooltip-arrow {
    color: white;
    font-size: 16px;
  }
`;

export interface PickClueDialogProps extends DialogProps {
  clues?: Record<string, ClueRes>;
  currentCharacter?: string;
  location?: string;
  newClues?: string[];
  onClueButtonClicked: (clue: string) => void;
  onPublishButtonClicked: (clue: string) => void;
  allowToInvestigate: boolean;
  forbiddenClues?: string[];
  unavailableClues?: string[];
  sleepAnimation?: string[];
  newCharacters?: Record<string, string | undefined>;
  onClipButtonClicked: (clue: string, clip?: string) => void;
  clipedClues?: Record<string, string>;
  mistCover?: string[];
}

export const PickClueDialog: FC<PickClueDialogProps> = function ({
  clues,
  currentCharacter,
  location,
  newClues,
  onClueButtonClicked,
  onPublishButtonClicked,
  allowToInvestigate,
  forbiddenClues,
  unavailableClues,
  sleepAnimation,
  newCharacters,
  onClipButtonClicked,
  clipedClues,
  mistCover,
  ...props
}) {

  const [playFlipSound] = useSound(FlipSound, { volume: 0.5, interrupt: true });
  const [hideActionButton, setHideActionButton] = useState<Record<string, boolean>>({});

  const [flipAnimation, setFlipAnimation] = useState<Record<string, boolean>>({});
  const clueState = useMemo(() => {
    return Object.fromEntries(Object.entries(clues ?? {}).map(([clue, clueInfo]) => [clue, newClues?.includes(clue) ? false : (clueInfo.isPublic || clueInfo.isFound === currentCharacter)]));
  }, [clues, currentCharacter, newClues]);

  useEffect(() => {
    if (props.open && newClues) {
      setFlipAnimation(Object.fromEntries(newClues.map((clue) => [clue, false])));
    }
  }, [newClues, props.open]);

  useEffect(() => {
    if (!props.open) {
      setHideActionButton({});
      setFlipAnimation({});
    }
  }, [props.open])

  return (
    <FullDialog {...props}>
      <ContentWrapper direction="column" alignItems="center">
        <TitleBox>{`${location}的线索`}</TitleBox>
        <ClueContainer>
          {Object.entries(clues ?? {}).map(([clue, clueInfo]) => (
            <Stack key={clue} direction="column" alignItems="center">
              <FlipButtonWrapper>
                {(clueInfo.isPublic || clueInfo.isFound === currentCharacter) &&
                  <FlipButton style={{ transform: flipAnimation[clue] ? 'rotate(0deg)' : 'rotate(180deg)' }}
                    icon={<FlipCameraAndroidIcon htmlColor="white" />}
                    onClick={() => setFlipAnimation((prev) => ({ ...prev, [clue]: (flipAnimation[clue] === undefined) ? true : !flipAnimation[clue] }))}
                  />}
              </FlipButtonWrapper>
              <ClueCardContainer>
                
                <FlipAnimationWrapper flip={clueState[clue]} animate={flipAnimation[clue]} onAnimationStart={() => { playFlipSound(); setHideActionButton((prev) => ({ ...prev, [clue]: true })) }} onAnimationEnd={() => { if (flipAnimation[clue] === false) setHideActionButton((prev) => ({ ...prev, [clue]: false })) }}>
                  <FlipCardFront src={getAssetUrl(clueInfo.frontUrl)} isFound isPublic clickable={false} draggable={false} onContextMenu={(e) => e.preventDefault()} />
                  <FlipCard src={getAssetUrl(clueInfo.backUrl)} isFound={!!clueInfo.isFound} isPublic={clueInfo.isPublic || clueInfo.isFound === currentCharacter} clickable={allowToInvestigate && !unavailableClues?.includes(clue) && !forbiddenClues?.includes(clue)} onClick={() => onClueButtonClicked(clue)} draggable={false} onContextMenu={(e) => e.preventDefault()} />
                </FlipAnimationWrapper>

                {!hideActionButton[clue] && (clueInfo.isPublic || clueInfo.isFound === currentCharacter) &&
                  <ClipTooltip arrow placement="top" title={
                    <Stack direction="row" spacing={1}>
                      {Clips.map((clip) =>
                        <CommonIconButton key={clip} icon={<StarIcon fontSize="large" htmlColor={ClipColor[clip]} />} onClick={() => onClipButtonClicked(clue, clip)} />
                      )}
                      <CommonIconButton icon={<NotInterestedIcon fontSize="large" htmlColor="red" />} onClick={() => onClipButtonClicked(clue)} />
                    </Stack>}>
                    <ClipBadge>
                      <StarIcon htmlColor={ClipColor[clipedClues?.[clue] ?? '']} />
                    </ClipBadge>
                  </ClipTooltip>}

                {sleepAnimation?.includes(clue) && <>
                  <SleepyZ style={{ animationDelay: "0s", right: '10px' }}>Z</SleepyZ>
                  <SleepyZ style={{ animationDelay: "0.75s", right: '-20px' }}>Z</SleepyZ>
                  <SleepyZ style={{ animationDelay: "1.5s", right: '0px' }}>Z</SleepyZ>
                </>}
                {mistCover?.includes(clue) && <MistCover src={Mist} />}
              </ClueCardContainer>
              <ClueText>
                {clueInfo.isFound
                  ? <>
                    {clueInfo.isPublic ? <>由<span style={{ color: '#FFC96B' }}>{newCharacters?.[clueInfo.isFound as string] ?? clueInfo.isFound}</span>公开</>
                      : <>
                        {clueInfo.isFound === currentCharacter ? <>
                          <span
                            style={{ color: '#4EB7FE', textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => onPublishButtonClicked(clue)}
                          >
                            点击此处
                          </span>
                          公开该线索
                        </> : `已被${newCharacters?.[clueInfo.isFound as string] ?? clueInfo.isFound}调查`}
                      </>}
                  </>
                  : <>
                    {(allowToInvestigate && !forbiddenClues?.includes(clue)) ? (unavailableClues?.includes(clue) && '暂时无法调查') : '你无法调查这里'}
                  </>}
              </ClueText>
            </Stack>
          ))}
        </ClueContainer>
        <Footer>
          <ThemeButton onClick={() => props.onClose?.({}, "escapeKeyDown")}>返回</ThemeButton>
        </Footer>
      </ContentWrapper>
    </FullDialog>
  );
};

export default PickClueDialog;
