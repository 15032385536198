import { useCallback, useContext, useMemo } from 'react';
import { SecretDialog, SecretDialogProps } from './SecretDialog';
import { createBinder } from 'utils/utils';
import { GameContext, StoryContext, UserContext } from 'utils/contexts';
import { socket } from 'utils/socketConnector';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

export type SecretDialogBinderManagedProps = 'privateAvailableSecrets' | 'conditionSatisfied' | 'secrets' | 'currentCharacter' | 'onSecretButtonClicked' | 'onPublishButtonClicked'
| 'onClipButtonClicked' | 'clipedClues'

export interface SecretDialogBinderProps extends Omit<SecretDialogProps, SecretDialogBinderManagedProps> { }

const useSecretDialogBinder = (props: SecretDialogBinderProps): SecretDialogProps => {

  const { user } = useContext(UserContext);
  const gameAudit = useContext(GameContext);
  const currentCharacter = gameAudit?.players?.[user?.userId ?? '']?.character;
  const storyDetail = useContext(StoryContext);
  const { gameId } = useParams();

  const isAudience = useMemo(() => !Object.keys(gameAudit?.players ?? {}).includes(user?.userId ?? ''), [gameAudit?.players, user?.userId]);

  const privateAvailableSecrets = useMemo(() => {
    let secrets: string[] = [];
    gameAudit?.players?.[user?.userId ?? '']?.privateClues?.forEach((privateClue) => {
      const clueSecrets  = Object.values(gameAudit?.rooms).find((roomInfo) => Object.keys(roomInfo.clues).includes(privateClue))?.clues[privateClue].secrets;
      if (clueSecrets) {
        secrets = [...secrets, ...clueSecrets];
      }
      const secretSecrets = gameAudit?.secrets?.[privateClue]?.secrets;
      if (secretSecrets) {
        secrets = [...secrets, ...secretSecrets];
      }
    })
    return secrets;
  }, [gameAudit?.players, gameAudit?.rooms, gameAudit?.secrets, user?.userId]);

  const conditionSatisfied = useMemo(() => {
    return Object.fromEntries(Object.entries(gameAudit?.secrets ?? {}).map(([secret, secretInfo]) => {
      if (secretInfo.condition) {
        if (secretInfo.condition.slice(0,4) === 'clue') {
          const clueInfo  = Object.values(gameAudit?.rooms ?? {}).find((roomInfo) => Object.keys(roomInfo.clues).includes(secretInfo.condition ?? ''))?.clues[secretInfo.condition];
          return [secret, (secretInfo.isAvailable || privateAvailableSecrets.includes(secret)) && (clueInfo?.isPublic || clueInfo?.isFound === currentCharacter)];
        } else {
          const conditionSecretInfo = gameAudit?.secrets?.[secretInfo.condition];
          return [secret, (secretInfo.isAvailable || privateAvailableSecrets.includes(secret)) && (conditionSecretInfo?.isPublic || conditionSecretInfo?.isFound === currentCharacter)];
        }
        
        
      } else {
        return [secret, secretInfo.isAvailable || privateAvailableSecrets.includes(secret)];
      }
    }))
  }, [currentCharacter, gameAudit?.rooms, gameAudit?.secrets, privateAvailableSecrets]);

  const onSecretButtonClicked = useCallback((secret: string) => {
    if (!conditionSatisfied[secret]) {
      return;
    }
    if (gameAudit?.clueTurn !== storyDetail?.characterList[currentCharacter ?? ''].index) {
      enqueueSnackbar('目前还未轮到您调查', { variant: 'error' });
      return;
    }
    if (!isAudience 
      && !gameAudit?.secrets?.[secret].isFound
      && gameAudit?.stage.slice(0,-1) === 'INVESTIGATING') {
      socket?.emit('playerPickSecret', user?.userId, gameId, secret);
    }
  }, [conditionSatisfied, currentCharacter, gameAudit?.clueTurn, gameAudit?.secrets, gameAudit?.stage, gameId, isAudience, storyDetail?.characterList, user?.userId]);

  const onPublishButtonClicked = useCallback((secret: string) => {
    if (!isAudience && gameAudit?.secrets?.[secret].isFound === currentCharacter) {
      socket?.emit('playerPublishSecret', user?.userId, gameId, secret);
    }
  }, [currentCharacter, gameAudit?.secrets, gameId, isAudience, user?.userId]);

  const onClipButtonClicked = useCallback((clue: string, clip?: string) => {
    socket?.emit('playerUpdateClipedClue', user?.userId, gameId, clue, clip);
  }, [gameId, user?.userId]);

  const managedProps: Pick<SecretDialogProps, SecretDialogBinderManagedProps> = {
    privateAvailableSecrets,
    conditionSatisfied,
    secrets: gameAudit?.secrets,
    currentCharacter,
    onSecretButtonClicked,
    onPublishButtonClicked,
    onClipButtonClicked,
    clipedClues: gameAudit?.players?.[user?.userId ?? '']?.clipedClues,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const SecretDialogBinder = createBinder(SecretDialog, useSecretDialogBinder);

export default SecretDialogBinder;
