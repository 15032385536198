import styled from '@emotion/styled';
import { FC } from 'react';
import { CommonUIProps } from 'utils/types';
import logo from 'assets/logo1024.png';
import { LinearProgress, Stack } from '@mui/material';
import { ThemeButton } from 'lib/common/ThemeButton';
import { StoryRes } from 'openapi';

const LogoImage = styled.img`
  height: 800px;
`;

const LoadingText = styled.div`
  color: white;
  font-size: 24px;
  margin-top: 15px;
`;

const ErrorMsgBox = styled.div`
  color: #ff5252;
  font-size: 32px;
  display: flex;
  text-align: center;
`;

const ProgressBar = styled(LinearProgress)`
  margin-bottom: 12px;
  width: 25vw;
  background-color: #C3E0E5;
  height: 8px;
`;

export interface LoadingPageProps extends CommonUIProps {
  errorMsg?: string;
  progress: number;
  onLoadingComplete?: (_storyDetail: StoryRes) => void;
  onReturnButtonClicked: () => void;
}

export const LoadingPage: FC<LoadingPageProps> = function ({
  errorMsg,
  progress,
  onReturnButtonClicked
}) {
  return (
    <>
      <LogoImage src={logo} />
      {errorMsg ? <Stack direction="column" alignItems="center" spacing={4}>
        <ErrorMsgBox>
          错误：{errorMsg}
          {errorMsg === '剧本体验次数不足' && <>
            <br /><br />
            升级成会员来享受每月更多的剧本体验次数！
          </>}
        </ErrorMsgBox>
        <ThemeButton width="120px" onClick={onReturnButtonClicked}>返回</ThemeButton>
      </Stack> : <>
        <ProgressBar variant="determinate" value={progress} />
        <LoadingText>调取档案中...</LoadingText>
      </>}
    </>
  );
};

export default LoadingPage;
