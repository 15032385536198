import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AppBinder from './binders/AppBinder';

const root = createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AppBinder />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
