import styled from '@emotion/styled';
import { FC, ChangeEvent, ReactNode } from 'react';
import { Button, Stack, TextField } from '@mui/material';
import { CommonUIProps } from 'utils/types';
import Logo from 'assets/logo1024.png';
import { ThemeButton } from 'lib/common/ThemeButton';
import { UserInfo } from 'utils/localStorage';
import { getStoryPosterUrl, parseAvatarUrl } from 'utils/utils';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { StoryData } from 'utils/storyData';
import PageFooter from './PageFooter';
import { CommonDialog } from 'lib/common/CommonDialog';

const PageContainer = styled.div`
  width: 100%;
  min-height: 100dvh;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
`;

const PageHeader = styled(Stack)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 16px;
  height: 76px;
  padding-right: 32px;
`;

const LoginButton = styled(Button)`
  width: 96px;
  height: 48px;
  font-size: 24px;
  border-radius: 12px;
  border: 2px #efefef solid;
  background-color: #343a40;
  color: white;

  &:hover {
    background-color: #475059;
  }
`;

const MyCasesButton = styled(ThemeButton)`
  width: 100px;
  height: 40px;
`;

const SubscriptionButton = styled.div`
  color: white;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
  }
`;

const CaseIDInput = styled.input`
  width: 600px;
  height: 56px;
  text-align: center;
  border-radius: 8px;
  font-size: 24px;
  background-color: white;
  border: 3px solid black;

  :focus::placeholder {
    color: transparent;
  }

  :focus {
    outline: none;
    border: 3px solid #274472;
  }
`;

const ErrorMsgBox = styled.div`
  margin-top: 8px;
  font-size: 18px;
  color: #df1818;
`;

const LogoImage = styled.img`
  height: calc(100dvh - 268px);
`;

const VersionText = styled.div`
  color: white;
  margin-bottom: 12px;
`;

const ButtonStack = styled(Stack)`
  margin-top: 24px;
`;

const HomePageButton = styled(ThemeButton)`
  font-size: 24px;
  font-weight: bold;
`;

const SeparatorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  font-weight: bold;
  color: white;
  margin-top: 120px;
`;

const Separator = styled.div`
  height: 5px;
  width: 150px;
  background-color: white;
  margin-left: 100px;
  margin-right: 100px;
`;

const CarouselWrapper = styled.div`
  width: 100vw;
  margin-top: 48px;
`;

const Poster = styled.img`
  width: 300px;
  height: 450px;
  display: flex;
  justify-content: center;
  margin: auto;
`;

const EditNicknameDialogContentWrapper = styled(Stack)`
  width: 280px;
`;

const EditAvatarDialogContentWrapper = styled(Stack)`
  width: 420px;
  margin-top: 4px;
`;

const AvatarGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

interface AvatarImageProps {
  isActive: boolean;
}

const AvatarImageWrapper = styled.div<AvatarImageProps>`
  width: 80px;
  height: 80px;
  border-radius: 42px;
  border: ${(props) => (props.isActive ? `2px solid #189AB4` : '2px solid transparent')};
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 8px;
  cursor: pointer;
  position: relative;

  &:hover {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: -2px;
      left: -2px;
      width: 80px;
      height: 80px;
      border-radius: 42px;
      background: rgba(255,255,255, 0.16);
    }
  }
`;

const AvatarImage = styled.img`
  width: 80px;
`;

const responsive = {
  largeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 8,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  }
};

export interface HomePageProps extends CommonUIProps {
  user?: UserInfo;
  errorMsg?: string;
  gameId: string;
  onLoginButtonClicked: () => void;
  onGameIdInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onJoinGameButtonClicked: () => void;
  onCreateGameButtonClicked: () => void;
  loginDialog: ReactNode;
  createGameDialog: ReactNode;
  avatarPopover: ReactNode;
  subscriptionDialog: ReactNode;
  subscriptionSuccessDialog: ReactNode;
  onSubscriptionButtonClicked: () => void;
  myCasesDialog: ReactNode;
  onMyCasesButtonClicked: () => void;
  avatars: string[],
  newNickname?: string,
  newAvatar?: string,
  handleNewNicknameChange: (e: ChangeEvent<HTMLInputElement>) => void,
  handleNewAvatarChange: (choice: string) => void,
  onConfirmEditNickname: () => void,
  onConfirmEditAvatar: () => void,
  showEditNicknameDialog: boolean,
  handleEditNicknameDialogClose: () => void,
  showEditAvatarDialog: boolean,
  handleEditAvatarDialogClose: () => void,
  welcomeDialog: ReactNode,
}

export const HomePage: FC<HomePageProps> = function ({
  user,
  errorMsg,
  gameId,
  onLoginButtonClicked,
  onGameIdInputChange,
  onJoinGameButtonClicked,
  onCreateGameButtonClicked,
  loginDialog,
  createGameDialog,
  avatarPopover,
  subscriptionDialog,
  subscriptionSuccessDialog,
  onSubscriptionButtonClicked,
  myCasesDialog,
  onMyCasesButtonClicked,
  avatars,
  newNickname,
  newAvatar,
  handleNewNicknameChange,
  handleNewAvatarChange,
  onConfirmEditNickname,
  onConfirmEditAvatar,
  showEditNicknameDialog,
  showEditAvatarDialog,
  handleEditNicknameDialogClose,
  handleEditAvatarDialogClose,
  welcomeDialog,
  ...props
}) {

  return (
    <PageContainer {...props}>
      <PageHeader direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
        {!!user && <SubscriptionButton onClick={onSubscriptionButtonClicked}>订阅和打赏</SubscriptionButton>}
        {!!user && <MyCasesButton onClick={onMyCasesButtonClicked}>我的案件</MyCasesButton>}
        {user ? avatarPopover : <LoginButton onClick={onLoginButtonClicked}>登录</LoginButton>}
      </PageHeader>
      <LogoImage src={Logo} />
      <VersionText>测试版 v0.2.10</VersionText>
      <CaseIDInput placeholder='Please enter the 6-digit case ID' value={gameId} onChange={onGameIdInputChange} />
      <ErrorMsgBox>{errorMsg}</ErrorMsgBox>
      <ButtonStack direction="row" spacing={6}>
        <HomePageButton color="primary" width="240px" height="60px" onClick={onJoinGameButtonClicked}>进入案件</HomePageButton>
        <HomePageButton color="primary" width="240px" height="60px" onClick={onCreateGameButtonClicked}>新的案件</HomePageButton>
      </ButtonStack>
      <SeparatorWrapper>
        <Separator />
        案件列表
        <Separator />
      </SeparatorWrapper>
      <CarouselWrapper>
        <Carousel
          infinite
          responsive={responsive}
          autoPlay
        >
          {Object.keys(StoryData).map((storyId) =>
            <Poster key={storyId} src={getStoryPosterUrl(storyId)} draggable={false} />
          )}
        </Carousel>
      </CarouselWrapper>
      <PageFooter />
      {loginDialog}
      {createGameDialog}
      {subscriptionDialog}
      {subscriptionSuccessDialog}
      {myCasesDialog}
      <CommonDialog title="修改昵称" open={showEditNicknameDialog} onClose={handleEditNicknameDialogClose}>
        <EditNicknameDialogContentWrapper direction="column" alignItems="center" spacing={2}>
          <TextField fullWidth value={newNickname} placeholder="新的昵称" onChange={handleNewNicknameChange} />
          <ThemeButton width="120px" onClick={onConfirmEditNickname}>确认修改</ThemeButton>
        </EditNicknameDialogContentWrapper>
      </CommonDialog>
      <CommonDialog title="修改头像" open={showEditAvatarDialog} onClose={handleEditAvatarDialogClose}>
        <EditAvatarDialogContentWrapper direction="column" alignItems="center" spacing={2}>
          <AvatarGrid>
            {avatars.map((avatarChoice) => (
              <AvatarImageWrapper key={avatarChoice} isActive={avatarChoice === newAvatar} onClick={() => handleNewAvatarChange(avatarChoice)}>
                <AvatarImage src={parseAvatarUrl(avatarChoice)}/>
              </AvatarImageWrapper>
            ))}
          </AvatarGrid>
          <ThemeButton width="120px" onClick={onConfirmEditAvatar}>确认修改</ThemeButton>
        </EditAvatarDialogContentWrapper>
      </CommonDialog>
      {welcomeDialog}
    </PageContainer>
  );
};

export default HomePage;
