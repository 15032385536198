import styled from '@emotion/styled';
import { FC, useState, useContext } from 'react';
import { DialogProps, InputBase, Stack } from '@mui/material';
import { CommonDialog } from 'lib/common/CommonDialog';
import { useParams } from 'react-router-dom';
import { GameContext } from 'utils/contexts';
import { StoryData } from 'utils/storyData';
import { ThemeButton } from 'lib/common/ThemeButton';
import { enqueueSnackbar } from 'notistack';

const ContentWrapper = styled(Stack)`
  width: 420px;
`;

const InviteInput = styled(InputBase)`
  height: 130px;
  width: 100%;
  color: black;
  align-items: flex-start;
  background-color: #EFEFEF;
  border-radius: 8px;
  border: 1px solid #9B9B9B;
  padding: 16px 14px 16px 14px;
  overflow: auto;
  text-align: justify;
`;

export interface InviteLinkDialogProps extends DialogProps { }

export const InviteLinkDialog: FC<InviteLinkDialogProps> = function ({
  ...props
}) {

  const storyId = useContext(GameContext)?.storyId ?? '';
  const { gameId } = useParams();
  const [inviteText, setInviteText] = useState<string>(`《${StoryData[storyId].name}》剧本房间已经开好，案件编号${gameId}，快通过链接https://www.agencyoftime.com/game/${gameId}上车，一起破案！`);

  return (
    <CommonDialog title="邀请链接" {...props}>
      <ContentWrapper direction="column" alignItems="center" spacing={2}>
        <InviteInput value={inviteText} multiline onChange={(e) => setInviteText(e.target.value)}/>
        <ThemeButton onClick={() => navigator.clipboard?.writeText(inviteText).then(() => enqueueSnackbar('邀请文本已复制到剪贴板', { variant: 'success' }))}>复制文本</ThemeButton>
      </ContentWrapper>
    </CommonDialog>
  );
};

export default InviteLinkDialog;
