import { createBinder, getAssetUrl } from "utils/utils";
import { YLSX_InvestigatePage, YLSX_InvestigatePageProps } from "./YLSX_InvestigatePage";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GameContext, UserContext, VolumeContext } from "utils/contexts";
import { socket } from "utils/socketConnector";
import { useParams } from "react-router-dom";
import PickClueDialogBinder from "lib/game/components/PickClueDialogBinder";
import { YLSX_ForbiddenRooms } from "./YLSX_StaticData";
import useSound from "use-sound";

export type YLSX_InvestigatePageBinderManagedProps = 'showMap' | 'showRestButton' | 'onRestButtonClicked' | 'isFloor3Locked'
  | 'isFrontYardLocked' | 'isBasementLocked' | 'numRemainingClues' | 'locationHasNewClue' | 'showLocationButtons'
  | 'pickClueDialog' | 'onLocationButtonClicked' | 'lockWobbles' | 'lockedRooms' | 'onLockAnimationEnd' | 'onLocationMaskClicked'
  | 'showStageTransition1' | 'showStageTransition2' | 'onStageTransitionEnd' | 'showConfirmDialog' | 'handleConfirmDialogClose' | 'onConfirmRest';

export interface YLSX_InvestigatePageBinderProps extends Omit<YLSX_InvestigatePageProps, YLSX_InvestigatePageBinderManagedProps> {
  showPickClueDialog: boolean;
  setShowPickClueDialog: (_: boolean) => void;
}

const useYLSX_InvestigatePageBinder = (props: YLSX_InvestigatePageBinderProps): YLSX_InvestigatePageProps => {

  const { user } = useContext(UserContext);
  const volume = useContext(VolumeContext);
  const gameAudit = useContext(GameContext);
  const { gameId } = useParams();
  const [newClues, setNewClues] = useState<Record<string, string[]>>({});
  const { showPickClueDialog, setShowPickClueDialog } = props;
  const [currentLocation, setCurrentLocation] = useState<string>();
  const [lockWobbles, setLockWobbles] = useState<Record<string, boolean>>({ 三楼: false, 正门: false, 侧门: false, 地下室: false });
  const lockedRooms = useMemo(() => ({
    三楼: !['INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameAudit?.stage ?? ''),
    前院: !gameAudit?.rooms['房间三']?.clues['clue41'].isPublic,
    地下室: !gameAudit?.secrets?.['secret06'].isPublic,
  }), [gameAudit?.rooms, gameAudit?.secrets, gameAudit?.stage]);
  const [showStageTransition1, setShowStageTransition1] = useState<boolean>(false);
  const [showStageTransition2, setShowStageTransition2] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const [musicPlay, { stop: musicStop }] = useSound(getAssetUrl('/YLSX/bgm.mp3'), {
    volume: volume / 100,
    loop: true,
    autoplay: true,
    interrupt: true,
  });

  useEffect(() => {
    return () => musicStop();
  }, [musicStop]);

  const numRemainingClues = useMemo(() =>
    Object.fromEntries(
      Object.entries(gameAudit?.rooms ?? {})
        .map(([room, roomInfo]) => [room, Object.values(roomInfo.clues).reduce(((numClues, clueInfo) => numClues + (clueInfo.isFound ? 0 : 1)), 0)]))
    , [gameAudit?.rooms]);

  useEffect(() => {

    const onClueFound = (clue: string, location: string) => {
      setNewClues((prev) => ({
        ...prev,
        [location]: prev[location] ? [...prev[location], clue] : [clue]
      }));
    }

    const onCluePublished = (clue: string, location: string, character: string) => {
      if (character !== gameAudit?.players?.[user?.userId ?? '']?.character) {
        setNewClues((prev) => ({
          ...prev,
          [location]: prev[location] ? [...prev[location], clue] : [clue]
        }));
      }
    }

    const onStageTransition = (newStage: string) => {
      if (newStage === 'READING2') {
        musicStop();
        setShowStageTransition1(true);
      }
      if (newStage === 'READING3') {
        musicStop();
        setShowStageTransition2(true);
      }
    }

    socket?.on('clueFound', onClueFound);
    socket?.on('cluePublished', onCluePublished);
    socket?.on('stageTransition', onStageTransition);

    return () => {
      socket?.off('clueFound', onClueFound);
      socket?.off('cluePublished', onCluePublished);
      socket?.off('stageTransition', onStageTransition);
    }
  }, [gameAudit?.players, musicStop, user?.userId]);

  const allowToInvestigate = useMemo(() => {
    if (!['INVESTIGATING2', 'INVESTIGATING3'].includes(gameAudit?.stage ?? '')) {
      return false;
    }
    const forbiddenRoom = YLSX_ForbiddenRooms[gameAudit?.players?.[user?.userId ?? '']?.character ?? ''];
    if (forbiddenRoom) {
      if (forbiddenRoom !== currentLocation) {
        return true;
      }
      return Object.keys(gameAudit?.rooms ?? {}).every((room) => {
        if (room === forbiddenRoom) {
          return true;
        }
        return Object.values(gameAudit?.rooms[room]?.clues ?? {}).every((clue) => clue.isFound);
      })
    }
    return true;
  }, [currentLocation, gameAudit?.players, gameAudit?.rooms, gameAudit?.stage, user?.userId]);

  const onConfirmRest = useCallback(() => {
    setShowConfirmDialog(false);
    if (gameAudit?.players?.[user?.userId ?? ''].character === '闵嘉雪') {
      socket?.emit('moveToStage', gameId, 'READING2');
    }
  }, [gameAudit?.players, gameId, user?.userId]);

  const onLocationMaskClicked = useCallback((location: string) => {
    if (location === '前院') {
      setLockWobbles((prev) => ({ ...prev, 正门: true, 侧门: true }));
    } else {
      setLockWobbles((prev) => ({ ...prev, [location]: true }));
    }
  }, []);

  const onLocationButtonClicked = useCallback((location: string) => {
    setCurrentLocation(location);
    setShowPickClueDialog(true);
  }, [setShowPickClueDialog]);

  const handlePickClueDialogClose = useCallback(() => {
    setShowPickClueDialog(false);
    if (currentLocation) {
      const temp = { ...newClues };
      delete temp[currentLocation];
      setNewClues(temp);
    }
  }, [currentLocation, newClues, setShowPickClueDialog]);

  const handleStageTransitionEnd = useCallback((_: Event, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === 'escapeKeyDown') {
      musicPlay();
      setShowStageTransition1(false);
      setShowStageTransition2(false);
    }
  }, [musicPlay]);

  const managedProps: Pick<YLSX_InvestigatePageProps, YLSX_InvestigatePageBinderManagedProps> = {
    showMap: !!gameAudit?.stage && gameAudit.stage.slice(0, -1) !== 'READING' && gameAudit.stage !== 'INTRODUCING',
    showRestButton: gameAudit?.stage === 'INVESTIGATING1' && gameAudit?.players?.[user?.userId ?? '']?.character === '闵嘉雪',
    onRestButtonClicked: () => setShowConfirmDialog(true),
    isFloor3Locked: !!gameAudit?.stage && !['INVESTIGATING3' || 'VOTING' || 'REVEALING'].includes(gameAudit.stage),
    isFrontYardLocked: !!gameAudit?.rooms['房间三']?.clues['clue41']?.isPublic,
    isBasementLocked: !!gameAudit?.secrets?.['secret6']?.isPublic,
    numRemainingClues,
    locationHasNewClue: useMemo(() => Object.keys(newClues), [newClues]),
    showLocationButtons: !!gameAudit?.stage && gameAudit.stage !== 'INVESTIGATING1',
    pickClueDialog: useMemo(() => <PickClueDialogBinder location={currentLocation} newClues={newClues[currentLocation ?? '']} open={showPickClueDialog} onClose={handlePickClueDialogClose} allowToInvestigate={allowToInvestigate} />, [allowToInvestigate, currentLocation, handlePickClueDialogClose, newClues, showPickClueDialog]),
    onLocationButtonClicked,
    lockWobbles,
    lockedRooms,
    onLockAnimationEnd: (location: string) => setLockWobbles((prev) => ({ ...prev, [location]: false })),
    onLocationMaskClicked,
    showStageTransition1,
    showStageTransition2,
    onStageTransitionEnd: handleStageTransitionEnd,
    showConfirmDialog,
    handleConfirmDialogClose: () => setShowConfirmDialog(false),
    onConfirmRest,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const YLSX_InvestigatePageBinder = createBinder(YLSX_InvestigatePage, useYLSX_InvestigatePageBinder);

export default YLSX_InvestigatePageBinder;