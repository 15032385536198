import { createBinder, getAssetUrl } from "utils/utils";
import { WQYF_InvestigatePage, WQYF_InvestigatePageProps } from "./WQYF_InvestigatePage";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { GameContext, UserContext, VolumeContext } from "utils/contexts";
import { socket } from "utils/socketConnector";
import { useParams } from "react-router-dom";
import PickClueDialogBinder from "lib/game/components/PickClueDialogBinder";
import { WQYF_ForbiddenClues } from "./WQYF_StaticData";
import useSound from "use-sound";
import BirdFlap from 'assets/sound/bird_flap.mp3';
import { enqueueSnackbar } from "notistack";
import { useRandomInterval } from "utils/hooks";

export type WQYF_InvestigatePageBinderManagedProps = 'showMap' | 'numRemainingClues' | 'locationHasNewClue'
  | 'onLocationButtonClicked' | 'pickClueDialog' | 'lockWobbles' | 'lockedRooms' | 'onLockAnimationEnd' | 'showBasementEntrance'
  | 'showConfirmDialog' | 'handleConfirmDialogClose' | 'onConfirmEnterBasement'
  | 'basementEntered' | 'bgm2MusicRef' | 'onBgm2MusicEnded';

export interface WQYF_InvestigatePageBinderProps extends Omit<WQYF_InvestigatePageProps, WQYF_InvestigatePageBinderManagedProps> {
  showPickClueDialog: boolean;
  setShowPickClueDialog: (_: boolean) => void;
}

const useWQYF_InvestigatePageBinder = (props: WQYF_InvestigatePageBinderProps): WQYF_InvestigatePageProps => {

  const { user } = useContext(UserContext);
  const volume = useContext(VolumeContext);
  const gameAudit = useContext(GameContext);
  const { gameId } = useParams();
  const [newClues, setNewClues] = useState<Record<string, string[]>>({});
  const { showPickClueDialog, setShowPickClueDialog } = props;
  const [currentLocation, setCurrentLocation] = useState<string>();
  const [lockWobbles, setLockWobbles] = useState<Record<string, boolean>>({ 上锁房间: false, 上锁礼堂: false, 上锁书库: false, 蔷薇馆外: false, 蔷薇图案的门: false });
  const lockedRooms: Record<string, boolean> = useMemo(() => ({
    地下室: !gameAudit?.rooms['房间五']?.clues['clue21']?.isPublic,
  }), [gameAudit?.rooms]);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const [playBirdFlapSound] = useSound(BirdFlap);

  const [musicPlay, { stop: musicStop }] = useSound(getAssetUrl('/WQYF/bgm.mp3'), {
    volume: volume / 100,
    loop: true,
    autoplay: true,
    interrupt: true
  });

  const [playZebub] = useSound(getAssetUrl('/WQYF/zebub.mp3'), {
    volume: 0.5,
    interrupt: true,
  });

  const [playWQRJ] = useSound(getAssetUrl('/WQYF/wqrj.mp3'), {
    volume: 0.3,
    interrupt: true,
  });

  useEffect(() => {
    return () => musicStop();
  }, [musicStop]);

  useRandomInterval(() => {
    if (['READING2', 'INVESTIGATING2', 'READING3', 'INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameAudit?.stage ?? '')) {
      playZebub();
    }
  }, 60 * 1000 * 8, 60 * 1000 * 10);

  useRandomInterval(() => {
    if (['READING3', 'INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameAudit?.stage ?? '')) {
      playWQRJ();
    }
  }, 60 * 1000 * 8, 60 * 1000 * 10);

  const bgm2MusicRef = useRef<HTMLAudioElement>(null);

  const numRemainingClues = useMemo(() =>
    Object.fromEntries(
      Object.entries(gameAudit?.rooms ?? {})
        .map(([room, roomInfo]) => [room, Object.values(roomInfo.clues).reduce(((numClues, clueInfo) => numClues + (clueInfo.isFound ? 0 : 1)), 0)]))
    , [gameAudit?.rooms]);

  const forbiddenClues = useMemo(() => WQYF_ForbiddenClues[gameAudit?.players?.[user?.userId ?? '']?.character ?? ''], [gameAudit?.players, user?.userId]);

  useEffect(() => {

    const onClueFound = (clue: string, location: string) => {
      setNewClues((prev) => ({
        ...prev,
        [location]: prev[location] ? [...prev[location], clue] : [clue]
      }));
    }

    const onCluePublished = (clue: string, location: string, character: string) => {
      if (character !== gameAudit?.players?.[user?.userId ?? '']?.character) {
        setNewClues((prev) => ({
          ...prev,
          [location]: prev[location] ? [...prev[location], clue] : [clue]
        }));
      }
    }

    const onStageTransition = (newStage: string) => {
      if (newStage === 'READING3') {
        bgm2MusicRef.current?.play();
        musicStop();
      }
      if (newStage === 'INVESTIGATING1') {
        playBirdFlapSound();
      }
    }

    socket?.on('clueFound', onClueFound);
    socket?.on('cluePublished', onCluePublished);
    socket?.on('stageTransition', onStageTransition);

    return () => {
      socket?.off('clueFound', onClueFound);
      socket?.off('cluePublished', onCluePublished);
      socket?.off('stageTransition', onStageTransition);
    }
  }, [gameAudit?.players, musicStop, playBirdFlapSound, user?.userId]);

  const onLocationButtonClicked = useCallback((location: string) => {
    if (lockedRooms[location]) {
      setLockWobbles((prev) => ({ ...prev, [location]: true }));
    } else if (location === '地下室') {
      if (gameAudit?.players?.[user?.userId ?? '']?.votedClue !== 'basement' && !['READING3', 'INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameAudit?.stage ?? '')) {
        setShowConfirmDialog(true);
      }
    } else {
      setCurrentLocation(location);
      setShowPickClueDialog(true);
    }
  }, [gameAudit?.players, gameAudit?.stage, lockedRooms, setShowPickClueDialog, user?.userId]);

  const handlePickClueDialogClose = useCallback(() => {
    setShowPickClueDialog(false);
    if (currentLocation) {
      const temp = { ...newClues };
      delete temp[currentLocation];
      setNewClues(temp);
    }
  }, [currentLocation, newClues, setShowPickClueDialog]);

  const onConfirmEnterBasement = useCallback(() => {
    const numOfClues = gameAudit?.numberOfCluesFound ?? 0;
    if (numOfClues >= 28 || (numOfClues >= 27 && !gameAudit?.secrets?.['secret07'].isFound)) {
      socket?.emit('WQYF:enterBasement', gameId, user?.userId);
    } else {
      enqueueSnackbar('请先调查完二层的所有线索', { variant: 'warning' });
    }
    setShowConfirmDialog(false);
  }, [gameAudit?.numberOfCluesFound, gameAudit?.secrets, gameId, user?.userId]);

  const onBgm2MusicEnded = useCallback(() => {
    musicPlay();
  }, [musicPlay]);

  const managedProps: Pick<WQYF_InvestigatePageProps, WQYF_InvestigatePageBinderManagedProps> = {
    showMap: !!gameAudit?.stage && gameAudit.stage.slice(0, -1) !== 'READING' && gameAudit.stage !== 'INTRODUCING',
    numRemainingClues,
    locationHasNewClue: useMemo(() => Object.keys(newClues), [newClues]),
    onLocationButtonClicked,
    pickClueDialog: useMemo(() => <PickClueDialogBinder location={currentLocation} newClues={newClues[currentLocation ?? '']} open={showPickClueDialog} onClose={handlePickClueDialogClose} allowToInvestigate forbiddenClues={forbiddenClues} />, [currentLocation, forbiddenClues, handlePickClueDialogClose, newClues, showPickClueDialog]),
    lockWobbles,
    lockedRooms,
    onLockAnimationEnd: (location: string) => setLockWobbles((prev) => ({ ...prev, [location]: false })),
    showBasementEntrance: !!gameAudit?.rooms['废墟']?.clues['clue14']?.isPublic,
    showConfirmDialog,
    handleConfirmDialogClose: () => setShowConfirmDialog(false),
    onConfirmEnterBasement,
    basementEntered: ['READING3', 'INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameAudit?.stage ?? ''),
    bgm2MusicRef,
    onBgm2MusicEnded,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const WQYF_InvestigatePageBinder = createBinder(WQYF_InvestigatePage, useWQYF_InvestigatePageBinder);

export default WQYF_InvestigatePageBinder;