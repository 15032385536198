import { IconButton, IconButtonProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import styled from '@emotion/styled';

const IIconButton = styled(IconButton)`
  &:hover {
    background: rgba(240, 240, 240, 0.16);
  }
`;

export interface CommonIconButtonProps extends IconButtonProps {
  icon: ReactNode;
  iconColor?: string;
}

export const CommonIconButton: FC<CommonIconButtonProps> = function ({ icon, iconColor, ...props }) {
  return (
    <IIconButton style={{ color: iconColor }} {...props}>
      {icon}
    </IIconButton>
  );
};
