import styled from '@emotion/styled';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { DialogProps, Stack } from '@mui/material';
import { getAssetUrl } from 'utils/utils';
import { ThemeButton } from 'lib/common/ThemeButton';
import { FullDialog } from 'lib/common/FullDialog';
import { GameContext } from 'utils/contexts';
import { NDYY_CharacterAbbr, NDYY_CharacterColors } from 'lib/stories/NDYY/NDYY_StaticData';
import { FlipBackKeyframes, FlipKeyframes } from 'utils/keyframes';
import FlipSound from 'assets/sound/flip.mp3';
import useSound from 'use-sound';
import { CommonIconButton } from 'lib/common/CommonIconButton';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import StarIcon from '@mui/icons-material/Star';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { ClipBadge, ClipTooltip } from './PickClueDialog';
import { ClipColor, Clips } from './ClueClipDialogBinder';

const ContentWrapper = styled(Stack)`
  width: 100vw;
  height: 100dvh;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  padding-bottom: 20px;
`;

const TitleBox = styled.div`
  width: 100vw;
  padding-top: 30px;
  color: white;
  font-size: 56px;
  font-family: '黑体';
  text-align: center;
`;

const ClueContainer = styled.div`
  width: 100vw;
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`;

const ClueCardContainer = styled.div<{ shouldFlip?: boolean }>`
  width: 656px;
  height: 426px;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 8px;
  position: relative;
`;

const FlipAnimationWrapper = styled.div<{ flip?: boolean, animate?: boolean }>`
  transform-style: preserve-3d;
  animation-duration: 0.8s;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-name: ${(props) => props.animate === true ? FlipKeyframes : (props.animate === false ? FlipBackKeyframes : null)};
  transform: rotateY(${props => props.flip ? '180deg' : '0deg'});
  perspective: 1000px;
`;

const ClueCard = styled.img<{ isFound?: boolean }>`
  width: 650px;
  height: 420px;
  border: 3px solid white;
  border-radius: 20px;
  backface-visibility: hidden;
  cursor: ${(props) => props.isFound ? 'auto' : 'pointer'};
  filter: ${(props) => props.isFound ? 'brightness(1.0)' : 'brightness(0.9)'};

  &:hover {
    filter: ${(props) => props.isFound ? 'brightness(1.0)' : 'brightness(1.1)'}
  }
`;

const FlipCard = styled(ClueCard)`
  position: absolute;
  left: 0;
  top: 0;
`;

const FlipCardFront = styled(ClueCard)`
  transform: rotateY(-180deg);
`;

const VotesContainer = styled.div`
  position: absolute;
  top: -28px;
  right: 50px;
  z-index: 1;
`;

const VoteBadge = styled.div<{ index: number, color?: string }>`
  width: 36px;
  height: 48px;
  border-radius: 3px;
  padding-top: 5px;
  font-family: 'Mircrosoft Yahei';
  font-size: 24px;
  color: white;
  text-align: center;
  position: absolute;
  top: 0px;
  right: ${(props) => `${(props.index - 1) * 25}px`};
  background-color: ${(props) => props.color};
  z-index: ${(props) => -1 - props.index};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  &:before {
    background-color: inherit;
    content: '';
    height: 26px;
    width: 26px;
    position: absolute;
    top: 33px;
    left: 5.25px;
    border-radius: 3px;
    transform: rotate(45deg);
    z-index: inherit;
  }
`;

const Footer = styled.div`
  width: 120px;
  height: 60px;
`;

const FlipButtonWrapper = styled.div`
  height: 40px;
`;

const FlipButton = styled(CommonIconButton)`
  width: 40px;
  transition: transform 0.8s ease-in-out;
`;

export interface VoteClueDialogProps extends DialogProps {
  location?: string;
  newClues?: string[];
  playerVotedClues?: Record<string, string | undefined>;
  onClueButtonClicked: (clue: string) => void;
  onClipButtonClicked: (clue: string, clip?: string) => void;
  clipedClues?: Record<string, string>;
}

export const VoteClueDialog: FC<VoteClueDialogProps> = function ({
  location,
  newClues,
  playerVotedClues,
  onClueButtonClicked,
  onClipButtonClicked,
  clipedClues,
  ...props
}) {

  const clues = useContext(GameContext)?.rooms?.[location ?? '']?.clues;
  const [playFlipSound] = useSound(FlipSound, { volume: 0.5, interrupt: true })
  const [hideActionButton, setHideActionButton] = useState<Record<string, boolean>>({});

  const [flipAnimation, setFlipAnimation] = useState<Record<string, boolean>>({});
  const clueState = useMemo(() => {
    return Object.fromEntries(Object.entries(clues ?? {}).map(([clue, clueInfo]) => [clue, newClues?.includes(clue) ? false : !!clueInfo.isFound]));
  }, [clues, newClues]);

  useEffect(() => {
    if (props.open && newClues) {
      setFlipAnimation(Object.fromEntries(newClues.map((clue) => [clue, false])));
    }
  }, [newClues, props.open]);

  useEffect(() => {
    if (!props.open) {
      setHideActionButton({});
      setFlipAnimation({});
    }
  }, [props.open])

  return (
    <FullDialog {...props}>
      <ContentWrapper direction="column" alignItems="center">
        <TitleBox>{`${location}的线索`}</TitleBox>

        <ClueContainer>
          {Object.entries(clues ?? {}).map(([clue, clueInfo]) => (
            <Stack key={clue} direction="column" alignItems="center">
              <FlipButtonWrapper>
                {clueInfo.isFound &&
                  <FlipButton style={{ transform: flipAnimation[clue] ? 'rotate(0deg)' : 'rotate(180deg)' }}
                    icon={<FlipCameraAndroidIcon htmlColor="white" />}
                    onClick={() => setFlipAnimation((prev) => ({ ...prev, [clue]: (flipAnimation[clue] === undefined) ? true : !flipAnimation[clue] }))}
                  />}
              </FlipButtonWrapper>

              <ClueCardContainer>
                <FlipAnimationWrapper flip={clueState[clue]} animate={flipAnimation[clue]} onAnimationStart={() => { playFlipSound(); setHideActionButton((prev) => ({ ...prev, [clue]: true })) }} onAnimationEnd={() => { if (flipAnimation[clue] === false) setHideActionButton((prev) => ({ ...prev, [clue]: false })) }}>
                  <FlipCardFront src={getAssetUrl(clueInfo.frontUrl)} isFound draggable={false} onContextMenu={(e) => e.preventDefault()} />
                  <FlipCard src={getAssetUrl(clueInfo.backUrl)} isFound={!!clueInfo.isFound} onClick={() => onClueButtonClicked(clue)} draggable={false} onContextMenu={(e) => e.preventDefault()}/>
                </FlipAnimationWrapper>
                {!hideActionButton[clue] && clueInfo.isFound &&
                  <ClipTooltip arrow placement="top" title={
                    <Stack direction="row" spacing={1}>
                      {Clips.map((clip) =>
                        <CommonIconButton key={clip} icon={<StarIcon fontSize="large" htmlColor={ClipColor[clip]} />} onClick={() => onClipButtonClicked(clue, clip)} />
                      )}
                      <CommonIconButton icon={<NotInterestedIcon fontSize="large" htmlColor="red" />} onClick={() => onClipButtonClicked(clue)} />
                    </Stack>}>
                    <ClipBadge>
                      <StarIcon htmlColor={ClipColor[clipedClues?.[clue] ?? '']} />
                    </ClipBadge>
                  </ClipTooltip>}
                <VotesContainer>
                  {Object.keys(playerVotedClues ?? {}).filter((player) => playerVotedClues?.[player] === clue).map((player, index) => (
                    <VoteBadge key={player} index={index} color={NDYY_CharacterColors[player]}>
                      {NDYY_CharacterAbbr[player]}
                    </VoteBadge>
                  ))}
                </VotesContainer>


              </ClueCardContainer>
            </Stack>
          ))}
        </ClueContainer>
        <Footer>
          <ThemeButton onClick={() => props.onClose?.({}, "escapeKeyDown")}>返回</ThemeButton>
        </Footer>
      </ContentWrapper>
    </FullDialog >
  );
};

export default VoteClueDialog;
