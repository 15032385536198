import styled from '@emotion/styled';
import { FC, useState } from 'react';
import { Divider, Stack } from '@mui/material';
import { CommonUIProps } from 'utils/types';
import DiscordLogo from 'assets/discord_logo_black.png';
import XHSLogo from 'assets/XHS_logo.webp';
import WechatLogo from 'assets/wechat_logo.webp';
import LogoBlack from 'assets/logo_black.png';
import RouterPath from 'router/RouterPath';
import 'react-multi-carousel/lib/styles.css';
import QRCodeDialog from './QRCodeDialog';

const FooterWrapper= styled.div`
  width: calc(100vw - 240px);
  height: 480px;
  background-color: #EFEFEF;
  margin-top: 60px;
  color: black;
  display: flex;
  flex-direction: column;
  padding-left: 120px;
  padding-right: 120px;
`;

const FooterContentStack = styled(Stack)`
  flex: 1 1 auto;
`;

const LogoImageBlack = styled.img`
  height: 120px;
  width: 120px;
`;

const DiscordLink = styled.a`
  display: flex;
  gap: 4px;
  width: 160px;
  padding: 8px;
  padding-top: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);
  color: black;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.14);
  }
`;

const XHSButton = styled.div`
  display: flex;
  gap: 4px;
  width: 160px;
  padding: 8px;
  padding-top: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);
  color: black;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.14);
  }
`;

const WechatButton = styled.div`
  display: flex;
  gap: 4px;
  width: 160px;
  padding: 8px;
  padding-top: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);
  color: black;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.14);
  }
`;

const DiscordImage = styled.img`
  width: 80%;
`;

const ButtonImage = styled.img`
  height: 25px;
`;

const TextButton = styled.a`
  color: black;
  text-decoration: none;
  margin-top: 16px;
  font-size: 18px;
  cursor: pointer;

  :hover {
    color: #5885AF;
  }
`;

const BottomTextStack = styled(Stack)`
  height: 60px;
  font-family: "Archivo", Helvetica, Arial, sans-serif, "Microsoft YaHei";
`;

const PolicyLink = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export interface PageFooterProps extends CommonUIProps {
}

export const PageFooter: FC<PageFooterProps> = function ({
  ...props
}) {

  const [showQRCodeDialog, setShowQRCodeDialog] = useState<boolean>(false);
  const [platform, setPlatform] = useState<string>();

  return (
    <FooterWrapper {...props}>
      <FooterContentStack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1}>
          <LogoImageBlack src={LogoBlack} />
          <Stack direction="column" spacing={1}>
            <span style={{ fontSize: 36, fontWeight: 'bold' }}>时空侦探局 Agency of Time</span>
            <span style={{ fontSize: 20 }}>好玩又有趣的剧本杀平台</span>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={8}>
          <Stack style={{ width: '120px' }} direction="column" alignItems="center" spacing={2}>
            <Divider flexItem />
            <TextButton href={RouterPath.Recruit}>团队招募</TextButton>
            <TextButton href={RouterPath.FAQ}>常见问题</TextButton>
            <Divider flexItem />
          </Stack>
          <Stack direction="column" alignItems="center" spacing={4}>
            <DiscordLink href="https://discord.gg/NHpurCBT6j" target="_blank">
              <DiscordImage src={DiscordLogo} />
              加入DC频道
            </DiscordLink>
            <XHSButton onClick={() => {
              setPlatform('XHS');
              setShowQRCodeDialog(true);
            }}>
              <ButtonImage src={XHSLogo} />
              关注官方小红书账号
            </XHSButton>
            <WechatButton onClick={() => {
              setPlatform('wechat');
              setShowQRCodeDialog(true);
            }}>
              <ButtonImage src={WechatLogo} />
              加入微信玩家群
            </WechatButton>
          </Stack>
        </Stack>
      </FooterContentStack>
      <Divider />
      <BottomTextStack direction="row" justifyContent="space-between" alignItems="center">
        <div>费米科技有限公司 (Fermionic Innovations Inc.) | © 2024  All rights reserved.</div>
        <Stack direction="row" alignItems="center" spacing={2}>
          <PolicyLink href={RouterPath.PrivacyPolicy}>隐私政策</PolicyLink>
          <PolicyLink href={RouterPath.TermsAndCondtions}>服务条款</PolicyLink>
          <span>商务合作: <span style={{ textDecoration: 'underline' }}>agency.of.time@gmail.com</span></span>
        </Stack>
      </BottomTextStack>
      <QRCodeDialog platform={platform} open={showQRCodeDialog} onClose={() => setShowQRCodeDialog(false)} />
    </FooterWrapper>
  );
};

export default PageFooter;
