import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';
import { FadeInAndOutKeyframes } from 'utils/keyframes';
import useSound from 'use-sound';
import BackgroundMusic from 'assets/music/fuguang.mp3';

const ContentContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-family: STFangSong;
  font-size: 32px;
  text-indent: 2em;
  text-align: justify;
`;

const Info = styled.div`
  width: 40vw;
  height: 100vh;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 10%;
  top: 0;
  bottom: 0;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  text-align: justify;
  line-height: 2;
`;

const Info1 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 10s ease-in-out both 2s;
`;

const Info2 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 13s;
`;

const Info3 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 26s;
`;

const Info4 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 39s;
`;

const InfoImage = styled.img`
  margin-bottom: 50px;
`;

const PosterIamge = styled.img`
  height: 60vh;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 15%;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 1.5s ease-in-out both;
`;


export interface YSZM_EpiloguePageProps extends CommonUIProps { }

export const YSZM_EpiloguePage: FC<YSZM_EpiloguePageProps> = function ({
  ...props
}) {
  const [, { stop }] = useSound(BackgroundMusic, {
    volume: 0.3,
    autoplay: 1,
    interrupt: true,
    loop: true
  });
  const [showPoster, setShowPoster] = useState<boolean>(false);

  useEffect(() => {
    return () => stop();
  }, [stop])

  return (
    <ContentContainer {...props}>
      <Info1>
        <InfoImage src={getAssetUrl('/YSZM/zhenghe.jpg')} height={600} />
        <p>1405年中国明代早期，郑和曾率舰队七下西洋，横跨了东亚、东南亚、印度、阿拉伯半岛以及东非等地，被认为是当时世界上规模最大的远洋航海项目。</p>
      </Info1>
      <Info2>
        <InfoImage src={getAssetUrl('/YSZM/columbus.jpg')} height={600} />
        <p>
          1492年，意大利航海家哥伦布首次横渡大西洋踏足美洲大陆，并建立永久居民点。他的一系列航行使美洲接入了现代文明，却也为西班牙侵略者打开了海上大门。
        </p>
      </Info2>
      <Info3>
        <InfoImage src={getAssetUrl('/YSZM/machupichu.jpg')} height={600} />
        <p>马丘比丘位于秘鲁南部的山脉之中，坐落在高耸入云的山脊之上，被称为“失落的印加之城”，后来入选世界七大奇迹之一。</p>
      </Info3>
      <Info4 onAnimationEnd={() => setShowPoster(true)}>武汉崇理学堂的曾教授、来自上海的催眠大师、丁老板伪造的明朝黄花梨等都曾出现在豪门惊情系列的其他剧本中，如果想了解更多他们的故事，可以继续体验《鄂西山灵》、《嗜睡蔷薇》、《幽灵复仇》等剧本。</Info4>
      <PosterIamge src={getAssetUrl('/YSZM/poster.webp')} style={{ opacity: showPoster ? '1' : '0' }} />
    </ContentContainer>
  );
};

export default YSZM_EpiloguePage;
