import { getAssetUrl } from 'utils/utils';

export const getYLSXInvestigatePageBackgroundUrl = (stage: string) => {
  switch (stage) {
    case 'READING1':
    case 'INTRODUCING':
    case 'INVESTIGATING1':
      return [getAssetUrl('/YLSX/background1.webp')];
    case 'READING2':
    case 'INVESTIGATING2':
    case 'DISCUSSING2':
      return [getAssetUrl('/YLSX/background2.webp'), getAssetUrl('/YLSX/background3.webp')];
    case 'READING3':
    case 'INVESTIGATING3':
    case 'VOTING':
    case 'REVEALING':
      return [getAssetUrl('/YLSX/background4.webp')];
    default:
      return undefined;
  }
};

export const YLSX_CharacterColors: Record<string, string> = {
  闵嘉雪: '#FF6060',
  易莲芝: '#D997FF',
  苏闻兆: '#E59C1E',
  阿扬: '#B79263',
  宋言沪: '#88A0FF',
  黛妮: '#8FAC62',
};

export const YLSX_CharacterAbbr: Record<string, string> = {
  闵嘉雪: '闵',
  易莲芝: '易',
  苏闻兆: '苏',
  阿扬: '扬',
  宋言沪: '宋',
  黛妮: '黛',
};

export const YLSX_VoteQuestions = ['是谁杀害了宋笃程？', '是谁杀害了易温？', '是谁杀害了闵嘉云？'];

export const YLSX_Scripts: Record<string, string> = {
  闵嘉雪: '/YLSX/scripts/MJX.pdf',
  易莲芝: '/YLSX/scripts/YLZ.pdf',
  苏闻兆: '/YLSX/scripts/SWZ.pdf',
  阿扬: '/YLSX/scripts/AY.pdf',
  宋言沪: '/YLSX/scripts/SYH.pdf',
  黛妮: '/YLSX/scripts/DN.pdf',
};

export const YLSX_ScriptMaxPage: Record<string, number> = {
  READING1: 5,
  INTRODUCING: 5,
  INVESTIGATING1: 5,
  READING2: 8,
  INVESTIGATING2: 8,
  DISCUSSING2: 8,
  READING3: 10,
  INVESTIGATING3: 10,
  VOTING: 10,
};

export const YLSX_TruthSummary: Record<string, string> = {
  背景故事:'1895年，易温、闵嘉云、苏冉仪先后入学江南黄岐庄，和宋笃程成为同学。宋笃程与闵嘉云情定终生，易温也与苏冉仪陷入热恋，偷尝禁果。结果事情闹大，导致宋笃程遭学校开除，闵嘉云自杀未果，苏冉仪被家中软禁，而易温也不得不离开，回到了易家湾。之后易温为做生意多次拜访闵家，年少英才的他很快就被闵嘉雪一见倾心。但此时易温仍然爱着苏冉仪，更是屡次写信诉说相思。苏冉仪求哥哥苏闻兆带其前往长沙寻找易温，却惨遭不测。易温失去爱人，生意又出现问题，痛苦无比，被闵嘉云借机灌醉，并在家中留宿了一夜。随后易温难愧此情，便向闵家提亲。\n\n       1905年，易温的公司生意遇到困难，需要与洋人合作周转资金，岳父闵德钦却反对洋人入城，拒绝签署合同，令易温寒心。闵老爷在谈判中不幸落水后，闵嘉云将遗嘱中所有闵嘉雪的名字偷偷改成了自己的，顺理成章的住进了闵宅，改名麓下馆，成为了女主人，易温也如愿以偿的获得了合同。',
  闵老爷落水案: '闵嘉云痛恨父亲偏心将所有遗产留给姐姐，于是从宋笃程处买来毒药“朱颜”，将瓶子标签涂改成“安眠剂”后交给易温，再利用姐姐对易温仍有的感情，劝诱闵嘉雪给父亲服下。最后闵老爷在船上毒发，落江而死。',
  苏冉仪遇袭案: '苏冉仪生下女儿后，难耐相思之苦，便前往长沙寻找易温。霜降这天，两人在屋外拥抱分别，却不巧被也来寻找易温的闵嘉雪看见，随后闵嘉雪妒火中烧，跟踪苏冉仪并在大雨中将她推下台阶，从而摔断了腿。此时同行的闵嘉云看准机会，先拉着姐姐回家，再趁没人时返回，用石头砸死了无法逃跑的苏冉仪。',
  众人昏睡之谜: '剁椒鱼头里的安眠剂是闵嘉云下的，老鸭汤里的安眠剂是闵嘉雪下的。餐桌上闵嘉云本准备让大家先尝尝剁椒鱼头，却被不知情的易温打断，盛情邀请大家先喝了姐姐易莲芝特地做的上海老鸭汤。',
  宋笃程之死: '宋笃程因不吃香菜，就没喝放了安眠剂的老鸭汤。闵嘉雪见状便掏出手枪，逼着宋笃程喝下当年易温给她，她又给父亲服下的“红色药瓶”。宋笃程不从，闵嘉雪便朝窗户开了一枪，宋笃程吓得只能照做。随后宋笃程七窍流血而亡，闵嘉雪将手枪放回三层的主人房里，再来到餐厅将时钟往回拨了半个小时，接着将面前的老鸭汤倒了一半回砂锅里，喝下了剩余的半碗，装作与众人一同睡去。',
  易温之死: '闵嘉雪睡去时不小心打翻了桌上的花瓶，流出的水将苏闻兆惊醒。苏闻兆见众人都在熟睡，便趁机前往三楼翻出了左轮手枪，再利用餐车将易温运到地下室中，开枪杀死了他。随后苏闻兆裁下当年易温写给妹妹的信，做成遗书放在桌上，将现场伪造成自杀的假象。最后苏闻兆回到餐厅，将时钟往回拨了半个小时，坐回餐桌前。苏闻兆见锅中还剩下半碗老鸭汤，便将其喝下，接着很快感到睡意，于是把面前的水抹开到一旁，再次睡去。',
  闵嘉云之死: '阿扬被面前的水弄醒，见众人都在熟睡，误以为是闵嘉云下的药。为了保护闵嘉雪，阿扬决定先下手为强，将闵嘉云抱去了三楼书房将其勒死，用长绸带套在尸体的脚踝上，再把尸体放在窗台外，最后让绳子绕过脖子后绑在了保险柜上。随后阿扬回到餐厅，将时钟往回拨了半个小时，再用花瓶里剩下的水泼在了宋言沪脸上，趁其醒来前趴回自己的位置上，装作熟睡的样子。之后阿扬送闵嘉雪回房休息时，借机说要开窗透气，实际上通过长绸带将闵嘉云的尸体拽了下来。',
};

export const getYLSXFooterText = (stage: string) => {
  switch (stage) {
    case 'READING1':
      return '阅读剧本阶段（阅读完请点击剧本左上角的“阅读完毕”）';
    case 'INTRODUCING':
      return '介绍阶段';
    case 'INVESTIGATING1':
      return '寒暄阶段（自由交谈，直到闵嘉雪提出回房休息）';
    case 'READING2':
      return '阅读第二幕剧本（阅读完请点击剧本左上角的“阅读完毕”）';
    case 'INVESTIGATING2':
      return '轮流调查阶段';
    case 'DISCUSSING2':
      return '讨论阶段';
    case 'READING3':
      return '阅读第三幕剧本（阅读完请点击剧本左上角的“阅读完毕”）';
    case 'INVESTIGATING3':
      return '轮流调查阶段';
    case 'VOTING':
      return '投票阶段（可以继续交流，直到所有人投完票）';
    case 'REVEALING':
      return '复盘阶段';
    default:
      return undefined;
  }
};

export const YLSX_ForbiddenRooms: Record<string, string> = {
  闵嘉雪: '嘉雪房',
  易莲芝: '客房四',
  苏闻兆: '娱乐室',
  阿扬: '客房一',
  宋言沪: '客房三',
  黛妮: '黛妮房',
};
