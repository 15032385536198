import { useContext, useEffect, useState } from "react";
import { createBinder } from "utils/utils";
import { LoadingPage, LoadingPageProps } from "./LoadingPage";
import { useNavigate } from "react-router-dom";
import RouterPath from "router/RouterPath";
import { StoryRes } from "openapi";
import clientApi from "utils/clientApi";
import { GameContext } from "utils/contexts";

export type LoadingPageBinderManagedProps = 'progress' | 'onReturnButtonClicked';

export interface LoadingPageBinderProps extends Omit<LoadingPageProps, LoadingPageBinderManagedProps> {
  errorMsg?: string;
  onLoadingComplete?: (_storyDetail: StoryRes) => void;
}

const useLoadingPageBinder = (props: LoadingPageBinderProps): LoadingPageProps => {

  const navigate = useNavigate();
  const gameAudit = useContext(GameContext);
  const { errorMsg, onLoadingComplete } = props;
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * 20;
        return Math.min(oldProgress + diff, 100);
      });
    }, 100);

    if (props.errorMsg) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [props.errorMsg]);

  useEffect(() => {
    if (gameAudit?.storyId && !errorMsg && onLoadingComplete) {
      Promise.all(
        [
          clientApi().getStoryDetail(gameAudit.storyId).then((result) => {
            onLoadingComplete(result.data);
          })
        ])
        .catch((error: unknown) => {
          console.error(error);
        })
    }
  }, [gameAudit?.storyId, errorMsg, onLoadingComplete]);


  const onReturnButtonClicked = () => {
    navigate(RouterPath.Home);
  }

  const managedProps: Pick<LoadingPageProps, LoadingPageBinderManagedProps> = {
    progress,
    onReturnButtonClicked,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const LoadingPageBinder = createBinder(LoadingPage, useLoadingPageBinder);

export default LoadingPageBinder;