import styled from '@emotion/styled';
import { FC, useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Stack } from '@mui/material';
import { FullDialog } from 'lib/common/FullDialog';
import CloseIcon from '@mui/icons-material/Close';
import { CommonIconButton } from 'lib/common/CommonIconButton';
import { GameContext, UserContext } from 'utils/contexts';
import HintIcon from 'assets/hint.webp';
import HintVIPIcon from 'assets/logo_white.png';
import Logo from 'assets/logo_black.png';
import { ThemeButton } from 'lib/common/ThemeButton';
import { getCharacterAbbr, getCharacterColors } from 'utils/utils';
import CheckIcon from '@mui/icons-material/Check';

const HintsWrapper = styled(Stack)`
  margin: auto;
  height: 72vh;
  width: 60vh;
  background-color: #f2f2f2;
  border-radius: 16px;
  border: 10px solid #adb2bd;
  box-shadow: inset -1px 2px 5px #404040, 6px 9px 1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const LogoImg = styled.img`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 15vh;
  bottom: 0;
  width: 30vh;
  opacity: 0.05;
  z-index: 0;
  pointer-events: none;
`;

const Eraser = styled.div`
  position: absolute;
  height: 20px;
  width: 100px;
  bottom: 10px;
  right: 100px;
  background-color: #2929a3;
  border-radius: 5px 5px 0 0;
  border-bottom: 6px solid grey;
`;

const BluePen = styled.div`
  position: absolute;
  height: 5px;
  width: 80px;
  bottom: 10px;
  right: 350px;
  background-color: #1f75fe;
  border-bottom: 3px solid #014ecb;
`;

const RedPen = styled.div`
  position: absolute;
  height: 5px;
  width: 80px;
  bottom: 10px;
  right: 250px;
  background-color: #FD7C6E;
  border-bottom: 3px solid #fc604f;
`;

const RemainingHints = styled(Stack)`
  position: absolute;
  left: 10px;
  top: 25px;
  color: black;
  font-size: 32px;
  font-weight: bold;
  background-color: #ffe6a7;
  padding: 8px;
  padding-left: 12px;
  padding-right: 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  transform: rotate(-20deg);
`;

const VIPRemainingHints = styled(Stack)`
  position: absolute;
  left: 10px;
  top: 110px;
  color: black;
  font-size: 32px;
  font-weight: bold;
  background-color: #ff2743;
  padding: 8px;
  padding-left: 12px;
  padding-right: 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  transform: rotate(-20deg);
  color: white;
`;

const HintImage = styled.img`
  width: 48px;
`;

const HintVIPImage = styled.img`
  width: 48px;
`;

const CloseButton = styled(CommonIconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Title = styled.div`
  font-size: 48px;
  font-weight: bold;
  margin-top: 10%;
`;

const Subtitle = styled.div`
  margin-top: 12px;
  font-size: 24px;
  color: #4A4A4A;
  text-align: center;
  line-height: 2;
`;

const CategoriesGrid = styled.div`
  margin-top: 2vh;
  display: grid;
  grid-template-columns: 15vh 15vh 15vh;
  grid-template-rows: 14vh 14vh 14vh;
  justify-items: center;
`;

const CategoryBubble = styled.div`
  width: 8vh;
  height: 8vh;
  padding: 2vh;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  z-index: 1;
  cursor: pointer;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: transform 0.2s ease-in-out;
  text-align: center;
  position: relative;

  :hover {
    transform: scale(1.2);
  }
`;

const HintTextWrapper = styled(Stack)`
  margin-top: 5vh;
  width: 48vh;
`;

const HintText = styled.div`
  font-size: 28px;
  color: #274472;
  line-height: 1.5;
`;

const CluesText = styled.div`
  font-size: 24px;
  line-height: 2;
`;

const Footer = styled.div`
  flex: 1 1 auto;
  font-size: 24px;
  color: #4A4A4A;
  display: flex;
  line-height: 2;
`;

const VotesContainer = styled.div`
  position: absolute;
  top: -35px;
  right: 0;
  z-index: 1;
`;

const VoteBadge = styled.div<{ index: number, color?: string }>`
  width: 36px;
  height: 48px;
  border-radius: 3px;
  padding-top: 5px;
  font-family: 'Mircrosoft Yahei';
  font-size: 24px;
  color: white;
  text-align: center;
  position: absolute;
  top: 2vh;
  right: ${(props) => `${props.index * 24}px`};
  background-color: ${(props) => props.color};
  z-index: ${(props) => -1 - props.index};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  pointer-events: none;

  &:before {
    background-color: inherit;
    content: '';
    height: 26px;
    width: 26px;
    position: absolute;
    top: 33px;
    left: 5.25px;
    border-radius: 3px;
    transform: rotate(45deg);
    z-index: inherit;
  }
`;

const RevealedIcon = styled(CheckIcon)`
  position: absolute;
  right: 0px;
  bottom: 0px;
  font-size: 42px;
  background-color: white;
  border-radius: 50%;
  border: 3px solid #A4A4A4;
`;

const NotYetText = styled.div`
  flex: 1 1 auto;
  text-align: center;
  font-size: 42px;
  color: #4A4A4A;
  display: flex;
  align-items: flex-end;
  line-height: 1.5;
  margin-bottom: 48px;
`;

const CategoryColors = ['#FFA500', '#5D3FD3', '#00A86B', '#800020', '#191970', '#0047AB', '#36454F', '#8B0000', '#006400'];

export interface HintsDialogProps extends DialogProps {
  activeCategory?: string;
  onCategoryClicked: (category: string) => void;
  onReturnButtonClicked: () => void;
  showExtraHintDialog: boolean,
  onExtraHintDialogClose: () => void;
  onConfirmExtraHint: () => void,
  onNotConfirmExtraHint: () => void;
  votedHints: Record<string, string[]>,
}

export const HintsDialog: FC<HintsDialogProps> = function ({
  activeCategory,
  onCategoryClicked,
  onReturnButtonClicked,
  showExtraHintDialog,
  onExtraHintDialogClose,
  onConfirmExtraHint,
  onNotConfirmExtraHint,
  votedHints,
  ...props
}) {

  const { user } = useContext(UserContext);
  const gameAudit = useContext(GameContext);

  return (
    <FullDialog {...props}>
      <HintsWrapper direction="column" alignItems="center">
        <LogoImg src={Logo} draggable={false} />
        <CloseButton icon={<CloseIcon htmlColor="black" />} onClick={() => props.onClose?.({}, 'escapeKeyDown')} />
        <RemainingHints direction="row" alignItems="center">
          <HintImage src={HintIcon} />
          × {gameAudit?.hints?.remaining}
        </RemainingHints>
        {['PLAN_B', 'PLAN_C'].includes(user?.role ?? '') && <VIPRemainingHints direction="row" alignItems="center">
          <HintVIPImage src={HintVIPIcon} />
          × {gameAudit?.players?.[user?.userId ?? ''].extraHint ? 0 : 1}
        </VIPRemainingHints>}
        {activeCategory === undefined ? <>
          <Title>灵光一闪！</Title>
          <Subtitle>
            当推理陷入瓶颈时，来这里获取提示吧。<br />
            会员用户可以获得额外一次提示机会哦！
          </Subtitle>
          {!['VOTING', 'REVEALING'].includes(gameAudit?.stage ?? '') ? <NotYetText>
            调查还未结束，<br />先继续探寻吧~
          </NotYetText> : <>
            <CategoriesGrid>
              {Object.entries(gameAudit?.hints?.categories ?? {}).map(([category, hintInfo], index) =>
                <CategoryBubble key={category} style={{ backgroundColor: CategoryColors[index] }} onClick={() => onCategoryClicked(category)}>
                  {category}
                  <VotesContainer>
                    {votedHints[category] && votedHints[category].map((character, index) =>
                      <VoteBadge key={character} index={index} color={getCharacterColors(gameAudit?.storyId)?.[character]}>
                        {getCharacterAbbr(character, gameAudit?.storyId)}
                      </VoteBadge>
                    )}
                  </VotesContainer>
                  {hintInfo.reveal && <RevealedIcon htmlColor="#50C878" />}
                </CategoryBubble>
              )}
            </CategoriesGrid>
            <Footer>
              开启每个提示需要至少一半的玩家同意， <br />
              会员用户的额外提示可以直接开启。
            </Footer>
          </>}
        </> : <>
          <Title>关于“{activeCategory}”的提示</Title>
          <Subtitle>
            尝试思考以下问题， <br />
            看能不能找到真相吧！
          </Subtitle>
          <HintTextWrapper direction="column" alignItems="center" spacing={4}>
            {gameAudit?.hints?.categories[activeCategory ?? ''].textList && gameAudit?.hints?.categories[activeCategory ?? ''].textList.map((text, index) =>
              <HintText key={`${activeCategory}-hint-${index}`}>
                {text}
              </HintText>
            )}
            {gameAudit?.hints?.categories[activeCategory ?? '']?.clues &&
              <Stack direction="column" alignItems="center">
                <span style={{ marginTop: '32px', fontWeight: 'bold', fontSize: '20px' }}>关键线索：</span>
                <CluesText>
                  {gameAudit?.hints?.categories[activeCategory ?? '']?.clues?.join('、')}
                </CluesText>
              </Stack>}
          </HintTextWrapper>
          <Footer style={{ flexDirection: "column", alignItems: "center", gap: '16px', marginTop: '32px', fontSize: '16px' }}>
            <ThemeButton width="80px" onClick={onReturnButtonClicked}>返回</ThemeButton>
            *提示以帮助大家还原故事为主，不会带有指向性的信息。<br />
          </Footer>
        </>}
        <Eraser />
        <BluePen />
        <RedPen />
      </HintsWrapper>
      <Dialog open={showExtraHintDialog} onClose={onExtraHintDialogClose}>
        <DialogTitle>会员功能</DialogTitle>
        <DialogContent>
          <DialogContentText>是否使用会员额外提示？（仅一次）</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onConfirmExtraHint}>使用（直接开启）</Button>
          <Button onClick={onNotConfirmExtraHint}>不使用（普通投票）</Button>
        </DialogActions>
      </Dialog>
    </FullDialog>
  );
};

export default HintsDialog;
