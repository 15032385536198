import { createBinder, getAssetUrl } from "utils/utils";
import { YSZM_InvestigatePage, YSZM_InvestigatePageProps } from "./YSZM_InvestigatePage";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GameContext, UserContext, VolumeContext } from "utils/contexts";
import { socket } from "utils/socketConnector";
import PickClueDialogBinder from "lib/game/components/PickClueDialogBinder";
import useSound from "use-sound";
import { YSZM_ForbiddenClues } from "./YSZM_StaticData";

export type YSZM_InvestigatePageBinderManagedProps = 'showMap' | 'gameTime' | 'numRemainingClues' | 'locationHasNewClue'
  | 'onLocationButtonClicked' | 'pickClueDialog';

export interface YSZM_InvestigatePageBinderProps extends Omit<YSZM_InvestigatePageProps, YSZM_InvestigatePageBinderManagedProps> {
  showPickClueDialog: boolean;
  setShowPickClueDialog: (_: boolean) => void;
}

const useYSZM_InvestigatePageBinder = (props: YSZM_InvestigatePageBinderProps): YSZM_InvestigatePageProps => {

  const { user } = useContext(UserContext);
  const volume = useContext(VolumeContext);
  const gameAudit = useContext(GameContext);
  const [newClues, setNewClues] = useState<Record<string, string[]>>({});
  const { showPickClueDialog, setShowPickClueDialog } = props;
  const [currentLocation, setCurrentLocation] = useState<string>();


  const [, { stop: musicStop }] = useSound(getAssetUrl('/YSZM/bgm.mp3'), {
    volume: volume / 100,
    loop: true,
    autoplay: true,
    interrupt: true,
  });

  useEffect(() => {
    return () => musicStop();
  }, [musicStop]);

  const numRemainingClues = useMemo(() =>
    Object.fromEntries(
      Object.entries(gameAudit?.rooms ?? {})
        .map(([room, roomInfo]) => [room, Object.values(roomInfo.clues).reduce(((numClues, clueInfo) => numClues + (clueInfo.isFound ? 0 : 1)), 0)]))
    , [gameAudit?.rooms]);

  const unavailableClues = useMemo(() => [
    ...(gameAudit?.stage !== 'INVESTIGATING3' ? ['clue04', 'clue05', 'clue13'] : []),
  ], [gameAudit?.stage]);

  const forbiddenClues = useMemo(() => YSZM_ForbiddenClues[gameAudit?.players?.[user?.userId ?? '']?.character ?? ''], [gameAudit?.players, user?.userId]);

  useEffect(() => {

    const onClueFound = (clue: string, location: string) => {
      setNewClues((prev) => ({
        ...prev,
        [location]: prev[location] ? [...prev[location], clue] : [clue]
      }));
    }

    const onCluePublished = (clue: string, location: string, character: string) => {
      if (character !== gameAudit?.players?.[user?.userId ?? '']?.character) {
        setNewClues((prev) => ({
          ...prev,
          [location]: prev[location] ? [...prev[location], clue] : [clue]
        }));
      }
    }

    const onStageTransition = (newStage: string) => {

    }

    socket?.on('clueFound', onClueFound);
    socket?.on('cluePublished', onCluePublished);
    socket?.on('stageTransition', onStageTransition);

    return () => {
      socket?.off('clueFound', onClueFound);
      socket?.off('cluePublished', onCluePublished);
      socket?.off('stageTransition', onStageTransition);
    }
  }, [gameAudit?.players, musicStop, user?.userId]);

  const onLocationButtonClicked = useCallback((location: string) => {
    setCurrentLocation(location);
    setShowPickClueDialog(true);
  }, [setShowPickClueDialog]);

  const handlePickClueDialogClose = useCallback(() => {
    setShowPickClueDialog(false);
    if (currentLocation) {
      const temp = { ...newClues };
      delete temp[currentLocation];
      setNewClues(temp);
    }
  }, [currentLocation, newClues, setShowPickClueDialog]);

  const managedProps: Pick<YSZM_InvestigatePageProps, YSZM_InvestigatePageBinderManagedProps> = {
    showMap: !!gameAudit?.stage && gameAudit.stage.slice(0, -1) !== 'READING' && gameAudit.stage !== 'INTRODUCING',
    gameTime: ['READING1', 'INTRODUCING', 'INVESTIGATING1'].includes(gameAudit?.stage ?? '') ? '12:15' : '13:15',
    numRemainingClues,
    locationHasNewClue: useMemo(() => Object.keys(newClues), [newClues]),
    onLocationButtonClicked,
    pickClueDialog: useMemo(() => <PickClueDialogBinder location={currentLocation} newClues={newClues[currentLocation ?? '']} open={showPickClueDialog} onClose={handlePickClueDialogClose} allowToInvestigate unavailableClues={unavailableClues} forbiddenClues={forbiddenClues} />, [currentLocation, forbiddenClues, handlePickClueDialogClose, newClues, showPickClueDialog, unavailableClues]),
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const YSZM_InvestigatePageBinder = createBinder(YSZM_InvestigatePage, useYSZM_InvestigatePageBinder);

export default YSZM_InvestigatePageBinder;