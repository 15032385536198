import { useCallback, useContext, useMemo } from 'react';
import { PrivateCluesDialog, PrivateCluesDialogProps } from './PrivateCluesDialog';
import { createBinder } from 'utils/utils';
import { GameContext, UserContext } from 'utils/contexts';
import { socket } from 'utils/socketConnector';
import { useParams } from 'react-router-dom';

export type PrivateCluesDialogBinderManagedProps = 'clues' | 'currentCharacter' | 'onPublishButtonClicked';

export interface PrivateCluesDialogBinderProps extends Omit<PrivateCluesDialogProps, PrivateCluesDialogBinderManagedProps> { }

const usePrivateCluesDialogBinder = (props: PrivateCluesDialogBinderProps): PrivateCluesDialogProps => {

  const { user } = useContext(UserContext);
  const gameAudit = useContext(GameContext);
  const currentCharacter = gameAudit?.players?.[user?.userId ?? '']?.character;
  const { gameId } = useParams();

  const isAudience = useMemo(() => !Object.keys(gameAudit?.players ?? {}).includes(user?.userId ?? ''), [gameAudit?.players, user?.userId]);
  const clues = useMemo(() => {
    const privateClues = gameAudit?.players?.[user?.userId ?? '']?.privateClues;
    if (privateClues && privateClues.length > 0) {
      return Object.fromEntries(privateClues.map((privateClue) => [
        privateClue,
        privateClue.slice(0, 4) === 'clue' ? Object.values(gameAudit?.rooms ?? {}).find((room) => Object.keys(room.clues).includes(privateClue))?.clues?.[privateClue] : gameAudit.secrets?.[privateClue]
      ]))
    }
  }, [gameAudit?.players, gameAudit?.rooms, gameAudit?.secrets, user?.userId]);

  const onPublishButtonClicked = useCallback((clue: string) => {
    if (clue.slice(0, 4) === 'clue') {
      const clueRoom = Object.keys(gameAudit?.rooms ?? {}).find((room) => Object.keys(gameAudit?.rooms[room]?.clues ?? {}).includes(clue));
      if (!isAudience && clueRoom) {
        socket?.emit('playerPublishClue', user?.userId, gameId, clue, clueRoom);
      }
    } else {
      if (!isAudience) {
        socket?.emit('playerPublishSecret', user?.userId, gameId, clue);
      }
    }
  }, [gameAudit?.rooms, gameId, isAudience, user?.userId]);

  const managedProps: Pick<PrivateCluesDialogProps, PrivateCluesDialogBinderManagedProps> = {
    clues,
    currentCharacter,
    onPublishButtonClicked,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const PrivateCluesDialogBinder = createBinder(PrivateCluesDialog, usePrivateCluesDialogBinder);

export default PrivateCluesDialogBinder;
