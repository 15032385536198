import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { DialogProps, Grid, Stack, Tooltip, TooltipProps } from '@mui/material';
import { getAssetUrl } from 'utils/utils';
import { ThemeButton } from 'lib/common/ThemeButton';
import { FullDialog } from 'lib/common/FullDialog';
import { FlipBackKeyframes } from 'utils/keyframes';
import { ClueRes } from 'openapi';
import FlipSound from 'assets/sound/flip.mp3';
import useSound from 'use-sound';
import StarIcon from '@mui/icons-material/Star';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { ClipColor, Clips } from '../../game/components/ClueClipDialogBinder';
import { CommonIconButton } from 'lib/common/CommonIconButton';

const ContentWrapper = styled(Stack)`
  width: 100vw;
  height: 100dvh;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
`;

const TitleBox = styled.div`
  width: 100vw;
  padding-top: 30px;
  color: white;
  font-size: 56px;
  font-family: '黑体';
  text-align: center;
`;

const ClueContainer = styled(Grid)`
  width: 100vw;
  margin-top: 12px;
  margin-bottom: 12px;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding-left: 10vw;
  padding-right: 10vw;
`;

const ClueCardContainer = styled.div<{ shouldFlip?: boolean }>`
  width: calc(33.2vh + 6px);
  height: calc(48vh + 6px);
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

const FlipAnimationWrapper = styled.div`
  transform-style: preserve-3d;
  animation-duration: 0.8s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-delay: 0.5s;
  animation-name: ${FlipBackKeyframes};
  perspective: 1000px;
`;

const ClueCard = styled.img<{ clickable?: boolean, isFound?: boolean, isPublic?: boolean }>`
  width: 33.2vh;
  height: 48vh;
  border: 3px solid white;
  border-radius: 20px;
  backface-visibility: hidden;
  cursor: ${(props) => (props.isFound || !props.clickable) ? 'auto' : 'pointer'};
  filter: ${(props) => props.isPublic ? 'brightness(1.0)' : ((props.isFound || !props.clickable) ? 'brightness(0.7)' : 'brightness(0.9)')};
  transition: opacity 2s ease-in-out 1s;

  &:hover {
    filter: ${(props) => props.isPublic ? 'brightness(1.0)' : ((props.isFound || !props.clickable) ? 'brightness(0.7)' : 'brightness(1.1)')};
  }
`;

const FlipCard = styled(ClueCard)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const FlipCardFront = styled(ClueCard)`
  transform: rotateY(-180deg);
`;

const ClueText = styled.div`
  height: 35px;
  margin-top: 12px;
  font-size: 24px;
  color: white;
  font-family: '微软雅黑';
  text-align: center;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.4), 0 6px 20px rgba(0, 0, 0, 0.3);
`;

const Footer = styled.div`
  width: 120px;
  padding-bottom: 24px;
`;

export const ClipBadge = styled.div<{ color?: string }>`
  width: 36px;
  height: 48px;
  border-radius: 3px;
  padding-top: 5px;
  font-family: 'Mircrosoft Yahei';
  font-size: 24px;
  color: white;
  text-align: center;
  position: absolute;
  top: -16px;
  left: 25px;
  background-color: #ffe6a7;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;

  &:before {
    background-color: inherit;
    content: '';
    height: 26px;
    width: 26px;
    position: absolute;
    top: 33px;
    left: 5.25px;
    border-radius: 3px;
    transform: rotate(45deg);
  }
`;

export const ClipTooltip = styled((({ className, ...props }: TooltipProps) => (
  <Tooltip leaveDelay={300} classes={{ popper: className }} {...props} />
)))`
  & .MuiTooltip-tooltip {
    max-width: 600px;
    background-color: white;
    border-radius: 16px;
  }

  & .MuiTooltip-arrow {
    color: white;
    font-size: 16px;
  }
`;

export interface ExploreDialogProps extends DialogProps {
  clues?: Record<string, ClueRes>;
  currentCharacter?: string;
  newClues?: string[];
  onClueButtonClicked: (clue: string) => void;
  onPublishButtonClicked: (clue: string) => void;
  newCharacters?: Record<string, string | undefined>;
  onClipButtonClicked: (clue: string, clip?: string) => void;
  clipedClues?: Record<string, string>;
  isSmallRoadFound: boolean;
}

export const ExploreDialog: FC<ExploreDialogProps> = function ({
  clues,
  currentCharacter,
  newClues,
  onClueButtonClicked,
  onPublishButtonClicked,
  newCharacters,
  onClipButtonClicked,
  clipedClues,
  isSmallRoadFound,
  ...props
}) {

  const [playFlipSound] = useSound(FlipSound, { volume: 0.5, interrupt: true });
  const [clueAnimationEnded, setClueAnimationEnded] = useState<string[]>([]);

  useEffect(() => {
    return () => setClueAnimationEnded([]);
  }, []);

  return (
    <FullDialog {...props}>
      <ContentWrapper direction="column" alignItems="center">
        <TitleBox>{`探索时珞庄外`}</TitleBox>
        <ClueContainer container rowSpacing={12}>
          {Object.entries(clues ?? {}).map(([clue, clueInfo]) => (
            <Grid key={clue} item xs={['clue23', 'clue24'].includes(clue) ? 6 : 4}>
              <Stack direction="column" >
                <ClueCardContainer>
                  {newClues?.includes(clue) ?
                    <>
                      <FlipAnimationWrapper onAnimationStart={() => playFlipSound()} onAnimationEnd={() => setClueAnimationEnded((prev) => [...prev, clue])}>
                        <FlipCardFront src={getAssetUrl(clueInfo.frontUrl)} isFound isPublic draggable={false} onContextMenu={(e) => e.preventDefault()} />
                        <FlipCard src={getAssetUrl(clueInfo.backUrl)} draggable={false} onContextMenu={(e) => e.preventDefault()} />
                      </FlipAnimationWrapper>
                      {clueAnimationEnded.includes(clue) &&
                        <ClipTooltip arrow placement="top" title={
                          <Stack direction="row" spacing={1} onAnimationStart={(event) => event.stopPropagation()}>
                            {Clips.map((clip) =>
                              <CommonIconButton key={clip} icon={<StarIcon fontSize="large" htmlColor={ClipColor[clip]} />} onClick={() => onClipButtonClicked(clue, clip)} />
                            )}
                            <CommonIconButton icon={<NotInterestedIcon fontSize="large" htmlColor="red" />} onClick={() => onClipButtonClicked(clue)} />
                          </Stack>}>
                          <ClipBadge>
                            <StarIcon htmlColor={ClipColor[clipedClues?.[clue] ?? '']} />
                          </ClipBadge>
                        </ClipTooltip>}
                    </> :
                    <>
                      <ClueCard
                        src={getAssetUrl((clueInfo.isPublic || clueInfo.isFound === currentCharacter) ? clueInfo.frontUrl : clueInfo.backUrl)}
                        isFound={!!clueInfo.isFound}
                        isPublic={clueInfo.isPublic || clueInfo.isFound === currentCharacter}
                        clickable
                        draggable={false}
                        onContextMenu={(e) => e.preventDefault()}
                        onClick={() => onClueButtonClicked(clue)}
                        style={{ opacity: ['clue23', 'clue24'].includes(clue) || isSmallRoadFound ? 1 : 0 }}
                      />
                      {(clueInfo.isPublic || clueInfo.isFound === currentCharacter) &&
                        <ClipTooltip arrow placement="top" title={
                          <Stack direction="row" spacing={1}>
                            {Clips.map((clip) =>
                              <CommonIconButton key={clip} icon={<StarIcon fontSize="large" htmlColor={ClipColor[clip]} />} onClick={() => onClipButtonClicked(clue, clip)} />
                            )}
                            <CommonIconButton icon={<NotInterestedIcon fontSize="large" htmlColor="red" />} onClick={() => onClipButtonClicked(clue)} />
                          </Stack>}>
                          <ClipBadge>
                            <StarIcon htmlColor={ClipColor[clipedClues?.[clue] ?? '']} />
                          </ClipBadge>
                        </ClipTooltip>}
                    </>
                  }
                </ClueCardContainer>
                <ClueText>
                  {clueInfo.isFound
                    ? <>
                      {clueInfo.isPublic ? <>由<span style={{ color: '#FFC96B' }}>{newCharacters?.[clueInfo.isFound as string] ?? clueInfo.isFound}</span>公开</>
                        : <>
                          {clueInfo.isFound === currentCharacter ? <>
                            <span
                              style={{ color: '#4EB7FE', textDecoration: 'underline', cursor: 'pointer' }}
                              onClick={() => onPublishButtonClicked(clue)}
                            >
                              点击此处
                            </span>
                            公开该线索
                          </> : `已被${newCharacters?.[clueInfo.isFound as string] ?? clueInfo.isFound}调查`}
                        </>}
                    </>
                    : <>
                    </>}
                </ClueText>
              </Stack>
            </Grid>
          ))}
        </ClueContainer>
        <Footer>
          <ThemeButton onClick={() => props.onClose?.({}, "escapeKeyDown")}>返回</ThemeButton>
        </Footer>
      </ContentWrapper>
    </FullDialog>
  );
};

export default ExploreDialog;
