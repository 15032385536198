import styled from '@emotion/styled';
import { ThemeButton } from 'lib/common/ThemeButton';
import { FC, useState } from 'react';
import { CommonUIProps } from 'utils/types';
import Calibration from 'assets/calibration.webp';
import Logo from 'assets/logo1024_white.png';
import { useMediaQuery } from 'react-responsive';
import ConfirmDialog from 'lib/common/ConfirmDialog';

const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: relative;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${Calibration});
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CalibrationText = styled.div`
  color: white;
  font-size: 20px;
  text-align: center;
  line-height: 1.5em;
`;

const LogoImage = styled.img`
  width: 400px;
  height: 400px;
`;

const ConfirmButton = styled(ThemeButton)`
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 50px;
`;

export interface CalibrationPageProps extends CommonUIProps {
  onConfirmButtonClicked: () => void;
}

export const CalibrationPage: FC<CalibrationPageProps> = function ({
  onConfirmButtonClicked,
  ...props
}) {

  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const tooSmall = useMediaQuery({ maxWidth: 2048 });
  const tooLarge = useMediaQuery({ minWidth: 2850 });


  return (
    <PageContainer {...props}>
      <LogoImage src={Logo} />
      <CalibrationText>
        为确保游戏页面正确渲染，请调整浏览器的缩放比例<br /><br />
        当前缩放比例：<span style={{ fontSize: '24px', fontWeight: 'bold', color: (tooSmall || tooLarge) ? '#d32f2f' : '#b5e550' }}>{tooSmall ? '过大' : tooLarge ? '过小' : '正好！'}</span><br /><br />

        Windows用户可以使用Ctrl + 鼠标滚轮，或打开浏览器右上角的设置菜单<br />
        Mac用户可以使用Command + 加减号<br />


        Ipad用户请使用Safari浏览器，点击网址最左侧的缩放按钮(“大小”字样) <br />
        (Ipad上Chrome浏览器暂不支持缩放效果)<br />


      </CalibrationText>
      <ConfirmButton width='120px' onClick={() => {
        if (tooSmall || tooLarge) {
          setShowConfirmDialog(true);
        } else {
          onConfirmButtonClicked();
        }
      }}>确认</ConfirmButton>
      <ConfirmDialog open={showConfirmDialog} onClose={() => setShowConfirmDialog(false)} title="确定以当前缩放比例进入游戏吗？" content="不设置成正确缩放比例，会使游戏画面失调。" onConfirmButtonClicked={onConfirmButtonClicked} />
    </PageContainer>
  );
};

export default CalibrationPage;
