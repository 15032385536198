import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';
import { FadeInAndOutKeyframes } from 'utils/keyframes';
import useSound from 'use-sound';
import BackgroundMusic from 'assets/music/changtuishushu.mp3';

const ContentContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-family: STFangSong;
  font-size: 32px;
  text-indent: 2em;
  text-align: justify;
`;

const Info = styled.div`
  width: 40vw;
  height: 100vh;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 10%;
  top: 0;
  bottom: 0;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  text-align: justify;
  line-height: 1.5;
`;

const Info1 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 10s ease-in-out both 2s;
`;

const Info2 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 13s;
`;

const Info3 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 15s ease-in-out both 26s;
`;

const Info4 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 42s;
`;

const InfoImage = styled.img`
  margin-bottom: 50px;
`;

const PosterIamge = styled.img`
  height: 60vh;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 15%;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 1.5s ease-in-out both;
`;


export interface YLW_EpiloguePageProps extends CommonUIProps { }

export const YLW_EpiloguePage: FC<YLW_EpiloguePageProps> = function ({
  ...props
}) {
  const [, { stop }] = useSound(BackgroundMusic, {
    volume: 0.5,
    autoplay: 1,
    interrupt: true,
    loop: true
  });
  const [showPoster, setShowPoster] = useState<boolean>(false);

  useEffect(() => {
    return () => stop();
  }, [stop])

  return (
    <ContentContainer {...props}>
      <Info1>
        <InfoImage src={getAssetUrl('/YLW/bingdilian.webp')} height={600} />
        <p>
          并蒂莲因花开并蒂而得名，非常罕见，有“花中君子”之称。其花语是缠绵爱情，永结同心，也暗指双胞胎。
        </p>
      </Info1>
      <Info2>
        <InfoImage src={getAssetUrl('/YLW/twin.webp')} height={600} />
        <p>现实生活中的双胞胎实验发生在1930年，美国因为经济危机，穷人家生双胞胎养不起，只好把其中一个送给福利机构。美国大学借此和福利机构合作进行大规模心理学实验，追踪调查被分开的双胞胎，研究他们的性格和经历。</p>
      </Info2>
      <Info3>
        <InfoImage src={getAssetUrl('/YLW/books.webp')} height={600} />
        <p>《时间机器》（The Time Machine）是英国小说家H.G.Wells在1895年发表的作品，是科幻小说的里程碑之作。《长腿叔叔》（Daddy-Long-Legs）是美国作家Jean Webster在1912年发表的小说，也是著名的女性向青春题材作品。</p>
      </Info3>
      <Info4 onAnimationEnd={() => setShowPoster(true)}>《月落洼》里出现的在上海的护士学校，安季奚在大学里的同事等，都曾出现在豪门惊情系列的其他剧本当中。如果想了解更多他们的故事，可以选择《孽岛疑云》、《岳麓山下》、《待月弑杀》等剧本进行体验。</Info4>
      <PosterIamge src={getAssetUrl('/YLW/poster.webp')} style={{ opacity: showPoster ? '1' : '0' }} />
    </ContentContainer>
  );
};

export default YLW_EpiloguePage;
