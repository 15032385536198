/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from '@emotion/styled';
import { FC } from 'react';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Stack } from '@mui/material';
import { CommonDialog } from 'lib/common/CommonDialog';
import { getRoleName } from 'utils/utils';
import { ThemeButton } from 'lib/common/ThemeButton';
import { StripeItem, UserRole } from 'openapi';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DonateImg from 'assets/donate.webp';

const SubscriptionInfo = styled(Stack)`
  margin-bottom: 8px;
`;

const PlanContainer = styled(Stack)`
  width: 280px;
  height: 480px;
  border-radius: 12px;
  padding: 12px;
  background-color: white;
`;

const PlanChip = styled(Chip)`
  color: white;
  font-size: 16px;
  font-weight: bold;
`;

const PlanShortDescription = styled.div`
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center;
`;

const PlanButton = styled(ThemeButton)`
  height: 36px;

  &.Mui-disabled {
    color: #C4C4C4;
  }
`;

const PlanList = styled.ul`
  background-color: #EFEFEF;
  border-radius: 8px;
  line-height: 1.5em;
  gap: 8xp;
  text-align: justify;
  padding-bottom: 12px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PlanListItem = styled.li`
  margin-top: 12px;
  margin-right: 16px;
`;

const PriceBox = styled.div`
  font-size: 32px;
  font-weight: bold;
`;

const FooterBox = styled.div`
  margin-top: 8px;
  font-size: 12px;
`;

const CancelSubscriptionSuccessContentWrapper = styled(Stack)`
  line-height: 1.5em;
`;

const QuantityStack = styled(Stack)`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #D4D4D4;
`;

const QuantityButton = styled.button`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.disabled ? 'gray' : '#4097fe'};
  pointer-events: ${(props) => props.disabled ? 'none' : 'auto'};
  background-color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 24px;

  &:hover {
    background-color: #f3f9ff;
  }
`;

const QuantityText = styled.div`
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #D4D4D4;
  border-right: 1px solid #D4D4D4;
`;

const Donate = styled.img`
  width: 100%;
`;

export interface SubscriptionDialogProps extends DialogProps {
  role?: UserRole;
  subscriptionStatus?: string;
  periodEndDate?: string;
  onPurchaseButtonClicked: (item: StripeItem | 'PLAN_A') => void;
  showConfirmCancelSubscriptionDialog: boolean;
  onConfirmCancelSubscriptionDialogClose: () => void;
  onConfirmCancelSubscriptionButtonClicked: () => void;
  showCancelSubscriptionSuccessDialog: boolean;
  onCancelSubscriptionSuccessDialogClose: () => void;
  subscriptionEndDate?: string;
  showConfirmDowngradeDialog: boolean;
  onConfirmDowngradeDialogClose: () => void;
  onConfirmDowngradeButtonClicked: () => void;
  showDowngradeSuccessDialog: boolean;
  onDowngradeSuccessDialogClose: () => void;
  downgradeEffectiveDate?: string;
  showUpgradeSuccessDialog: boolean;
  onUpgradeSuccessDialogClose: () => void;
  upgradeBillDate?: string;
  showUncancelButton: boolean;
  onUncancelButtonClicked: () => void;
  quantity: number;
  onQuantityChange: (change: number) => void;
}

export const SubscriptionDialog: FC<SubscriptionDialogProps> = function ({
  role,
  subscriptionStatus,
  periodEndDate,
  onPurchaseButtonClicked,
  showConfirmCancelSubscriptionDialog,
  onConfirmCancelSubscriptionDialogClose,
  onConfirmCancelSubscriptionButtonClicked,
  showCancelSubscriptionSuccessDialog,
  onCancelSubscriptionSuccessDialogClose,
  subscriptionEndDate,
  showConfirmDowngradeDialog,
  onConfirmDowngradeDialogClose,
  onConfirmDowngradeButtonClicked,
  showDowngradeSuccessDialog,
  onDowngradeSuccessDialogClose,
  downgradeEffectiveDate,
  showUpgradeSuccessDialog,
  onUpgradeSuccessDialogClose,
  upgradeBillDate,
  showUncancelButton,
  onUncancelButtonClicked,
  quantity,
  onQuantityChange,
  ...props
}) {
  return (
    <CommonDialog title="订阅和打赏"{...props}>
      <SubscriptionInfo direction="row" alignItems="center" justifyContent="center" spacing={2}>
        <span>{`当前计划：${getRoleName(role)}`}</span>
        <span>{`订阅状态：${subscriptionStatus ?? 'N/A'}`}</span>
        <span>{`本月订阅结束日期: ${periodEndDate ?? 'N/A'}`}</span>
        {showUncancelButton && <ThemeButton height="36px" width="100px" onClick={onUncancelButtonClicked}>恢复订阅</ThemeButton>}
      </SubscriptionInfo>
      <Stack direction="row" alignItems="center" spacing={2}>
        <PlanContainer direction="column" alignItems="center" spacing={2} style={{ border: role === 'PLAN_A' ? '3px solid #6f9568' : '3px solid #D4D4D4' }}>
          <PlanChip label={getRoleName('PLAN_A')} style={{ backgroundColor: "#A5DD9B" }} />
          <PlanShortDescription>所有用户都可以免费体验平台剧本!</PlanShortDescription>
          <PlanButton disabled={role === 'PLAN_A'} onClick={() => onPurchaseButtonClicked('PLAN_A')}>{role === 'PLAN_A' ? '当前计划' : '加入'}</PlanButton>
          <PlanList>
            <PlanListItem>所有平台上的案件都是免费游玩的</PlanListItem>
            <PlanListItem>邀请好友一起来侦破民国三年的那些奇案吧！</PlanListItem>
          </PlanList>
          <PriceBox style={{ color: "#6f9568" }}>免费！</PriceBox>
        </PlanContainer>
        <PlanContainer direction="column" alignItems="center" spacing={2} style={{ border: role === 'PLAN_C' ? '3px solid #EE4266' : '3px solid #D4D4D4' }}>
          <PlanChip label={getRoleName('PLAN_C')} style={{ backgroundColor: "#EE4266" }} />
          <PlanShortDescription>成为会员，支持平台制作更多有趣好玩的剧本，并解锁专属会员功能！</PlanShortDescription>
          <PlanButton disabled={role === 'PLAN_C'} onClick={() => onPurchaseButtonClicked('PLAN_C')}>{role === 'PLAN_C' ? '当前计划' : '加入'}</PlanButton>
          <PlanList>
            <PlanListItem>给辛苦的程序员小哥哥买一杯热乎乎的咖啡</PlanListItem>
            <PlanListItem>特殊会员功能（完整复盘、专属效果、额外提示等）</PlanListItem>
          </PlanList>
          <PriceBox><span style={{ color: "#EE4266" }}>CA$2.99</span><span style={{ fontSize: 18, fontWeight: 'normal' }}>/月</span></PriceBox>
        </PlanContainer>
        <PlanContainer direction="column" alignItems="center" spacing={2} style={{ border: '3px solid #D4D4D4' }}>
          <PlanChip label="打赏" style={{ backgroundColor: "#FBA834" }} />
          <PlanShortDescription>捐助任意金额，帮助制作更多好玩的剧本吧！</PlanShortDescription>
          <PlanButton onClick={() => window.open('https://donate.stripe.com/4gw8ya5hR2Sl0xy144', '_blank')}>打赏</PlanButton>
          <Stack style={{ flex: '1 1 auto' }} justifyContent="center">
            <Donate src={DonateImg} draggable={false} />
          </Stack>
        </PlanContainer>
      </Stack>
      <FooterBox>
        *会员订阅价格可能会随地区变动<br />
        *会员订阅可以随时取消，点击加入免费计划即可，所有权益会在当月继续保留
      </FooterBox>
      <Dialog open={showConfirmCancelSubscriptionDialog} onClose={onConfirmCancelSubscriptionDialogClose}>
        <DialogTitle>很抱歉您想要取消当前的订阅。</DialogTitle>
        <DialogContent>
          <DialogContentText>取消之后您的支付方式在下个月不会被扣款，并且可以在该账单周期内继续享受所有的服务。</DialogContentText>
          <DialogActions>
            <Button onClick={onConfirmCancelSubscriptionButtonClicked}>取消订阅</Button>
            <Button onClick={onConfirmCancelSubscriptionDialogClose}>离开</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog open={showConfirmDowngradeDialog} onClose={onConfirmDowngradeDialogClose}>
        <DialogTitle>很抱歉当前的订阅计划没能达到您的期望。</DialogTitle>
        <DialogContent>
          <DialogContentText>降级之后您在本账单周期内仍可享受所有当前计划的服务，并在下个月按新的价格继续订阅。</DialogContentText>
          <DialogActions>
            <Button onClick={onConfirmDowngradeButtonClicked}>继续降级</Button>
            <Button onClick={onConfirmDowngradeDialogClose}>离开</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <CommonDialog open={showCancelSubscriptionSuccessDialog} onClose={onCancelSubscriptionSuccessDialogClose}>
        <CancelSubscriptionSuccessContentWrapper direction="column" alignItems="center" spacing={2}>
          {`您已成功取消订阅，您的会员服务会在${subscriptionEndDate}到期。`}<br />
          您可以随时续订，期待您的回归！
          <ThemeButton width="80px" height="36px" onClick={onCancelSubscriptionSuccessDialogClose}>确定</ThemeButton>
        </CancelSubscriptionSuccessContentWrapper>
      </CommonDialog>

      <CommonDialog open={showDowngradeSuccessDialog} onClose={onDowngradeSuccessDialogClose}>
        <CancelSubscriptionSuccessContentWrapper direction="column" alignItems="center" spacing={2}>
          {`您已成功降级成会员订阅计划，您当前的VIP服务会保留直到${downgradeEffectiveDate}。`}<br />
          <ThemeButton width="80px" height="36px" onClick={onDowngradeSuccessDialogClose}>确定</ThemeButton>
        </CancelSubscriptionSuccessContentWrapper>
      </CommonDialog>

      <CommonDialog open={showUpgradeSuccessDialog} onClose={onUpgradeSuccessDialogClose}>
        <CancelSubscriptionSuccessContentWrapper direction="column" alignItems="center" spacing={2}>
          {`恭喜您成功升级成VIP订阅计划！您可以即可享受所有VIP服务，当前月的剩余天数会按VIP订阅价格折算，并添加至您的下个账单(${upgradeBillDate})中。`}<br />
          <ThemeButton width="80px" height="36px" onClick={onUpgradeSuccessDialogClose}>确定</ThemeButton>
        </CancelSubscriptionSuccessContentWrapper>
      </CommonDialog>
    </CommonDialog>
  );
};

export default SubscriptionDialog;
