import styled from '@emotion/styled';
import { ChangeEvent, FC, useState } from 'react';
import { CommonUIProps } from 'utils/types';
import { Document, Page } from 'react-pdf';
import { CommonIconButton } from 'lib/common/CommonIconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Pagination, Stack } from '@mui/material';
import Logo from 'assets/logo1024_white.png';
import Warning from 'assets/warning.webp';

const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #4D4D4D;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: 640px 640px;
  background-position: center;
  position: relative;
`;

const WarningTape = styled.div`
    width: 100vw;
    height: 100dvh;
    position: absolute;
    left: 0;
    top: 0;
    background-position: center;
    z-index: 0;
`;

const PdfContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    overflow: hidden;
    border: 10px #EFEFEF solid;
    z-index: 1;
`;

const IPagination = styled(Pagination)`
  margin-top: 16px;
  background-color: black;
  border-radius: 8px;
  padding: 4px;
  z-index: 1;
  
  & .MuiButtonBase-root {
    color: white;
  }
`;

const PageButton = styled(CommonIconButton)`
  background-color: black;
`;

export interface TruthPageProps extends CommonUIProps {
    pdfData?: ArrayBuffer,
}

export const TruthPage: FC<TruthPageProps> = function ({
    pdfData,
    ...props
}) {

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [numPages, setNumPages] = useState<number>();

    return (
        <PageContainer style={{ backgroundImage: `url(${Logo})` }} {...props}>
            <WarningTape style={{ backgroundImage: `url(${Warning})` }}/>
            {pdfData && <>
                <Stack direction="row" alignItems="center" spacing={4}>
                    <PageButton icon={<ChevronLeftIcon htmlColor={currentPage > 0 ? "white" : "gray"} fontSize="large" />} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage <= 0} />
                    <PdfContainer>

                        <Document className="pdf-container" file={pdfData} loading={null} onContextMenu={(e) => e.preventDefault()} onLoadSuccess={({ numPages }: { numPages: number }) => setNumPages(numPages)}>
                            <Page canvasBackground="transparent" loading={null} pageIndex={currentPage} height={window.innerHeight * 0.90} renderAnnotationLayer={false} renderTextLayer={false} />
                        </Document>
                    </PdfContainer>
                    <PageButton icon={<ChevronRightIcon htmlColor={currentPage < (numPages ?? 0) - 1 ? "white" : "gray"} fontSize="large" />} onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage >= (numPages ?? 0) - 1} />
                </Stack>
                <IPagination size="large" count={numPages} color="primary" page={currentPage + 1} onChange={(_: ChangeEvent<unknown>, page: number) => setCurrentPage(page - 1)} />
            </>}
        </PageContainer>
    );
};

export default TruthPage;
