import { useContext, useMemo } from 'react';
import { MemoryDialog, MemoryDialogProps } from './MemoryDialog';
import { createBinder } from 'utils/utils';
import { GameContext, UserContext } from 'utils/contexts';

export type MemoryDialogBinderManagedProps = 'currentCharacter' | 'memories';

export interface MemoryDialogBinderProps extends Omit<MemoryDialogProps, MemoryDialogBinderManagedProps> { }

const useMemoryDialogBinder = (props: MemoryDialogBinderProps): MemoryDialogProps => {

  const { user } = useContext(UserContext);
  const gameAudit = useContext(GameContext);

  const memories = useMemo(() =>
    Object.fromEntries(
      Object.entries(gameAudit?.memories?.[gameAudit?.players?.[user?.userId ?? '']?.character ?? ''] ?? {})
        .sort(([, memoryA], [, memoryB]) => memoryA.index - memoryB.index))
    , [gameAudit?.memories, gameAudit?.players, user?.userId]
  );

  const managedProps: Pick<MemoryDialogProps, MemoryDialogBinderManagedProps> = {
    currentCharacter: gameAudit?.players?.[user?.userId ?? '']?.character,
    memories,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const MemoryDialogBinder = createBinder(MemoryDialog, useMemoryDialogBinder);

export default MemoryDialogBinder;
