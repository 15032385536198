import styled from '@emotion/styled';
import { FC } from 'react';
import { DialogProps, Grid, Stack } from '@mui/material';
import { CommonDialog } from 'lib/common/CommonDialog';
import { getStoryPosterUrl, parseGameStatus } from 'utils/utils';
import { GameThumbnail } from 'openapi';
import LoginIcon from '@mui/icons-material/Login';
import { CommonIconButton } from 'lib/common/CommonIconButton';

const ContentWrapper = styled(Stack)`
  max-height: 60dvh;
  min-width: 400px;
  margin-top: 12px;
`;

const StoryGrid = styled(Grid)`
  height: 60dvh;
  overflow: auto;
  width: 40vw;
  min-width: 800px;

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: #E0E0E0;
    border-radius: 100px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #274472;
    border-radius: 100px;
    border: 4px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #274472AA;
  }
`;

const StoryChoiceWrapper = styled(Stack)`
  margin-left: 8px;
  margin-right: 8px;
`;

const PosterContainer = styled.div`
  position: relative;
`;

const StoryPoster = styled.img`
  width: 240px;
  border: 3px solid #D4D4D4;
  border-radius: 4px;
`;

const PosterMask = styled.div`
  width: 240px;
  height: 339.45px;
  position: absolute;
  left: 3px;
  top: 3px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  > div {
    display: none;
  }
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.4);

    > div {
      display: flex;
    }
  }
`;

const ButtonStack = styled(Stack)`
  border-radius: 16px;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.8);
`;


const StoryInfoStack = styled(Stack)`
  width: 100%;
  padding-bottom: 36px;
`;

const GameDate = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const StautsText = styled.div`
  font-size: 20px;
`;

const EmptyText = styled.div`
  width: 100%;
  height: 400px;
  font-size: 36px;
  color: #A4A4A4AA;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 2;
`;

export interface MyCasesDialogProps extends DialogProps {
  cases?: Record<string, GameThumbnail>;
  onEnterCasesButtonClicked: (gameId: string) => void;
}

export const MyCasesDialog: FC<MyCasesDialogProps> = function ({
  cases,
  onEnterCasesButtonClicked,
  ...props
}) {
  return (
    <CommonDialog title="我的案件" {...props}>
      <ContentWrapper direction="row" alignItems="center" spacing={2}>
        {cases ?
          <StoryGrid container rowSpacing={3}>
            {Object.entries(cases ?? {}).map(([gameId, gameThumbnail]) => (
              <Grid key={gameId} item xs={4}>
                <StoryChoiceWrapper direction="column" alignItems="center" spacing={1}>
                  <PosterContainer>
                    <StoryPoster src={getStoryPosterUrl(gameThumbnail.storyId)} />
                    <PosterMask>
                      <ButtonStack direction="row">
                        <CommonIconButton icon={<LoginIcon htmlColor="white" />} onClick={() => onEnterCasesButtonClicked(gameId)} />
                      </ButtonStack>
                    </PosterMask>
                  </PosterContainer>

                  <StoryInfoStack direction="row" justifyContent="center" spacing={4}>
                    <GameDate>{new Date(gameThumbnail.createTime).toISOString().slice(0, 10)}</GameDate>
                    <StautsText>{parseGameStatus(gameThumbnail.status)}</StautsText>
                  </StoryInfoStack>
                </StoryChoiceWrapper>
              </Grid>
            ))}
          </StoryGrid> :
          <EmptyText>您暂时没有任何案件哦<br />快和朋友们一起去破案吧！</EmptyText>
        }
      </ContentWrapper>

    </CommonDialog>
  );
};

export default MyCasesDialog;
