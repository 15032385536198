import { useState, useCallback, useEffect, useContext } from 'react';
import { MyCasesDialog, MyCasesDialogProps } from './MyCasesDialog';
import { createBinder } from 'utils/utils';
import { GameThumbnail } from 'openapi';
import clientApi from 'utils/clientApi';
import { useNavigate } from 'react-router-dom';
import RouterPath from 'router/RouterPath';
import { UserContext } from 'utils/contexts';

export type MyCasesDialogBinderManagedProps = 'cases' | 'onEnterCasesButtonClicked';

export interface MyCasesDialogBinderProps extends Omit<MyCasesDialogProps, MyCasesDialogBinderManagedProps> { }

const useMyCasesDialogBinder = (props: MyCasesDialogBinderProps): MyCasesDialogProps => {

  const { user } = useContext(UserContext);
  const [myCases, setMyCases] = useState<Record<string, GameThumbnail>>();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !myCases) {
      clientApi().getUserCases().then((result) => {
        setMyCases(result.data);
      }).catch((error: unknown) => {
        console.error(error);
      })
    }
  }, [myCases, user]);

  const onEnterCasesButtonClicked = useCallback((gameId: string) => {
    navigate(`${RouterPath.Game}/${gameId}`);
  }, [navigate]);

  const managedProps: Pick<MyCasesDialogProps, MyCasesDialogBinderManagedProps> = {
    cases: myCases,
    onEnterCasesButtonClicked,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const MyCasesDialogBinder = createBinder(MyCasesDialog, useMyCasesDialogBinder);

export default MyCasesDialogBinder;
