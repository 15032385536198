/* eslint-disable react/jsx-pascal-case */
import styled from '@emotion/styled';
import { LocationButton } from 'lib/game/components/LocationButton';
import { FC, ReactNode, useContext, useState } from 'react';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';
import { GameContext } from 'utils/contexts';
import { ThemeButton } from 'lib/common/ThemeButton';
import ConfirmDialog from 'lib/common/ConfirmDialog';
import { Stack } from '@mui/material';
import { Falling0Keyframes, Falling1Keyframes, Falling2Keyframes, Falling3Keyframes, Falling4Keyframes, WalkingKeyframes } from 'utils/keyframes';
import { useRandomInterval } from 'utils/hooks';
import { css } from '@emotion/react';

const MapContainer = styled.div`
  width: 50vw;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const Map = styled.img`
  width: 100%;
`;

const GameStageInfo = styled.div`
  position: absolute;
  top: 40px;
  right: 60px;
  text-align: center;
  font-family: 'STZhongSong';
  font-size: 40px;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  color: white;
`;

const ILocationButton = styled(LocationButton)`
  border-width: 8px;
  &:hover {
    border-color: #ff7b7b;
  }
`;

const FuCiYa = styled(ILocationButton)`
  width: 50.6%;
  height: 43.9%;
  left: 0;
  top: 0.9%;
`;

const PengFloor1 = styled(ILocationButton)`
  width: 14.8%;
  height: 38.5%;
  right: 1.8%;
  top: 1.5%;
`;

const PengFloor2 = styled(ILocationButton)`
  width: 19.1%;
  height: 38.6%;
  right: 24.4%;
  top: 1.5%;
`;

const JiLouFloor1 = styled(ILocationButton)`
  width: 27.0%;
  height: 44.4%;
  left: 28%;
  top: 47.5%;
`;

const JiLouFloor2 = styled(ILocationButton)`
  width: 19.0%;
  height: 38.5%;
  left: 0;
  top: 52.5%;
`;

const BaJia = styled(ILocationButton)`
  width: 39.1%;
  height: 49.2%;
  border-radius: 50%;
  right: 0%;
  top: 47.3%;
`;

const InviteButton = styled(ThemeButton)`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: -60px;
`;

const getStageInfo = (stage: string): ReactNode => {
  switch (stage) {
    case 'INVESTIGATING1':
      return <>
        <span style={{ fontSize: '32px' }}>第一幕</span><br />
        先灵之祭<br />
      </>
    case 'INVESTIGATING2':
    case 'DISCUSSING2':
      return <>
        <span style={{ fontSize: '32px' }}>第二幕</span><br />
        昭然失踪<br />
      </>
    case 'INVESTIGATING3':
    case 'VOTING':
      return <>
        <span style={{ fontSize: '32px' }}>第三幕</span><br />
        祓仆殒命<br />
      </>
    default:
      return null;
  }
}

const MapInfoWrapper = styled(Stack)`
  position: absolute;
  top: 18vh;
  right: 4vw;
  color: white;
  font-size: 20px;
  width: 14vw;
  font-family: 'STZhongSong';
  text-align: justify;
`;

const HouseImage = styled.img`
  width: 12vw;
  margin-left: 10px;
  margin-bottom: 10px;
`;

const PlagueDoctor = styled.img<{ animate?: boolean }>`
  width: 200px;
  position: absolute;
  bottom: -15px;
  right: -20vw;
  animation: ${(props) => props.animate ? css`${WalkingKeyframes}` : null};
  animation-duration: 12s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
`;

const Mask = styled.img<{ animation: number }>`
  width: 160px;
  height: 160px;
  position: absolute;
  cursor: pointer;
  animation-name: ${(props) => {
    switch (props.animation) {
      case 0:
        return css`${Falling0Keyframes}`;
      case 1:
        return css`${Falling1Keyframes}`;
      case 2:
        return css`${Falling2Keyframes}`;
      case 3:
        return css`${Falling3Keyframes}`;
      case 4:
        return css`${Falling4Keyframes}`;
      default:
        return null
    }
  }};
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  transition: opacity 0.8s ease-in-out;
`;

interface MaskProps extends CommonUIProps {
  maskIndex: number;
  animation: number;
  animationDuration: number;
  handleTransitionEnd: () => void;
}

export const NuoMask: FC<MaskProps> = function ({
  maskIndex,
  animation,
  animationDuration,
  handleTransitionEnd,
  ...props
}) {

  const [fade, setFade] = useState<boolean>(false);

  return (
    <Mask src={getAssetUrl(`/XLJ/mask${maskIndex + 1}.webp`)}
      style={{ opacity: fade ? '0' : '1', animationDuration: `${animationDuration}s` }}
      animation={animation}
      draggable={false}
      onClick={() => setFade(true)}
      onTransitionEnd={handleTransitionEnd}
      onAnimationEnd={handleTransitionEnd}
      {...props}
    />
  )
}

export interface XLJ_InvestigatePageProps extends CommonUIProps {
  showMap: boolean;
  gameTime: string;
  numRemainingClues: Record<string, number>;
  locationHasNewClue: string[];
  onLocationButtonClicked: (location: string) => void;
  pickClueDialog: ReactNode;
  showPickClueDialog: boolean;
  setShowPickClueDialog: (_: boolean) => void;
  showInviteButton: boolean;
  onInviteButtonClicked: () => void;
  showConfirmDialog: boolean;
  handleConfirmDialogClose: () => void;
  onConfirmInvite: () => void;
}

export const XLJ_InvestigatePage: FC<XLJ_InvestigatePageProps> = function ({
  showMap,
  gameTime,
  numRemainingClues,
  locationHasNewClue,
  onLocationButtonClicked,
  pickClueDialog,
  showPickClueDialog,
  setShowPickClueDialog,
  showInviteButton,
  onInviteButtonClicked,
  showConfirmDialog,
  handleConfirmDialogClose,
  onConfirmInvite,
  ...props
}) {

  const gameStage = useContext(GameContext)?.stage ?? '';

  const [startDoctorAnimation, setStartDoctorAnimation] = useState<boolean>(false);

  useRandomInterval(() => {
    if (['READING2', 'INVESTIGATING2', 'READING3', 'INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameStage)) {
      setStartDoctorAnimation(true);
    }
  }, 8 * 60000, 12 * 60000);

  const [mask, setMask] = useState<ReactNode>();

  useRandomInterval(() => {
    if (!mask) {
      const animation = Math.floor(Math.random() * 5);
      const animationDuration = Math.floor(Math.random() * 5) + 10;
      const maskIndex = Math.floor(Math.random() * 3);
      setMask(<NuoMask maskIndex={maskIndex} animation={animation} animationDuration={animationDuration} handleTransitionEnd={() => setMask(undefined)} />);
    }
  }, 60 * 1000 * 3, 60 * 1000 * 10);

  return (
    <div {...props}>
      {showMap &&
        <>
          <MapContainer>
            <div style={{ width: '100%', position: 'relative' }}>
              <Map src={getAssetUrl('/XLJ/map.webp')} draggable={false} />
              {showInviteButton && <InviteButton width="160px" onClick={onInviteButtonClicked}>去逛一逛吧！</InviteButton>}
              {['INVESTIGATING1', 'DISCUSSING1', 'INVESTIGATING2', 'DISCUSSING2', 'INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameStage) && <>
                <FuCiYa badge={numRemainingClues['祓赐垭']} badgeStyle={{ bottom: '5%', left: '85%' }} hasNewClue={locationHasNewClue.includes('祓赐垭')} onClick={() => onLocationButtonClicked('祓赐垭')} />
                <PengFloor1 badge={numRemainingClues['彭家一层']} badgeStyle={{ bottom: '-15%', left: '105%' }} hasNewClue={locationHasNewClue.includes('彭家一层')} onClick={() => onLocationButtonClicked('彭家一层')} />
              </>}
              {['INVESTIGATING2', 'DISCUSSING2', 'INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameStage) && <>
                <BaJia badge={numRemainingClues['巴家']} badgeStyle={{ bottom: '10%', left: '3%' }} hasNewClue={locationHasNewClue.includes('巴家')} onClick={() => onLocationButtonClicked('巴家')} />
                <PengFloor2 badge={numRemainingClues['彭家二层']} badgeStyle={{ bottom: '-15%', left: '95%' }} hasNewClue={locationHasNewClue.includes('彭家二层')} onClick={() => onLocationButtonClicked('彭家二层')} />
              </>}
              {['INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameStage) && <>
                <JiLouFloor1 badge={numRemainingClues['祭楼一层']} badgeStyle={{ bottom: '-12.5%', left: '71%' }} hasNewClue={locationHasNewClue.includes('祭楼一层')} onClick={() => onLocationButtonClicked('祭楼一层')} />
                <JiLouFloor2 badge={numRemainingClues['祭楼二层']} badgeStyle={{ bottom: '-15%', left: '95%' }} hasNewClue={locationHasNewClue.includes('祭楼二层')} onClick={() => onLocationButtonClicked('祭楼二层')} />
              </>}
            </div>
          </MapContainer>
          <GameStageInfo>
            {getStageInfo(gameStage)}
          </GameStageInfo>
          <MapInfoWrapper direction="column" spacing={2}>
            <Stack direction="column" alignItems="center">
              <HouseImage src={getAssetUrl('/XLJ/diaojiaolou.webp')} />
              <span style={{ fontSize: '28px' }}>吊脚楼外观</span>
            </Stack>
            <span style={{ lineHeight: 1.5 }}>
              背景补充：<br /><br />
              （1）明朝已经有成熟的蒸馏技术，可以制造高烈度白酒。<br /><br />
              （2）戴上神兽面具后说话，声音会隔着神兽面具。<br /><br />
              （3）面具作为“傩仪式”的道具——在祭祀活动中，戴上“傩面”就代表转变为神使，会主动大幅改变说话的声音和语调，甚至刻意表现出与平常时不同。
            </span>
          </MapInfoWrapper>
        </>
      }
      <PlagueDoctor animate={startDoctorAnimation} src={getAssetUrl('/XLJ/plaguedoctor.webp')} onAnimationEnd={() => setStartDoctorAnimation(false)} draggable={false} />
      {pickClueDialog}
      <ConfirmDialog open={showConfirmDialog} onClose={handleConfirmDialogClose} title="确定带大家逛一逛祓赐垭吗？" content="在你提出邀请之后，大家可以开始轮流调查。" onConfirmButtonClicked={onConfirmInvite} />
      {mask}
    </div>
  );
};

export default XLJ_InvestigatePage;
