import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl} from 'utils/utils';
import { FadeInAndOutKeyframes } from 'utils/keyframes';
import useSound from 'use-sound';
import BackgroundMusic from 'assets/music/xing.mp3';

const ContentContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-family: STFangSong;
  font-size: 32px;
  text-indent: 2em;
  text-align: justify;
`;

const Info = styled.div`
  width: 40vw;
  height: 100vh;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 10%;
  top: 0;
  bottom: 0;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  text-align: justify;
  line-height: 1.5;
`;

const Info1 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 2s;
`;

const Info2 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 18s ease-in-out both 15s;
`;

const Info3 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 16s ease-in-out both 34s;
`;

const Info4 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 10s ease-in-out both 51s;
`;

const InfoImage = styled.img`
  margin-bottom: 50px;
`;

const PosterIamge = styled.img`
  height: 60vh;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 15%;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 1.5s ease-in-out both;
`;


export interface NDYY_EpiloguePageProps extends CommonUIProps { }

export const NDYY_EpiloguePage: FC<NDYY_EpiloguePageProps> = function ({
  ...props
}) {
  const [, { stop }] = useSound(BackgroundMusic, {
    volume: 0.2,
    autoplay: 1,
    interrupt: true,
  });
  const [showPoster, setShowPoster] = useState<boolean>(false);

  useEffect(() => {
    return () => stop();
  }, [stop])

  return (
    <ContentContainer {...props}>
      <Info1>
        <InfoImage src={getAssetUrl('/NDYY/stjohnlogo.webp')} width={500} />
        <p>圣约翰大学（Saint John’s University），是美国人在1879年于上海建立的一所教会制大学，开设文、理、医、神四个学院，曾被誉为“东方哈佛”。</p>
      </Info1>
      <Info2>
        <InfoImage src={getAssetUrl('/NDYY/drjandmrh.webp')} height={600} />
        <p>
          《化身博士》（Dr. Jekyll & Mr. Hyde）是罗伯特·路易斯·史蒂文森出版于1886年的作品，讲述了一位博士喝了自己配制的药剂而分裂出邪恶人格的故事。“Jekyll and
          Hyde”后来也成为了心理学中“双重人格”的代名词，而史蒂文森也是《金银岛》一书的作者。
        </p>
      </Info2>
      <Info3>
        <InfoImage src={getAssetUrl('/NDYY/clues/卧室1-1front.webp')} height={400} />
        十二把刻着字的镀银匕首实际上是宋玉馨与贺先生结婚时的嫁妆（宋家世袭武官），上面的字连起来是“贺宋联姻，珠连璧合，桂馥兰馨”，该祝词常见于民国的结婚证上。
      </Info3>
      <Info4 onAnimationEnd={() => setShowPoster(true)}>故事中的唐莫离、夏叔娥、贺先生都曾出现在豪门惊情系列的其他剧本当中。如果想了解更多他们的故事，可以选择《傀儡的记忆》、《幽灵复仇》、《待月弑杀》等剧本进行体验。</Info4>
      <PosterIamge src={getAssetUrl('/NDYY/poster.webp')} style={{ opacity: showPoster ? '1' : '0'}} />
    </ContentContainer>
  );
};

export default NDYY_EpiloguePage;
