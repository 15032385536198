import { keyframes } from '@emotion/react';

export const RotateKeyframes = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export const RotateWithDelayKeyframes = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  33% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export const WobbleHorTopKeyframes = keyframes`
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-10px) rotate(6deg);
    transform: translateX(-10px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateX(5px) rotate(-6deg);
    transform: translateX(5px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateX(-5px) rotate(3.6deg);
    transform: translateX(-5px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateX(2px) rotate(-2.4deg);
    transform: translateX(2px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateX(-1.5px) rotate(1.2deg);
    transform: translateX(-1.5px) rotate(1.2deg);
  }
`;

export const FlipKeyframes = keyframes`
  0% {
    transform: rotateY(-180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
`;

export const FlipBackKeyframes = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(-180deg);
  }
`;

export const PulseKeyframes = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
`;

export const HeartbeatKeyframes = keyframes`
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.2);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.2);
  }

  70% {
    transform: scale(1);
  }
`;

export const RainKeyframes = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 10% 100%;
  }
`;

export const Lightning1Keyframes = keyframes`
  0% {
    filter: brightness(1);
  }
  12% {
    filter: brightness(2);
  }
  30% {
    filter: brightness(1);
  }
  42% {
    filter: brightness(1.8);
  }
  56% {
    filter: brightness(1.3);
  }
  70% {
    filter: brightness(1.5);
  }
  100% {
    filter: brightness(1);
  }
`;

export const Lightning2Keyframes = keyframes`
  0% {
    filter: brightness(1);
  }
  16% {
    filter: brightness(2.5);
  }
  30% {
    filter: brightness(2.1);
  }
  42% {
    filter: brightness(2.5);
  }
  56% {
    filter: brightness(1.3);
  }
  70% {
    filter: brightness(1);
  }
  100% {
    filter: brightness(1);
  }
`;

export const Lightning0Keyframes = keyframes`
  0% {
    filter: brightness(1);
  }
  100% {
    filter: brightness(1);
  }
`;

export const ShockwaveKeyframes = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 2px rgba(255, 87, 87, 0.33), inset 0 0 1px rgba(255, 87, 87, 0.33);
  }
  95% {
    box-shadow: 0 0 50px rgba(0, 0, 0, 0), inset 0 0 30px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(2.25);
  }
`;

export const MarqueeKeyframes = keyframes`
  0% {
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
  }
  100% {
    transfrom: translateY(-140%);
    -webkit-transform: translateY(-140%);
  }
`;

export const FadeInAndOutKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
export const BlackOutKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  3% {
    opacity: 1;
  }
  93% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const SleepyKeyframes = keyframes`
  0% {
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    filter: blur(0px);
    -webkit-filter: blur(0px);
  }
  7.2% {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    filter: blur(5px);
    -webkit-filter: blur(5px);
  }
  8.3% {
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }
  10.4% {
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }
  15.6% {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    filter: blur(10px);
    -webkit-filter: blur(10px);
  }
  16.6% {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    filter: blur(3px);
    -webkit-filter: blur(3px);
  }
  17.7% {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    filter: blur(3px);
    -webkit-filter: blur(3px);
  }
  20.8% {
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    filter: blur(20px);
    -webkit-filter: blur(20px);
  }
  89.6% {
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    filter: blur(20px);
    -webkit-filter: blur(20px);
  }
  92.2% {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    filter: blur(10px);
    -webkit-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.8);
  }
  100% {
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    filter: blur(0px);
    -webkit-filter: blur(0px);
    background-color: transparent;
  }
`;

export const Falling0Keyframes = keyframes`
  0% {
    transform:
      translate3d(80vw,-10vh,0)
      rotate(0deg);
  }
  100% {
    transform:
        translate3d(50vw,100vh,0)
        rotate(180deg);
  }
}`;

export const Falling1Keyframes = keyframes`
   0% {
      -webkit-transform:
          translate3d(20vw,-10vh,0)
          rotate(-20deg);
  }
  
  100% {
      -webkit-transform:
          translate3d(40vw,100vh,0)
          rotate(-70deg);
  }
}`;

export const Falling2Keyframes = keyframes`
   0% {
      -webkit-transform:
          translate3d(50vw,-10vh,0)
          rotate(90deg);
  }
  
  100% {
      -webkit-transform:
          translate3d(100vw,100vh,0)
          rotate(0deg);
  }
}`;

export const Falling3Keyframes = keyframes`
   0% {
      -webkit-transform:
          translate3d(-10vw,20vh,0)
          rotate(20deg);
  }
  
  100% {
      -webkit-transform:
          translate3d(100vw,60vh,0)
          rotate(-40deg);
  }
}`;
export const Falling4Keyframes = keyframes`
   0% {
      -webkit-transform:
          translate3d(50vw,-10vh,0)
          rotate(-15deg);
  }
  
  100% {
      -webkit-transform:
          translate3d(-10vw,40vh,0)
          rotate(65deg);
  }
}`;

export const ScreenFoldingKeyframes = keyframes`
  0% {
    transform: 
      scaleX(1);
    opacity: 1;
  }

  100% {
    transform: 
      scaleX(0.5);
    opacity: 0;
  }
`;

export const ZZZKeyframes = keyframes`
  0% {
      color: rgba(0,171,208,0);
      font-size: 30px;
      transform: translateY(100%);
  }
  100% {
      color: rgba(0,171,208,1);
      font-size: 72px;
      transform: translateY(-100%);
  }
`;

export const SlideInRightKeyframes = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
`;

export const EarthquakeKeyframes = keyframes`
  0% { 
    transform: translate(1px, 1px) rotate(0deg); 
    letter-spacing:0px;
  }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { 
    transform: translate(3px, 2px) rotate(0deg); 
    text-shadow: 0 0 2rem #ff2525;
    filter: brightness(100%) blur(0.05rem);
    letter-spacing:2px;
  }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { 
    transform: translate(1px, 2px) rotate(0deg); 
    text-shadow: 0 0 3rem #ff2525;
    filter: brightness(150%) blur(0.07rem);
    letter-spacing:3px;
  }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`;

export const LureKeyframes = keyframes`
  0% {
    top: 60px;
    transform: translateX(10px);
  }
  25% {
    top: 20px;
  }
  50% {
    top: 60px;
    transform: translateX(-10px);
  }
  75% {
    top: 20px;
  }
  100% {
    top: 60px;
    transform: translateX(10px);
  }
`;

export const BlinkingKeyframes = keyframes`
  from { opacity: 1.0; }
  50% { opacity: 0.5; }
  to { opacity: 1.0; }
`;

export const WalkingKeyframes = keyframes`
  0% {
    right: -20vw;
    bottom: -15px;
  }
  2.5% {
    bottom: -30px;
  }
  5% {
    bottom: -15px;
  }
  7.5% {
    bottom: 0px;
  }
  10% {
    bottom: -15px;
  }
  12.5% {
    bottom: -30px;
  }
  15% {
    bottom: -15px;
  }
  17.5% {
    bottom: 0px;
  }
  20% {
    bottom: -15px;
  }
  22.5% {
    bottom: -30px;
  }
  25% {
    bottom: -15px;
  }
  27.5% {
    bottom: 0px;
  }
  30% {
    bottom: -15px;
  }
  32.5% {
    bottom: -30px;
  }
  35% {
    bottom: -15px;
  }
  37.5% {
    bottom: 0px;
  }
  40% {
    bottom: -15px;
  }
  42.5% {
    bottom: -30px;
  }
  45% {
    bottom: -15px;
  }
  47.5% {
    bottom: 0px;
  }
  50% {
    right: 60vw;
    bottom: -15px;
  }
  60% {
    bottom: -15px;
    right: 60vw;
    transform: scaleX(1);
  }
  61% {
    transform: scaleX(-1);
  }
  62.5% {
    bottom: -15px;
  }
  65% {
    bottom: 0px;
  }
  67.5% {
    bottom: -15px;
  }
  70% {
    bottom: -30px;
  }
  72.5% {
    bottom: -15px;
  }
  75% {
    bottom: 0px;
  }
  77.5% {
    bottom: -15px;
  }
  80% {
    bottom: -30px;
  }
  82.5% {
    bottom: -15px;
  }
  85% {
    bottom: 0px;
  }
  87.5% {
    bottom: -15px;
  }
  90% {
    bottom: -30px;
  }
  92.5% {
    bottom: -15px;
  }
  95% {
    bottom: 0px;
  }
  97.5% {
    bottom: -15px;
  }
  100% {
    bottom: -30px;
    right: -20vw;
    transform: scaleX(-1);
  }
`;

export const RisingKeyframes = keyframes`
  0% { bottom: -200px; }
  25% { bootom: -50px; }
  30% { bottom: 0; }
  70% { bottom: 0; }
  75% { bootom: -50px; }
  100% { bottom: -200; }
`;

export const BlinkEyeKeyframes = keyframes`
  from {background-position:0 0;}
  to {background-position:-320px 0;}
`;