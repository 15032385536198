import { getAssetUrl } from 'utils/utils';

export const getMHZYInvestigatePageBackgroundUrl = (stage: string) => {
  switch (stage) {
    case 'READING1':
    case 'INTRODUCING':
    case 'INVESTIGATING1':
      return [getAssetUrl('/MHZY/background1.webp')];
    case 'READING2':
    case 'INVESTIGATING2':
    case 'DISCUSSING2':
      return [getAssetUrl('/MHZY/background1.webp')];
    case 'READING3':
    case 'INVESTIGATING3':
    case 'DISCUSSING3':
    case 'VOTING':
    case 'ESCAPING':
      return [getAssetUrl('/MHZY/background2.webp')];
    case 'REVEALING':
      return [getAssetUrl('/MHZY/background3.webp'), getAssetUrl('/MHZY/background.webp'), getAssetUrl('/MHZY/background1.webp'), getAssetUrl('/MHZY/background2.webp')];
    default:
      return undefined;
  }
};

export const MHZY_CharacterColors: Record<string, string> = {
  于跃洋: '#378CE7',
  颜睦宛: '#C73659',
  浦澄裳: '#B2B377',
  孙孟真: '#8FAC62',
  王赶海: '#739da2',
};

export const MHZY_CharacterAbbr: Record<string, string> = {
  于跃洋: '于',
  颜睦宛: '颜',
  浦澄裳: '浦',
  孙孟真: '孙',
  王赶海: '潜',
}

export const MHZY_VoteQuestions = ['是谁杀害了船长？', '是谁杀害了樊宗楚？', '是谁杀害了王赶海？', '是谁杀害了潜水过渡舱里的人？'];

export const MHZY_Scripts: Record<string, string> = {
  于跃洋: '/MHZY/scripts/YYY.pdf',
  颜睦宛: '/MHZY/scripts/YMW.pdf',
  浦澄裳: '/MHZY/scripts/PCC.pdf',
  孙孟真: '/MHZY/scripts/SMZ.pdf',
  王赶海: '/MHZY/scripts/WGH.pdf',
};

export const MHZY_ScriptMaxPage: Record<string, number> = {
  READING1: 4,
  INTRODUCING: 4,
  INVESTIGATING1: 4,
  READING2: 11,
  INVESTIGATING2: 11,
  DISCUSSING2: 11,
  READING3: 14,
  INVESTIGATING3: 14,
  DISCUSSING3: 14,
};

export const MHZY_TruthSummary: Record<string, string> = {
  背景故事:
    '浦诚学痴迷《海底两万里》中的故事，听妹妹浦澄裳说也想去海底后，立志设计出小说中一样的潜艇“诺第留斯”（鹦鹉螺）号，实现妹妹的愿望。\n\n       浦诚学上学时与于跃洋相识，怀揣相同梦想的两人共同加入樊宗楚的“吞万有限公司”成为潜艇工程师，却因研究方向不同而产生分歧。于跃洋从“伊藤重工”订购材料，率先开发出名为“内德兰德”（《海底两万里》中的捕鲸手）的潜航系统，但存在缺陷而不能交付实用，于是被于跃洋锁在仓库里。浦诚学以为自己落后，为了追赶而彻夜赶工，日渐憔悴。\n\n       后来仓库失火，浦诚学为了好友的设计不付诸东流，冲进去抢救设计图纸，无意间发现于跃洋的潜航系统非常天才。因为老板的施压，也为了早点完成设计好和爱人结婚，浦诚学将设计图藏了起来，并将其用在了自己的方案中，最后在工厂中制造完成了实验用的“小诺号”。浦诚学因为剽窃好友的研究成果而心怀愧疚，在于跃洋辞职时没去道别，也在给颜睦宛的信中将自己的虚荣心比喻成“安康鱼”一样的怪物。',
  '“小诺号”的实验事故':
    '由于设计缺陷，“小诺号”在离开码头一段距离后发生故障无法上浮，慌乱的浦诚学穿起潜水服，从潜水过渡舱离艇逃出，却因穿着铁鞋无法及时上浮而缺氧昏迷。樊宗楚找来村里的渔夫，开渔船将浦诚学送去青岛的“圣格林医院”治疗。顺便趁机霸占所有研究成果，同时为了吸引更多资金，对外宣称“小诺号”已经可以远达太平洋，工程师浦诚学意外失踪。',
  '“铁鲸号”的首次潜航':
    '樊宗楚不懂设计，仍按之前的方案制造出了新的潜艇“铁鲸号”，就连安全手册也完全照抄浦诚学的笔记，才会在发电室出现“No Nemo’s Cigar”这样小说中才有的话语。随后日本对德国宣战，进攻青岛，取代了德国在山东的势力。樊宗楚为了骗取新的投资，邀请日方“伊藤重工”参加铁鲸号的首航，找来之前的渔夫假冒船长，准备利用先前编造“小诺号”沉没太平洋的谎言，让日方以为吞万已经拥有潜航太平洋的能力。被收买的渔夫贪财，从浦诚学身上摸走怀表，却看不懂英文Nemo，给自己起名“欧闻”（Owen）。',
  仿生物设计:
    '日方只关心潜艇能否用于未来战争，于是樊宗楚特地在铁鲸号的头部储气舱一侧预留了鱼雷发射孔，仿生鲸鱼的喷气孔。为了秘密不被识破，潜艇必须长时间停留在海底而不能上浮，于是樊宗楚就用潜水服的思路，制作了巨大的软管连接在预留的鱼雷发射孔上，保证潜艇内部始终有用于呼吸的空气。同时樊宗楚自己受不了潜艇内艰苦的生活，还在巨大软管中搭建了逃生软梯，每天可以回到岸上休息，还能给颜睦宛带来食堂的面包和火腿。只有船长欧闻、助理张辜仁知道这个秘密，因此他们永远知道准确的时间，也不会因为狭小的空间而陷入精神压抑。\n\n       由于潜艇的实际下潜深度只有10米，所以张辜仁每天都在食物中添加含有安眠效果的“Schlaf”，众人醒来的时间其实都是晚上，所以才看不到阳光。',
  海里的怪物: '观景窗外蠕动的黑影是通气的软管，船舱里带触手的生物是买给日方贵宾的“美味料理”——活章鱼。',
  不明身份的潜水员: '玩家中的潜水员是浦诚学。缺氧昏迷后的浦诚学大脑受损失去了记忆，一直留在“圣格林医院”。铁鲸号出航需要有工程师陪同以防出现故障，所以樊宗楚只好也将浦诚学带上潜艇，和张辜仁一起住在双人房里（樊宗楚自己每天上岸睡觉）。樊宗楚并不知道”伊藤重工“的人会让于跃洋代表日方参加首航，因此笔记中的工程师实际上都是指浦诚学。\n\n       在张辜仁带两个女生出去潜水的时候，樊宗楚在船长室操作探照灯，导致浦诚学无人看管。他擅自行动时被船长发现，追赶中来到了潜水准备室，被打了注射器昏迷。此时几人正从海底回来，为了不让浦诚学被发现，便将他藏进了第四件潜水服中。',
  郁郁不得志的于跃洋: '于跃洋与浦诚学本是无话不谈的好友，却因樊宗楚的挑拨产生裂隙，更因为爱上了同一个女人而分道扬镳。同为颜教授的得意门生，颜睦宛却只喜欢成熟稳重的浦诚学，不喜欢更门当户对的于跃洋。失意的于跃洋本已放弃潜艇事业，听闻好友失事后，幡然醒悟，决心带着浦诚学的遗愿加入”伊藤重工“，立志设计出小说那样能够航行太平洋海底的潜艇！',
  海底寻爱的颜小姐: '颜睦宛得知爱人浦诚学失踪后，焦急万分，千方百计要登上铁鲸号前往小诺号的失事地点，寻找浦诚学的下落。她利用樊宗楚对自己的感情，悄悄登上铁鲸号，一直住在仓库里。她期待着那场浦诚学曾许诺过，在海底瑰丽的珊瑚丛中举行的西洋婚礼，无法接受爱人可能已死的事实。',
  恩怨分明的孙孟真: '孙孟真年少时受浦诚学照顾，一直怀揣知遇之恩，自然看不惯和终日浦诚学争吵的于跃洋。一天孙孟真偶然见到于跃洋偷偷将样品藏进仓库中，误以为于跃洋为了胜负不择手段，便偷偷用烟头点火，想给于跃洋一个教训。',
  心系兄长的浦姑娘: '浦家生活不易，大哥浦诚学是顶梁柱。听闻噩耗后，母亲一下就病倒了，含泪嘱咐浦澄裳要将大哥的尸骨寻回，入土为安。浦澄裳从小和浦诚学的感情就很好，也非常希望大哥能魂归故里，甚至答应对张辜仁以身相许，也要偷偷登上铁鲸号，前往太平洋海底。',
  樊宗楚和船长欧闻之死: '颜睦宛连续多日生活在昏暗压抑的环境中，精神紧张，逐渐产生幻觉。再加上见到船长欧闻手持自己送给浦诚学的怀表，更加坚信爱人是被两人联手害死，于是决定报仇！\n\n       颜睦宛先在给樊宗楚的水中下了Schlaf安眠药，在其无力抵抗后将活章鱼塞进他的喉咙里，造成窒息死亡。之后在众人一起寻找船长时，偷偷用注射器将空气注入进船长的静脉中，造成空气栓塞死亡。',
  张辜仁和王赶海之死: '喜欢浦姑娘的孙孟真偷听到张辜仁要浦澄裳做他的女人，误以为浦澄裳被威胁，于是计划在航行时除掉张辜仁。\n\n       孙孟真在来到潜水准备室的时候，正好张辜仁潜水回来，要孙孟真帮忙拆除头盔上的软管。孙孟真看准机会，将活章鱼塞入软管的气孔中，造成张辜仁窒息死亡。行凶完出来的时候却被王赶海看见，随后王赶海又开始说疯话，孙孟真担心事情败露，就决定将王赶海一并灭口。\n\n       孙孟真知道潜艇的通风管道结构，在双人房的上铺利用被子堵死了通风口，使得禁闭室中的王赶海缺氧窒息而死。',
  正确的逃生方法: '只要从操舵台的密封阀门进入储气舱，就可以找到通向岸边的逃生软梯。'
};

export const getMHZYFooterText = (stage: string) => {
  switch (stage) {
    case 'READING1':
      return '阅读剧本阶段（阅读完请点击剧本左上角的“阅读完毕”）';
    case 'INTRODUCING':
      return '介绍阶段';
    case 'INVESTIGATING1':
      return '寒暄阶段（自由交谈，直到于跃洋提出去见“伊藤先生”）';
    case 'READING2':
      return '阅读第二幕剧本（阅读完请点击剧本左上角的“阅读完毕”）';
    case 'INVESTIGATING2':
      return '轮流调查阶段';
    case 'DISCUSSING2':
      return '讨论阶段';
    case 'READING3':
      return '阅读第三幕剧本（阅读完请点击剧本左上角的“阅读完毕”）';
    case 'INVESTIGATING3':
      return '轮流调查阶段';
    case 'VOTING':
      return '投票阶段（可以继续交流，直到所有人投完票）';
    case 'ESCAPING':
      return '逃生阶段（在地图上选择从哪个位置逃离潜艇，或不选择任何位置等待救援）'
    case 'REVEALING':
      return '复盘阶段';
    default:
      return undefined;
  }
};

export const MHZY_ForbiddenRooms: Record<string, string> = {
  于跃洋: '贵宾室',
  颜睦宛: '仓库',
  浦澄裳: '厨房',
  孙孟真: '发电室',
  王赶海: '双人房',
};
