import styled from '@emotion/styled';
import { FC } from 'react';
import { DialogProps, Stack } from '@mui/material';
import { FullDialog } from 'lib/common/FullDialog';
import WifiOffIcon from '@mui/icons-material/WifiOff';

const ContentWrapper = styled(Stack)`
  width: 150px;
  height: 150px;
  padding: 16px;
  border-radius: 16px;
  background-color: #0f0f0f;
  border: 3px solid #4D4D4D;
`;

const DisconnectIcon = styled(WifiOffIcon)`
  font-size: 60px;
  color: #B71C1C;
`;

const DisconnectText = styled.div`
  line-height: 1.5;
  color: white;
  text-align: center;
`;

export interface DisconnectDialogProps extends DialogProps {

}

export const DisconnectDialog: FC<DisconnectDialogProps> = function ({
  ...props
}) {

  return (
    <FullDialog {...props}>
      <ContentWrapper direction="column" alignItems="center" justifyContent="center" spacing={2}>
        <DisconnectIcon />
        <DisconnectText>您与服务器的链接已断开，请刷新重试！</DisconnectText>
      </ContentWrapper>
    </FullDialog>
  );
};

export default DisconnectDialog;
