import { getAssetUrl } from 'utils/utils';

export const getXLJInvestigatePageBackgroundUrl = (stage: string) => {
  switch (stage) {
    case 'READING1':
    case 'GREETING':
    case 'INVESTIGATING1':
    case 'DISCUSSING1':
      return [getAssetUrl('/XLJ/background1.webp')];
    case 'READING2':
    case 'INVESTIGATING2':
    case 'DISCUSSING2':
      return [getAssetUrl('/XLJ/background2.webp')];
    case 'READING3':
    case 'INVESTIGATING3':
    case 'DISCUSSING3':
    case 'VOTING':
    case 'REVEALING':
      return [getAssetUrl('/XLJ/background3.webp'), getAssetUrl('/XLJ/background2.webp'), getAssetUrl('/XLJ/background1.webp'), getAssetUrl('/XLJ/background.webp')];
    default:
      return undefined;
  }
};

export const XLJ_CharacterColors: Record<string, string> = {
  过岭: '#A9A9A9',
  风角: '#6B8E23',
  昭然: '#FF6F6F',
  阿喏: '#4682B4',
  艳侣: '#FFA07A',
  银钥: '#9370DB',
};

export const XLJ_CharacterAbbr: Record<string, string> = {
  过岭: '岭',
  风角: '风',
  昭然: '祀',
  阿喏: '喏',
  艳侣: '侣',
  银钥: '钥',
};

export const XLJ_VoteQuestions = ['是谁杀害了巴傩沼？', '是谁杀害了乌取？', '是谁杀害了沼区的女尸？', '是谁造成了“彭先生”的失踪？'];

export const XLJ_Scripts: Record<string, string> = {
  过岭: '/XLJ/scripts/GL.pdf',
  风角: '/XLJ/scripts/FJ.pdf',
  昭然: '/XLJ/scripts/ZR.pdf',
  阿喏: '/XLJ/scripts/AN.pdf',
  艳侣: '/XLJ/scripts/YL.pdf',
  银钥: '/XLJ/scripts/YY.pdf',
};

export const XLJ_ScriptMaxPage: Record<string, number> = {
  READING1: 4,
  GREETING: 4,
  INVESTIGATING1: 4,
  DISCUSSING1: 4,
  READING2: 10,
  INVESTIGATING2: 10,
  DISCUSSING2: 10,
  READING3: 12,
  INVESTIGATING3: 12,
  DISCUSSING3: 12,
};

export const XLJ_TruthSummary: Record<string, string> = {
  背景故事:
    '17世纪初，巴家先人来到还未得名的祓赐垭，在山泽中发现一处金矿，便在此定居，用祭祀等名义暗中开采黄金。开采出的金矿石需要进行提炼，而附近刚好就出产朱砂，可以制成水银再用于土法炼金。\n\n       巴家将勘探出的丹矿赠予当地地主乌家，得到其支持，每次能优先在产出的朱砂中挑选品质好的，也就获得了炼金所需的高纯度水银。所谓每二十年的祓礼，其实是巴家等金矿攒够了之后，在洞穴中用汞蒸气提炼黄金；出现预兆就是准备好了足量炼制所需的水银和工具，以及下一代已经熟练掌握了炼金的技艺。\n\n       巴家对外宣称能通过“秘法”与先灵神兽沟通，将炼出的一部分黄金分给当地解决天灾、瘟疫等，获得信任与尊重，再恩威并施，让地主“乌家”多年来一直保护祭祀。',
  炼金的代价:
    '一开始巴家只用自己人进入洞穴炼金，但缺少防护措施，在炼制过程中会大量摄入有毒的汞蒸气，因此早期“祓仆”多为短命。1814年，巴家偶然收养了一个女孩，教会她如何炼金后，称其为“祀女”送入洞中提炼黄金，代替巴家人成为牺牲品。此后，巴家便如法炮制，一直寻找孤女培养成祀女。这么做虽然避免了自家族人早逝，但巴家也明白此事伤天害理，便提出可以用金钱实现祀女的一个愿望，以此谋求心理安慰。',
  鸟嘴的启示:
    '即使巴家在开采的过程中小心翼翼，可此时已经过去了二百多年，洞内的金矿即将枯竭，沼区内也造成了大量的水银污染，野兽们因为汞中毒而被当成妖怪。这一代的祓仆巴府山受到西洋书上“鸟嘴医生”的启发，借用神兽的长嘴造型，制作出填充了过滤物和提神醒脑药物的祭祀面具，要求祓仆和祀女在祭祀的过程中必须佩戴，无论发生什么都不能取下面具，否则就会遭到神罚，迎来死亡。之后为了改良面具，巴府山还多次前往武昌的“黄家药铺”买药，这才与老板黄余振相熟。',
  唯唯喏喏:
    '继续开采金矿的年份快到了，巴府山按照惯例去寻找和合适的祀女目标，很快物色到了父母双亡的丰又唯，她只有一个弟弟丰再喏。根据规定，祀女的亲人都不能留在祓赐垭，于是巴府山就年幼的阿喏送去了武汉，托黄余振找人收养，最后送到了朋友彭须藏家。\n\n       丰又唯开始接受祀女的培训，一时无法适应，又因思念弟弟而晚上常做噩梦，巴傩沼见状于心不忍，就想给她找一位同龄人相陪。在外出采购的过程中，巴傩沼顺路雇用了女佣阿米，让其平时照顾丰又唯。巴府山与巴康寨的巫师家有婚约，便让弟弟巴傩沼接任祓仆，将培训祀女的任务和各种秘法都交给了他。在日渐相处中，巴傩沼和丰又唯心生爱意，情定终生后在河边的石头上刻下誓言。',
  风门余氏:
    '1892年，巴府山在巴康寨雇了10个人，来祓赐垭将祖坟迁过去。雇农中有一个姓”风“的，因为妻子刚刚生了儿子需要钱，惦记起此地的先灵传说，擅自闯入沼区寻找，缺因误陷泥潭困死。他的妻子正是阿米，原名余米，她后来之所以愿意和巴傩沼来祓赐垭，除了赚钱养活儿子风角外，也是为了寻找失去音讯的丈夫。\n\n       乌家一直好奇祭祀的秘密，却不敢过多打探，乌家少主过岭便通过阿米了解情况，一来二去也喜欢上了聪慧早熟的阿米。阿米一直没有找到丈夫，寂寞时有过岭的陪伴，便隐瞒了自己的过往，收下了过岭给的金饰，和他在外人不能接近的祭楼附近山坡上缠绵。',
  阿米之死: '阿米来照顾祀女已有两年，在祭祀之后就完成了工作，打算带上赚的钱回去看儿子。风角读书需要彭须藏帮忙，阿米就打算和彭先生一同回武汉。这令深陷爱河的过岭难以接受，在争吵中将阿米推搡下山坡摔死。过岭后悔万分，找来巴傩沼求其用巴家祖传的秘术为阿米”招魂“。\n\n       巴傩沼起先拒绝，后来转念想到丰又唯作为祀女在祭祀结束后会失去性命，便想出一计，让过岭故意找巴府山喝酒，然后把阿米的尸体伪装成祀女的样子，再让彭先生第二天一早将丰又唯带回武汉，投奔黄家。随后巴傩沼对过岭说阿米已经跟彭先生走了，又对巴府山说祀女在炼金后死亡，成功骗过两人。\n\n       由于阿米代替了原本的牺牲者，按照规定，也要实现其愿望。于是，巴傩沼便想方设法让风角读书娶妻。',
  丰又唯之死:
    '因为巴府山去了巴康寨，巴傩沼只能留守祓赐垭，无法去武汉寻找心上人丰又唯。丰又唯等不来巴傩沼，当年离开时又已经怀上了孩子，没办法的她只能委身于看上她美貌的黄余振，没有经过明媒正娶就成了黄余振的妻子。巴傩沼曾托过岭去武汉时送信，但黄余振不想放弃刚刚到手的美人，就全都隐瞒下来，将过岭打发走。次年，丰又唯和黄余振生下了另一个女儿黄昭然。\n\n       由于村民之间传起沼区有妖怪的事情，乌取给过岭送朱砂货物时，想在黄家药铺附近买一些驱邪的物品，恰好看到带着大女儿的丰又唯，认出她就是当年应该已经死去的祀女。乌取想逼问出祭祀的秘密，威胁要抓其回去，但丰又唯作为祀女时发过誓要保守秘密，也绝对不会背叛和出卖巴傩沼，所以毅然选择投水自尽。黄余振不愿意花钱养别人的孩子，便将丰又唯的大女儿送去了孤儿院，单独抚养小女儿黄昭然。',
  彭须藏之死: '除了要让风角念书之外，阿米还有一个愿望，就是让儿子娶妻。于是，巴傩沼便向彭先生提亲，而彭须藏觉得这是一个打探出祭祀秘密的好机会，便答应下来这门婚事，按规矩作为姐姐的金钥应该先出嫁。\n\n       阿喏和两个妹妹之间没有血缘关系，在朝夕相处中喜欢上了大妹金钥，也亲口听她说过喜欢哥哥。见父亲要将金钥嫁人，阿喏情急之下便向彭须藏说出实情，结果被骂是不知羞耻的孽障。加上此前彭须藏对待阿喏如同下人，积怨日深，阿喏便起了杀心，因为只要彭须藏死了，家里的事情就要由他这个大哥来做主。\n\n       阿喏利用彭须藏对祭祀的好奇心，在带小妹银钥离开那天，用竹筒里的迷药将将其麻醉安置在旅舍，随后返回祓赐垭，将彭须藏骗去坟场后用石头砸死。',
  真假黄昭然: '风角在上学时喜欢上了黄昭然，后来托过岭作为长辈向黄家提亲，还找义父巴傩沼要了一大笔钱做聘礼。黄余振见钱眼开，立即满口答应下来，谁知黄昭然不喜欢这个“赖汉”，选择逃婚离家，用母亲留下的字条中的几个字起了假名“双艳侣”，在上海找到了工作。\n\n       黄余振找不到女儿，又不愿退聘金，便去找回了孤儿院的大女儿，两人同母外貌相似，风角又多年没见过黄昭然，正好伪装成黄昭然嫁人。丰又唯的大女儿只记得小时候母亲讲过许多祓赐垭的故事，又听黄余振吹嘘嫁过去就成了少奶奶，每天有人伺候又吃香喝辣，从小生活在孤儿院的她便答应下来。',
  以银换金: '金钥温柔体贴更会照顾人，银钥胆大心细总自作主张。当初彭须藏答应将金钥嫁人时，金钥不愿意留在祓赐垭过一辈子，于是两人想出办法，先让父亲答应送银钥去武汉上学，再在出发前互换身份。真正的金钥在船上喝下阿喏的麻醉药昏迷，由于年纪太小，药物副作用很强而失去记忆，醒来后被当作银钥，告知了去念书等事，在引导中认为自己就是银钥。\n\n       真正的银钥在父亲失踪后想要寻找真相，便自作主张留在了祓赐垭，被选中成为了新的祀女。通过父亲留下的资料和日复一日的训练，她逐渐猜出祭祀的秘密是在提炼黄金，为了不让哥哥姐姐再为钱发愁，她想找到方法得到金子。',
  今天的命案:
    '假昭然为了替母亲丰又唯报仇，冒充祀女将乌取骗入沼区的山洞中，令其吸入有毒的汞蒸气而死亡，自己则因为戴了面具幸免。\n\n       阿喏计划带走金钥，于是想出用大家都不认识的艳侣掉包祀女的方法，分别用竹筒里的麻醉剂将两人迷晕。真假昭然昏迷后都失去了记忆，醒来时没有经过正确的引导，反而因为咒语想起了小时候母亲丰又唯给她们讲过的祓赐垭的故事。\n\n       玩家中的“银钥”（即真正的金钥）认为“姐姐”不愿意离开是因为收到了巴傩沼的蛊惑，便在桂花糊中下了有毒的劣质朱砂粉，加热后带往巴家骗嘴馋的巴傩沼喝下，毒发身亡。一直暗中调查的“金钥”（即真正的银钥）跟踪巴傩沼时发现了姐姐杀人，为了再次保护她，就穿上了羽衣和面具伪装成祓仆，将尸体搬进了大藤箱，让随后而来的风角抬到了祭楼。\n\n       过岭后来也喜欢上金钥，为了防止祀女献出生命，同样准备杀害巴傩沼将“金钥”救走。在和“金钥”假扮的祓仆共同将大藤箱搬进屋内后，过岭将下了毒的桂花糊递给“金钥”，由于她也十分爱喝，便倒入常用来喝水的竹筒中再藏在羽衣里。等众人离开后，“金钥”把巴傩沼的尸体从藤箱中挪出，翻出窗户准备绕回正门，见四处无人便偷偷拿出竹筒喝酒，很快毒发，尸体被野兽啃食。\n\n       过岭随后在藤箱上发现巴傩沼的尸体，以为自己下毒成功，随即用短刀捅进心口试图伪造死亡方式。',
};

export const getXLJFooterText = (stage: string) => {
  switch (stage) {
    case 'READING1':
      return '阅读剧本阶段（阅读完请点击剧本左上角的“阅读完毕”）';
    case 'GREETING':
      return '寒暄阶段（自由交谈，直到过岭提出带大家逛一逛）';
    case 'INVESTIGATING1':
      return '轮流调查阶段';
    case 'DISCUSSING1':
      return '讨论阶段';
    case 'READING2':
      return '阅读第二幕剧本（阅读完请点击剧本左上角的“阅读完毕”）';
    case 'INVESTIGATING2':
      return '轮流调查阶段';
    case 'DISCUSSING2':
      return '讨论阶段';
    case 'READING3':
      return '阅读第三幕剧本（阅读完请点击剧本左上角的“阅读完毕”）';
    case 'INVESTIGATING3':
      return '轮流调查阶段';
    case 'VOTING':
      return '投票阶段（可以继续交流，直到所有人投完票）';
    case 'REVEALING':
      return '复盘阶段';
    default:
      return undefined;
  }
};

export const XLJ_ForbiddenRooms: Record<string, string> = {};
