import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';
import { FadeInAndOutKeyframes } from 'utils/keyframes';
import useSound from 'use-sound';
import BackgroundMusic from 'assets/music/jinian.mp3';

const ContentContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-family: STFangSong;
  font-size: 32px;
  text-indent: 2em;
  text-align: justify;
`;

const Info = styled.div`
  width: 40vw;
  height: 100vh;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 10%;
  top: 0;
  bottom: 0;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  text-align: justify;
  line-height: 1.5;
`;

const Info1 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 10s ease-in-out both 2s;
`;

const Info2 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 13s;
`;

const Info3 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 26s;
`;

const Info4 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 39s;
`;

const InfoImage = styled.img`
  margin-bottom: 50px;
`;

const PosterIamge = styled.img`
  height: 60vh;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 15%;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 1.5s ease-in-out both;
`;


export interface WQYF_EpiloguePageProps extends CommonUIProps { }

export const WQYF_EpiloguePage: FC<WQYF_EpiloguePageProps> = function ({
  ...props
}) {

  const [, { stop }] = useSound(BackgroundMusic, {
    volume: 0.8,
    autoplay: 1,
    interrupt: true,
  });

  const [showPoster, setShowPoster] = useState<boolean>(false);


  useEffect(() => {
    return () => stop();
  }, [stop]);


  return (
    <ContentContainer {...props}>
      <Info1>
        <InfoImage src={getAssetUrl('/WQYF/alchemist.webp')} height={600} />
        <p>炼金术（Alchemy）是中世纪时期，人们试图通过“贤者之石”（Philosopher's Stone）将物质转变成黄金，或制作出万灵药让人长生不老。虽然现代科学表明这种方法是行不通的，但炼金术仍然为现代化学奠定了深厚的思想基础。</p>
      </Info1>
      <Info2>
        <InfoImage src={getAssetUrl('/WQYF/symbols.webp')} height={600} />
        <p>
          炼金术士们认为世界由水、火、土、气四种基本元素组成，每种金属则与太阳系中的行星对应。他们创造了各种各样的符号来方便公式的记载，而这种符号系统目前仍被现代天文学所沿用。
        </p>
      </Info2>
      <Info3>
        <InfoImage src={getAssetUrl('/WQYF/mauveine.webp')} height={600} />
        <p>“柯记纱厂”所使用的秘密配方是一种苯胺染料，很容易在高温下燃烧产生爆炸。1856年，英国化学家威廉·铂金在实验室中无意间合成出了苯胺紫，既不容易水洗掉色，也远比天然染剂廉价，因此成为了人类历史上最早的化工产品之一。</p>
      </Info3>
      <Info4 onAnimationEnd={() => setShowPoster(true)}>西卜博士在东方的炼金术之旅还远远没有结束。感兴趣的玩家，可以继续体验《嗜睡蔷薇》、《曼娜》、《仙灵祭》等剧本。</Info4>
      <PosterIamge src={getAssetUrl('/WQYF/poster.webp')} style={{ opacity: showPoster ? '1' : '0' }} />

    </ContentContainer>
  );
};

export default WQYF_EpiloguePage;
