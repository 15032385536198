import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import { CommonUIProps } from 'utils/types';
import { UserInfo } from 'utils/localStorage';


const PageContainer = styled.div`
  height: 100dvh;
  width: 100vw;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export interface GamePageProps extends CommonUIProps {
  user?: UserInfo;
  gameContent: ReactNode;
  disconnectDialog: ReactNode;
}

export const GamePage: FC<GamePageProps> = function ({
  user,
  gameContent,
  disconnectDialog,
  ...props
}) {
  return (
    <PageContainer {...props}>
      {gameContent}
      {disconnectDialog}
    </PageContainer>
  );
};

export default GamePage;
