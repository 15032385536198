import { getAssetUrl } from 'utils/utils';

export const getYSZMInvestigatePageBackgroundUrl = (stage: string) => {
  switch (stage) {
    case 'READING1':
    case 'INTRODUCING':
    case 'GREETING':
    case 'INVESTIGATING1':
    case 'DISCUSSING1':
      return [getAssetUrl('/YSZM/background1.webp')];
    case 'READING2':
    case 'INVESTIGATING2':
    case 'DISCUSSING2':
      return [getAssetUrl('/YSZM/background2.webp')];
    case 'READING3':
    case 'INVESTIGATING3':
    case 'DISCUSSING3':
    case 'VOTING':
    case 'REVEALING':
      return [getAssetUrl('/YSZM/background3.webp'), getAssetUrl('/YSZM/background2.webp'), getAssetUrl('/YSZM/background1.webp'), getAssetUrl('/YSZM/background.webp')];
    default:
      return undefined;
  }
};

export const YSZM_CharacterColors: Record<string, string> = {
  苦石: '#9370DB',
  孤瓦: '#4682B4',
  杨明璐: '#B2B377',
  洛迪: '#6B8E23',
  缇玛: '#FFA07A',
};

export const YSZM_CharacterAbbr: Record<string, string> = {
  苦石: '苦',
  孤瓦: '瓦',
  杨明璐: '杨',
  洛迪: '洛',
  缇玛: '玛',
};

export const YSZM_VoteQuestions = ['是谁杀害了大祭司？', '是谁杀害了丁老板？', '是谁杀害了曾教授？', '是谁杀害了船长？'];

export const YSZM_Scripts: Record<string, string> = {
  苦石: '/YSZM/scripts/KS.pdf',
  孤瓦: '/YSZM/scripts/GW.pdf',
  杨明璐: '/YSZM/scripts/YML.pdf',
  洛迪: '/YSZM/scripts/LD.pdf',
  缇玛: '/YSZM/scripts/TM.pdf',
};

export const YSZM_ScriptMaxPage: Record<string, number> = {
  READING1: 6,
  GREETING: 6,
  INVESTIGATING1: 6,
  DISCUSSING1: 6,
  READING2: 9,
  INVESTIGATING2: 9,
  DISCUSSING2: 9,
  READING3: 11,
  INVESTIGATING3: 11,
  DISCUSSING3: 11,
};

export const YSZM_TruthSummary: Record<string, string> = {
  背景故事:
    '明永乐至明宣德时期（1403年—1435年），朝廷数次派船队远洋航行，最远曾到达过非洲南端，为明朝带来大量舶来品，例如被称作“麒麟”的长颈动物。其中有一艘船队未随众船返航，继续向西，经过南美洲海角之后沿陆地向北行驶，之后到达位于秘鲁的印加帝国。后来不幸沉船后众人登岸，作为外来人在此定居，和当地人互相交流学习语言。由于船队在外并不知道明朝已经更换了年号的事，所以才会一直用宣德记载，也因此书中出现了历史上并不存在的宣德六十七年。\n\n       1492年，哥伦布发现美洲新大陆，从此开始了白种人对原住民的殖民入侵。后来西班牙人带兵进攻印加帝国，俘虏了国王，向王族索要大量黄金。此后入侵者变本加厉，不仅占领了印加都城，还四处抓捕居民搜刮财物。王族无奈只能筹备物资，准备逃亡，之后在一位王族公主的带领下来到了当年明朝船队后人居住的地方，希望能用之前剩下的船只带着族人逃往强盛的大明寻求庇护，躲避殖民者。\n\n       数年后，修整好船队的人们出发向西前往大明。当船队抵达菲律宾时，西班牙人已经在此殖民并设立总督，无奈船队只能继续西行，最后来到了海南岛。此时明朝实行海禁，船队无法返回中土，只好留在海南岛，和印加人在此建立新的家邦。他们在王族和祭祀的带领下，依然保留以往的生活方式，在被遗忘的时光裂隙之中延续文明。印加人没有文字，靠口耳相传和绳结记事，在漫长的三百年间，他们的后人逐渐忘记了横跨大洋的经历，把这里当作故乡。这些遗民在改朝换代的变化中，更像语言不通的土著，从未引起注意……',
  大预言书:
    '1858年，海南的琼州（海口）被写入《天津条约》，此后对洋人开埠行商。年轻的大祭司第一次看到白人，顿时想起了祖先留下的记载文献。大祭司曾跟着岛上的人学过汉字，加上身为祭司家族，从小也经历过历法、算术等训练，就把残页上的内容都当作“预言”（实际上是发生在南美洲的历史），非常害怕。',
  两位公主:
    '苏珐是印加的女儿，经常跑出贵族区听大祭司讲故事，与孤瓦和苦石相识，两人也因此都爱上了公主。就像《奥扬泰》里的传说一样，苏珐更喜欢身为勇士的孤瓦，两人经常在晚上跑去最顶层的天象区，躺在古老的山上仰望星空，互诉爱意。但按照传统，苏珐必须嫁给同族的王室成员，于是孤瓦决定带苏珐私奔，却被嫉妒两人关系的好友苦石告密，很快被抓回来囚禁。\n\n       没有娶到苏珐公主的王室成员只好与另一位王室女性成婚，之后生下了小公主，也就是后来的杨明璐。',
  逆天改命:
    '因为害怕像预言中那样遇害，印加国王决定主动进攻白种人，却因为武器过于落后，兵败被俘。他带去的勇士全军覆没，只有一个被放了回来索要赎金。王族成员作为印加的继承人，携带妻女来到石宫筹集黄金，小公主才会有了贵族区的生活记忆。\n\n       苏珐为了让孤瓦重获自由，便提出牺牲自己成为汝纳伊来改变预言。随后大祭司举行仪式，亲手将苏珐杀死。孤瓦和苏珐曾定下过来世之约，为了让苏珐的灵魂不被束缚可以投胎转世，孤瓦偷偷放火将苏珐的尸体烧毁。结果这场预料之外的大火被大祭司和王族成员当成了预言之力不可改变的证明，觉得即使给白种人送去赎金，也无法改变印加被杀害的命运。\n\n       这位王族成员之前外出和汉人交易物资时，曾认识过一位姓杨的朋友（杨折桂）。为了保护妻女不被杀害，他便拿了些黄金给杨折桂，托他将自己的妻子和小公主带到安全的地方照顾。而他自己只身返回王宫，继承了王位，成为新的印加。',
  杨明璐的噩梦:
    '杨折桂用得到的黄金和财物，移居到广东开了一家古董店。年幼的小公主一直见不到父亲十分恐慌，渡海时又晕船，发了高烧，她的母亲不久后也因为水土不服去世。杨折桂便将她当作自己女儿抚养，起名杨明璐。而她对自己的身世记忆逐渐模糊，只看到“父亲”又娶妻生子，此前的往事便成为了一直纠缠的“噩梦”。',
  萨维船长: '萨维之前是王族成员的近侍，学过中文，一直帮助新印加和当地汉人做交易。后来新印加为了躲避预言，决定带着宝物库里的东西逃走，图谋不轨的萨维趁机将其杀害，凶器是有尖端的青铜短杖。萨维用抢来的财物买下了“公主号”成为船长，而杨折桂因长期与新印加失联，恐有祸患，便举家搬去了上海。',
  明朝黄花梨:
    '在海南经营木材生意的丁老板与萨维相识，前往气候与海南相似的南美洲寻找能冒充黄花梨的木材。萨维因为语言相通，便雇了人当大副和厨师，此后开始跑这条航线。\n\n       丁老板来到秘鲁后，找了一位姓雷的当地人深入雨林寻找合适的木材，结果姓雷的被毒虫蛰咬，不治身亡。丁老板想逃避责任，带着木材回国，做成古董明朝黄花梨出售，也因此结识了曾教授。',
  雨田雷氏: '当年南美洲印加的公主前来请求大明船队时，有一位雷姓水手的后人因为已经与当地通婚，最后选择留下。他画下了公主的画像，留作纪念，并写了文明志。这幅画一直在雷氏后人中不断传承，到了洛迪这一代，他被画中的公主深深迷住，每日临摹，直到上面的汉字都能铭记于心。\n\n       缇玛与洛迪都是留在南美洲印加族的后人，从小一起长大，后来缇玛被萨维雇佣上船工作。当公主号到达上海后，缇玛偶然见到了洛迪整日临摹的汉字，于是回来后便告诉了他。洛迪盼望着能见到画中的人，便要求缇玛介绍他上船工作，之后两人随船来到上海。',
  反向航行: '丁老板的木材生意出了问题，在报纸上听说了土伊镇附近沉船的消息，便打起了船木的主意。他邀请曾教授一同前往南美洲，曾教授随后又带上了杨明璐。三人登船时，洛迪误把杨明璐当作画中的公主，跑下船大喊大叫，被生气的萨维关进了小黑屋。\n\n       缇玛关心洛迪，便在厨师喝的酒里下了安眠药，偷出食物给洛迪送去。洛迪想念杨明璐，在自由之后便溜进驾驶舱，试图调转船舵回去找公主，却在茫茫大海中看不出方向，只能放弃离开。此时船已经离开上海，在洛迪操作船舵后调头朝西北方向航行，最后在海南岛附近遭遇风暴沉没。\n\n       众人搭乘逃生小船来到岸边，见到了海南的印加部族苦石与孤瓦，误以为自己见到的是南美洲的印加部族。公主号从上海离开是9月份，沉没上岸时是秋分后一日，短短十几天不可能到达远在大洋彼岸的秘鲁。',
  今天的命案:
    '大祭司听说公主转世归来，执意完成当年的汝纳伊仪式，但没见到杨明璐，误把缇玛当成转世的公主。在下药将缇玛麻醉后，大祭司开始安排准备献祭的仪式。苦石不希望父亲再次犯下同样的错误，在争执中将大祭司杀死。为掩人耳目，苦石利用陶罐和青铜尺制作了机关，想要将现场伪装成意外。\n\n       孤瓦见到“重生”的公主十分高兴，希望杨明璐能留下来一直生活在这里，便计划杀害要带她回去的曾教授。孤瓦在三件金饰上都涂了见血封喉的剧毒，试图利用割破手指验血的方法骗曾教授中毒。\n\n       苦石在制作机关时，碰巧被曾教授撞见。由于担心诡计被识破，苦石便将曾教授骗到山坡上，掏出金镯子吸引注意，随后用尖锐的金星扎向曾教授。虽然没有刺中要害，但由于上面涂了毒药，曾教授很快毒发身亡。\n\n       金镯子滚落后，被丁老板发现，贪财的他立即跑过去将其藏在身上。洛迪见丁老板神色不对，误以为他中毒，想起了孤瓦说的验毒方法，便用小刀将丁老板的手指割破。不知情的丁老板用嘴吮吸手指，将毒药舔进了伤口，很快中毒死亡。\n\n       萨维船长在帮大祭司做事时，看见屋里的金饰便心生歹意，偷走了金珠。过桥时碰见苦石，害怕暴露便将金珠藏入口中，举起双手主动被搜身。由于嘴里有疮，萨维也很快中毒，跌入桥下身亡。',
};

export const getYSZMFooterText = (stage: string) => {
  switch (stage) {
    case 'READING1':
      return '阅读剧本阶段（阅读完请点击剧本左上角的“阅读完毕”）';
    case 'GREETING':
      return '寒暄阶段（自由交谈，直到苦石提出带大家逛一逛）';
    case 'INVESTIGATING1':
      return '轮流调查阶段';
    case 'DISCUSSING1':
      return '讨论阶段';
    case 'READING2':
      return '阅读第二幕剧本（阅读完请点击剧本左上角的“阅读完毕”）';
    case 'INVESTIGATING2':
      return '轮流调查阶段';
    case 'DISCUSSING2':
      return '讨论阶段';
    case 'READING3':
      return '阅读第三幕剧本（阅读完请点击剧本左上角的“阅读完毕”）';
    case 'INVESTIGATING3':
      return '轮流调查阶段';
    case 'VOTING':
      return '投票阶段（可以继续交流，直到所有人投完票）';
    case 'REVEALING':
      return '复盘阶段';
    default:
      return undefined;
  }
};

export const YSZM_ForbiddenClues: Record<string, string[]> = {
  苦石: ['clue41'],
  孤瓦: ['clue42'],
  杨明璐: ['clue43'],
  洛迪: ['clue44'],
  缇玛: ['clue45'],
};
