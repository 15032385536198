import { createBinder } from "utils/utils";
import { AdminPage, AdminPageProps } from "./AdminPage";
import { useCallback, useEffect, useState } from "react";
import { AdminStatsRes, CommentRes, GameAudit } from "openapi";
import clientApi from "utils/clientApi";
import { useNavigate } from "react-router-dom";
import RouterPath from "router/RouterPath";

export type AdminPageBinderManagedProps = 'errorMsg' | 'gameList' | 'onRefreshButtonClicked' | 'onJoinGameButtonClicked' | 'onForceUpdateButtonClicked' | 'stats'
| 'commentList' | 'onApproveButtonClicked';


export interface AdminPageBinderProps extends Omit<AdminPageProps, AdminPageBinderManagedProps> { }

const useAdminPageBinder = (props: AdminPageBinderProps): AdminPageProps => {

  const navigate = useNavigate();
  const [gameList, setGameList] = useState<Record<string, GameAudit>>();
  const [errorMsg, setErrorMsg] = useState<string>();
  const [stats, setStats] = useState<AdminStatsRes>();
  const [commentList, setCommentList] = useState<Record<string, CommentRes>>();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setErrorMsg(undefined);
    clientApi().getGameList().then((result) => {
      setGameList(Object.fromEntries(Object.entries(result.data).sort((gameA, gameB) => (gameB[1]?.createTime ?? 0) - (gameA[1].createTime ?? 0))));
    }).catch((error: Error) => {
      console.log(error);
      setErrorMsg(error.message);
    });
    clientApi().getStats().then((result) => {
      setStats(result.data);
    }).catch((error: Error) => {
      console.log(error);
      setErrorMsg(error.message);
    });
    clientApi().getCommentList().then((result) => {
      setCommentList(Object.fromEntries(Object.entries(result.data).sort((commentA, commentB) => (commentB[1]?.createTime ?? 0) - (commentA[1].createTime ?? 0))));
    }).catch((error: Error) => {
      console.log(error);
      setErrorMsg(error.message);
    });
  }

  const onJoinGameButtonClicked = useCallback((gameId: string) => {
    navigate(`${RouterPath.Game}/${gameId}`);
  }, [navigate]);

  const onForceUpdateButtonClicked = useCallback((gameId: string) => {
    clientApi().forceUpdateGame(gameId);
  }, []);

  const onApproveButtonClicked = useCallback((commentId: string, approval: boolean) => {
    clientApi().approveComment({ commentId, approval }).then(() => {
      setCommentList({
        ...commentList,
        [commentId]: {
          ...commentList![commentId],
          approval
        }
      })
    }).catch((error: Error) => {
      console.log(error);
    })
  }, [commentList]);

  const managedProps: Pick<AdminPageProps, AdminPageBinderManagedProps> = {
    errorMsg,
    gameList,
    commentList,
    onRefreshButtonClicked: fetchData,
    onJoinGameButtonClicked,
    onForceUpdateButtonClicked,
    onApproveButtonClicked,
    stats,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const AdminPageBinder = createBinder(AdminPage, useAdminPageBinder);

export default AdminPageBinder;