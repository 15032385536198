import styled from '@emotion/styled';
import { DialogProps } from '@mui/material';
import { FullDialog } from 'lib/common/FullDialog';
import { FC, useState } from 'react';
import useSound from 'use-sound';
import { useTimeout } from 'utils/hooks';
import { ScreenFoldingKeyframes } from 'utils/keyframes';
import { getAssetUrl } from 'utils/utils';
import { css } from '@emotion/react';
import Scary from 'assets/sound/scary_impact.mp3';
import CaveDrip from 'assets/sound/cave_drip.mp3';

const TransitionContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: relative;
`;

const BackgroundImage = styled.div`
  width: 100vw;
  height: 100dvh;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transition : background-image 1s ease-in-out, -webkit-filter 1s linear;
`;

const Subtitle = styled.div`
  font-size: 36px;
  font-family: STZhongSong;
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 48px;
  margin: auto;
  text-align: center;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.4), 0 6px 20px rgba(0, 0, 0, 0.6);
`;

const ScreenImage = styled.img<{ animate: boolean }>`
  width: 85vh;
  height: 85vh;
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: ${(props) => props.animate ? 'auto' : 'pointer'};
  z-index: 1;
  animation: ${(props) => props.animate ? css`${ScreenFoldingKeyframes} 1s ease-in-out forwards` : null}; 
`;

const MrsRoseImage = styled.img`
  height: 80vh;
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

export interface SSQW_StageTransitionProps extends DialogProps { }

export const SSQW_StageTransition: FC<SSQW_StageTransitionProps> = function ({
  ...props
}) {

  const [playTransitionVoiceLine] = useSound(getAssetUrl('/SSQW/voices/transition.mp3'));
  const [playTransitionVoiceLine2] = useSound(getAssetUrl('/SSQW/voices/transition2.mp3'));
  const [playScarySound] = useSound(Scary, { volume: 0.8 });
  const [playCaveDripSound] = useSound(CaveDrip);

  const [subtitleText, setSubtitle] = useState<string>();
  const [brightness, setBrightness] = useState<number>(0);
  const [animateScreen, setAnimateScreen] = useState<boolean>(false);
  const [invertImg, setInvertImg] = useState<boolean>(false);
  const [stage, setStage] = useState<number>(1);

  useTimeout(() => {
    playCaveDripSound();
  }, props.open ? 500 : null);

  useTimeout(() => {
    setBrightness(0.1);
    playTransitionVoiceLine();
    setSubtitle('你们打开了隐藏的大门，里面是一条昏暗的地下通道。');
  }, props.open ? 5000 : null);

  useTimeout(() => {
    setSubtitle('罗明纱似乎知道这是什么地方，她让王怀武和韩仁陆点亮了通道两旁的油灯。');
  }, props.open ? 11100 : null);

  useTimeout(() => {
    setSubtitle('');
    setBrightness(0.7);
  }, props.open ? 18100 : null);

  useTimeout(() => {
    setSubtitle('周围一下亮了起来，你们小心地往前走着，尽头出现了一扇木门。');
  }, props.open ? 20800 : null);

  useTimeout(() => {
    setStage(2);
  }, props.open ? 27500 : null);

  useTimeout(() => {
    setSubtitle('罗千海：“我跟随家主人这么久，却从没来过这里。”');
  }, props.open ? 28000 : null);

  useTimeout(() => {
    setSubtitle('王怀武：“这里到底是什么鬼地方！”');
  }, props.open ? 32600 : null);

  useTimeout(() => {
    setSubtitle('罗明纱没有回答，径直拉开了木门，带着大家进去。');
  }, props.open ? 36000 : null);

  useTimeout(() => {
    setStage(3);
    setSubtitle('');
  }, props.open ? 40300 : null);

  useTimeout(() => {
    setSubtitle('进入屋内后，房间里放着一扇屏风，角落里还堆着许多箱子和酒桶。');
  }, props.open ? 43800 : null);

  useTimeout(() => {
    setSubtitle('韩仁陆：“这地上像蜡一样的都是些什么？”');
  }, props.open ? 50800 : null);

  useTimeout(() => {
    setSubtitle('罗明纱：“这个摆放方式，我好像见过……”');
  }, props.open ? 54300 : null);

  useTimeout(() => {
    setSubtitle('小霁：“等一下，这个屏风后面……”');
  }, props.open ? 57900 : null);

  useTimeout(() => {
    setStage(4);
    setSubtitle('（请点击屏风查看）');
  }, props.open ? 61400 : null);

  useTimeout(() => {
    playScarySound();
    setInvertImg(true);
  }, animateScreen ? 1000 : null);

  useTimeout(() => {
    playTransitionVoiceLine2();
    setSubtitle('太太：这是……在我的梦里……出现的女人!')
  }, animateScreen ? 2500 : null);

  useTimeout(() => {
    props.onClose?.({}, 'escapeKeyDown');
  }, animateScreen ? 7500: null);

  return (
    <FullDialog disableEscapeKeyDown {...props}>
      <TransitionContainer>
        {stage < 3 && <BackgroundImage style={{ filter: `brightness(${brightness})`, backgroundImage: `url(${getAssetUrl(stage === 1 ? '/SSQW/transition1.webp' : '/SSQW/transition2.webp')})`}} />}
        {stage === 4 && <>
          <ScreenImage src={getAssetUrl('/SSQW/screen.webp')} animate={animateScreen} onClick={() => setAnimateScreen(true)} />
          <MrsRoseImage src={getAssetUrl('/SSQW/mrs_rose.webp')} style={{ filter: invertImg ? 'invert(1)' : 'invert(0)' }} />
        </>}
        <Subtitle>{subtitleText}</Subtitle>
      </TransitionContainer>
    </FullDialog>
  );
};

export default SSQW_StageTransition;
