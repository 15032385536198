import { ComponentType, createElement, FC, MutableRefObject, PropsWithChildren, useRef } from 'react';
import crypto from 'crypto-es';
import avatar1 from 'assets/avatars/avatar1.webp';
import avatar2 from 'assets/avatars/avatar2.webp';
import avatar3 from 'assets/avatars/avatar3.webp';
import avatar4 from 'assets/avatars/avatar4.webp';
import avatar5 from 'assets/avatars/avatar5.webp';
import avatar6 from 'assets/avatars/avatar6.webp';
import avatar7 from 'assets/avatars/avatar7.webp';
import avatar8 from 'assets/avatars/avatar8.webp';

import { GameType, UserRole } from 'openapi';
import { NDYY_CharacterAbbr, NDYY_CharacterColors } from 'lib/stories/NDYY/NDYY_StaticData';
import { YLSX_CharacterAbbr, YLSX_CharacterColors } from 'lib/stories/YLSX/YLSX_StaticData';
import { SSQW_CharacterAbbr, SSQW_CharacterColors } from 'lib/stories/SSQW/SSQW_StaticData';
import { ZTFR_CharacterAbbr, ZTFR_CharacterColors } from 'lib/stories/ZTFR/ZTFR_StaticData';
import { EXSL_CharacterAbbr, EXSL_CharacterColors } from 'lib/stories/EXSL/EXSL_StaticData';
import { YLW_CharacterAbbr, YLW_CharacterColors } from 'lib/stories/YLW/YLW_StaticData';
import { MHZY_CharacterAbbr, MHZY_CharacterColors } from 'lib/stories/MHZY/MHZY_StaticData';
import { WQYF_CharacterAbbr, WQYF_CharacterColors } from 'lib/stories/WQYF/WQYF_StaticData';
import { XLJ_CharacterAbbr, XLJ_CharacterColors } from 'lib/stories/XLJ/XLJ_StaticData';
import { YSZM_CharacterAbbr, YSZM_CharacterColors } from 'lib/stories/YSZM/YSZM_StaticData';

export const createBinder = <OuterProps, InnerProps extends {}>(Component: ComponentType<InnerProps>, hook: (props: OuterProps) => InnerProps): FC<PropsWithChildren<OuterProps>> => {
  const binder: FC<PropsWithChildren<OuterProps>> = (props: OuterProps) => createElement(Component, hook(props));
  binder.displayName = hook.name;
  return binder;
};

export const hashStringWithSha256 = (code: string) => {
  return crypto.SHA256(code).toString();
};

export const parseAvatarUrl = (avatar?: string) => {
  switch (avatar) {
    case 'avatar1':
      return avatar1;
    case 'avatar2':
      return avatar2;
    case 'avatar3':
      return avatar3;
    case 'avatar4':
      return avatar4;
    case 'avatar5':
      return avatar5;
    case 'avatar6':
      return avatar6;
    case 'avatar7':
      return avatar7;
    case 'avatar8':
      return avatar8;
    default:
      return avatar;
  }
};

export const getStoryPosterUrl = (storyId?: string) => {
  return getAssetUrl(`/${storyId}/poster.webp`);
};

export const getAssetUrl = (url?: string) => {
  return `${process.env['REACT_APP_AXIOS_SERVER_URL']}${url}`;
}

export const getRoleName = (role?: UserRole) => {
  switch (role) {
    case 'ADMIN':
      return '管理员';
    case 'DM':
      return 'DM';
    case 'PLAN_A':
      return '普通用户';
    case 'PLAN_B':
      return '会员';
    case 'PLAN_C':
      return 'VIP用户';
    case 'RETAILER':
      return '店家';
    default:
      break;
  }
}

export const getGameType = (type?: GameType) => {
  switch (type) {
    case 'GENERAL':
      return '普通案件';
    case 'HOST_BY_VIP':
      return '会员案件'
    default:
      return type;
  }
}

export const getCharacterColors = (storyId?: string) => {
  switch (storyId) {
    case 'NDYY':
      return NDYY_CharacterColors;
    case 'YLSX':
      return YLSX_CharacterColors;
    case 'SSQW':
      return SSQW_CharacterColors;
    case 'ZTFR':
      return ZTFR_CharacterColors;
    case 'EXSL':
      return EXSL_CharacterColors;
    case 'YLW':
      return YLW_CharacterColors;
    case 'MHZY':
      return MHZY_CharacterColors;
    case 'WQYF':
      return WQYF_CharacterColors;
    case 'XLJ':
      return XLJ_CharacterColors;
    case 'YSZM':
      return YSZM_CharacterColors;
    default:
      return undefined;
  }
}

export const getCharacterAbbr = (character: string, storyId?: string) => {
  switch (storyId) {
    case 'NDYY':
      return NDYY_CharacterAbbr[character];
    case 'YLSX':
      return YLSX_CharacterAbbr[character];
    case 'SSQW':
      return SSQW_CharacterAbbr[character];
    case 'ZTFR':
      return ZTFR_CharacterAbbr[character];
    case 'EXSL':
      return EXSL_CharacterAbbr[character];
    case 'YLW':
      return YLW_CharacterAbbr[character];
    case 'MHZY':
      return MHZY_CharacterAbbr[character];
    case 'WQYF':
      return WQYF_CharacterAbbr[character];
    case 'XLJ':
      return XLJ_CharacterAbbr[character];
    case 'YSZM':
      return YSZM_CharacterAbbr[character];
    default:
      return undefined;
  }
}

export const parseGameStatus = (gameStatus: string) => {
  switch (gameStatus) {
    case 'OPEN':
      return '等待中';
    case 'STARTED':
      return '已开始';
    case 'OVER':
      return '已结案';
    default:
      return '未知';
  }
}

export const useCurrentRef = <T>(current: T): MutableRefObject<T> => {
  const ref = useRef(current);
  ref.current = current;
  return ref;
};
