/* eslint-disable react/jsx-pascal-case */
import styled from '@emotion/styled';
import { CSSProperties, FC, HTMLAttributes, ReactNode, useState } from 'react';
import { Lightning0Keyframes, Lightning1Keyframes, Lightning2Keyframes, RainKeyframes, HeartbeatKeyframes, ShockwaveKeyframes } from 'utils/keyframes';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';
import { NDYY_CharacterAbbr, NDYY_CharacterColors } from './NDYY_StaticData';
import { css } from '@emotion/react';
import NDYY_StageTransition from './NDYY_StageTransition';
import GameLock from 'lib/game/components/GameLock';

const Map = styled.div`
  width: 100vw;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
`;

const VotesContainer = styled.div<{ tightLayout: boolean }>`
  position: absolute;
  top: -20px;
  right: ${(props) => props.tightLayout ? '15px' : '40px'};
  z-index: 1;
`;

const VoteBadge = styled.div<{ index: number, color?: string, tightLayout: boolean }>`
  width: 36px;
  height: 48px;
  border-radius: 3px;
  padding-top: 5px;
  font-family: 'Mircrosoft Yahei';
  font-size: 24px;
  color: white;
  text-align: center;
  position: absolute;
  top: 0px;
  right: ${(props) => `${(props.index - 1) * (props.tightLayout ? 10 : 25)}px`};
  background-color: ${(props) => props.color};
  z-index: ${(props) => -1 - props.index};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  &:before {
    background-color: inherit;
    content: '';
    height: 26px;
    width: 26px;
    position: absolute;
    top: 33px;
    left: 5.25px;
    border-radius: 3px;
    transform: rotate(45deg);
    z-index: inherit;
  }
`;

const LocationBadge = styled(({ badge, animate = false, ...props }: { badge?: number, animate?: boolean } & HTMLAttributes<HTMLDivElement>) => (
  <div {...props}>
    {badge}
  </div>
))`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 26px;
  font-family: 黑体;
  background-color: #f14d4d;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  animation: ${(props) => props.animate ? css`${HeartbeatKeyframes} 1s ease-out infinite` : null};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 80%;
    animation: ${(props) => props.animate ? css`${ShockwaveKeyframes} 1s ease-out infinite` : null};
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    animation: ${(props) => props.animate ? css`${ShockwaveKeyframes} 1s ease-out infinite` : null};
  }
`;

const LocationButton = styled(({ badge, badgeStyle, hasNewClue, votedPlayers = [], tightLayout = false, isLocked = false, ...props }: { badge?: number; badgeStyle?: CSSProperties, hasNewClue?: boolean, votedPlayers?: string[], tightLayout?: boolean, isLocked?: boolean } & HTMLAttributes<HTMLDivElement>) => (
  <div {...props}>
    {isLocked ? null : <LocationBadge badge={badge} style={badgeStyle} animate={hasNewClue} />}
    <VotesContainer tightLayout={tightLayout}>
      {votedPlayers.map((player, index) =>
        <VoteBadge key={player} index={index} color={NDYY_CharacterColors[player]} tightLayout={tightLayout}>
          {NDYY_CharacterAbbr[player]}
        </VoteBadge>
      )}
    </VotesContainer>
    {props.children}
  </div>
))`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 5px;
  border: 5px solid transparent;
  cursor: pointer;

  &:hover {
    border-color: #7eb2ff;
  }
`;

const MasterRoom = styled(LocationButton)`
  width: 437px;
  height: 146px;
  left: 146px;
  top: -940px;
`;

const YuXinRoom = styled(LocationButton)`
  width: 246px;
  height: 195px;
  left: 827px;
  top: -892px;
`;

const Bedroom1 = styled(LocationButton)`
  width: 198px;
  height: 146px;
  left: -876px;
  top: -940px;
`;

const Bedroom2 = styled(LocationButton)`
  width: 197px;
  height: 146px;
  left: -485px;
  top: -940px;
`;

const Kitchen = styled(LocationButton)`
  width: 246px;
  height: 243px;
  left: -827px;
  top: -114px;
`;

const MeetingRoom = styled(LocationButton)`
  width: 488px;
  height: 243px;
  left: -97px;
  top: -115px;
`;

const StudyRoom = styled(LocationButton)`
  width: 245px;
  height: 198px;
  left: 632px;
  top: -163px;
`;

const Toilet = styled(LocationButton)`
  width: 100px;
  height: 146px;
  left: 972px;
  top: -212px;
`;

const FrontDoor = styled(LocationButton)`
  width: 413px;
  height: 98px;
  left: -20px;
  top: 225px;
  border-width: 5px 5px 5px 0;
  border-radius: 3px;

  &:before {
    content: '';
    position: absolute;
    width: 160px;
    height: 98px;
    top: -5px;
    left: -157px;
    border: inherit;
    border-width: 5px 0 0 0;
  }

  &:after {
    content: '';
    position: absolute;
    width: 170px;
    height: 98px;
    top: -5px;
    left: -321px;
    border: inherit;
    border-width: 5px 0 5px 5px;
    border-radius: 3px;
  }
`;

const ButlerRoom = styled(LocationButton)`
  width: 242px;
  height: 195px;
  left: 630px;
  top: 577px;
`;

const BasementHallyway = styled(LocationButton)`
  width: 388px;
  height: 98px;
  top: 964px;
  left: 3px;
  border-width: 5px 5px 5px 0;
  border-radius: 3px;

  &:before {
    content: '';
    position: absolute;
    width: 102px;
    height: 108px;
    top: -10px;
    left: -103px;
    border: inherit;
    border-width: 0 0 5px 5px;
    border-radius: 3px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 98px;
    height: 241px;
    top: -247px;
    left: -103px;
    border: inherit;
    border-width: 5px 5px 0 5px;
    border-radius: 3px;
  }
`;

const Room1 = styled(LocationButton)`
  width: 197px;
  height: 244px;
  left: 193px;
  top: 627px;
`;

const Room2 = styled(LocationButton)`
  width: 197px;
  height: 244px;
  left: -195px;
  top: 627px;
`;

const Room3 = styled(LocationButton)`
  width: 244px;
  height: 147px;
  left: -829px;
  top: 917px;
`;

const Room4 = styled(LocationButton)`
  width: 244px;
  height: 197px;
  left: -829px;
  top: 575px;
`;

const GameTime = styled.div`
  position: absolute;
  top: 40px;
  right: 60px;
  text-align: center;
  font-family: 'STZhongSong';
  font-size: 40px;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  color: white;
`;

const Rain = styled.div`
  background-image: url(${getAssetUrl('/NDYY/rain.webp')});
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  animation: ${RainKeyframes} 0.8s linear infinite;
  pointer-events: none;
`;

const Lightning = styled.div<{ lightning: number }>`
  width: 100vw;
  height: 100dvh;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url(${getAssetUrl('/NDYY/background2.webp')});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: ${(props) => {
    switch (props.lightning) {
      case 0:
        return css`1s ease-out 34.7s ${Lightning0Keyframes}`;
      case 1:
        return css`0.8s ease-out 23.2s ${Lightning1Keyframes}`;
      case 2:
        return css`0.8s ease-out 17.5s ${Lightning2Keyframes}`;
      default:
        return null
    }
  }};
`;

export interface NDYY_InvestigatePageProps extends CommonUIProps {
  showMap?: boolean;
  lockWobbles: Record<string, boolean>;
  lockedRooms: string[];
  numRemainingClues: Record<string, number>;
  locationHasNewClue: string[];
  playerVotedLocations: Record<string, string | undefined>;
  onLocationButtonClicked: (location: string) => void;
  onLockAnimationEnd: (location: string) => void;
  voteClueDialog: ReactNode;
  showStageTransition: boolean;
  onStageTransitionEnd: (_: Event, reason: "backdropClick" | "escapeKeyDown") => void;
  gameTime?: number;
  raining: boolean;
}

export const NDYY_InvestigatePage: FC<NDYY_InvestigatePageProps> = function ({
  showMap,
  lockWobbles,
  lockedRooms,
  numRemainingClues,
  locationHasNewClue,
  playerVotedLocations,
  onLocationButtonClicked,
  onLockAnimationEnd,
  voteClueDialog,
  showStageTransition,
  onStageTransitionEnd,
  gameTime,
  raining,
  ...props
}) {

  const [lightning, setLightning] = useState<number>(1);

  return (
    <div {...props}>
      {raining ? <>
        <Lightning lightning={lightning} onAnimationEnd={() => setLightning((lightning + 1) % 3)} />
        <Rain />
      </> : null}
      {showMap ? <Map style={{ backgroundImage: `url(${getAssetUrl('/NDYY/map.webp')})` }}>
        <MasterRoom badge={numRemainingClues['主人房']} badgeStyle={{ bottom: '10px' }} hasNewClue={locationHasNewClue.includes('主人房')} votedPlayers={Object.keys(playerVotedLocations).filter((player) => playerVotedLocations[player] === '主人房')} onClick={() => onLocationButtonClicked('主人房')} />
        <YuXinRoom badge={numRemainingClues['玉馨房']} badgeStyle={{ bottom: '30px' }} hasNewClue={locationHasNewClue.includes('玉馨房')} votedPlayers={Object.keys(playerVotedLocations).filter((player) => playerVotedLocations[player] === '玉馨房')} onClick={() => onLocationButtonClicked('玉馨房')} />
        <Bedroom1 badge={numRemainingClues['卧室1']} badgeStyle={{ bottom: '10px' }} hasNewClue={locationHasNewClue.includes('卧室1')} votedPlayers={Object.keys(playerVotedLocations).filter((player) => playerVotedLocations[player] === '卧室1')} onClick={() => onLocationButtonClicked('卧室1')} />
        <Bedroom2 badge={numRemainingClues['卧室2']} badgeStyle={{ bottom: '10px' }} hasNewClue={locationHasNewClue.includes('卧室2')} votedPlayers={Object.keys(playerVotedLocations).filter((player) => playerVotedLocations[player] === '卧室2')} onClick={() => onLocationButtonClicked('卧室2')} />
        <Kitchen badge={numRemainingClues['厨房']} badgeStyle={{ bottom: '50px' }} hasNewClue={locationHasNewClue.includes('厨房')} votedPlayers={Object.keys(playerVotedLocations).filter((player) => playerVotedLocations[player] === '厨房')} onClick={() => onLocationButtonClicked('厨房')} />
        <MeetingRoom badge={numRemainingClues['大厅']} badgeStyle={{ bottom: '50px' }} hasNewClue={locationHasNewClue.includes('大厅')} votedPlayers={Object.keys(playerVotedLocations).filter((player) => playerVotedLocations[player] === '大厅')} onClick={() => onLocationButtonClicked('大厅')} />
        <StudyRoom badge={numRemainingClues['书房']} badgeStyle={{ bottom: '25px' }} hasNewClue={locationHasNewClue.includes('书房')} votedPlayers={Object.keys(playerVotedLocations).filter((player) => playerVotedLocations[player] === '书房')} onClick={() => onLocationButtonClicked('书房')} />
        <Toilet badge={numRemainingClues['厕所']} badgeStyle={{ bottom: '15px' }} hasNewClue={locationHasNewClue.includes('厕所')} votedPlayers={Object.keys(playerVotedLocations).filter((player) => playerVotedLocations[player] === '厕所')} tightLayout onClick={() => onLocationButtonClicked('厕所')} />
        <FrontDoor badge={numRemainingClues['正门']} badgeStyle={{ left: '-238px', bottom: '32px' }} hasNewClue={locationHasNewClue.includes('正门')} votedPlayers={Object.keys(playerVotedLocations).filter((player) => playerVotedLocations[player] === '正门')} onClick={() => onLocationButtonClicked('正门')} />
        <ButlerRoom badge={numRemainingClues['管家房']} badgeStyle={{ bottom: '20px' }} hasNewClue={locationHasNewClue.includes('管家房')} votedPlayers={Object.keys(playerVotedLocations).filter((player) => playerVotedLocations[player] === '管家房')} onClick={() => onLocationButtonClicked('管家房')} />
        <BasementHallyway
          badge={numRemainingClues['地下走廊']}
          badgeStyle={{ right: '280px', bottom: '32px' }}
          hasNewClue={locationHasNewClue.includes('地下走廊')}
          votedPlayers={Object.keys(playerVotedLocations).filter((player) => playerVotedLocations[player] === '地下走廊')}
          isLocked={lockedRooms.includes('地下走廊')}
          onClick={() => onLocationButtonClicked('地下走廊')}
        >
          {lockedRooms.includes('地下走廊') ? <GameLock wobble={lockWobbles['地下走廊']} style={{ right: 10, bottom: 30 }} onAnimationEnd={() => onLockAnimationEnd('地下走廊')} /> : null}
        </BasementHallyway>
        <Room1
          badge={numRemainingClues['一号房']}
          badgeStyle={{ bottom: '50px' }}
          hasNewClue={locationHasNewClue.includes('一号房')}
          votedPlayers={Object.keys(playerVotedLocations).filter((player) => playerVotedLocations[player] === '一号房')}
          isLocked={lockedRooms.includes('一号房')}
          onClick={() => onLocationButtonClicked('一号房')}
        >
          {lockedRooms.includes('一号房') ? <GameLock wobble={lockWobbles['一号房']} style={{ left: 0, right: 0, bottom: 15 }} onAnimationEnd={() => onLockAnimationEnd('一号房')} /> : null}
        </Room1>
        <Room2
          badge={numRemainingClues['二号房']}
          badgeStyle={{ bottom: '50px' }}
          hasNewClue={locationHasNewClue.includes('二号房')}
          votedPlayers={Object.keys(playerVotedLocations).filter((player) => playerVotedLocations[player] === '二号房')}
          isLocked={lockedRooms.includes('二号房')}
          onClick={() => onLocationButtonClicked('二号房')}
        >
          {lockedRooms.includes('二号房') ? <GameLock wobble={lockWobbles['二号房']} style={{ left: 0, right: 0, bottom: 15 }} onAnimationEnd={() => onLockAnimationEnd('二号房')} /> : null}
        </Room2>
        <Room3
          badge={numRemainingClues['三号房']}
          badgeStyle={{ bottom: '10px' }}
          hasNewClue={locationHasNewClue.includes('三号房')}
          votedPlayers={Object.keys(playerVotedLocations).filter((player) => playerVotedLocations[player] === '三号房')}
          isLocked={lockedRooms.includes('三号房')}
          onClick={() => onLocationButtonClicked('三号房')}
        >
          {lockedRooms.includes('三号房') ? <GameLock wobble={lockWobbles['三号房']} style={{ right: 10, top: 29 }} onAnimationEnd={() => onLockAnimationEnd('三号房')} /> : null}
        </Room3>
        <Room4
          badge={numRemainingClues['四号房']}
          badgeStyle={{ bottom: '25px' }}
          hasNewClue={locationHasNewClue.includes('四号房')}
          votedPlayers={Object.keys(playerVotedLocations).filter((player) => playerVotedLocations[player] === '四号房')}
          isLocked={lockedRooms.includes('四号房')}
          onClick={() => onLocationButtonClicked('四号房')}
        >
          {lockedRooms.includes('四号房') ? <GameLock wobble={lockWobbles['四号房']} style={{ right: 10, top: 78 }} onAnimationEnd={() => onLockAnimationEnd('四号房')} /> : null}
        </Room4>
        <GameTime>
          海砂馆时间<br />
          {gameTime ? new Date(gameTime * 60 * 1000).toISOString().slice(11, 16) : null}
        </GameTime>
      </Map> : null}
      {voteClueDialog}
      <NDYY_StageTransition open={showStageTransition} onClose={onStageTransitionEnd} />
    </div >
  );
};

export default NDYY_InvestigatePage;
