/* eslint-disable react/jsx-pascal-case */
import styled from '@emotion/styled';
import { LocationButton } from 'lib/game/components/LocationButton';
import { FC, ReactNode, useContext, useState } from 'react';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';
import { GameContext } from 'utils/contexts';
import { Stack } from '@mui/material';
import { Falling0Keyframes, Falling1Keyframes, Falling2Keyframes, Falling3Keyframes, Falling4Keyframes, RisingKeyframes, BlinkEyeKeyframes } from 'utils/keyframes';
import { useRandomInterval } from 'utils/hooks';
import { css } from '@emotion/react';
import BagImg from 'assets/bag.webp';
import useSound from 'use-sound';

const MapContainer = styled.div`
  width: 60vw;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 5dvh;
  display: flex;
  align-items: center;
`;

const Map = styled.img`
  width: 100%;
`;

const Luggage = styled(LocationButton)`
  position: relative;
  color: white;
  width: 260px;
  padding: 16px;
  text-align: center;
  font-family: 'STZhongSong';
  font-size: 32px;
  border-radius: 16px;
  border: 5px solid transparent;

  &:hover {
    border-color: #7eb2ff;
  }
`;

const LuggageImg = styled.img`
  width: 100%;
`;

const Belongings = styled(LocationButton)`
  position: relative;
  color: white;
  width: 260px;
  padding: 16px;
  text-align: center;
  font-family: 'STZhongSong';
  font-size: 32px;
  border-radius: 16px;
  border: 5px solid transparent;

  &:hover {
    border-color: #7eb2ff;
  }
`;

const BelongingsImg = styled.img`
  width: 100%;
`;

const ILocationButton = styled(LocationButton)`
  border-width: 8px;
`;

const CivilianArea = styled(ILocationButton)`
  width: 6.6%;
  height: 22.0%;
  bottom: 23.8%;
  right: 14.7%;
  transform: perspective(15px) rotateX(1deg) skewX(4deg);

  > div {
    transform: skewX(-4deg);
  }
`;

const LlamaPen = styled(ILocationButton)`
  width: 20.8%;
  height: 7.6%;
  bottom: 8.1%;
  right: 17.4%;
  transform: perspective(22px) rotateX(1deg) skewX(-1deg);

  > div {
    transform: skewX(1deg);
  }
`;

const PriestArea = styled(ILocationButton)`
  width: 9.0%;
  height: 4.3%;
  bottom: 33.3%;
  left: 33.3%;
  transform: perspective(25px) rotateX(1deg) skewX(-20deg);

  > div {
    transform: skewX(20deg);
  }
`;

const AltarArea = styled(ILocationButton)`
  width: 10.0%;
  height: 4.8%;
  bottom: 32.9%;
  left: 49.6%;
  transform: perspective(25px) rotateX(1deg) skewX(-10deg);

  > div {
    transform: skewX(10deg);
  }
`;

const SunStone = styled(ILocationButton)`
  width: 4.1%;
  height: 2.9%;
  bottom: 52.2%;
  left: 40.9%;
  transform: perspective(25px) rotateX(1deg) skewX(-20deg);

  > div {
    transform: skewX(20deg);
  }
`;

const NobelArea = styled(ILocationButton)`
  width: 6.2%;
  height: 4.5%;
  bottom: 50.3%;
  left: 47.9%;
  transform: perspective(25px) rotateX(1deg) skewX(-20deg);

  > div {
    transform: skewX(20deg);
  }
`;

const StorageArea = styled(ILocationButton)`
  width: 6.7%;
  height: 5.3%;
  bottom: 49.1%;
  left: 57.6%;
  transform: perspective(25px) rotateX(1deg) skewX(-10deg);

  > div {
    transform: skewX(10deg);
  }
`;

const SecurityArea = styled(ILocationButton)`
  width: 5.6%;
  height: 12.0%;
  bottom: 38.5%;
  left: 69.0%;
  transform: perspective(40px) rotateX(1deg) skewX(1deg);

  > div {
    transform: skewX(-1deg);
  }
`;

const Field = styled(ILocationButton)`
  width: 31.4%;
  height: 5.1%;
  bottom: 12.5%;
  left: 24.0%;
  transform: perspective(40px) rotateX(1deg) skewX(-20deg);

  > div {
    transform: skewX(20deg);
  }
`;

const MapInfo = styled.div`
  color: white;
  font-family: 'STZhongSong';
  font-size: 28px;
  text-align: justify;
  line-height: 1.5;
  position: absolute;
  top: 3%;
  right: 2%;
`;

const MapInfoWrapper = styled(Stack)`
  position: absolute;
  top: 2vh;
  right: 4vw;
  color: white;
  font-size: 20px;
  width: 14vw;
  font-family: 'STZhongSong';
  text-align: justify;
`;

const GlobeImage = styled.img`
  width: 12vw;
  margin-left: 10px;
  margin-bottom: 10px;
`;

const Alpaca = styled.div<{ animate?: boolean }>`
  height: 200px;
  width: 160px;
  position: absolute;
  bottom: -200px;
  left: 15vw;
  background: url(${getAssetUrl('/YSZM/alpaca.webp')}) 0 0 no-repeat;
  animation: ${(props) => props.animate ? css`${RisingKeyframes} 10s ease-in-out, ${BlinkEyeKeyframes} 0.3s steps(2) 4s 2` : null};
  cursor: pointer;
`;

const Gold = styled.img<{ animation: number }>`
  width: 160px;
  height: 160px;
  position: absolute;
  cursor: pointer;
  animation-name: ${(props) => {
    switch (props.animation) {
      case 0:
        return css`${Falling0Keyframes}`;
      case 1:
        return css`${Falling1Keyframes}`;
      case 2:
        return css`${Falling2Keyframes}`;
      case 3:
        return css`${Falling3Keyframes}`;
      case 4:
        return css`${Falling4Keyframes}`;
      default:
        return null
    }
  }};
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  transition: opacity 0.8s ease-in-out;
`;

interface GoldProps extends CommonUIProps {
  imgIndex: number;
  animation: number;
  animationDuration: number;
  handleTransitionEnd: () => void;
}

export const GoldJewellery: FC<GoldProps> = function ({
  imgIndex,
  animation,
  animationDuration,
  handleTransitionEnd,
  ...props
}) {

  const [fade, setFade] = useState<boolean>(false);

  return (
    <Gold src={getAssetUrl(`/YSZM/gold${imgIndex + 1}.webp`)}
      style={{ opacity: fade ? '0' : '1', animationDuration: `${animationDuration}s` }}
      animation={animation}
      draggable={false}
      onClick={() => setFade(true)}
      onTransitionEnd={handleTransitionEnd}
      onAnimationEnd={handleTransitionEnd}
      {...props}
    />
  )
}

export interface YSZM_InvestigatePageProps extends CommonUIProps {
  showMap: boolean;
  gameTime: string;
  numRemainingClues: Record<string, number>;
  locationHasNewClue: string[];
  onLocationButtonClicked: (location: string) => void;
  pickClueDialog: ReactNode;
  showPickClueDialog: boolean;
  setShowPickClueDialog: (_: boolean) => void;
}

export const YSZM_InvestigatePage: FC<YSZM_InvestigatePageProps> = function ({
  showMap,
  gameTime,
  numRemainingClues,
  locationHasNewClue,
  onLocationButtonClicked,
  pickClueDialog,
  showPickClueDialog,
  setShowPickClueDialog,
  ...props
}) {

  const gameStage = useContext(GameContext)?.stage ?? '';

  const [startAlpacaAnimation, setStartAlpacaAnimation] = useState<boolean>(false);

  const [playAlpaca] = useSound(getAssetUrl('/YSZM/alpaca.mp3'), {
    interrupt: true,
    sprite: {
      meh1: [300, 1000],
      meh2: [2300, 1100],
      meh3: [4000, 700]
    }
  });

  useRandomInterval(() => {
    if (['INVESTIGATING1', 'DISCUSSING1', 'READING2', 'INVESTIGATING2', 'READING3', 'INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameStage)) {
      setStartAlpacaAnimation(true);
    }
  }, 8 * 60000, 12 * 60000);

  const [gold, setGold] = useState<ReactNode>();

  useRandomInterval(() => {
    if (!gold && ['READING2', 'INVESTIGATING2', 'READING3', 'INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameStage)) {
      const animation = Math.floor(Math.random() * 5);
      const animationDuration = Math.floor(Math.random() * 5) + 10;
      const maskIndex = Math.floor(Math.random() * 4);
      setGold(<GoldJewellery imgIndex={maskIndex} animation={animation} animationDuration={animationDuration} handleTransitionEnd={() => setGold(undefined)} />);
    }
  }, 60 * 1000 * 3, 60 * 1000 * 10);

  return (
    <div {...props}>
      {showMap &&
        <>
          <MapContainer>
            <div style={{ width: '100%', position: 'relative' }}>
              <Map src={getAssetUrl('/YSZM/map.webp')} draggable={false} />
              <MapInfo>
                1、天象区  &nbsp;2、贵族区<br />
                3、仓储区  &nbsp;4、神职区<br />
                5、祭坛区  &nbsp;6、警备区<br />
                7、石桥  &nbsp;&nbsp;&nbsp;&nbsp;8、下游石桥<br />
                9、平民区  &nbsp;10、农田<br />
                11、水渠  &nbsp;12、羊驼圈<br />
                每层高3米左右，1~5区都有围墙
              </MapInfo>
              {['INVESTIGATING2', 'DISCUSSING2', 'INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameStage) && <>
                <PriestArea badge={numRemainingClues['神职区']} badgeStyle={{ bottom: '10%' }} hasNewClue={locationHasNewClue.includes('神职区')} onClick={() => onLocationButtonClicked('神职区')} />
                <AltarArea badge={numRemainingClues['祭坛区']} badgeStyle={{ bottom: '20%', left: '35%' }} hasNewClue={locationHasNewClue.includes('祭坛区')} onClick={() => onLocationButtonClicked('祭坛区')} />
              </>}
              {['INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameStage) && <>
                <CivilianArea badge={numRemainingClues['平民区']} badgeStyle={{ bottom: '5%' }} hasNewClue={locationHasNewClue.includes('平民区')} onClick={() => onLocationButtonClicked('平民区')} />
                <LlamaPen badge={numRemainingClues['羊驼圈']} badgeStyle={{ bottom: '45%', left: '-15%' }} hasNewClue={locationHasNewClue.includes('羊驼圈')} onClick={() => onLocationButtonClicked('羊驼圈')} />
                <SunStone badge={numRemainingClues['栓日石']} badgeStyle={{ bottom: '5%', left: '85%' }} hasNewClue={locationHasNewClue.includes('栓日石')} onClick={() => onLocationButtonClicked('栓日石')} />
                <NobelArea badge={numRemainingClues['贵族区']} badgeStyle={{ bottom: '10%', left: '85%' }} hasNewClue={locationHasNewClue.includes('贵族区')} onClick={() => onLocationButtonClicked('贵族区')} />
                <StorageArea badge={numRemainingClues['仓储区']} badgeStyle={{ top: '5%', left: '-45%' }} hasNewClue={locationHasNewClue.includes('仓储区')} onClick={() => onLocationButtonClicked('仓储区')} />
                <SecurityArea badge={numRemainingClues['警备区']} badgeStyle={{ top: '10%', left: '45%' }} hasNewClue={locationHasNewClue.includes('警备区')} onClick={() => onLocationButtonClicked('警备区')} />
                <Field badge={numRemainingClues['农田']} badgeStyle={{ bottom: '25%', left: '40%' }} hasNewClue={locationHasNewClue.includes('农田')} onClick={() => onLocationButtonClicked('农田')} />
              </>}
            </div>
          </MapContainer>

          <MapInfoWrapper direction="column" alignItems="center" spacing={1}>
            <GlobeImage src={getAssetUrl('/YSZM/worldmap.webp')} />
            {['INVESTIGATING1', 'DISCUSSING1', 'INVESTIGATING2', 'DISCUSSING2', 'INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameStage) && <>
              <Luggage badge={numRemainingClues['整理行李']} badgeStyle={{ bottom: '6%', left: '60%' }} hasNewClue={locationHasNewClue.includes('整理行李')} onClick={() => onLocationButtonClicked('整理行李')}>
                <LuggageImg src={getAssetUrl('/YSZM/luggage.webp')} />
                整理行李
              </Luggage>
            </>}
            {['INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameStage) && <>
              <Belongings badge={numRemainingClues['随身物品']} badgeStyle={{ bottom: '6%', left: '60%' }} hasNewClue={locationHasNewClue.includes('随身物品')} onClick={() => onLocationButtonClicked('随身物品')}>
                <BelongingsImg src={BagImg} />
                随身物品
              </Belongings>
            </>}
          </MapInfoWrapper>
        </>
      }
      {gold}
      <Alpaca animate={startAlpacaAnimation}
        onAnimationEnd={(event) => event.animationName === RisingKeyframes.name && setStartAlpacaAnimation(false)}
        onClick={() => {
          const soundIndex = Math.floor(Math.random() * 3);
          playAlpaca({ id: `meh${soundIndex + 1}` });
        }}
        draggable={false}
      />
      {pickClueDialog}
    </div>
  );
};

export default YSZM_InvestigatePage;
