import { useState, ChangeEvent, useCallback, useContext, useEffect } from 'react';
import { CreateGameDialog, CreateGameDialogProps, CreateGameDialogState } from './CreateGameDialog';
import { createBinder } from 'utils/utils';
import { UserContext } from 'utils/contexts';
import { enqueueSnackbar } from 'notistack';
import clientApi from 'utils/clientApi';
import { StoryCommentRes } from 'openapi';

export type CreateGameDialogBinderManagedProps = 'state' | 'selectedStory' | 'onSelectedStoryChange' | 'comments'
| 'onNextButtonClicked' | 'onPreviousButtonClicked' | 'onCreateGameButtonClicked'
| 'showVIPGameOption' | 'isVIPGame' | 'onToggleVIPGame' | 'showConfirmDialog' | 'onConfirmVIPGame' | 'handleConfirmDialogClose';

export interface CreateGameDialogBinderProps extends Omit<CreateGameDialogProps, CreateGameDialogBinderManagedProps> { }

const useCreateGameDialogBinder = (props: CreateGameDialogBinderProps): CreateGameDialogProps => {

  const { handleCreateGame } = props;
  const [state, setState] = useState<CreateGameDialogState>(CreateGameDialogState.BROWSE);
  const [selectedStory, setSelectedStory] = useState<string>('NDYY');
  const { user } = useContext(UserContext);
  // const showVIPGameOption = user?.role === 'PLAN_C';
  const [isVIPGame, setIsVIPGame] = useState<boolean>(false);
  const [comments, setComments] = useState<Record<string, StoryCommentRes>>();

  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setState(CreateGameDialogState.BROWSE);
      setComments(undefined);
    };
  }, []);

  useEffect(() => {
    if (state === CreateGameDialogState.DETAIL) {
      clientApi().fetchCommentForStory(selectedStory).then((result) => {
        setComments(result.data);
      }).catch((error: Error) => {
        console.error(error);
      })
    }
  }, [selectedStory, state]);

  const onSelectedStoryChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedStory(e.target.value);
    setComments(undefined);
  }, []);

  const onCreateGameButtonClicked = useCallback(() => {
    if (isVIPGame) {
      if (user?.coupon) {
        setShowConfirmDialog(true);
      } else {
        enqueueSnackbar('本月会员案件次数已用完', { variant: 'error' });
      }
    } else {
      handleCreateGame(selectedStory);
    }
  }, [handleCreateGame, isVIPGame, selectedStory, user?.coupon]);

  const onConfirmVIPGame = useCallback(() => {
    handleCreateGame(selectedStory, true);
  }, [handleCreateGame, selectedStory]);

  const managedProps: Pick<CreateGameDialogProps, CreateGameDialogBinderManagedProps> = {
    state,
    selectedStory,
    comments,
    onSelectedStoryChange,
    onNextButtonClicked: () => setState(CreateGameDialogState.DETAIL),
    onPreviousButtonClicked: () => setState(CreateGameDialogState.BROWSE),
    onCreateGameButtonClicked,
    showVIPGameOption: false,
    isVIPGame,
    onToggleVIPGame: () => setIsVIPGame((prev) => !prev),
    showConfirmDialog,
    onConfirmVIPGame,
    handleConfirmDialogClose: () => setShowConfirmDialog(false),
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const CreateGameDialogBinder = createBinder(CreateGameDialog, useCreateGameDialogBinder);

export default CreateGameDialogBinder;
