import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';
import { FadeInAndOutKeyframes } from 'utils/keyframes';
import useSound from 'use-sound';
import BackgroundMusic from 'assets/music/wanziqingshi.mp3';

const ContentContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-family: STFangSong;
  font-size: 32px;
  text-indent: 2em;
  text-align: justify;
`;

const Info = styled.div`
  width: 40vw;
  height: 100vh;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 10%;
  top: 0;
  bottom: 0;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  text-align: justify;
  line-height: 1.5;
`;

const Info1 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 10s ease-in-out both 2s;
`;

const Info2 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 13s;
`;

const Info3 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 26s;
`;

const Info4 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 39s;
`;

const InfoImage = styled.img`
  margin-bottom: 50px;
`;

const PosterIamge = styled.img`
  height: 60vh;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 15%;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 1.5s ease-in-out both;
`;


export interface YLSX_EpiloguePageProps extends CommonUIProps { }

export const YLSX_EpiloguePage: FC<YLSX_EpiloguePageProps> = function ({
  ...props
}) {
  const [, { stop }] = useSound(BackgroundMusic, {
    volume: 0.2,
    autoplay: 1,
    interrupt: true,
  });
  const [showPoster, setShowPoster] = useState<boolean>(false);

  useEffect(() => {
    return () => stop();
  }, [stop])

  return (
    <ContentContainer {...props}>
      <Info1>
        <InfoImage src={getAssetUrl('/YLSX/yuelushuyuan.webp')} height={600} />
        <p>坐落于湖南长沙的岳麓山历史悠久，古树稀木数不胜数，风景名胜琳琅满目。中国四大名亭之爱晚亭，四大书院之岳麓书院，皆在岳麓山下。</p>
      </Info1>
      <Info2>
        <InfoImage src={getAssetUrl('/YLSX/hongloumeng.webp')} height={600} />
        <p>
          苏黛妮原名“苏珠颦”，起名于其随身携带的刻字白玉，上面写着“珠仙喜有泪，颦颦蹙非悲。”此句出自《红楼梦》，暗示林黛玉对贾宝玉爱而不得，在其与薛宝钗成婚之日气郁而终。
        </p>
      </Info2>
      <Info3>
        <InfoImage src={getAssetUrl('/YLSX/vitamin.webp')} height={600} />
        <p>维他命(Vitamin)是1912年由波兰化学家卡西米尔所发现的一种可治疗多种疾病的物质。由于当时尚不清楚其化学本质，被误认为是一种维持生命与健康所必须的胺类，因此广为流传，甚至还出现了“唯有他才可以保命”的说法……</p>
      </Info3>
      <Info4 onAnimationEnd={() => setShowPoster(true)}>易莲芝的丈夫“阿成”是豪门惊情系列开篇人物之一，他与吴百安、宋笃程都是江南黄岐庄的同学。关于他们的故事，可在剧本《傀儡的记忆》、《孝衣新娘》当中一探究竟。</Info4>
      <PosterIamge src={getAssetUrl('/YLSX/poster.webp')} style={{ opacity: showPoster ? '1' : '0' }} />
    </ContentContainer>
  );
};

export default YLSX_EpiloguePage;
