import { ThemeProvider } from '@mui/material';
import { FC, StrictMode, useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from 'router/AppRouter';
import clientApi, { SetupInterceptorsComponent } from 'utils/clientApi';
import { UserContext } from 'utils/contexts';
import localStorageService, { UserInfo } from 'utils/localStorage';
import theme from 'utils/theme';
import ResponsiveBinder from './ResponsiveBinder';
import { GoogleOAuthProvider } from '@react-oauth/google';
import SnackbarProvider from 'lib/common/Snackbar';

const AppBinder: FC = function () {

  const [currentUser, setCurrentUser] = useState<UserInfo | undefined>(localStorageService.getUserInfo());

  const userLogin = useCallback(() => {
    clientApi().loginWithToken().then((result) => {
      if (result.data) {
        const { accessToken, refreshToken, ...userInfo } = result.data;
        setCurrentUser(userInfo);
        localStorageService.setUserInfo(userInfo);
        localStorageService.setAccessToken(accessToken);
        localStorageService.setRefreshToken(refreshToken);
      }
    }).catch((error: unknown) => {
      console.error(error);
    })
  }, []);

  const userContextValue = {
    user: currentUser,
    setUser: (userInfo?: UserInfo) => {
      setCurrentUser(userInfo);
      if (!userInfo) {
        localStorageService.clearUser();
        localStorageService.clearToken();
      }
    },
    refreshUser: userLogin,
  };

  useEffect(() => {
    if (currentUser) {
      userLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StrictMode>
      <ThemeProvider theme={theme}>
        <GoogleOAuthProvider clientId={process.env['REACT_APP_GOOGLE_CLIENT_ID'] ?? ''}>
          <UserContext.Provider value={userContextValue}>
            <Router basename={process.env.PUBLIC_URL}>
              <SnackbarProvider>
                <ResponsiveBinder>
                  <SetupInterceptorsComponent />
                  <AppRouter />
                </ResponsiveBinder>
              </SnackbarProvider>
            </Router>
          </UserContext.Provider>
        </GoogleOAuthProvider>
      </ThemeProvider>
    </StrictMode>
  );
};

export default AppBinder;