import { useState, useCallback, useContext, useMemo } from 'react';
import { pdfjs } from 'react-pdf';
import { ScriptDialog, ScriptDialogProps } from './ScriptDialog';
import { createBinder } from 'utils/utils';
import { GameContext, UserContext } from 'utils/contexts';
import { socket } from 'utils/socketConnector';
import { useParams } from 'react-router-dom';
import { NDYY_ScriptMaxPage } from 'lib/stories/NDYY/NDYY_StaticData';
import { YLSX_ScriptMaxPage } from 'lib/stories/YLSX/YLSX_StaticData';
import { SSQW_ScriptMaxPage } from 'lib/stories/SSQW/SSQW_StaticData';
import { ZTFR_ScriptMaxPage } from 'lib/stories/ZTFR/ZTFR_StaticData';
import { EXSL_ScriptMaxPage } from 'lib/stories/EXSL/EXSL_StaticData';
import { YLW_ScriptMaxPage } from 'lib/stories/YLW/YLW_StaticData';
import { MHZY_ScriptMaxPage } from 'lib/stories/MHZY/MHZY_StaticData';
import { WQYF_ScriptMaxPage } from 'lib/stories/WQYF/WQYF_StaticData';
import { XLJ_ScriptMaxPage } from 'lib/stories/XLJ/XLJ_StaticData';
import { YSZM_ScriptMaxPage } from 'lib/stories/YSZM/YSZM_StaticData';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export type ScriptDialogBinderManagedProps = 'currentCharacter' | 'numPages' | 'showFinishReadingButton' | 'onLoadSuccess' | 'onFinishReadingButtonClicked';

export interface ScriptDialogBinderProps extends Omit<ScriptDialogProps, ScriptDialogBinderManagedProps> { }

const useScriptDialogBinder = (props: ScriptDialogBinderProps): ScriptDialogProps => {

  const { user } = useContext(UserContext);
  const gameAudit = useContext(GameContext);
  const { gameId } = useParams();
  const [numPages, setNumPages] = useState<number>();

  const maxPages = useMemo(() => {
    switch (gameAudit?.storyId) {
      case 'NDYY':
        return NDYY_ScriptMaxPage[gameAudit?.stage];
      case 'YLSX':
        return YLSX_ScriptMaxPage[gameAudit?.stage];
      case 'SSQW':
        return SSQW_ScriptMaxPage[gameAudit?.stage];
      case 'ZTFR':
        return ZTFR_ScriptMaxPage[gameAudit?.stage];
      case 'EXSL':
        return EXSL_ScriptMaxPage[gameAudit?.stage];
      case 'YLW':
        return YLW_ScriptMaxPage[gameAudit?.stage];
      case 'MHZY':
        return MHZY_ScriptMaxPage[gameAudit?.stage];
      case 'WQYF':
        return WQYF_ScriptMaxPage[gameAudit?.stage];
      case 'XLJ':
        return XLJ_ScriptMaxPage[gameAudit?.stage];
      case 'YSZM':
        return YSZM_ScriptMaxPage[gameAudit?.stage];
      default:
        return undefined;
    }
  }, [gameAudit?.stage, gameAudit?.storyId]);

  const onFinishReadingButtonClicked = useCallback(() => {
    socket?.emit('playerFinishReading', user?.userId, gameId);
    props.onClose?.({}, 'escapeKeyDown');
  }, [gameId, props, user?.userId]);

  const managedProps: Pick<ScriptDialogProps, ScriptDialogBinderManagedProps> = {
    currentCharacter: gameAudit?.players?.[user?.userId ?? '']?.character,
    numPages: maxPages ?? numPages,
    showFinishReadingButton: !gameAudit?.players?.[user?.userId ?? '']?.hasRead,
    onLoadSuccess: ({ numPages }: { numPages: number }) => setNumPages(numPages),
    onFinishReadingButtonClicked,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const ScriptDialogBinder = createBinder(ScriptDialog, useScriptDialogBinder);

export default ScriptDialogBinder;
