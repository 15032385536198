import styled from '@emotion/styled';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC, useContext, useMemo } from 'react';
import { CommonUIProps } from 'utils/types';
import { GameContext, StoryContext } from 'utils/contexts';
import { getAssetUrl } from 'utils/utils';
import RouterPath from 'router/RouterPath';
import { Link, useParams } from 'react-router-dom';

const SummaryContainer = styled.div`
  pointer-events: auto;
  width: 60vw;
  height: 80vh;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  color: #fff;
  background: #0f0f0f;
  border-radius: 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const Title = styled.div`
  width: 100%;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 24px;
  background: #0f0f0f;
  border-radius: 16px;
`;

const IAccordion = styled(Accordion)`
  font-size: 24px;
  background: #0f0f0f;
  color: white;
  margin-bottom: 8px;
  box-shadow: none;

  &.Mui-expanded {
    margin: 8px 0;
  }
`;

const IAccordionSummary = styled(AccordionSummary)`
  height: 52px;
  color: white;
  font-size: 26px;
  border-radius: 4px;
  background: #1e1e1e;

  &.Mui-expanded {
    min-height: 52px;
  }
`;

const IAccordionDetails = styled(AccordionDetails)`
  color: #D9D9D9;
  padding: 24px;
  line-height: 1.5;
  text-indent: 2em;
  text-align: justify;
  white-space: pre-wrap;
`;

const ContentWrapper = styled.div`
  padding: 0 10px;
  max-height: calc(80vh - 80px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(240, 240, 240, 0.24);
    border-style: initial;
    border-radius: 8px;
  }
`;

const QuestionTitle = styled.div`
  color: white;
  font-size: 32px;
`;

const CharacterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CharacterAvatar = styled(Avatar)`
  width: 60px;
  height: 60px;
`;

const CharacterName = styled.div`
  font-size: 18x;
  margin-top: 5px;
  text-align: center;
  font-family: 'STZhongSong';
  text-indent: 0;
`;

const VoteBar = styled.div<{ votes: number }>`
  height: 24px;
  width: ${(props) => props.votes * 50}px;
  background-color: #e2556d;
  border-radius: 8px;
  border: 3px #f0eacc solid;
`;

const VoteText = styled.div`
  font-size: 24px;
  font-family: 'Microsoft Yahei';
  color: white;
  text-indent: 0;
`;

const VoteResultStack = styled(Stack)`
  padding-bottom: 120px;
`;

const CloseButtonBox = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: rgba(15, 15, 15, 0.8);
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: -44px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 32px;
      height: 32px;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.16);
    }
  }
`;

const BottomStack = styled(Stack)`
  font-size: 26px;
  margin-top: 32px;
  margin-bottom: 32px;
`;

const TruthLink = styled(Link)`
  color: #1E90FF;

  :hover {
    color: #4169E1;
  }
`;

const DonateLink = styled.span`
  font-size: 26px;
  color: #1E90FF;
  text-decoration: underline;
  cursor: pointer;

  :hover {
    color: #4169E1;
  }
`;

export interface TruthSummaryProps extends CommonUIProps {
  questions: string[];
  truth: Record<string, string>;
  onDonateButtonClicked: () => void;
  onCloseButtonClicked: () => void;
}

export const TruthSummary: FC<TruthSummaryProps> = function ({
  questions,
  truth,
  onDonateButtonClicked,
  onCloseButtonClicked,
  ...props
}) {

  const { gameId } = useParams();
  const gameAudit = useContext(GameContext);
  const characterList = useContext(StoryContext)?.characterList;
  const voteResults = useMemo(() => {
    const counter: Record<string, number>[] = Array.from({ length: questions.length ?? 0 }, () => ({}));
    Object.values(gameAudit?.players ?? {}).forEach((player) => {
      player.votedMurderers?.forEach((vote, index) => {
        if (counter[index][vote]) {
          counter[index][vote] += 1;
        } else {
          counter[index][vote] = 1;
        }
      })
    });
    return counter;
  }, [gameAudit?.players, questions.length]);  

  return (
    <SummaryContainer {...props}>
      <Title>真相简述</Title>
      <ContentWrapper>
        {Object.entries(truth).map(([title, info], index) => (
          <IAccordion key={title} defaultExpanded={index === 0}>
            <IAccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>{`${index + 1}、${title}`}</IAccordionSummary>
            <IAccordionDetails>{info}</IAccordionDetails>
          </IAccordion>
        ))}
        <IAccordion >
          <IAccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>{`${Object.keys(truth).length + 1}、投票结果`}</IAccordionSummary>
          <IAccordionDetails>
            <VoteResultStack direction="column" alignItems="center" spacing={12}>
              {voteResults.map((voteResult, index) => (
                <Stack key={`voteResult${index}`} direction="column" alignItems="center" spacing={2}>
                  <QuestionTitle>{questions[index]}</QuestionTitle>
                  <Stack direction="row" spacing={4}>
                    {Object.entries(voteResult).map(([character, votes]) => (
                      <Stack key={`voteResult${index}${character}`} direction="row" alignItems="center" spacing={2}>
                        <CharacterWrapper>
                          <CharacterAvatar src={getAssetUrl(characterList?.[character]?.avatar)} />
                          <CharacterName>{character}</CharacterName>
                        </CharacterWrapper>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <VoteBar votes={votes} />
                          <VoteText>{`${votes}票`}</VoteText>
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              ))}
            </VoteResultStack>
          </IAccordionDetails>
        </IAccordion>
        <BottomStack direction="column" alignItems="center" spacing={2}>
          <span>会员用户可以<TruthLink to={`${RouterPath.Truth}/${gameId}`} target="_blank">点击此处查看完整版真相手册</TruthLink></span>
          <span>如果您喜欢这个平台，可以通过<DonateLink onClick={onDonateButtonClicked}>订阅或打赏</DonateLink>的方式，一起助力制作更多好玩有趣的剧本~</span>
          <span>更多联动彩蛋和深入解析，请关注小红书——AOT剧本杀!</span>
        </BottomStack>
      </ContentWrapper>
      <CloseButtonBox onClick={onCloseButtonClicked}>
        <CloseIcon htmlColor="white" />
      </CloseButtonBox>
    </SummaryContainer>
  );
};

export default TruthSummary;
