import styled from '@emotion/styled';
import { FC } from 'react';
import { DialogProps, InputBase, Rating, Stack } from '@mui/material';
import { CommonDialog } from 'lib/common/CommonDialog';
import { getAssetUrl } from 'utils/utils';
import { ThemeButton } from 'lib/common/ThemeButton';

const ContentWrapper = styled(Stack)`
  max-width: 50vw;
  max-height: 60vh;
  margin-top: 12px;
`;

const StoryPoster = styled.img`
  width: 300px;
  height: 450px;
  border: 3px solid #D4D4D4;
  border-radius: 4px;
`;

const Subtitle = styled.div`
  font-size: 16px;
  color: #4A4A4A;
  text-align: center;
`;

const FeedbackInput = styled(InputBase)`
  height: 327px;
  width: 100%;
  color: black;
  align-items: flex-start;
  background-color: #EFEFEF;
  border-radius: 8px;
  border: 1px solid #9B9B9B;
  padding: 16px 14px 16px 14px;
  overflow: auto;
`;

export interface FeedbackDialogProps extends DialogProps {
  storyId: string;
  rating: number;
  onRatingChange: (event: React.SyntheticEvent, value: number | null) => void;
  feedbackInputRef?: React.RefObject<HTMLInputElement>;
  onSubmitButtonClicked: () => void;
}

export const FeedbackDialog: FC<FeedbackDialogProps> = function ({
  storyId,
  rating,
  onRatingChange,
  feedbackInputRef,
  onSubmitButtonClicked,
  ...props
}) {
  return (
    <CommonDialog title="剧本评测" {...props}>
      <ContentWrapper direction="row" alignItems="center" justifyContent="center" spacing={2}>
        <StoryPoster src={getAssetUrl(`/${storyId}/poster.webp`)} />
        <Stack direction="column" alignItems="center" spacing={1}>
          <Subtitle>您喜欢这个剧本吗？与其他玩家分享您的感受吧！</Subtitle>
          <div>
            <Rating precision={0.5} size="large" value={rating} onChange={onRatingChange} />
          </div>
          <FeedbackInput placeholder='注意不要剧透哦~' inputProps={{ ref: feedbackInputRef }} multiline />
          <ThemeButton onClick={onSubmitButtonClicked} disabled={rating === 0}>提交</ThemeButton>
        </Stack>
      </ContentWrapper>
    </CommonDialog>
  );
};

export default FeedbackDialog;
