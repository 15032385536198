import styled from '@emotion/styled';
import { FC, MouseEvent } from 'react';
import { Popover } from '@mui/material';
import { ThemeButton } from 'lib/common/ThemeButton';
import CommonAvatar from 'lib/common/CommonAvatar';
import { CommonUIProps } from 'utils/types';
import { getRoleName, parseAvatarUrl } from 'utils/utils';
import { UserRole } from 'openapi';

const UserAvatar = styled(CommonAvatar)`
  width: 60px;
  height: 60px;
  border: 2px solid #4a4a4a;
  cursor: pointer;
  box-shadow: 0 0px 6px 0 rgba(255, 255, 255, 0.1), 0 0px 20px 0 rgba(255, 255, 255, 0.10);
`;

const IPopover = styled(Popover)`
  .MuiPaper-root {
    background-color: transparent;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 20px;
  width: 200px;
  background-color: #efefef;
  border-radius: 20px;
  border: 5px solid #4a4a4a;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #4a4a4a;
  position: relative;

  ::before {
    content: '';
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #4a4a4a;
    position: absolute;
    top: -20px;
    right: 20px;
  }
`;

const PopoverAvatar = styled(CommonAvatar)`
  width: 80px;
  height: 80px;
  margin-top: 16px;
  background-color: white;
  border: 2px solid white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;

  &:hover {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: -2px;
      left: -2px;
      width: 80px;
      height: 80px;
      border-radius: 42px;
      background: rgba(255,255,255, 0.16);
    }
  }
`;

const PopoverNickname = styled.div`
  max-width: 150px;
  margin-top: 16px;
  font-size: 24px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;

  :hover {
    color: #5885AF;
  }
`;

const SubscriptionButton = styled.div`
  margin-top: 8px;
  font-size: 14px;
  color: black;
  cursor: pointer;

  :hover {
    color: #5885AF;
    text-decoration: underline;
  }
`;

// const GameTokenText= styled.div`
//   font-size: 14px;
//   margin-top: 8px;
// `;

const LogoutButton = styled(ThemeButton)`
  border: 2px solid #4a4a4a;
  background-color: black;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export interface AvatarPopoverProps extends CommonUIProps {
  avatarAnchorEl: HTMLImageElement | null,
  nickname?: string,
  avatar?: string,
  role?: UserRole,
  showPopover: boolean,
  handlePopoverOpen: (event: MouseEvent<HTMLElement>) => void,
  handlePopoverClose: () => void,
  onEditNicknameButtonClicked: () => void,
  onEditAvatarButtonClicked: () => void,
  onLogoutButtonClicked: () => void,
  onSubscriptionButtonClicked: () => void,
}

export const AvatarPopover: FC<AvatarPopoverProps> = function ({
  avatarAnchorEl,
  nickname,
  avatar,
  role,
  showPopover,
  handlePopoverOpen,
  handlePopoverClose,
  onEditNicknameButtonClicked,
  onEditAvatarButtonClicked,
  onLogoutButtonClicked,
  onSubscriptionButtonClicked,
  ...props
}) {

  // const { user } = useContext(UserContext);

  return (
    <>
      <UserAvatar src={parseAvatarUrl(avatar)} onClick={handlePopoverOpen} {...props} />
      <IPopover
        open={showPopover}
        onClose={handlePopoverClose}
        anchorEl={avatarAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <ContentWrapper>
          <PopoverAvatar src={parseAvatarUrl(avatar)} onClick={onEditAvatarButtonClicked} />
          <PopoverNickname onClick={onEditNicknameButtonClicked}>{nickname}</PopoverNickname>
          <SubscriptionButton onClick={onSubscriptionButtonClicked}>{`${getRoleName(role)}`}</SubscriptionButton>
          {/* <GameTokenText>剧本币：{user?.gameToken}</GameTokenText> */}
          <LogoutButton width="60px" color="secondary" onClick={onLogoutButtonClicked}>登出</LogoutButton>
        </ContentWrapper>
      </IPopover>

    </>
  );
};

export default AvatarPopover;
