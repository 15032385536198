import { useCallback, useContext, useMemo } from 'react';
import { PickClueDialog, PickClueDialogProps } from './PickClueDialog';
import { createBinder } from 'utils/utils';
import { GameContext, StoryContext, UserContext } from 'utils/contexts';
import { socket } from 'utils/socketConnector';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

export type PickClueDialogBinderManagedProps = 'clues' | 'currentCharacter' | 'onClueButtonClicked' | 'onPublishButtonClicked' | 'onClipButtonClicked' | 'clipedClues';

export interface PickClueDialogBinderProps extends Omit<PickClueDialogProps, PickClueDialogBinderManagedProps> {
  location?: string;
  allowToInvestigate: boolean;
  forbiddenClues?: string[];
  unavailableClues?: string[];
}

const usePickClueDialogBinder = (props: PickClueDialogBinderProps): PickClueDialogProps => {

  const { user } = useContext(UserContext);
  const gameAudit = useContext(GameContext);
  const currentCharacter = gameAudit?.players?.[user?.userId ?? '']?.character;
  const storyDetail = useContext(StoryContext);
  const { gameId } = useParams();

  const isAudience = useMemo(() => !Object.keys(gameAudit?.players ?? {}).includes(user?.userId ?? ''), [gameAudit?.players, user?.userId]);

  const onClueButtonClicked = useCallback((clue: string) => {
    if (gameAudit?.clueTurn !== storyDetail?.characterList[currentCharacter ?? ''].index) {
      enqueueSnackbar('目前还未轮到您调查', { variant: 'error' });
      return;
    }
    if (!isAudience
      && !gameAudit?.rooms?.[props.location ?? '']?.clues[clue].isFound
      && gameAudit?.stage.slice(0, -1) === 'INVESTIGATING'
      && props.allowToInvestigate
      && !props.forbiddenClues?.includes(clue)
      && !props.unavailableClues?.includes(clue)) {
      socket?.emit('playerPickClue', user?.userId, gameId, clue, props.location);
    }
  }, [currentCharacter, gameAudit?.clueTurn, gameAudit?.rooms, gameAudit?.stage, gameId, isAudience, props.allowToInvestigate, props.forbiddenClues, props.location, props.unavailableClues, storyDetail?.characterList, user?.userId]);

  const onPublishButtonClicked = useCallback((clue: string) => {
    if (!isAudience && gameAudit?.rooms?.[props.location ?? '']?.clues[clue].isFound === currentCharacter) {
      socket?.emit('playerPublishClue', user?.userId, gameId, clue, props.location);
    }
  }, [currentCharacter, gameAudit?.rooms, gameId, isAudience, props.location, user?.userId]);

  const onClipButtonClicked = useCallback((clue: string, clip?: string) => {
    socket?.emit('playerUpdateClipedClue', user?.userId, gameId, clue, clip);
  }, [gameId, user?.userId]);

  const managedProps: Pick<PickClueDialogProps, PickClueDialogBinderManagedProps> = {
    clues: gameAudit?.rooms?.[props.location ?? '']?.clues,
    currentCharacter,
    onClueButtonClicked,
    onPublishButtonClicked,
    onClipButtonClicked,
    clipedClues: gameAudit?.players?.[user?.userId ?? '']?.clipedClues,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const PickClueDialogBinder = createBinder(PickClueDialog, usePickClueDialogBinder);

export default PickClueDialogBinder;
