import styled from '@emotion/styled';
import { ChangeEvent, FC } from 'react';
import { DialogProps, Pagination, Stack } from '@mui/material';
import { Document, Page } from 'react-pdf';
import { getAssetUrl } from 'utils/utils';
import { ThemeButton } from 'lib/common/ThemeButton';
import Close from '@mui/icons-material/Close';
import { CommonIconButton } from 'lib/common/CommonIconButton';
import { FullDialog } from 'lib/common/FullDialog';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useMediaQuery } from 'react-responsive';

const ContentWrapper = styled(Stack)`
  width: 100vw;
  height: 100dvh;
`;

const ScriptContainer = styled.div`
  background-color: #7B583D;
  padding: 12px;
  border-radius: 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const ScriptHeader = styled(Stack)`
  padding-bottom: 12px;
  position: relative;
`;

const FinishReadingButton = styled(ThemeButton)`
  position: absolute;
  left: 0;
`;

const CloseButton = styled(CommonIconButton)`
  position: absolute;
  right: 0;
`;

const ScriptTitle = styled.div`
  height: 45px;
  color: white;
  font-size: 32px;
  font-family: 'STZhongSong';
  text-align: center;
  border-bottom: 1px solid #D4D4D4;
`;

const ScrollRegion = styled.div`
  max-height: 85dvh;
  border-radius: 12px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(240, 240, 240, 0.24);
    border-style: initial;
    border-radius: 8px;
  }
`;

const IPagination = styled(Pagination)`
  margin-top: 16px;
  background-color: black;
  border-radius: 8px;
  padding: 4px;
  & .MuiButtonBase-root {
    color: white;
  }
`;

export interface ScriptDialogProps extends DialogProps {
  currentCharacter?: string;
  scriptUrl?: string;
  currentPage: number;
  numPages?: number;
  showFinishReadingButton: boolean;
  onLoadSuccess: ({ numPages }: { numPages: number }) => void;
  onPageChange: (event: ChangeEvent<unknown>, value: number) => void;
  onFinishReadingButtonClicked: () => void;
  onLeftButtonClicked: () => void;
  onRightButtonClicked: () => void;
}

export const ScriptDialog: FC<ScriptDialogProps> = function ({
  currentCharacter,
  scriptUrl,
  currentPage,
  numPages,
  showFinishReadingButton,
  onLoadSuccess,
  onPageChange,
  onFinishReadingButtonClicked,
  onLeftButtonClicked,
  onRightButtonClicked,
  ...props
}) {

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })

  return (
    <FullDialog {...props}>
      <ContentWrapper direction="column" alignItems="center" justifyContent="center">
        <Stack direction="row" alignItems="center" spacing={4}>
          <CommonIconButton icon={<ChevronLeftIcon htmlColor={currentPage > 0 ? "white" : "gray"} fontSize="large" />} onClick={() => onLeftButtonClicked()} disabled={currentPage <= 0} />
          <ScriptContainer>
            <ScriptHeader direction="row" alignItems="center" justifyContent="center">
              {showFinishReadingButton ? <FinishReadingButton color="success" width="120px" onClick={onFinishReadingButtonClicked}>阅读完毕</FinishReadingButton> : null}
              <ScriptTitle>{`${currentCharacter}的故事`}</ScriptTitle>
              <CloseButton icon={<Close htmlColor="white" />} onClick={() => props.onClose?.({}, 'escapeKeyDown')} />
            </ScriptHeader>
            <ScrollRegion style={{ width: `calc(${isDesktopOrLaptop ? 60 : 80}vw + 17px)`}} >
              <Document className="pdf-container" file={getAssetUrl(scriptUrl)} loading={null} onContextMenu={(e) => e.preventDefault()} onLoadSuccess={onLoadSuccess}>
                <Page canvasBackground="transparent" loading={null} pageIndex={currentPage} width={window.innerWidth * (isDesktopOrLaptop ? 0.6 : 0.8)} renderAnnotationLayer={false} renderTextLayer={false} />
              </Document>
            </ScrollRegion>
          </ScriptContainer>
          <CommonIconButton icon={<ChevronRightIcon htmlColor={currentPage < (numPages ?? 0) - 1 ? "white" : "gray"} fontSize="large" />} onClick={() => onRightButtonClicked()} disabled={currentPage >= (numPages ?? 0) - 1} />
        </Stack>
        <IPagination count={numPages} color="primary" page={currentPage + 1} onChange={onPageChange} />
      </ContentWrapper>
    </FullDialog>
  );
};

export default ScriptDialog;
