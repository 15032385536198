import { PASSWORD_MINIMUM_LENGTH, NICKNAME_MAXIMUM_LENGTH } from './constants';

export const validateEmail = (emailString: string): boolean => {
  return /^\S+@\S+\.\S+$/.test(emailString);
};

export const validatePassword = (passwordString: string): boolean => {
  return passwordString.length >= PASSWORD_MINIMUM_LENGTH;
};

export const validateNickname = (userName: string | undefined): boolean => {
  return userName ? userName.length <= NICKNAME_MAXIMUM_LENGTH : false;
};

export const validateVerificationCode = (value: string) => {
  return /^\d+$/.test(value);
};