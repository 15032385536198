import styled from '@emotion/styled';
import { Slider, Stack, Tooltip, TooltipProps } from '@mui/material';
import CommonAvatar from 'lib/common/CommonAvatar';
import { CharacterRes, GameAudit } from 'openapi';
import { FC, ReactNode, useContext } from 'react';
import { PlayersContext } from 'utils/contexts';
import { UserInfo } from 'utils/localStorage';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl, parseAvatarUrl } from 'utils/utils';
import PlayerDisconnected from 'assets/player_disconnected.png';
import { GameButton } from 'lib/game/components/GameButton';
import { MusicNote, MusicOff } from '@mui/icons-material';
import { RotateWithDelayKeyframes, SlideInRightKeyframes } from 'utils/keyframes';
import ChatBubble from './components/ChatBubble';
import { ThemeButton } from 'lib/common/ThemeButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ClueCardIcon from 'assets/clue_card.png';
import { CommonIconButton } from 'lib/common/CommonIconButton';
import ConfirmDialog from 'lib/common/ConfirmDialog';
import CharacterVoice from './components/CharacterVoice';
import { SerializedStyles } from '@emotion/react';


const PageContainer = styled.div<{ animation?: SerializedStyles }>`
  height: 100dvh;
  width: 100vw;
  display: flex;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-image 0.5s ease-in-out;
  animation: ${(props) => props.animation};
`;

const PlayerBarIndicatorsStack = styled(Stack)`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 240px;
`;

const TurnIndicator = styled(ChevronLeftIcon)`
  font-size: 60px;
  color: #FFC107;
  margin-right: -10px;
`;

const ClueCardImage = styled.img`
  padding: 5px;
`;

const PrivateClues = styled(({ numberOfClues, ...props }: CommonUIProps & { numberOfClues: number }) => (
  <Stack direction="column" alignItems="center" {...props}>
    <ClueCardImage width={60} src={ClueCardIcon} />
    {numberOfClues}
  </Stack>
))`
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
  margin-top: 15px;
`;

const CurrentPlayerPrivateClues = styled(({ numberOfClues, onClick, ...props }: CommonUIProps & { numberOfClues: number }) => (
  <Stack direction="column" alignItems="center" {...props}>
    <CommonIconButton icon={<ClueCardImage width={80} src={ClueCardIcon} />} onClick={onClick} />
    <span>{`您有${numberOfClues}条线索未公开`}</span>
  </Stack>
))`
  width: 200px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin-top: 15px;
  pointer-events: auto;
`;


const CurrentPlayerContainer = styled(Stack)`
  position: absolute;
  left: 48px;
  top: 36px;
  pointer-events: none;
`;

const CurrentPlayerBar = styled(Stack)`
  height: 160px;
  width: 360px;
  border-radius: 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: relative;
  pointer-events: auto;
  overflow: hidden;
`;

const CurrentPlayerChangeCharacterWrapper = styled(Stack)`
  height: 160px;
  width: 720px;
  position: absolute;
  top: 0;
  animation: ${SlideInRightKeyframes} 1s ease-in-out forwards;
`;

const CurrentPlayerInfoWrapper = styled.div`
  display: flex;
  width: 240px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CurrentPlayerCharacterTitle = styled.div`
  color: #efefef;
  font-size: 24px;
  font-family: 'Microsoft Yahei';
`;

const CurrentPlayerCharacterName = styled.div`
  color: white;
  font-size: 48px;
  margin-top: 6px;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  border-bottom: 3px white solid;
  font-family: 黑体;
`;

const CurrentPlayerCharacterAvatar = styled(CommonAvatar)`
  width: 120px;
  height: 120px;
  margin: auto;
  margin-right: 20px;
`;

const PlayerListContainer = styled(Stack)`
  position: absolute;
  top: 22vh;
  left: 0px;
  pointer-events: none;
`;

const PlayerBarContainer = styled(Stack)`
  position: relative;
`;

const PlayerBar = styled(Stack)`
  height: 100px;
  width: 230px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: relative;
  pointer-events: auto;
  overflow: hidden;
`;

const ChangeCharacterWrapper = styled(Stack)`
  height: 100px;
  width: 460px;
  position: absolute;
  top: 0;
  animation: ${SlideInRightKeyframes} 1s ease-in-out forwards;
`;

const PlayerCharacterName = styled.div`
  width: 142px;
  color: white;
  font-size: 32px;
  text-align: center;
  font-family: 黑体;
`;

const PlayerCharacterAvatarWrapper = styled.div`
  width: 72px;
  height: 72px;
  margin-right: 16px;
  position: relative;
`;

const PlayerCharacterAvatar = styled(CommonAvatar)`
  width: 72px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 0;
`;

const PlayerDisconnectedCover = styled.img`
  width: 72px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 0;
`;

const CharacterTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip classes={{ popper: className }} {...props} />
))`
  & .MuiTooltip-tooltip {
      width: 300px;
      background-color: ${(props) => props.color};
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      font-family: 微软雅黑;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      padding: 0;
  }
`;

const TooltipPortriat = styled.img`
  margin-top: 20px;
  border-radius: 16px;
`;

const TooltipCharacterName = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  border-bottom: 3px white solid;
`;

const TooltipCharacterIntro = styled.div`
  width: 240px;
  margin-top: 12px;
  margin-bottom: 4px;
  font-size: 20px;
  text-indent: 2em;
  text-align: justify;
  line-height: 1.5em;
  color: #EFEFEF;
  font-family: 宋体;
`;

const TooltipPlayerWrapper = styled.div`
  width: 100%;
  height: 50px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  background-color: white;
`;

const TooltipPlayerAvatar = styled(CommonAvatar)`
  width: 36px;
  height: 36px;
  margin-right: 5px;
`;

const TooltipPlayerName = styled.div`
  max-width: 200px;
  color: #4A4A4A;
  font-size: 18px;
  margin-left: 5px;
  margin-top: -2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
`;

const GameButtonStack = styled(Stack)`
  position: absolute;
  right: 48px;
  bottom: 60px;
  display: flex;
`;

const VolumeControl = styled(Stack)`
  background-color: white;
  padding-left: 6px;
  padding-right: 16px;
  border-radius: 5px 0 0 5px;
  width: 180px;
  height: 36px;
  position: absolute;
  top: 24px;
  right: -168px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 1;
  transition: ease-in-out 0.4s;

  &:hover {
    right: 0px;
  }
`;

const MusicNoteIcon = styled(MusicNote)`
  animation-name: ${RotateWithDelayKeyframes};
  animation-duration: 4s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`;

const FooterText = styled.div`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 32px;
  color: white;
  font-size: 32px;
  text-align: center;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.4), 0 6px 20px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const GreetingStageText = styled.div`
  margin: auto;
  color: white;
  font-size: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.5;
`;

const IntroductionHelpList = styled.ul`
  text-align: left;
  font-size: 32px;
`;

const FooterTextButton = styled(ThemeButton)`
  height: 36px;
  margin-top: 4px;
`;

const TooltipCharacterNameWrapper = styled(Stack)`
  height: 40px;
  position: relative;
  margin-top: 12px;
`;

const CharacterVoiceButton = styled(CharacterVoice)`
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: -32px;
`;

export interface InvestigatePageProps extends CommonUIProps {
  user?: UserInfo;
  playerAudit: GameAudit['players'];
  backgroundUrl?: string;
  playerCharacterMap: Record<string, string>;
  characters?: Record<string, CharacterRes>;
  characterColors?: Record<string, string>;
  chatBubbleMessages: Record<string, { message?: string, delay?: number }>;
  showChatBubble: Record<string, boolean>;
  handleChatBubbleClose: (character: string) => void;
  storyLayout: ReactNode;
  volume: number;
  handleVolumeChange: (event: Event, value: number | number[]) => void;
  showVoteButton: boolean;
  hasVoted: boolean;
  voteDialog: ReactNode;
  onVoteButtonClicked: () => void;
  scriptDialog: ReactNode;
  onScriptButtonClicked: () => void;
  showMemoryButton: boolean;
  memoryDialog: ReactNode;
  onMemoryButtonClicked: () => void;
  showChoiceButton: boolean;
  choiceDialog: ReactNode;
  onChoiceButtonClicked: () => void;
  hasNewScripts: boolean;
  newMemories: string[];
  newChoices: string[];
  showTruthButton: boolean;
  animateTruthButton: boolean;
  onTruthButtonClicked: () => void;
  truthSummary: ReactNode;
  onEpilogueButtonClicked: () => void;
  isAudience: boolean;
  footerText?: string;
  introducingTurn?: string;
  onFinishIntroducingButtonClicked: () => void;
  clueTurn?: string;
  showSecretButton: boolean;
  secretDialog: ReactNode;
  newSecrets: string[];
  onSecretButtonClicked: () => void;
  privateCluesDialog: ReactNode;
  onPrivateCluesButtonClicked: () => void;
  showEndDiscussionButton: boolean;
  onEndDiscussionButtonClicked: () => void;
  showConfirmDialog: boolean;
  handleConfirmDialogClose: () => void;
  onConfirmEndDiscussion: () => void;
  toolbar: ReactNode;
  currentCharacter: string;
  numberOfPlayers: number;
  newCharacters: Record<string, string | undefined>;
  changeCharacters?: string[];
  feedbackDialog: ReactNode;
  clueClipDialog: ReactNode;
  guidanceDialog: ReactNode;
  onSkipClueTurnButtonClicked: () => void;
  backgroundAnimation?: SerializedStyles;
  onBackgroundAnimationEnd: () => void;
  hintsDialog: ReactNode;
  donateDialog: ReactNode;
}

export const InvestigatePage: FC<InvestigatePageProps> = function ({
  user,
  playerAudit,
  backgroundUrl,
  playerCharacterMap,
  characters,
  characterColors,
  chatBubbleMessages,
  showChatBubble,
  handleChatBubbleClose,
  storyLayout,
  volume,
  handleVolumeChange,
  showVoteButton,
  hasVoted,
  voteDialog,
  onVoteButtonClicked,
  scriptDialog,
  onScriptButtonClicked,
  showMemoryButton,
  memoryDialog,
  onMemoryButtonClicked,
  showChoiceButton,
  choiceDialog,
  onChoiceButtonClicked,
  hasNewScripts,
  newMemories,
  newChoices,
  showTruthButton,
  animateTruthButton,
  onTruthButtonClicked,
  truthSummary,
  onEpilogueButtonClicked,
  isAudience,
  footerText,
  introducingTurn,
  onFinishIntroducingButtonClicked,
  clueTurn,
  showSecretButton,
  secretDialog,
  newSecrets,
  onSecretButtonClicked,
  privateCluesDialog,
  onPrivateCluesButtonClicked,
  showEndDiscussionButton,
  onEndDiscussionButtonClicked,
  showConfirmDialog,
  handleConfirmDialogClose,
  onConfirmEndDiscussion,
  toolbar,
  currentCharacter,
  numberOfPlayers,
  newCharacters,
  changeCharacters,
  feedbackDialog,
  clueClipDialog,
  guidanceDialog,
  onSkipClueTurnButtonClicked,
  backgroundAnimation,
  onBackgroundAnimationEnd,
  hintsDialog,
  donateDialog,
  ...props
}) {

  const players = useContext(PlayersContext);

  return (
    <PageContainer style={{ backgroundImage: `url(${backgroundUrl})` }} animation={backgroundAnimation} onAnimationEnd={onBackgroundAnimationEnd} {...props}>
      {storyLayout}
      {!isAudience &&
        <CurrentPlayerContainer direction="row" alignItems="center">
          <CharacterTooltip placement="right-start" enterTouchDelay={0} leaveTouchDelay={3000} color={characterColors?.[playerCharacterMap[user?.userId ?? '']]} title={
            <>
              <TooltipPortriat src={getAssetUrl(characters?.[currentCharacter]?.portrait)} />
              <TooltipCharacterNameWrapper direction="row" alignItems="center">
                <TooltipCharacterName>{`${characters?.[currentCharacter]?.title}: ${playerCharacterMap[user?.userId ?? '']}`}</TooltipCharacterName>
                {characters?.[currentCharacter]?.voice && <CharacterVoiceButton voiceUrl={getAssetUrl(characters?.[currentCharacter].voice)} />}
              </TooltipCharacterNameWrapper>
              <TooltipCharacterIntro>{characters?.[currentCharacter]?.intro}</TooltipCharacterIntro>
              <TooltipPlayerWrapper>
                <TooltipPlayerAvatar src={parseAvatarUrl(user?.avatar)} />
                <TooltipPlayerName>{user?.nickname}</TooltipPlayerName>
              </TooltipPlayerWrapper>
            </>
          }>
            <CurrentPlayerBar direction="row" alignItems="center" style={{ backgroundColor: characterColors?.[playerCharacterMap[user?.userId ?? '']] + 'AA' }}>
              {changeCharacters?.includes(playerCharacterMap[user?.userId ?? '']) ? <CurrentPlayerChangeCharacterWrapper direction="row" alignItems="center" style={{ animationDelay: '1s' }}>
                <CurrentPlayerInfoWrapper>
                  <CurrentPlayerCharacterTitle>{characters?.[playerCharacterMap[user?.userId ?? '']]?.title}</CurrentPlayerCharacterTitle>
                  <CurrentPlayerCharacterName>{playerCharacterMap[user?.userId ?? '']}</CurrentPlayerCharacterName>
                </CurrentPlayerInfoWrapper>
                <CurrentPlayerCharacterAvatar src={getAssetUrl(characters?.[playerCharacterMap[user?.userId ?? '']]?.avatar)} />
                <CurrentPlayerInfoWrapper>
                  <CurrentPlayerCharacterTitle>{characters?.[currentCharacter]?.title}</CurrentPlayerCharacterTitle>
                  <CurrentPlayerCharacterName>{currentCharacter}</CurrentPlayerCharacterName>
                </CurrentPlayerInfoWrapper>
                <CurrentPlayerCharacterAvatar src={getAssetUrl(characters?.[currentCharacter]?.avatar)} />
              </CurrentPlayerChangeCharacterWrapper> : <>
                <CurrentPlayerInfoWrapper>
                  <CurrentPlayerCharacterTitle>{characters?.[currentCharacter]?.title}</CurrentPlayerCharacterTitle>
                  <CurrentPlayerCharacterName>{currentCharacter}</CurrentPlayerCharacterName>
                </CurrentPlayerInfoWrapper>
                <CurrentPlayerCharacterAvatar src={getAssetUrl(characters?.[currentCharacter]?.avatar)} />
              </>}
            </CurrentPlayerBar>
          </CharacterTooltip>
          <ChatBubble show={showChatBubble[playerCharacterMap[user?.userId ?? '']]} message={chatBubbleMessages[playerCharacterMap[user?.userId ?? '']]?.message} hideAfter={chatBubbleMessages[playerCharacterMap[user?.userId ?? '']]?.delay} onClose={() => handleChatBubbleClose(playerCharacterMap[user?.userId ?? ''])} />
          <PlayerBarIndicatorsStack direction="row" alignItems="center" style={{ left: "370px" }} >
            {(introducingTurn === playerCharacterMap[user?.userId ?? ''] || clueTurn === playerCharacterMap[user?.userId ?? '']) && <TurnIndicator />}
            {(playerAudit?.[user?.userId ?? ''].privateClues?.length ?? 0) > 0 && <CurrentPlayerPrivateClues numberOfClues={playerAudit?.[user?.userId ?? ''].privateClues?.length ?? 0} onClick={onPrivateCluesButtonClicked} />}
          </PlayerBarIndicatorsStack>
        </CurrentPlayerContainer>
      }
      <PlayerListContainer direction="column" spacing={4}>
        {Object.entries(characters ?? {}).map(([_character, characterInfo]) => {
          if (_character !== playerCharacterMap[user?.userId ?? ''] && characterInfo.index <= numberOfPlayers) {
            const playerId = Object.keys(playerCharacterMap).find((id) => playerCharacterMap[id] === _character);
            const character = newCharacters[_character] ?? _character;
            return (
              <PlayerBarContainer key={character} direction="row" alignItems="center">
                <CharacterTooltip placement={characterInfo.index > 3 ? "right-end" : "right-start"} enterTouchDelay={0} leaveTouchDelay={3000} color={characterColors?.[_character]} title={
                  <>
                    <TooltipPortriat src={getAssetUrl(characters?.[character].portrait)} />
                    <TooltipCharacterNameWrapper direction="row" alignItems="center">
                      <TooltipCharacterName>{`${characters?.[character].title}: ${character}`}</TooltipCharacterName>
                      {characters?.[character].voice && <CharacterVoiceButton voiceUrl={getAssetUrl(characters?.[character].voice)} />}
                    </TooltipCharacterNameWrapper>
                    <TooltipCharacterIntro>{characters?.[character].intro}</TooltipCharacterIntro>
                    <TooltipPlayerWrapper>
                      <TooltipPlayerAvatar src={parseAvatarUrl(players[playerId ?? '']?.avatar)} />
                      <TooltipPlayerName>{players[playerId ?? '']?.nickname}</TooltipPlayerName>
                    </TooltipPlayerWrapper>
                  </>
                }>
                  <PlayerBar direction="row" alignItems="center" style={{ backgroundColor: characterColors?.[_character] + 'CC' }}>
                    {changeCharacters?.includes(_character) ? <ChangeCharacterWrapper direction="row" alignItems="center" style={{ animationDelay: `${characterInfo.index * 0.25 + 1}s` }}>
                      <PlayerCharacterName>{_character}</PlayerCharacterName>
                      <PlayerCharacterAvatarWrapper>
                        <PlayerCharacterAvatar src={getAssetUrl(characterInfo.avatar)} />
                        {playerAudit?.[playerId ?? '']?.isOnline ? null : <PlayerDisconnectedCover src={PlayerDisconnected} />}
                      </PlayerCharacterAvatarWrapper>
                      <PlayerCharacterName>{character}</PlayerCharacterName>
                      <PlayerCharacterAvatarWrapper>
                        <PlayerCharacterAvatar src={getAssetUrl(characters?.[character].avatar)} />
                        {playerAudit?.[playerId ?? '']?.isOnline ? null : <PlayerDisconnectedCover src={PlayerDisconnected} />}
                      </PlayerCharacterAvatarWrapper>
                    </ChangeCharacterWrapper> : <>
                      <PlayerCharacterName>{character}</PlayerCharacterName>
                      <PlayerCharacterAvatarWrapper>
                        <PlayerCharacterAvatar src={getAssetUrl(characters?.[character].avatar)} />
                        {playerAudit?.[playerId ?? '']?.isOnline ? null : <PlayerDisconnectedCover src={PlayerDisconnected} />}
                      </PlayerCharacterAvatarWrapper>
                    </>}
                  </PlayerBar>
                </CharacterTooltip>
                <ChatBubble show={showChatBubble[_character]} message={chatBubbleMessages[_character]?.message} hideAfter={chatBubbleMessages[_character]?.delay} onClose={() => handleChatBubbleClose(_character)} />
                <PlayerBarIndicatorsStack direction="row" alignItems="center">
                  {(introducingTurn === character || clueTurn === _character) && <TurnIndicator />}
                  {(playerAudit?.[playerId ?? '']?.privateClues?.length ?? 0) > 0 && <PrivateClues numberOfClues={playerAudit?.[playerId ?? '']?.privateClues?.length ?? 0} />}
                </PlayerBarIndicatorsStack>
              </PlayerBarContainer>
            )
          }
          return null;
        })}
      </PlayerListContainer>
      <GameButtonStack direction="column" spacing={4}>
        {showTruthButton && !animateTruthButton && <GameButton buttonColor="#887BB0" handleClick={onEpilogueButtonClicked}>尾声</GameButton>}
        {showTruthButton && <GameButton buttonColor="#76B947" animate={animateTruthButton} handleClick={onTruthButtonClicked}>真相</GameButton>}
        {showVoteButton && <GameButton buttonColor="#d32f2f" handleClick={onVoteButtonClicked} disabled={hasVoted}>{hasVoted ? '已投票' : '投票'}</GameButton>}
        {showChoiceButton && <GameButton buttonColor="#5B8696" badge={newChoices.length} animate={newChoices.length > 0} handleClick={onChoiceButtonClicked}>抉择</GameButton>}
        {showSecretButton && <GameButton buttonColor="#9DA993" badge={newSecrets.length} animate={newSecrets.length > 0} handleClick={onSecretButtonClicked}>秘密</GameButton>}
        {showMemoryButton && <GameButton buttonColor="#39918C" badge={newMemories.length} animate={newMemories.length > 0} handleClick={onMemoryButtonClicked}>回忆</GameButton>}
        {!isAudience && <GameButton buttonColor="#7B583D" animate={hasNewScripts} handleClick={onScriptButtonClicked}>剧本</GameButton>}
      </GameButtonStack>
      <VolumeControl direction="row" alignItems="center" spacing={2}>
        {volume === 0 ? <MusicOff /> : <MusicNoteIcon />}
        <Slider color="info" value={volume} onChange={handleVolumeChange} />
      </VolumeControl>
      {voteDialog}
      {scriptDialog}
      {memoryDialog}
      {choiceDialog}
      {secretDialog}
      {privateCluesDialog}
      {truthSummary}
      {introducingTurn &&
        <GreetingStageText>
          {introducingTurn === playerCharacterMap[user?.userId ?? ''] ?
            <div>
              现在轮到你做简单的人物介绍(5分钟左右)
              <br />
              可以参考下列提示也可以自由发挥
              <br />
              但要注意自己的人设和立场
              <IntroductionHelpList>
                <li>你的基本情况</li>
                <li>你知道的的人物关系</li>
                <li>你出现在这里的原因</li>
              </IntroductionHelpList>
              <ThemeButton width="120px" onClick={onFinishIntroducingButtonClicked}>介绍完毕</ThemeButton>
            </div>
            : `现在轮到${introducingTurn}做自我介绍`}
        </GreetingStageText>}
      <FooterText>
        {footerText === '轮流调查阶段'
          ? <>
            轮流调查阶段 - 现在轮到<span style={{ fontWeight: 'bold', color: characterColors?.[clueTurn ?? ''] }}>{newCharacters[clueTurn ?? ''] ?? clueTurn}</span>进行调查
            {clueTurn === playerCharacterMap[user?.userId ?? ''] && <FooterTextButton width="100px" onClick={onSkipClueTurnButtonClicked}>跳过本轮</FooterTextButton>}
          </>
          : <>
            {footerText}
            {showEndDiscussionButton && <FooterTextButton width="100px" onClick={onEndDiscussionButtonClicked}>讨论结束</FooterTextButton>}
          </>
        }
      </FooterText>
      <ConfirmDialog open={showConfirmDialog} onClose={handleConfirmDialogClose} title="确定结束讨论吗？" content="结束讨论后，游戏会进入下一阶段。" onConfirmButtonClicked={onConfirmEndDiscussion} />
      {toolbar}
      {feedbackDialog}
      {clueClipDialog}
      {guidanceDialog}
      {hintsDialog}
      {donateDialog}
    </PageContainer>
  );
};

export default InvestigatePage;
