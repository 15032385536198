import { createContext } from "react";
import { UserInfo } from "./localStorage";
import { GameAudit, StoryRes, UserThumbnail } from "openapi";

interface UserContextType {
  user?: UserInfo,
  setUser: (_userInfo?: UserInfo) => void;
  refreshUser: () => void;
}

export const UserContext = createContext<UserContextType>({
  setUser: () => {},
  refreshUser: () => {}
})

export const GameContext = createContext<GameAudit | null>(null);

export const StoryContext = createContext<StoryRes | null>(null);

export const PlayersContext = createContext<Partial<Record<string, Partial<UserThumbnail>>>>({});

export const VolumeContext = createContext<number>(50);