import { useCallback, useContext, useMemo } from 'react';
import { ExploreDialog, ExploreDialogProps } from './YLW_ExploreDialog';
import { createBinder } from 'utils/utils';
import { GameContext, StoryContext, UserContext } from 'utils/contexts';
import { socket } from 'utils/socketConnector';
import { useParams } from 'react-router-dom';

export type ExploreDialogBinderManagedProps = 'clues' | 'currentCharacter' | 'onClueButtonClicked' | 'onPublishButtonClicked' | 'onClipButtonClicked' | 'clipedClues' | 'isSmallRoadFound';

export interface ExploreDialogBinderProps extends Omit<ExploreDialogProps, ExploreDialogBinderManagedProps> { }

const useExploreDialogBinder = (props: ExploreDialogBinderProps): ExploreDialogProps => {

  const { user } = useContext(UserContext);
  const gameAudit = useContext(GameContext);
  const currentCharacter = gameAudit?.players?.[user?.userId ?? '']?.character;
  const storyDetail = useContext(StoryContext);
  const { gameId } = useParams();

  const isAudience = useMemo(() => !Object.keys(gameAudit?.players ?? {}).includes(user?.userId ?? ''), [gameAudit?.players, user?.userId]);

  const isSmallRoadFound = useMemo(() => !!gameAudit?.rooms['时珞庄外']?.clues['clue23'].isFound, [gameAudit?.rooms]);

  const onClueButtonClicked = useCallback((clue: string) => {
    if (!isAudience
      && !gameAudit?.rooms?.['时珞庄外']?.clues[clue].isFound
      && gameAudit?.clueTurn === storyDetail?.characterList[currentCharacter ?? ''].index
      && gameAudit?.stage.slice(0, -1) === 'INVESTIGATING') {
      socket?.emit('playerPickClue', user?.userId, gameId, clue, '时珞庄外');
    }
  }, [currentCharacter, gameAudit?.clueTurn, gameAudit?.rooms, gameAudit?.stage, gameId, isAudience, storyDetail?.characterList, user?.userId]);

  const onPublishButtonClicked = useCallback((clue: string) => {
    if (!isAudience && gameAudit?.rooms?.['时珞庄外']?.clues[clue].isFound === currentCharacter) {
      socket?.emit('playerPublishClue', user?.userId, gameId, clue, '时珞庄外');
    }
  }, [currentCharacter, gameAudit?.rooms, gameId, isAudience, user?.userId]);

  const onClipButtonClicked = useCallback((clue: string, clip?: string) => {
    socket?.emit('playerUpdateClipedClue', user?.userId, gameId, clue, clip);
  }, [gameId, user?.userId]);

  const managedProps: Pick<ExploreDialogProps, ExploreDialogBinderManagedProps> = {
    clues: gameAudit?.rooms?.['时珞庄外']?.clues,
    currentCharacter,
    onClueButtonClicked,
    onPublishButtonClicked,
    onClipButtonClicked,
    clipedClues: gameAudit?.players?.[user?.userId ?? '']?.clipedClues,
    isSmallRoadFound,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const ExploreDialogBinder = createBinder(ExploreDialog, useExploreDialogBinder);

export default ExploreDialogBinder;
