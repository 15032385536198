/* eslint-disable react/jsx-pascal-case */
import styled from '@emotion/styled';
import { LocationButton } from 'lib/game/components/LocationButton';
import GameLock from 'lib/game/components/GameLock';
import { FC, LegacyRef, ReactNode, useState } from 'react';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';
import { Falling0Keyframes, Falling1Keyframes, Falling2Keyframes, Falling3Keyframes, Falling4Keyframes } from 'utils/keyframes';
import ConfirmDialog from 'lib/common/ConfirmDialog';
import SSQW_StageTransition from './SSQW_StageTransition';
import { css } from '@emotion/react';
import { SSQW_CharacterAbbr, SSQW_CharacterColors } from './SSQW_StaticData';

const Map = styled.div`
  width: 60vw;
  height: 100dvh;
  position: absolute;
  top: 0;
  left: 20vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
    margin-top: 12vh;
    margin-bottom: 12vh;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d4aa70;
    border-radius: 100px;
    border: 4px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #ac926d;
  }
`;

const Floor3 = styled.div`
  margin-top: 12vh;
  margin-right: 130px;
  width: 75%;
  position: relative;
`;

const Floor3Map = styled.img`
  width: 100%;
`;

const Floor2 = styled.div`
  margin-top: 60px;
  margin-right: 130px;
  width: 75%;
  position: relative;
`;

const Floor2Map = styled.img`
  width: 100%;
`;

const Floor1 = styled.div`
  margin-top: 60px;
  margin-right: 130px;
  width: calc(100% - 130px);
  position: relative;
  margin-bottom: 12vh;
`;

const Floor1Map = styled.img`
  width: 100%;
`;

const GameTime = styled.div`
  position: absolute;
  top: 40px;
  right: 60px;
  text-align: center;
  font-family: 'STZhongSong';
  font-size: 40px;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  color: white;
`;

const MapInfoWrapper = styled.div`
  position: absolute;
  top: 15vh;
  right: 8vh;
  color: white;
  font-size: 2vh;
  width: 320px;
  font-family: 'STZhongSong';
  text-align: justify;
`;

const MapInfo = styled.img`
  width: 320px;
  margin-left: 10px;
  margin-bottom: 10px;
  border: 3px solid white;
  border-radius: 8px;
`;

const LQHRoom = styled(LocationButton)`
  width: 25.4%;
  height: 25.6%;
  right: 1.2%;
  top: 2.5%;
`;

const LockedRoom = styled(LocationButton)`
  width: 16.2%;
  height: 25.6%;
  left: 23.2%;
  top: 2.5%;
`;

const LockedAuditorium = styled(LocationButton)`
  width: 26.2%;
  height: 25.6%;
  right: 1.2%;
  bottom: 21.4%;
`;

const ShowRoom = styled(LocationButton)`
  width: 34.4%;
  height: 25.6%;
  right: 28.1%;
  bottom: 21.4%;
`;

const LockedLibrary = styled(LocationButton)`
  width: 26.2%;
  height: 25.6%;
  left: 9.7%;
  bottom: 21.2%;
`;

const MainBedroom = styled(LocationButton)`
  width: 20.9%;
  height: 50.6%;
  right: 1.2%;
  top: 2.5%;
`;

const UtilityRoom = styled(LocationButton)`
  width: 25.0%;
  height: 17.3%;
  right: 22.8%;
  top: 2.5%;
`;

const XJRoom = styled(LocationButton)`
  width: 16.4%;
  height: 27.2%;
  left: 23.1%;
  top: 2.5%;
`;

const StudyRoom = styled(LocationButton)`
  width: 34.3%;
  height: 27.7%;
  right: 1.2%;
  bottom: 15.6%;
`;

const LMSRoom = styled(LocationButton)`
  width: 34.3%;
  height: 27.6%;
  left: 9.7%;
  bottom: 15.4%;
`;

const DinningRoom = styled(LocationButton)`
  width: 21.3%;
  height: 31.2%;
  right: 1.0%;
  top: 1.4%;
`;

const Storage = styled(LocationButton)`
  width: 17.1%;
  height: 10.3%;
  right: 22.9%;
  top: 1.4%;
`;

const MaidRoom = styled(LocationButton)`
  width: 13.7%;
  height: 17.0%;
  left: 35.5%;
  top: 1.4%;
`;

const Kitchen = styled(LocationButton)`
  width: 21.3%;
  height: 16.8%;
  right: 1.0%;
  top: 33.5%;
`;

const FootmanRoom = styled(LocationButton)`
  width: 21.4%;
  height: 17.1%;
  left: 24.1%;
  top: 33.5%;
`;

const Shrub = styled(LocationButton)`
  width: 6.2%;
  height: 8.7%;
  left: 46.0%;
  top: 52.0%;
`;

const GardenEast = styled(LocationButton)`
  width: 6.2%;
  height: 8.7%;
  left: 51.2%;
  top: 68.0%;
`;

const PoolNearby = styled(LocationButton)`
  width: 4.2%;
  height: 8.7%;
  right: 25.2%;
  top: 57.3%;
`;

const RoseRoom = styled(LocationButton)`
  width: 13.7%;
  height: 10.2%;
  right: 10.5%;
  top: 56.4%;
`;

const VotesContainer = styled.div`
  position: absolute;
  top: -35px;
  right: 0;
  z-index: 1;
`;

const VoteBadge = styled.div<{ index: number, color?: string }>`
  width: 36px;
  height: 48px;
  border-radius: 3px;
  padding-top: 5px;
  font-family: 'Mircrosoft Yahei';
  font-size: 24px;
  color: white;
  text-align: center;
  position: absolute;
  top: 0px;
  right: ${(props) => `${props.index * 20}px`};
  background-color: ${(props) => props.color};
  z-index: ${(props) => -1 - props.index};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  &:before {
    background-color: inherit;
    content: '';
    height: 26px;
    width: 26px;
    position: absolute;
    top: 33px;
    left: 5.25px;
    border-radius: 3px;
    transform: rotate(45deg);
    z-index: inherit;
  }
`;

const Flower = styled.img<{ animation: number }>`
  position: absolute;
  cursor: pointer;
  animation-name: ${(props) => {
    switch (props.animation) {
      case 0:
        return css`${Falling0Keyframes}`;
      case 1:
        return css`${Falling1Keyframes}`;
      case 2:
        return css`${Falling2Keyframes}`;
      case 3:
        return css`${Falling3Keyframes}`;
      case 4:
        return css`${Falling4Keyframes}`;
      default:
        return null
    }
  }};
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  transition: opacity 0.8s ease-in-out;
`;

interface RoserFlowerProps extends CommonUIProps {
  width: number;
  animation: number;
  animationDuration: number;
  handleTransitionEnd: () => void;
}

export const RoseFlower: FC<RoserFlowerProps> = function ({
  width,
  animation,
  animationDuration,
  handleTransitionEnd,
  ...props
}) {

  const [fade, setFade] = useState<boolean>(false);

  return (
    <Flower src={getAssetUrl('/SSQW/rose.webp')}
      style={{ width, height: width, opacity: fade ? '0' : '1', animationDuration: `${animationDuration}s` }}
      animation={animation}
      draggable={false}
      onClick={() => setFade(true)}
      onTransitionEnd={handleTransitionEnd}
      onAnimationEnd={handleTransitionEnd}
      {...props}
    />
  )
}

export interface SSQW_InvestigatePageProps extends CommonUIProps {
  showMap: boolean;
  gameTime: string;
  numRemainingClues: Record<string, number>;
  locationHasNewClue: string[];
  onLocationButtonClicked: (location: string) => void;
  pickClueDialog: ReactNode;
  showPickClueDialog: boolean;
  setShowPickClueDialog: (_: boolean) => void;
  lockWobbles: Record<string, boolean>;
  lockedRooms: Record<string, boolean>;
  onLockAnimationEnd: (location: string) => void;
  showRoseRoom: boolean;
  showConfirmDialog: boolean;
  handleConfirmDialogClose: () => void;
  onConfirmEnterRoseRoom: () => void;
  showStageTransition: boolean;
  onStageTransitionEnd: (_: Event, reason: "backdropClick" | "escapeKeyDown") => void;
  roseRoomEntered: boolean;
  flower: ReactNode;
  bgm2MusicRef: LegacyRef<HTMLAudioElement>;
  onBgm2MusicEnded: () => void;
  roseRoomVoted: string[];
}

export const SSQW_InvestigatePage: FC<SSQW_InvestigatePageProps> = function ({
  showMap,
  gameTime,
  numRemainingClues,
  locationHasNewClue,
  onLocationButtonClicked,
  pickClueDialog,
  showPickClueDialog,
  setShowPickClueDialog,
  lockWobbles,
  lockedRooms,
  onLockAnimationEnd,
  showRoseRoom,
  showConfirmDialog,
  handleConfirmDialogClose,
  onConfirmEnterRoseRoom,
  showStageTransition,
  onStageTransitionEnd,
  roseRoomEntered,
  flower,
  bgm2MusicRef,
  onBgm2MusicEnded,
  roseRoomVoted,
  ...props
}) {
  return (
    <div {...props}>
      {showMap &&
        <>
          <Map>
            <Floor3>
              <Floor3Map src={getAssetUrl('/SSQW/floor3.webp')} />
              <LQHRoom badge={numRemainingClues['客房（罗千海）']} badgeStyle={{ top: '15%', left: '45%' }} hasNewClue={locationHasNewClue.includes('客房（罗千海）')} onClick={() => onLocationButtonClicked('客房（罗千海）')} />
              <LockedRoom badge={numRemainingClues['上锁房间']} badgeStyle={{ bottom: '5px' }} hasNewClue={locationHasNewClue.includes('上锁房间')} onClick={() => onLocationButtonClicked('上锁房间')} isLocked={lockedRooms['上锁房间']}>
                {lockedRooms['上锁房间'] && <GameLock wobble={lockWobbles['上锁房间']} style={{ left: '40%', bottom: '5px' }} onAnimationEnd={() => onLockAnimationEnd('上锁房间')} />}
              </LockedRoom>
              <LockedAuditorium badge={numRemainingClues['上锁礼堂']} badgeStyle={{ bottom: '5px' }} hasNewClue={locationHasNewClue.includes('上锁礼堂')} onClick={() => onLocationButtonClicked('上锁礼堂')} isLocked={lockedRooms['上锁礼堂']}>
                {lockedRooms['上锁礼堂'] && <GameLock wobble={lockWobbles['上锁礼堂']} style={{ left: '29%', top: '2px' }} onAnimationEnd={() => onLockAnimationEnd('上锁礼堂')} />}
              </LockedAuditorium>
              <ShowRoom badge={numRemainingClues['陈列室']} badgeStyle={{ top: '35%', left: '45%' }} hasNewClue={locationHasNewClue.includes('陈列室')} onClick={() => onLocationButtonClicked('陈列室')} />
              <LockedLibrary badge={numRemainingClues['上锁书库']} badgeStyle={{ bottom: '5px' }} hasNewClue={locationHasNewClue.includes('上锁书库')} onClick={() => onLocationButtonClicked('上锁书库')} isLocked={lockedRooms['上锁书库']}>
                {lockedRooms['上锁书库'] && <GameLock wobble={lockWobbles['上锁书库']} style={{ right: '28%', top: '2px' }} onAnimationEnd={() => onLockAnimationEnd('上锁书库')} />}
              </LockedLibrary>
            </Floor3>
            <Floor2>
              <Floor2Map src={getAssetUrl('/SSQW/floor2.webp')} />
              <MainBedroom badge={numRemainingClues['主卧室']} badgeStyle={{ bottom: '25%' }} hasNewClue={locationHasNewClue.includes('主卧室')} onClick={() => onLocationButtonClicked('主卧室')} />
              <UtilityRoom badge={numRemainingClues['杂物房']} badgeStyle={{ bottom: '32%', left: '55%' }} hasNewClue={locationHasNewClue.includes('杂物房')} onClick={() => onLocationButtonClicked('杂物房')} />
              <XJRoom badge={numRemainingClues['客房（小霁）']} badgeStyle={{ top: '15%', left: '65%' }} hasNewClue={locationHasNewClue.includes('客房（小霁）')} onClick={() => onLocationButtonClicked('客房（小霁）')} />
              <StudyRoom badge={numRemainingClues['书房']} badgeStyle={{ bottom: '5%' }} hasNewClue={locationHasNewClue.includes('书房')} onClick={() => onLocationButtonClicked('书房')} />
              <LMSRoom badge={numRemainingClues['客房（罗明纱）']} badgeStyle={{ top: '16%', left: '35%' }} hasNewClue={locationHasNewClue.includes('客房（罗明纱）')} onClick={() => onLocationButtonClicked('客房（罗明纱）')} />
            </Floor2>
            <Floor1>
              <Floor1Map src={getAssetUrl(showRoseRoom ? '/SSQW/floor1_after.webp' : '/SSQW/floor1.webp')} />
              <DinningRoom badge={numRemainingClues['餐厅']} badgeStyle={{ bottom: '25%' }} hasNewClue={locationHasNewClue.includes('餐厅')} onClick={() => onLocationButtonClicked('餐厅')} />
              <Storage badge={numRemainingClues['仓库']} badgeStyle={{ bottom: '30%', left: '50%' }} hasNewClue={locationHasNewClue.includes('仓库')} onClick={() => onLocationButtonClicked('仓库')} />
              <MaidRoom badge={numRemainingClues['女仆房']} badgeStyle={{ bottom: '5%' }} hasNewClue={locationHasNewClue.includes('女仆房')} onClick={() => onLocationButtonClicked('女仆房')} />
              <Kitchen badge={numRemainingClues['厨房']} badgeStyle={{ bottom: '5%' }} hasNewClue={locationHasNewClue.includes('厨房')} onClick={() => onLocationButtonClicked('厨房')} />
              <FootmanRoom badge={numRemainingClues['男仆房']} badgeStyle={{ bottom: '5%' }} hasNewClue={locationHasNewClue.includes('男仆房')} onClick={() => onLocationButtonClicked('男仆房')} />

              {lockedRooms['蔷薇馆外'] ? <>
                <GameLock wobble={lockWobbles['蔷薇馆外']} style={{ left: '22.8%', top: '14%' }} />
                <GameLock wobble={lockWobbles['蔷薇馆外']} style={{ right: '37.0%', top: '55.5%' }} />
                <GameLock wobble={lockWobbles['蔷薇馆外']} style={{ right: '4.9%', top: '49%' }} />
              </> : <>
                <Shrub badge={numRemainingClues['树丛']} badgeStyle={{ top: '5px' }} hasNewClue={locationHasNewClue.includes('树丛')} onClick={() => onLocationButtonClicked('树丛')} />
                <GardenEast badge={numRemainingClues['花园东']} badgeStyle={{ bottom: '20px' }} hasNewClue={locationHasNewClue.includes('花园东')} onClick={() => onLocationButtonClicked('花园东')} />
                <PoolNearby badge={numRemainingClues['水池旁']} badgeStyle={{ bottom: '20px' }} hasNewClue={locationHasNewClue.includes('水池旁')} onClick={() => onLocationButtonClicked('水池旁')} />
                {showRoseRoom && !roseRoomEntered && <RoseRoom onClick={() => onLocationButtonClicked('蔷薇房间')}>
                  {lockedRooms['蔷薇房间'] && <GameLock wobble={lockWobbles['蔷薇房间']} style={{ bottom: '25%' }} onAnimationEnd={() => onLockAnimationEnd('蔷薇房间')} />}
                  <VotesContainer>
                    {roseRoomVoted && roseRoomVoted.map((player, index) =>
                      <VoteBadge key={player} index={index} color={SSQW_CharacterColors[player]}>
                        {SSQW_CharacterAbbr[player]}
                      </VoteBadge>
                    )}
                  </VotesContainer>
                </RoseRoom>}
              </>}
            </Floor1>
          </Map>
          <GameTime>
            蔷薇馆时间<br />
            {gameTime}
          </GameTime>
          <MapInfoWrapper>
            <MapInfo src={getAssetUrl('/SSQW/mansion.webp')} />
            注：“蔷薇馆”没有通电，需要蜡烛照明，因此每个房间都有蜡烛和洋火。
          </MapInfoWrapper>
        </>
      }
      {pickClueDialog}
      <SSQW_StageTransition open={showStageTransition} onClose={onStageTransitionEnd} />
      <ConfirmDialog open={showConfirmDialog} onClose={handleConfirmDialogClose} title="确定进入水池门后隐藏的地下通道吗？" content="之后发生的事也许无法预料。" onConfirmButtonClicked={onConfirmEnterRoseRoom} />
      {flower}
      <audio ref={bgm2MusicRef} src={getAssetUrl('/SSQW/bgm2.mp3')} onEnded={onBgm2MusicEnded} />
    </div>
  );
};

export default SSQW_InvestigatePage;
