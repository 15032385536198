import { useState } from 'react';
import { GuidanceDialog, GuidanceDialogProps } from './GuidanceDialog';
import { createBinder } from 'utils/utils';

export type GuidanceDialogBinderManagedProps = 'activeTab' | 'onTabChange';
 
export interface GuidanceDialogBinderProps extends Omit<GuidanceDialogProps, GuidanceDialogBinderManagedProps> { }

const useGuidanceDialogBinder = (props: GuidanceDialogBinderProps): GuidanceDialogProps => {

  const [activeTab, setActiveTab] = useState<number>(0);


  const managedProps: Pick<GuidanceDialogProps, GuidanceDialogBinderManagedProps> = {
    activeTab,
    onTabChange: (_, newValue: number) => setActiveTab(newValue),
  }
    return {
      ...props,
      ...managedProps,
    };
  };

  export const GuidanceDialogBinder = createBinder(GuidanceDialog, useGuidanceDialogBinder);

  export default GuidanceDialogBinder;
