import { useState, useCallback, useContext, useMemo } from 'react';
import { ClueClipDialog, ClueClipDialogProps } from './ClueClipDialog';
import { createBinder } from 'utils/utils';
import { GameContext, UserContext } from 'utils/contexts';
import { useParams } from 'react-router-dom';
import { socket } from 'utils/socketConnector';

export const ClipColor: Record<string, string> = {
  clip1: '#ff5a5f',
  clip2: '#0077b6',
  clip3: '#6a994e',
}

export const Clips = Object.keys(ClipColor);

export type ClueClipDialogBinderManagedProps = 'clueOrientation' | 'activeClip' | 'onClipClicked' | 'clues' | 'onRemoveClipedClueButtonClicked';
 
export interface ClueClipDialogBinderProps extends Omit<ClueClipDialogProps, ClueClipDialogBinderManagedProps> { }

const useClueClipDialogBinder = (props: ClueClipDialogBinderProps): ClueClipDialogProps => {

  const { user } = useContext(UserContext);
  const gameAudit = useContext(GameContext);
  const { gameId } = useParams();
  
  const [activeClip, setActiveClip] = useState<string>('clip1');

  const clues = useMemo(() => {
    const userClipedClues = gameAudit?.players?.[user?.userId ?? '']?.clipedClues ?? {};
    const activeClipedClues = Object.keys(userClipedClues).filter((clue) => userClipedClues[clue] === activeClip);
    if (activeClipedClues && activeClipedClues.length > 0) {
      return Object.fromEntries(activeClipedClues.map((clipedClue) => [
        clipedClue,
        (() => {
          if (clipedClue.slice(0, 4) === 'clue') {
            const clueLocation = Object.keys(gameAudit?.rooms ?? {}).find((room) => Object.keys(gameAudit?.rooms[room]?.clues ?? {}).includes(clipedClue));
            return { location: clueLocation, ...gameAudit?.rooms[clueLocation ?? '']?.clues?.[clipedClue] };
          } else {
            return gameAudit?.secrets?.[clipedClue]
          }
        })()
      ]))
    }
  },[activeClip, gameAudit?.players, gameAudit?.rooms, gameAudit?.secrets, user?.userId]);

  const onRemoveClipedClueButtonClicked = useCallback((clue: string) => {
    socket?.emit('playerUpdateClipedClue', user?.userId, gameId, clue);
  }, [gameId, user?.userId]);


  const managedProps: Pick<ClueClipDialogProps, ClueClipDialogBinderManagedProps> = {
    clueOrientation: ['NDYY'].includes(gameAudit?.storyId ?? '') ? 'horizontal' : 'vertical',
    activeClip,
    onClipClicked: (clip: string) => setActiveClip(clip),
    clues,
    onRemoveClipedClueButtonClicked,
  }
    return {
      ...props,
      ...managedProps,
    };
  };

  export const ClueClipDialogBinder = createBinder(ClueClipDialog, useClueClipDialogBinder);

  export default ClueClipDialogBinder;
