import styled from '@emotion/styled';
import { FC } from 'react';
import { DialogProps, Stack } from '@mui/material';
import { CommonDialog } from 'lib/common/CommonDialog';

const ContentWrapper = styled(Stack)`
  width: 640px;
  display: flex;
  justify-content: center;
  padding: 16px;
`;

const Ttile = styled.div`
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 24px;
`;

const MainText = styled.div`
  font-size: 24px;
  line-height: 1.5;
  text-indent: 2rem;
`;

export interface WelcomeDialogProps extends DialogProps {

}

export const WelcomeDialog: FC<WelcomeDialogProps> = function ({
  ...props
}) {
  return (
    <CommonDialog {...props}>
      <ContentWrapper direction="column" alignItems="center">
        <Ttile>欢迎来到时空侦探局！</Ttile>
        <MainText>
          <p>本网站是一个免费的剧本杀工具平台，目前收录了许多部《豪门惊情系列》剧本，供您和您的好友随时随地游玩。</p>
          <p>您可以通过任何在线语音工具进行交流，或者在线下使用平板电脑面对面游玩也是可以的，不过暂时不支持手机。您也可以点击页面最下方的按钮也可以加入我们的DC频道，以及官方小红书和微信群！</p>
          <p>如果您是第一次游玩该系列的作品，强烈建议从《孽岛疑云》和《岳麓山下》开始。如果在不熟悉的情况下冒然尝试难度过高的剧本，可能会严重影响体验！</p>
          <p>在召集好小伙伴之后，点击主页上的“新的案件”按钮就可以创建自己感兴趣的案件，随后把案件编号发给小伙伴们，等大家都加入房间后就可以开始游戏了。</p>
          <p>祝您有一场愉快的推理之旅！</p>
        </MainText>
      </ContentWrapper>
    </CommonDialog>
  );
};

export default WelcomeDialog;
