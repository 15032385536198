import { io, Socket } from 'socket.io-client';

class SocketConnector {
  protected socket: Socket | undefined;

  protected connecting = false;

  async connect() {
    if (this.socket && !this.connecting) {
      return this.socket;
    }

    const socket = this.socket || (this.socket = io(process.env['REACT_APP_SOCKET_SERVER_URL'] ?? ''));

    this.connecting = true;
    try {
      const event: Event = await ((): Promise<Event> =>
        new Promise((resolve, reject) => {
          socket.once('connect', () => {
            resolve(new Event('connect'));
          });
          socket.once('connect_error', (err) => {
            reject(err);
          });
          socket.once('disconnect', (reason) => {
            reject(reason);
          });
        }))();
      switch (event.type) {
        case 'connect': {
          return socket;
        }

        case 'connect_error': {
          socket.close();
          this.socket = undefined;
          const errorEvent = event as ErrorEvent;
          throw errorEvent.error || new Error(errorEvent.message);
        }

        case 'disconnect':
        default: {
          this.socket = undefined;
          throw new Error('Socket closed unexpectedly');
        }
      }
    } catch (error: unknown) {
      console.error(error);
    } finally {
      // Make sure `connecting` is always set to false when method finishes.
      this.connecting = false;
    }
  }

  disconnect(): void {
    if (this.socket) {
      this.socket.close();
      this.socket = undefined;
    }
  }

  getSocket(): Socket | undefined {
    return this.socket;
  }

  isConnected(): boolean {
    return !!this.socket && !this.connecting;
  }
}

const socketConnector = new SocketConnector();
socketConnector.connect();

export const socket = socketConnector.getSocket();
