import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import RouterPath from './RouterPath';
import HomePageBinder from 'lib/home/HomePageBinder';
import GamePageBinder from 'lib/game/GamePageBinder';
import RequireAuthBinder from 'binders/RequireAuthBinder';
import AdminPageBinder from 'lib/admin/AdminPageBinder';
import PrivacyPolicyPage from 'lib/home/PrivacyPolicyPage';
import TermsConditionsPage from 'lib/home/TermsConditonsPage';
import NotFoundPage from 'lib/home/NotFoundPage';
import RecruitPage from 'lib/home/RecruitPage';
import FAQPage from 'lib/home/FAQPage';
import TruthPageBinder from 'lib/game/TruthPageBinder';

export interface AppRouterProps { }

export const AppRouter: FC<AppRouterProps> = function () {
  return (
    <Routes>
      <Route path={RouterPath.Home} element={<HomePageBinder />} />
      <Route path={`${RouterPath.Game}/:gameId`} element={
        <RequireAuthBinder>
          <GamePageBinder />
        </RequireAuthBinder>
      } />
      <Route path={RouterPath.Admin} element={<AdminPageBinder />} />
      <Route path={RouterPath.PrivacyPolicy} element={<PrivacyPolicyPage />} />
      <Route path={RouterPath.TermsAndCondtions} element={<TermsConditionsPage />} />
      <Route path={RouterPath.Recruit} element={<RecruitPage />} />
      <Route path={RouterPath.FAQ} element={<FAQPage />} />
      <Route path={`${RouterPath.Truth}/:gameId`} element={
        <RequireAuthBinder>
          <TruthPageBinder />
        </RequireAuthBinder>
      } />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRouter;