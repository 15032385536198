import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { createBinder } from "utils/utils";
import { GamePage, GamePageProps } from "./GamePage";
import { useParams } from "react-router-dom";
import { socket } from "utils/socketConnector";
import { GameAudit, StoryRes, UserThumbnail } from "openapi";
import LoadingPageBinder from "./LoadingPageBinder";
import WaitingPageBinder from "./WaitingPageBinder";
import clientApi from "utils/clientApi";
import CharacterPageBinder from "./CharacterPageBinder";
import { GameContext, PlayersContext, StoryContext, UserContext } from "utils/contexts";
import InvestigatePageBinder from "./InvestigatePageBinder";
import EpiloguePageBinder from "./EpiloguePageBinder";
import CalibrationPage from "./CalibrationPage";
import DisconnectDialog from "./components/DisconnectDialog";
import useWindowDimensions from "utils/hooks";


export type GamePageBinderManagedProps = 'user' | 'gameContent' | 'disconnectDialog';

export interface GamePageBinderProps extends Omit<GamePageProps, GamePageBinderManagedProps> { }

const useGamePageBinder = (props: GamePageBinderProps): GamePageProps => {

  const { width } = useWindowDimensions();

  const { user, setUser } = useContext(UserContext);
  const { gameId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCalibrated, setIsCalibrated] = useState<boolean>(2048 < width && width < 2850);
  const [showEpiloguePage, setShowEpiloguePage] = useState<boolean>(false);
  const [gameAudit, setGameAudit] = useState<GameAudit | null>(null);
  const [storyDetail, setStoryDetail] = useState<StoryRes | null>(null);
  const [errorMsg, setErrorMsg] = useState<string>();
  const [players, setPlayers] = useState<Record<string, Partial<UserThumbnail>>>(user ? {
    [user.userId]: { nickname: user.nickname, avatar: user.avatar }
  } : {});

  const [showDisconnectDialog, setShowDisconnectDialog] = useState<boolean>(false);

  //Debug
  // useEffect(() => {
  //   console.log(gameAudit);
  // }
  //   , [gameAudit]);

  useEffect(() => {
    return () => {
      socket?.emit('playerLeftGame');
      setGameAudit(null);
    }
  }, []);

  useEffect(() => {
    if (user?.userId && gameId && !gameAudit) {
      socket?.emit('playerJoinGame', user?.userId, gameId);
    }

    const onGameStarted = () => {
      // if (user) {
      //   setUser({ ...user, gameToken: user?.gameToken - 1 });
      // }
    }

    const onGameAuditUpdated = (gameAudit: GameAudit) => {
      setGameAudit(gameAudit);
    }

    const onPlayerOnline = (playerId: string) => {
      setGameAudit((oldGameAudit) => {
        if (oldGameAudit) {
          return {
            ...oldGameAudit,
            players: {
              ...oldGameAudit.players,
              [playerId]: {
                ...oldGameAudit.players?.[playerId],
                isOnline: true
              }
            }
          }
        }
        return null;
      });
    }

    const onPlayerOffline = (playerId: string) => {
      setGameAudit((oldGameAudit) => {
        if (oldGameAudit) {
          return {
            ...oldGameAudit,
            players: {
              ...oldGameAudit.players,
              [playerId]: {
                ...oldGameAudit.players?.[playerId],
                isOnline: false
              }
            }
          }
        }
        return null;
      });
    }

    const onGameError = (message: string) => {
      setErrorMsg(message);
    }

    const onReconnect = () => {
      setShowDisconnectDialog(false);
      socket?.emit('playerJoinGame', user?.userId, gameId);
    }

    const onDisconnect = (reason: string) => {
      setShowDisconnectDialog(true);
      console.log('disconnect', reason);
    }

    socket?.io.on('reconnect', onReconnect);
    socket?.on('gameStarted', onGameStarted);
    socket?.on('gameAuditUpdated', onGameAuditUpdated);
    socket?.on('playerOnline', onPlayerOnline);
    socket?.on('playerOffline', onPlayerOffline);
    socket?.on('gameError', onGameError);
    socket?.on('disconnect', onDisconnect);

    return () => {
      socket?.off('playerOnline', onPlayerOnline);
      socket?.off('playerOffline', onPlayerOffline);
      socket?.off('gameAuditUpdated', onGameAuditUpdated);
      socket?.off('gameStarted', onGameStarted);
      socket?.off('gameError', onGameError);
      socket?.io.off('reconnect', onReconnect);
      socket?.off('disconnect', onDisconnect);
    }
  }, [gameAudit, gameId, setUser, user]);

  useEffect(() => {
    if (gameAudit?.players) {
      Object.keys(gameAudit.players).forEach((playerId) => {
        if (!players[playerId]) {
          clientApi().getUserThumbnail(playerId).then((result) => setPlayers((prev) => ({ ...prev, [playerId]: result.data }))).catch((error: Error) => console.error(error));
        }
      })
    }
  }, [gameAudit?.players, players]);

  useEffect(() => {
    if (gameAudit?.audiences) {
      gameAudit.audiences.forEach((playerId) => {
        if (!players[playerId]) {
          clientApi().getUserThumbnail(playerId).then((result) => setPlayers((prev) => ({ ...prev, [playerId]: result.data }))).catch((error: Error) => console.error(error));
        }
      })
    }
  }, [gameAudit?.audiences, players]);

  const onLoadingComplete = useCallback((_storyDetail: StoryRes) => {
    setStoryDetail(_storyDetail);
    setIsLoading(false);
  }, []);

  const renderGameContent = useMemo(() => {
    if (isLoading || !gameAudit || errorMsg) {
      return <LoadingPageBinder errorMsg={errorMsg} onLoadingComplete={onLoadingComplete} />;
    } else if (!isCalibrated) {
      return <CalibrationPage onConfirmButtonClicked={() => setIsCalibrated(true)} />;
    } else if (showEpiloguePage) {
      return <EpiloguePageBinder onReturnButtonClicked={() => setShowEpiloguePage(false)} />;
    } else {
      switch (gameAudit?.status) {
        case 'OPEN':
          return <WaitingPageBinder />;
        case 'STARTED':
        case 'OVER':
          switch (gameAudit.stage) {
            case 'PICKING':
              return <CharacterPageBinder />;
            default:
              return <InvestigatePageBinder onEpilogueButtonClicked={() => setShowEpiloguePage(true)} />;
          }
        default:
          return null;
      }
    }
  }, [isLoading, errorMsg, gameAudit, isCalibrated, showEpiloguePage, onLoadingComplete]);

  const managedProps: Pick<GamePageProps, GamePageBinderManagedProps> = {
    user,
    gameContent:
      <GameContext.Provider value={gameAudit}>
        <StoryContext.Provider value={storyDetail}>
          <PlayersContext.Provider value={players}>
            {renderGameContent}
          </PlayersContext.Provider>
        </StoryContext.Provider>
      </GameContext.Provider>,
    disconnectDialog: useMemo(() => <DisconnectDialog open={showDisconnectDialog} />, [showDisconnectDialog]),
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const GamePageBinder = createBinder(GamePage, useGamePageBinder);

export default GamePageBinder;