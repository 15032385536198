import styled from '@emotion/styled';
import { FC, ChangeEvent } from 'react';
import { Button, DialogProps, Divider, InputBase, Stack, TextField } from '@mui/material';
import OAuth2Login from 'react-simple-oauth2-login';
import { CommonDialog } from 'lib/common/CommonDialog';
import { ThemeButton } from 'lib/common/ThemeButton';
import discordLogo from 'assets/discord_logo.png';
import discordLogoHover from 'assets/discord_logo_hover.png';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { VERIFICATION_CODE_LENGTH } from 'utils/constants';
import { UserRes } from 'openapi';

const ContentWrapper = styled(Stack)`
  width: 480px;
`;

const ErrorMsgBox = styled.div`
  color: #df1818;
`;

const TextButton = styled.div`
  text-align: center;
  width: 72px;
  color: #274472;
  border-bottom: 1px solid #274472;
  cursor: pointer;
`;

const LoginDivider = styled(Divider)`
  width: 100%;
`;

const DiscordButton = styled(OAuth2Login)`
  background-image: url(${discordLogo});
  background-size: cover;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  :hover {
    background-image: url(${discordLogoHover});
  }
`;

const VerificationTitle = styled.div`
  font-size: 24px;
`;

const VerificationSubtitle = styled.div`
  font-size: 16px;
`;

const CodeInputBox = styled(Stack)`
  margin: 20px 0;
`;

const CodeInput = styled(InputBase)`
  width: 48px;
  height: 48px;
  font-size: 28px;
  font-weight: bold;
  border-radius: 8px;
  border: 2px solid #4D4D4D;
  caret-color: #274472AA;
  padding: 16px 14px 16px 14px;
    
  &.Mui-focused {
    border-color: #274472AA;
  }
`;

const ResendButton = styled(Button)`
  color: black;
  text-decoration: none;
  margin-top: 16px;
  font-size: 14px;
  cursor: pointer;

  :hover {
    color: #5885AF;
  }
`;

export enum LoginFormTypeEnum {
  Login,
  EmailVerification,
  SetUserName,
}

export interface LoginDialogProps extends DialogProps {
  loginFormType: LoginFormTypeEnum,
  email: string,
  verificationCode: string[],
  nickname: string,
  errorMsg?: string,
  onEmailInputChange: (e: ChangeEvent<HTMLInputElement>) => void,
  onNicknameInputChange: (e: ChangeEvent<HTMLInputElement>) => void,
  onLoginButtonClicked: () => void;
  onGoogleLoginSuccess: (response: CredentialResponse) => void;
  onGoogleLoginError: () => void;
  onDiscordLoginSuccess: (response: Record<string, any>) => void;
  onDiscordLoginFailure: (error: Error) => void;
  onReturnToLoginButtonClicked: () => void;
  onCloseButtonClicked: () => void;
  onCodeInputBoxClicked: (index: number) => void;
  setCodeInputRef: (ref: HTMLInputElement, index: number) => void;
  onCodeChange: (el: ChangeEvent<HTMLInputElement>) => void;
  onPaste: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onResendButtonClicked: () => void;
  resendTime?: number;
  onLoginSuccess: (_user: UserRes) => void;
}

export const LoginDialog: FC<LoginDialogProps> = function ({
  loginFormType,
  email,
  verificationCode,
  nickname,
  errorMsg,
  onEmailInputChange,
  onNicknameInputChange,
  onLoginButtonClicked,
  onGoogleLoginSuccess,
  onGoogleLoginError,
  onDiscordLoginSuccess,
  onDiscordLoginFailure,
  onReturnToLoginButtonClicked,
  onCloseButtonClicked,
  onCodeInputBoxClicked,
  setCodeInputRef,
  onCodeChange,
  onPaste,
  onKeyDown,
  onResendButtonClicked,
  resendTime,
  onLoginSuccess,
  ...props
}) {
  return (
    <CommonDialog onClose={onCloseButtonClicked} {...props}>
      <ContentWrapper direction="column" alignItems="center" spacing={2}>
        {loginFormType === LoginFormTypeEnum.Login && <>
          <TextField fullWidth placeholder='邮箱' value={email} onChange={onEmailInputChange} />
          <ErrorMsgBox>{errorMsg}</ErrorMsgBox>
          <ThemeButton onClick={onLoginButtonClicked}>登录 / 注册</ThemeButton>
          <LoginDivider variant="middle">或使用以下方式登录</LoginDivider>
          <Stack direction="row" justifyContent="center" spacing={2}>
            <GoogleLogin type="icon" shape="circle" width={400} onSuccess={onGoogleLoginSuccess} onError={onGoogleLoginError} />
            <DiscordButton
              authorizationUrl='https://discord.com/api/oauth2/authorize'
              responseType='token'
              clientId={process.env['REACT_APP_DISCORD_CLIENT_ID'] ?? ''}
              scope='identify email'
              redirectUri={`${process.env['REACT_APP_FRONTEND_URL']}/oauth-callback`}
              onSuccess={onDiscordLoginSuccess}
              onFailure={onDiscordLoginFailure}
              buttonText=''
            />
          </Stack>
        </>}

        {loginFormType === LoginFormTypeEnum.EmailVerification && <>
          <VerificationTitle>您的登录验证码已发送至以下邮箱：</VerificationTitle>
          <VerificationSubtitle>{email}</VerificationSubtitle>
          <CodeInputBox direction="row" spacing={2}>
            {Array.from({ length: VERIFICATION_CODE_LENGTH }).map((_, index) => {
              return (
                <CodeInput
                  key={`code-${index.toString()}`}
                  inputRef = {(ref: HTMLInputElement) => setCodeInputRef(ref, index)}
                  value={verificationCode[index]}
                  onClick={() => onCodeInputBoxClicked(index)}
                  onChange={onCodeChange}
                  inputProps={{
                    autoComplete: 'false',
                    onKeyDown,
                    maxLength: 1,
                    pattern: '[0-9]*',
                    onPaste,
                  }}
                />
              );
            })}
          </CodeInputBox>
          <ErrorMsgBox>{errorMsg}</ErrorMsgBox>
          <ResendButton disabled={!!resendTime} onClick={onResendButtonClicked}>重新发送{resendTime ? `(${resendTime})` : null}</ResendButton>
          <TextButton onClick={onReturnToLoginButtonClicked}>返回登陆</TextButton>
        </>}
      </ContentWrapper>
    </CommonDialog>
  );
};

export default LoginDialog;
