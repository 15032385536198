import { getAssetUrl } from 'utils/utils';

export const getNDYYInvestigatePageBackgroundUrl = (_: string) => {
  return [getAssetUrl('/NDYY/background2.webp')];
};

export const NDYY_CharacterColors: Record<string, string> = {
  唐小姐: '#D46060',
  玉馨: '#B46FB7',
  舒望: '#88A0FF',
  阿杰: '#B79263',
  阿海: '#90C281',
};

export const NDYY_VoteQuestions = ['是谁杀害了贺先生？'];

export const NDYY_Scripts: Record<string, string> = {
  唐小姐: '/NDYY/scripts/TXJ.pdf',
  阿海: '/NDYY/scripts/AH.pdf',
  玉馨: '/NDYY/scripts/YX.pdf',
  阿杰: '/NDYY/scripts/AJ.pdf',
  舒望: '/NDYY/scripts/SW.pdf',
};

export const NDYY_ScriptMaxPage: Record<string, number> = {
  READING1: 8,
  INTRODUCING: 8,
  INVESTIGATING1: 8,
  READING2: 10,
  INVESTIGATING2: 10,
  VOTING: 10,
};

export const NDYY_CharacterAbbr: Record<string, string> = {
  唐小姐: '唐',
  阿海: '海',
  玉馨: '馨',
  阿杰: '杰',
  舒望: '舒',
};

export const NDYY_TruthSummary: Record<string, string> = {
  背景故事:
    '1900年，宋禹功带部下偷袭敌军，攻下已被洋人军队占领的县衙粮仓，抢出粮食分给逃荒的百姓，却被宋家仇人秦陇川安上了“勾结寇匪，夺抢官粮”的罪名，连带家族，所有男丁全部判了死刑。宋家忠仆吉叔带着少爷玉书来海砂馆逃难，投奔嫁给贺先生的姐姐玉馨，却被渔船的人泄露了行踪，遭到官兵围捕。为了给宋家留后，贺先生本打算用阿海代替玉书交给衙门，却被玉馨阻止，不愿牺牲无辜的人。玉馨最终说服贺先生，由她替死，保下宋家最后的血脉。\n       1904年8月25日，贺先生在海滩上用妻子曾经的嫁妆“镀银匕首”杀死了玉馨。为了伪造玉书的头颅，他只能在地下室中将妻子的长发剃掉，随后怀着巨大的痛苦，亲手锯下了爱人的头颅交给官兵。',
  海砂馆的秘密: '贺先生是圣约翰大学的教授，致力于青少年心理健康的治疗，这在当时被认为是“撞邪”。海砂馆里的孩子因为各自悲惨的经历，都患一定程度的心理疾病，被父母送来海砂馆找贺先生治疗。而贺先生本人也因当初被迫杀害妻子，患上了人格分裂。',
  杀人魔与贺先生:
    '杀人魔是贺先生的双重人格。在杀害了自己妻子之后，贺先生每年都会让阿杰撑船带他去城里，用“镀银匕首”杀害一名长发女性，并割下带有长发的头皮回到海砂馆，再将裹着带血匕首的黑色外衣拿去焚烧。而死者的头发则被做成黑发娃娃，每年送给玉馨。贺先生一直尝试治愈自己，却始终未能奏效，甚至看到长发的唐小姐也会产生杀害她的冲动，只能通过服下安眠药物“安神”制止。为了除掉“杀人魔”的人格，贺先生选择求助他人。',
  玉馨和玉书:
    '真正的玉馨是贺先生的妻子，已经死亡，而玩家中的“玉馨”实际上是宋玉书的双重人格。当时为了骗过官兵，玉书被贺先生要求穿上玉馨留下的衣服，打扮成姐姐的样子，结果分裂出了姐姐的人格。贺先生发现之后，因思念亡妻，也为了自己的研究，继续将玉书培养成了第二个“玉馨”。',
  阿杰与阿海: '阿海是阿杰在昏迷之后，因向往有钱人家而分裂出的双重人格。主人格阿杰只在每年的一天中醒来，撑船送贺先生去上海。',
  舒望与夏叔娥: '夏叔娥小时候曾目睹父亲惨死，分裂出了人格“舒望”，在叔娥陷入困境时就会出现并主导行为，有时会对外界有过度反击的情况。',
  唐莫离: '唐小姐因为母亲意外逝世造成青春期自闭，被父亲唐茂光送来海砂馆找贺先生治疗，是这里唯一没有多重人格的孩子。',
  贺先生之死: '贺先生为了除掉“杀人魔”，告诉玉书自己就是杀害他姐姐玉馨的凶手，诱导玉书对自己开枪。中枪之后贺先生唤醒了玉馨的人格，为保护玉书而让其帮助处理现场。玉馨先擦去了手枪上的指纹，接着用大焰火筒的引线与爆竹伪造了枪声。',
};

export const getNDYYFooterText = (stage: string) => {
  switch (stage) {
    case 'READING1':
      return '阅读剧本阶段（阅读完请点击剧本左上角的“阅读完毕”）';
    case 'INTRODUCING':
      return '寒暄阶段';
    case 'INVESTIGATING1':
      return '调查阶段（当超过半数同意时，吉叔便会前去查看线索并公开）';
    case 'READING2':
      return '阅读第二幕剧本（阅读完请点击剧本左上角的“阅读完毕”）';
    case 'INVESTIGATING2':
      return '继续调查（当超过半数同意时，吉叔便会前去查看线索并公开）';
    case 'VOTING':
      return '投票阶段';
    case 'REVEALING':
      return '复盘阶段';
    default:
      return undefined;
  }
};
