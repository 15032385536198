import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';
import { FadeInAndOutKeyframes } from 'utils/keyframes';
import useSound from 'use-sound';
import BackgroundMusic from 'assets/music/pingtanyinxiang.mp3';

const ContentContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-family: STFangSong;
  font-size: 32px;
  text-indent: 2em;
  text-align: justify;
`;

const Info = styled.div`
  width: 40vw;
  height: 100vh;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 10%;
  top: 0;
  bottom: 0;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  text-align: justify;
  line-height: 2;
`;

const Info1 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 18s ease-in-out both 2s;
`;

const Info2 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 18s ease-in-out both 21s;
`;

const Info3 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 18s ease-in-out both 40s;
`;

const Info4 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 59s;
`;

const InfoImage = styled.img`
  margin-bottom: 50px;
`;

const PosterIamge = styled.img`
  height: 60vh;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 15%;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 1.5s ease-in-out both;
`;


export interface XLJ_EpiloguePageProps extends CommonUIProps { }

export const XLJ_EpiloguePage: FC<XLJ_EpiloguePageProps> = function ({
  ...props
}) {
  const [, { stop }] = useSound(BackgroundMusic, {
    volume: 0.5,
    autoplay: 1,
    interrupt: true,
    loop: true
  });
  const [showPoster, setShowPoster] = useState<boolean>(false);

  useEffect(() => {
    return () => stop();
  }, [stop])

  return (
    <ContentContainer {...props}>
      <Info1>
        <InfoImage src={getAssetUrl('/XLJ/drschnabel.png')} height={600} />
        <p>鸟嘴医生（Dr. Schnabel）起源于中世纪欧洲，是当地负责治疗黑死病患者的医师。这种造型奇特的服装是现代医学中防护服和防毒面具的雏形，鸟嘴中的芳香填充物可以有效隔绝疾病的飞沫传播。由于其出现的地方往往都伴随着大规模传染病的爆发，有些缺乏专业知识的医师还会采用放血等错误的方法进行治疗，因此也在大众心中树立了恐怖的形象，一度被认为是瘟疫使者。</p>
      </Info1>
      <Info2>
        <InfoImage src={getAssetUrl('/XLJ/tiangongkaiwu.png')} height={600} />
        <p>
          中国的冶金技术源远流长，最早的书面记载可追溯到明朝宋应星所著的《天工开物》。书中详细描绘了纺织、提炼、铸造等一百三十多项生产技术，先后被译为多种语言，广为流传。也正是因为明朝时期冶金技术的蓬勃发展，金银等稀有金属的产量大幅提高，银两才逐渐成为了后来主要的流通货币。
        </p>
      </Info2>
      <Info3>
        <InfoImage src={getAssetUrl('/XLJ/shuiyubing.jpeg')} height={600} />
        <p>“汞齐冶金法”是最早从原矿中提炼金属的方法，过程中需要用到非常多的汞，而产生的汞蒸气会极大污染生态环境。水银中毒会造成手足麻痹、言语障碍等症状，严重者甚至会出现关节扭曲导致的畸形。由于安全意识的缺失，早期世界各地的冶金行业都出现过严重的大规模水银中毒案例，比如1956年日本著名的“水俣病”事件。</p>
      </Info3>
      <Info4 onAnimationEnd={() => setShowPoster(true)}>迁往巴康寨的巴府山、彭先生在武汉的教授朋友、神秘的洋人博士都曾出现在其他的豪门惊情系列剧本当中。如果想了解更多他们的故事，可以继续体验《鄂西山灵》、《嗜睡蔷薇》、《雾起云浮》等剧本。</Info4>
      <PosterIamge src={getAssetUrl('/XLJ/poster.webp')} style={{ opacity: showPoster ? '1' : '0' }} />
    </ContentContainer>
  );
};

export default XLJ_EpiloguePage;
