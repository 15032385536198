import styled from '@emotion/styled';
import { FC } from 'react';
import { DialogProps, Stack } from '@mui/material';
import { CommonDialog } from 'lib/common/CommonDialog';
import Logo from 'assets/logo_black.png';

const ContentWrapper = styled(Stack)`
  width: 560px;
  min-height: 240px;
  display: flex;
  justify-content: center;
`;

const ProcessingBox = styled.div`
  font-size: 26px;
  font-weight: bold;
`;

const LogoImage = styled.img`
  width: 80px;
  height: 80px;
`;

const ErrorTitle = styled(Stack)`
  font-size: 26px;
  font-weight: bold;
`;

const SuccessTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: #274472;
  margin-bottom: 16px;
`;

const SuccessSubtitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
`;

export interface SubscriptionSuccessDialogProps extends DialogProps {
  isVerifying: boolean;
  errorMsg?: string;
}

export const SubscriptionSuccessDialog: FC<SubscriptionSuccessDialogProps> = function ({
  isVerifying,
  errorMsg,
  ...props
}) {
  return (
    <CommonDialog {...props}>
      <ContentWrapper direction="column" alignItems="center">
        <LogoImage src={Logo} />
        {isVerifying ?
          <ProcessingBox>
            正在处理支付结果…
          </ProcessingBox> :
          errorMsg ?
            <Stack alignItems="center" spacing={2}>
              <ErrorTitle>抱歉，您的订单似乎遇到了问题。</ErrorTitle>
              <span style={{ color: '#D24545' }}>{errorMsg}</span>
              <span>请稍后重试或联系客服<span style={{ textDecoration: "underline" }}>agency.of.time@gmail.com</span></span>
            </Stack>
            : <Stack alignItems="center" spacing={1}>
              <SuccessTitle>感谢您的购买！</SuccessTitle>
              <SuccessSubtitle>
                您可以即刻享受该次购买带来的所有权益。<br />
                赶快邀请好友一起开始探索感兴趣的案件吧！
              </SuccessSubtitle>
            </Stack>
        }
      </ContentWrapper>
    </CommonDialog>
  );
};

export default SubscriptionSuccessDialog;
