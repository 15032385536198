import { useState, useCallback, useContext, useMemo } from 'react';
import { VoteDialog, VoteDialogProps } from './VoteDialog';
import { createBinder } from 'utils/utils';
import { UserContext } from 'utils/contexts';
import { socket } from 'utils/socketConnector';
import { useParams } from 'react-router-dom';

export type VoteDialogBinderManagedProps = 'currentPage' | 'votedMurderers' | 'isAllQuestionVoted' | 'onPrevPageButtonClicked' | 'onNextPageButtonClicked' | 'onSelectMurderer' | 'onSubmitButtonClicked'
| 'showConfirmDialog' | 'handleConfirmDialogClose' | 'onConfirmVote';

export interface VoteDialogBinderProps extends Omit<VoteDialogProps, VoteDialogBinderManagedProps> { 
  questions: string[];
}

const useVoteDialogBinder = (props: VoteDialogBinderProps): VoteDialogProps => {

  const { user } = useContext(UserContext);
  const { gameId } = useParams();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [votedMurderers, setVotedMurderers] = useState<Array<string | null>>(Array(props.questions.length).fill(null));
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const isAllQuestionVoted = useMemo(() => Object.keys(votedMurderers).length > 0 && Object.values(votedMurderers).every((vote) => !!vote), [votedMurderers]);

  const onPrevPageButtonClicked = useCallback(() => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage]);
  const onNextPageButtonClicked = useCallback(() => {
    if (currentPage + 1 < props.questions.length) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, props.questions.length]);

  const onSelectMurderer = useCallback((murderer: string) => {
    const temp = [...votedMurderers];
    temp[currentPage] = murderer;
    setVotedMurderers(temp);
  }, [currentPage, votedMurderers]);

  const onConfirmVote= useCallback(() => {
    if (isAllQuestionVoted) {
      socket?.emit('playerVoteMurderers', user?.userId, gameId, votedMurderers);
      props.onClose?.({}, 'escapeKeyDown');
    }
  }, [gameId, isAllQuestionVoted, props, user?.userId, votedMurderers]);
  


  const managedProps: Pick<VoteDialogProps, VoteDialogBinderManagedProps> = {
    currentPage,
    votedMurderers,
    isAllQuestionVoted,
    onPrevPageButtonClicked,
    onNextPageButtonClicked,
    onSelectMurderer,
    onSubmitButtonClicked: () => setShowConfirmDialog(true),
    showConfirmDialog,
    handleConfirmDialogClose: () => setShowConfirmDialog(false),
    onConfirmVote,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const VoteDialogBinder = createBinder(VoteDialog, useVoteDialogBinder);

export default VoteDialogBinder;
