/* eslint-disable react/jsx-pascal-case */
import styled from '@emotion/styled';
import { LocationButton } from 'lib/game/components/LocationButton';
import { FC, ReactNode, useContext, useState } from 'react';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';
import { GameContext } from 'utils/contexts';
import { ThemeButton } from 'lib/common/ThemeButton';
import ConfirmDialog from 'lib/common/ConfirmDialog';
import { MHZY_StageTransition1, MHZY_StageTransition2 } from './MHZY_StageTransition';
import { SerializedStyles } from '@emotion/react';
import './bubbles.css';
import { BlinkingKeyframes, LureKeyframes } from 'utils/keyframes';
import { useRandomInterval } from 'utils/hooks';
import { MHZY_CharacterAbbr, MHZY_CharacterColors } from './MHZY_StaticData';
import MHZY_EscapteResults from './MHZY_EscapeResults';
import { FullDialog } from 'lib/common/FullDialog';

const MapContainer = styled.div`
  width: 70vw;
  position: absolute;
  margin: auto;
  top: 0;
  left: 5vw;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const Map = styled.img`
  width: 100%;
`;

const ILocationButton = styled(LocationButton)`
  &:hover {
    border-color: #ff7b7b;
  }
`;

const EngineRoom = styled(ILocationButton)`
  width: 6.6%;
  height: 6.0%;
  right: 7.6%;
  top: 66.4%;
`;

const PowerRoom = styled(ILocationButton)`
  width: 5.7%;
  height: 7.5%;
  right: 16.4%;
  top: 69.0%;
`;

const Kitchen = styled(ILocationButton)`
  width: 11.3%;
  height: 4.2%;
  right: 37.3%;
  top: 61.7%;
`;

const Storage = styled(ILocationButton)`
  width: 7.4%;
  height: 7.7%;
  right: 42.6%;
  top: 66.4%;
`;

const CaptainRoom = styled(ILocationButton)`
  width: 5.6%;
  height: 12.7%;
  left: 33.4%;
  top: 61.4%;
`;

const SteeringPlatform = styled(ILocationButton)`
  width: 5.6%;
  height: 13.5%;
  left: 27.5%;
  top: 63.0%;
`;

const CrewCabin = styled(ILocationButton)`
  width: 7.7%;
  height: 3.7%;
  right: 31%;
  top: 73.6%;
`;

const VIPRoom = styled(ILocationButton)`
  width: 5.0%;
  height: 6.5%;
  right: 31.9%;
  top: 62.0%;
`;

const DoubleRoom = styled(ILocationButton)`
  width: 4.5%;
  height: 6.2%;
  right: 27%;
  top: 62.2%;
`;

const PreparationRoom = styled(ILocationButton)`
  width: 3.9%;
  height: 6.2%;
  right: 22.7%;
  top: 62.2%;
`;

const TransitionCabin = styled(ILocationButton)`
  width: 5.8%;
  height: 5.8%;
  right: 16.4%;
  top: 62.6%;
`;

const Washroom = styled(ILocationButton)`
  width: 3.7%;
  height: 3.5%;
  right: 26.9%;
  top: 73.6%;
`;

const ConfinementRoom = styled(ILocationButton)`
  width: 3.7%;
  height: 3.4%;
  right: 22.7%;
  top: 73.6%;
`;

const MedicRoom = styled(ILocationButton)`
  width: 7.2%;
  height: 4.1%;
  right: 49.1%;
  top: 61.6%;
`;

const MeetingRoom = styled(ILocationButton)`
  width: 6.0%;
  height: 7.7%;
  right: 50.6%;
  top: 66.4%;
  border-width: 5px 5px 5px 0;

  &:before {
    content: '';
    position: absolute;
    width: 62%;
    height: 100%;
    top: 0px;
    left: -62%;
    border: inherit;
    border-width: 0 0 5px 5px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 57%;
    height: 70%;
    top: -75%;
    left: -62%;
    border: inherit;
    border-width: 5px 5px 0 5px;
  }
`;

const ReportButton = styled(ThemeButton)`
  background-color: #378CE7;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 120px;

  &:hover {
    background-color: #378CE7AA;
  }
`;

const AnglerFish = styled.div`
  position: absolute;
  left: 120px;
  bottom: 120px;
  transition: opacity 3s ease-in-out;
`;

const AnglerFish2 = styled.div`
  position: absolute;
  right: 120px;
  top: 200px;
  transition: opacity 3s ease-in-out;
`;

const AnglerFishImg = styled.img`
  width: 160px;
  height: 160px;
  opacity: 0;
  transition: opacity 3s ease-in-out;
`;

const LightBall = styled.div`
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  margin: auto;
  top: 20px;
  left: 0;
  right: 0;
  cursor: pointer;
  box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.5);
  animation: ${LureKeyframes} 3s ease-in-out infinite alternate;
`;

const LightBall2 = styled.div`
  width: 15px;
  height: 15px;
  background-color: #CAF4FF;
  border-radius: 50%;
  position: absolute;
  margin: auto;
  top: 20px;
  left: 0;
  right: 0;
  cursor: pointer;
  box-shadow: 0 0 10px 5px rgb(202, 244, 255, 0.5);
  animation: ${LureKeyframes} 5s ease-in-out infinite alternate;
`;

const EscapeText = styled.div`
  height: 48px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 5dvh;
  left: 0;
  right: 0;
  color: red;
  font-size: 52px;
  font-weight: bold;
  text-align: center;
  text-shadow: 1px 0px 4px white, 2px 0px 4px white, 3px 0px 4px white, 2px 0px 3px red, 2px 3px 15px red, 2px 0px 15px, 5px 0px 250px, 20px 0vw 400px red,40px 0vw 400px red;
  animation: ${BlinkingKeyframes} 1s linear infinite;
`;

const VotesContainer = styled.div`
  position: absolute;
  top: -35px;
  right: 0;
  z-index: 1;
`;

const VoteBadge = styled.div<{ index: number, color?: string }>`
  width: 36px;
  height: 48px;
  border-radius: 3px;
  padding-top: 5px;
  font-family: 'Mircrosoft Yahei';
  font-size: 24px;
  color: white;
  text-align: center;
  position: absolute;
  top: 0px;
  right: ${(props) => `${props.index * 15}px`};
  background-color: ${(props) => props.color};
  z-index: ${(props) => -1 - props.index};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  pointer-events: none;

  &:before {
    background-color: inherit;
    content: '';
    height: 26px;
    width: 26px;
    position: absolute;
    top: 33px;
    left: 5.25px;
    border-radius: 3px;
    transform: rotate(45deg);
    z-index: inherit;
  }
`;

export interface MHZY_InvestigatePageProps extends CommonUIProps {
  showMap: boolean;
  gameTime: string;
  numRemainingClues: Record<string, number>;
  locationHasNewClue: string[];
  onLocationButtonClicked: (location: string) => void;
  pickClueDialog: ReactNode;
  showPickClueDialog: boolean;
  setShowPickClueDialog: (_: boolean) => void;
  showReportButton: boolean;
  onReportButtonClicked: () => void;
  showConfirmDialog: boolean;
  handleConfirmDialogClose: () => void;
  onConfirmInvite: () => void;
  showConfirmEscapeDialog: boolean;
  handleConfirmEscapeDialogClose: () => void;
  onConfirmEscape: () => void;
  showStageTransition1: boolean;
  showStageTransition2: boolean;
  onStageTransitionEnd: (_: Event, reason: "backdropClick" | "escapeKeyDown") => void;
  setControllerBackground: (backgroundUrl?: string) => void;
  setBackgroundAnimation: (animation?: SerializedStyles) => void;
  escapeTimer: number;
  escapeLocations: Record<string, string[]>;
  showEscapeResults: boolean;
  onEscapeReultsClose: () => void;
}

export const MHZY_InvestigatePage: FC<MHZY_InvestigatePageProps> = function ({
  showMap,
  gameTime,
  numRemainingClues,
  locationHasNewClue,
  onLocationButtonClicked,
  pickClueDialog,
  showPickClueDialog,
  setShowPickClueDialog,
  showReportButton,
  onReportButtonClicked,
  showConfirmDialog,
  handleConfirmDialogClose,
  onConfirmInvite,
  showConfirmEscapeDialog,
  handleConfirmEscapeDialogClose,
  onConfirmEscape,
  showStageTransition1,
  showStageTransition2,
  onStageTransitionEnd,
  setControllerBackground,
  setBackgroundAnimation,
  escapeTimer,
  escapeLocations,
  showEscapeResults,
  onEscapeReultsClose,
  ...props
}) {

  const gameStage = useContext(GameContext)?.stage ?? '';

  const [showAnglerFish, setShowAnglerFish] = useState<boolean>(false);
  const [showAnglerFishImg, setShowAnglerFishImg] = useState<boolean>(false);

  const [showAnglerFish2, setShowAnglerFish2] = useState<boolean>(false);
  const [showAnglerFishImg2, setShowAnglerFishImg2] = useState<boolean>(false);

  useRandomInterval(() => {
    if (!showAnglerFish && ['READING2', 'INVESTIGATING2', 'DISCUSSING2', 'READING3', 'INVESTIGATING3', 'VOTING', 'ESCAPING', 'REVEALING'].includes(gameStage)) {
      setShowAnglerFishImg(false);
      setShowAnglerFish(true);
    }
  }, 60 * 1000, 60 * 1000 * 2);

  useRandomInterval(() => {
    if (!showAnglerFish2 && ['READING2', 'INVESTIGATING2', 'DISCUSSING2', 'READING3', 'INVESTIGATING3', 'VOTING', 'ESCAPING', 'REVEALING'].includes(gameStage)) {
      setShowAnglerFishImg2(false);
      setShowAnglerFish2(true);
    }
  }, 60 * 1000 * 2, 60 * 1000 * 5);

  return (
    <div {...props}>
      {showReportButton && <ReportButton width="180px" onClick={onReportButtonClicked}>去见「伊藤先生」</ReportButton>}
      {showMap &&
        <>
          <MapContainer>
            <div style={{ width: '100%', position: 'relative' }}>
              <Map src={getAssetUrl('/MHZY/submarine.webp')} draggable={false} />
              {['INVESTIGATING2', 'DISCUSSING2', 'INVESTIGATING3', 'VOTING', 'ESCAPING', 'REVEALING'].includes(gameStage) && <>
                <EngineRoom badge={gameStage === 'ESCAPING' ? undefined : numRemainingClues['轮机舱']} badgeStyle={{ top: '25%', left: '105%' }} hasNewClue={locationHasNewClue.includes('轮机舱')} onClick={() => onLocationButtonClicked('轮机舱')}>
                  <VotesContainer>
                    {escapeLocations['轮机舱'] && escapeLocations['轮机舱'].map((player, index) =>
                      <VoteBadge key={player} index={index} color={MHZY_CharacterColors[player]}>
                        {MHZY_CharacterAbbr[player]}
                      </VoteBadge>
                    )}
                  </VotesContainer>
                </EngineRoom>
                <PowerRoom badge={gameStage === 'ESCAPING' ? undefined : numRemainingClues['发电室']} badgeStyle={{ top: '110%' }} hasNewClue={locationHasNewClue.includes('发电室')} onClick={() => onLocationButtonClicked('发电室')}>
                  <VotesContainer>
                    {escapeLocations['发电室'] && escapeLocations['发电室'].map((player, index) =>
                      <VoteBadge key={player} index={index} color={MHZY_CharacterColors[player]}>
                        {MHZY_CharacterAbbr[player]}
                      </VoteBadge>
                    )}
                  </VotesContainer>
                </PowerRoom>
                <Kitchen badge={gameStage === 'ESCAPING' ? undefined : numRemainingClues['厨房']} badgeStyle={{ top: '10%', left: '75%' }} hasNewClue={locationHasNewClue.includes('厨房')} onClick={() => onLocationButtonClicked('厨房')}>
                  <VotesContainer>
                    {escapeLocations['厨房'] && escapeLocations['厨房'].map((player, index) =>
                      <VoteBadge key={player} index={index} color={MHZY_CharacterColors[player]}>
                        {MHZY_CharacterAbbr[player]}
                      </VoteBadge>
                    )}
                  </VotesContainer>
                </Kitchen>
                <Storage badge={gameStage === 'ESCAPING' ? undefined : numRemainingClues['仓库']} badgeStyle={{ top: '27%', left: '68%' }} hasNewClue={locationHasNewClue.includes('仓库')} onClick={() => onLocationButtonClicked('仓库')}>
                  <VotesContainer>
                    {escapeLocations['仓库'] && escapeLocations['仓库'].map((player, index) =>
                      <VoteBadge key={player} index={index} color={MHZY_CharacterColors[player]}>
                        {MHZY_CharacterAbbr[player]}
                      </VoteBadge>
                    )}
                  </VotesContainer>
                </Storage>
              </>}
              {['INVESTIGATING3', 'VOTING', 'ESCAPING', 'REVEALING'].includes(gameStage) && <>
                <CaptainRoom badge={gameStage === 'ESCAPING' ? undefined : numRemainingClues['船长室']} badgeStyle={{ top: '72%', left: '62%' }} hasNewClue={locationHasNewClue.includes('船长室')} onClick={() => onLocationButtonClicked('船长室')}>
                  <VotesContainer>
                    {escapeLocations['船长室'] && escapeLocations['船长室'].map((player, index) =>
                      <VoteBadge key={player} index={index} color={MHZY_CharacterColors[player]}>
                        {MHZY_CharacterAbbr[player]}
                      </VoteBadge>
                    )}
                  </VotesContainer>
                </CaptainRoom>
                <SteeringPlatform badge={gameStage === 'ESCAPING' ? undefined : numRemainingClues['操舵台']} badgeStyle={{ top: '5%', left: '-20%' }} hasNewClue={locationHasNewClue.includes('操舵台')} onClick={() => onLocationButtonClicked('操舵台')}>
                  <VotesContainer>
                    {escapeLocations['操舵台'] && escapeLocations['操舵台'].map((player, index) =>
                      <VoteBadge key={player} index={index} color={MHZY_CharacterColors[player]}>
                        {MHZY_CharacterAbbr[player]}
                      </VoteBadge>
                    )}
                  </VotesContainer>
                </SteeringPlatform>
                <CrewCabin badge={gameStage === 'ESCAPING' ? undefined : numRemainingClues['船员仓']} badgeStyle={{ bottom: '-105%' }} hasNewClue={locationHasNewClue.includes('船员仓')} onClick={() => onLocationButtonClicked('船员仓')}>
                  <VotesContainer>
                    {escapeLocations['船员仓'] && escapeLocations['船员仓'].map((player, index) =>
                      <VoteBadge key={player} index={index} color={MHZY_CharacterColors[player]}>
                        {MHZY_CharacterAbbr[player]}
                      </VoteBadge>
                    )}
                  </VotesContainer>
                </CrewCabin>
                <VIPRoom badge={gameStage === 'ESCAPING' ? undefined : numRemainingClues['贵宾室']} badgeStyle={{ top: '-62%' }} hasNewClue={locationHasNewClue.includes('贵宾室')} onClick={() => onLocationButtonClicked('贵宾室')}>
                  <VotesContainer>
                    {escapeLocations['贵宾室'] && escapeLocations['贵宾室'].map((player, index) =>
                      <VoteBadge key={player} index={index} color={MHZY_CharacterColors[player]}>
                        {MHZY_CharacterAbbr[player]}
                      </VoteBadge>
                    )}
                  </VotesContainer>
                </VIPRoom>
                <DoubleRoom badge={gameStage === 'ESCAPING' ? undefined : numRemainingClues['双人房']} badgeStyle={{ top: '-62%' }} hasNewClue={locationHasNewClue.includes('双人房')} onClick={() => onLocationButtonClicked('双人房')}>
                  <VotesContainer>
                    {escapeLocations['双人房'] && escapeLocations['双人房'].map((player, index) =>
                      <VoteBadge key={player} index={index} color={MHZY_CharacterColors[player]}>
                        {MHZY_CharacterAbbr[player]}
                      </VoteBadge>
                    )}
                  </VotesContainer>
                </DoubleRoom>
                <PreparationRoom badge={gameStage === 'ESCAPING' ? undefined : numRemainingClues['潜水准备室']} badgeStyle={{ top: '-62%' }} hasNewClue={locationHasNewClue.includes('潜水准备室')} onClick={() => onLocationButtonClicked('潜水准备室')}>
                  <VotesContainer>
                    {escapeLocations['潜水准备室'] && escapeLocations['潜水准备室'].map((player, index) =>
                      <VoteBadge key={player} index={index} color={MHZY_CharacterColors[player]}>
                        {MHZY_CharacterAbbr[player]}
                      </VoteBadge>
                    )}
                  </VotesContainer>
                </PreparationRoom>
                <TransitionCabin badge={gameStage === 'ESCAPING' ? undefined : numRemainingClues['潜水过渡舱']} badgeStyle={{ top: '-67%' }} hasNewClue={locationHasNewClue.includes('潜水过渡舱')} onClick={() => onLocationButtonClicked('潜水过渡舱')}>
                  <VotesContainer>
                    {escapeLocations['潜水过渡舱'] && escapeLocations['潜水过渡舱'].map((player, index) =>
                      <VoteBadge key={player} index={index} color={MHZY_CharacterColors[player]}>
                        {MHZY_CharacterAbbr[player]}
                      </VoteBadge>
                    )}
                  </VotesContainer>
                </TransitionCabin>
                <Washroom badge={gameStage === 'ESCAPING' ? undefined : numRemainingClues['卫生间']} badgeStyle={{ bottom: '-112%' }} hasNewClue={locationHasNewClue.includes('卫生间')} onClick={() => onLocationButtonClicked('卫生间')}>
                  <VotesContainer>
                    {escapeLocations['卫生间'] && escapeLocations['卫生间'].map((player, index) =>
                      <VoteBadge key={player} index={index} color={MHZY_CharacterColors[player]}>
                        {MHZY_CharacterAbbr[player]}
                      </VoteBadge>
                    )}
                  </VotesContainer>
                </Washroom>
                <ConfinementRoom badge={gameStage === 'ESCAPING' ? undefined : numRemainingClues['禁闭室']} badgeStyle={{ bottom: '-113%' }} hasNewClue={locationHasNewClue.includes('禁闭室')} onClick={() => onLocationButtonClicked('禁闭室')}>
                  <VotesContainer>
                    {escapeLocations['禁闭室'] && escapeLocations['禁闭室'].map((player, index) =>
                      <VoteBadge key={player} index={index} color={MHZY_CharacterColors[player]}>
                        {MHZY_CharacterAbbr[player]}
                      </VoteBadge>
                    )}
                  </VotesContainer>
                </ConfinementRoom>
                <MedicRoom badge={gameStage === 'ESCAPING' ? undefined : numRemainingClues['医务室']} badgeStyle={{ top: '-97%' }} hasNewClue={locationHasNewClue.includes('医务室')} onClick={() => onLocationButtonClicked('医务室')}>
                  <VotesContainer>
                    {escapeLocations['医务室'] && escapeLocations['医务室'].map((player, index) =>
                      <VoteBadge key={player} index={index} color={MHZY_CharacterColors[player]}>
                        {MHZY_CharacterAbbr[player]}
                      </VoteBadge>
                    )}
                  </VotesContainer>
                </MedicRoom>
                <MeetingRoom badge={gameStage === 'ESCAPING' ? undefined : numRemainingClues['会议厅']} badgeStyle={{ top: '-65%', left: '-153%' }} hasNewClue={locationHasNewClue.includes('会议厅')} onClick={() => onLocationButtonClicked('会议厅')}>
                  <VotesContainer>
                    {escapeLocations['会议厅'] && escapeLocations['会议厅'].map((player, index) =>
                      <VoteBadge key={player} index={index} color={MHZY_CharacterColors[player]}>
                        {MHZY_CharacterAbbr[player]}
                      </VoteBadge>
                    )}
                  </VotesContainer>
                </MeetingRoom>
              </>}
            </div>
          </MapContainer>
        </>
      }
      {['READING2', 'INVESTIGATING2', 'DISCUSSING2', 'READING3', 'INVESTIGATING3', 'VOTING', 'ESCAPING', 'REVEALING'].includes(gameStage) && <>
        <div className="bubbles">
          <div className="bubble" />
          <div className="bubble" />
          <div className="bubble" />
          <div className="bubble" />
          <div className="bubble" />
          <div className="bubble" />
          <div className="bubble" />
          <div className="bubble" />
          <div className="bubble" />
          <div className="bubble" />
          <div className="bubble" />
          <div className="bubble" />
          <div className="bubble" />
          <div className="bubble" />
          <div className="bubble" />
        </div>
        <AnglerFish style={{ opacity: showAnglerFish ? 1 : 0 }}>
          <AnglerFishImg style={{ opacity: showAnglerFishImg ? 1 : 0 }} src={getAssetUrl('/MHZY/anglerfish1.webp')} onTransitionEnd={() => {
            if (showAnglerFishImg) {
              setShowAnglerFish(false);
              setShowAnglerFishImg(false);
            }
          }} />
          <LightBall onClick={() => setShowAnglerFishImg(true)} />
        </AnglerFish>

        <AnglerFish2 style={{ opacity: showAnglerFish2 ? 1 : 0 }}>
          <AnglerFishImg style={{ opacity: showAnglerFishImg2 ? 1 : 0 }} src={getAssetUrl('/MHZY/anglerfish2.webp')} onTransitionEnd={() => {
            if (showAnglerFishImg2) {
              setShowAnglerFish2(false);
              setShowAnglerFishImg2(false);
            }
          }} />
          <LightBall2 onClick={() => setShowAnglerFishImg2(true)} />
        </AnglerFish2>
      </>}
      {pickClueDialog}
      <FullDialog open={gameStage === 'ESCAPING'} disableEscapeKeyDown style={{ pointerEvents: 'none' }} hideBackdrop>
        <div style={{ height: '100dvh', width: '100vw'}}>
          <EscapeText>船长已经死亡，潜艇无法上浮，请想办法逃生！{new Date(escapeTimer * 1000).toISOString().substring(14, 19)}</EscapeText>
        </div>
      </FullDialog>
      <MHZY_StageTransition1 open={showStageTransition1} onClose={onStageTransitionEnd} />
      <MHZY_StageTransition2 open={showStageTransition2} onClose={onStageTransitionEnd} setBackgroundAnimation={setBackgroundAnimation} />
      <MHZY_EscapteResults open={showEscapeResults} onClose={onEscapeReultsClose} />
      <ConfirmDialog open={showConfirmDialog} onClose={handleConfirmDialogClose} title="确定回去见伊藤先生吗？" content="在你回去之后，游戏会进入下一阶段。" onConfirmButtonClicked={onConfirmInvite} />
      <ConfirmDialog open={showConfirmEscapeDialog} onClose={handleConfirmEscapeDialogClose} title="确定从这个位置逃生吗？" content="你的决定无法更改。" onConfirmButtonClicked={onConfirmEscape} />
    </div>
  );
};

export default MHZY_InvestigatePage;
