/* eslint-disable max-len */
import styled from '@emotion/styled';
import { Stack, Dialog, DialogProps } from '@mui/material';
import { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { CommonIconButton } from './CommonIconButton';

const DialogContainer = styled(Dialog)`
  & .MuiDialog-paper {
    max-width: 100vw;
    border-radius: 8px;
    overflow: hidden;
  }
`;

const DialogHeader = styled(Stack)`
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
`;

const CloseButton = styled(CommonIconButton)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const Content = styled.div`
  padding: 0px 20px 20px 20px;
`;

export interface CommonDialogProps extends DialogProps {
  title?: string;
}

/**
 * NOTE: To use ref in subcomponents for Dialog, you have to use a callback function to assign the node to the ref.
 * Please see example below:
 *
 * const ref: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);
 *
 * <div
 *    ref={(node: HTMLDivElement) => {
                ref.current = node;
              }
            }
    />
 * More Info on https://stackoverflow.com/questions/63527214/react-hooks-material-ui-unable-to-obtain-dialog-ref-on-first-load-in-useeffec
 *
 */

export const CommonDialog: FC<CommonDialogProps> = function ({
  children,
  title,
  ...props
}) {
  return (
    <DialogContainer {...props}>
      <DialogHeader alignItems="center" justifyContent="center">
        {title}
        <CloseButton icon={<CloseIcon />} onClick={() => props.onClose?.({}, 'escapeKeyDown')} />
      </DialogHeader>
      <Content>{children}</Content>
    </DialogContainer>
  );
};
