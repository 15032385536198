import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';
import { FadeInAndOutKeyframes } from 'utils/keyframes';
import useSound from 'use-sound';
import BackgroundMusic from 'assets/music/tujia.mp3';

const ContentContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-family: STFangSong;
  font-size: 32px;
  text-indent: 2em;
  text-align: justify;
`;

const Info = styled.div`
  width: 40vw;
  height: 100vh;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 10%;
  top: 0;
  bottom: 0;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  text-align: justify;
  line-height: 1.5;
`;

const Info1 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 2s;
`;

const Info2 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 15s;
`;

const Info3 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 15s ease-in-out both 28s;
`;

const Info4 = styled(Info)`
  animation: ${FadeInAndOutKeyframes} 12s ease-in-out both 44s;
`;

const InfoImage = styled.img`
  margin-bottom: 50px;
`;

const PosterIamge = styled.img`
  height: 60vh;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  left: 15%;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 1.5s ease-in-out both;
`;


export interface EXSL_EpiloguePageProps extends CommonUIProps { }

export const EXSL_EpiloguePage: FC<EXSL_EpiloguePageProps> = function ({
  ...props
}) {
  const [, { stop }] = useSound(BackgroundMusic, {
    volume: 0.2,
    autoplay: 1,
    interrupt: true,
    loop: true
  });
  const [showPoster, setShowPoster] = useState<boolean>(false);

  useEffect(() => {
    return () => stop();
  }, [stop])

  return (
    <ContentContainer {...props}>
      <Info1>
        <InfoImage src={getAssetUrl('/EXSL/tujiamiaozu.webp')} height={600} />
        <p>虽说“巴山楚水凄凉地”，可这里的山水仍然孕育出了土家族、苗族等深厚壮丽的少数民族文化，是中国传统文化中璀璨的瑰宝。</p>
      </Info1>
      <Info2>
        <InfoImage src={getAssetUrl('/EXSL/diaojiaolou2.webp')} height={600} />
        <p>
          吊脚楼是土家族传统民居，多依山靠河而建。高悬地面既通风干燥，又防蛇兽，其建造技艺是中国重要的非物质文化遗产。
        </p>
      </Info2>
      <Info3>
        <InfoImage src={getAssetUrl('/EXSL/poppyplant.jpg')} height={600} />
        <p>故事中原版的“山灵之歌”，实际上是从罂粟中提炼鸦片的方法。19世纪，英国出口大量鸦片到中国，也引发了之后的第一次鸦片战争。虽有林则徐“虎门销烟”，可罂粟的种植方法却已被广泛传播，流毒至今……</p>
      </Info3>
      <Info4 onAnimationEnd={() => setShowPoster(true)}>《鄂西山灵》还有一部姊妹篇《先灵祭》，在那里同样有一片未开化的传统村落，以及戴面具祈福的祭祀仪式。不过，传闻那里的秘术可以和先灵“沟通”，预兆灾祸……</Info4>
      <PosterIamge src={getAssetUrl('/EXSL/poster.webp')} style={{ opacity: showPoster ? '1' : '0' }} />
    </ContentContainer>
  );
};

export default EXSL_EpiloguePage;
