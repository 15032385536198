import styled from '@emotion/styled';
import { FC, useContext, useState } from 'react';
import { FullDialog } from 'lib/common/FullDialog';
import { DialogProps, Stack } from '@mui/material';
import { GameContext } from 'utils/contexts';
import { useTimeout } from 'utils/hooks';
import { getAssetUrl } from 'utils/utils';

const ContentWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-image: ${`url(${getAssetUrl('/MHZY/background3.webp')})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const ResultsStack = styled(Stack)`
  width: 60vw;
`;

const ResultText = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: white;
  line-height: 1.5em;
  opacity: 0;
  transition: opacity 3s ease-in-out;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
`;

export interface MHZY_EscapteResultsProps extends DialogProps { }

export const MHZY_EscapteResults: FC<MHZY_EscapteResultsProps> = function ({
  ...props
}) {

  const gameAudit = useContext(GameContext);
  const wrong = Object.values(gameAudit?.players ?? {}).filter((player) => !!player.votedClue && player.votedClue !== '操舵台').map((player) => player.character === '王赶海' ? '潜水员' : player.character);
  const stay = Object.values(gameAudit?.players ?? {}).filter((player) => !player.votedClue).map((player) => player.character === '王赶海' ? '潜水员' : player.character);
  const correct = Object.values(gameAudit?.players ?? {}).filter((player) => player.votedClue === '操舵台').map((player) => player.character === '王赶海' ? '潜水员' : player.character);

  const [showPretext, setShowPretext] = useState<boolean>(false);
  const [showText1, setShowText1] = useState<boolean>(false);
  const [showText2, setShowText2] = useState<boolean>(false);
  const [showText3, setShowText3] = useState<boolean>(false);

  useTimeout(() => setShowPretext(true), props.open ? 1000 : null);

  useTimeout(() => setShowText1(true), props.open && wrong.length > 0 ? 6000 : null);

  useTimeout(() => setShowText2(true), props.open && stay.length > 0 ? (wrong.length > 0 ? 8000 : 0) + 6000 : null);

  useTimeout(() => setShowText3(true), props.open && correct.length > 0 ? (wrong.length > 0 ? 8000 : 0) + (stay.length > 0 ? 10000 : 0) + 6000 : null);

  useTimeout(() => props.onClose?.({}, 'escapeKeyDown'), props.open ? (wrong.length > 0 ? 8000 : 0) + (stay.length > 0 ? 10000 : 0) + (correct.length > 0 ? 10000 : 0) +  6000 : null);

  return (
    <FullDialog disableEscapeKeyDown {...props}>
      <ContentWrapper>
        <ResultsStack direction="column" justifyContent="center" spacing={8}>
          <ResultText style={{ opacity: showPretext ? 1 : 0 }}>你们将尸体留在船舱内，进行了简单的告别仪式。</ResultText>
          {wrong.length > 0 && <ResultText style={{ opacity: showText1 ? 1 : 0 }}><span style={{ color: 'red' }}>{wrong.join('、')}</span>来到选择的逃离地点，想方设法打开了与海底相连的地方——海水在一瞬间疯狂涌入，甚至来不及挣扎就被巨大的冲击撞倒，很快失去了意识……</ResultText>}
          {stay.length > 0 && <ResultText style={{ opacity: showText2 ? 1 : 0 }}><span style={{ color: 'red' }}>{stay.join('、')}</span>把所有的淡水和罐头集中起来，选择留在海底等待救援。时间一分一秒地流逝，在此刻显得格外漫长——就在逐渐失去希望时，观景窗外突然出现了逐渐亮起的光芒。你不知道这是幻觉，还是海底那种名为“安康”的怪物……</ResultText>}
          {correct.length > 0 && <ResultText style={{ opacity: showText3 ? 1 : 0 }}><span style={{ color: 'red' }}>{correct.join('、')}</span>来到操舵台，打开了密封阀门，竟然在储气舱中发现了通向上方的巨大软管，里面还有逃生软梯！顺着软梯爬上去，很快来到了陆地，熟悉的“毗海村”码头再次出现在眼前……</ResultText>}
        </ResultsStack>
      </ContentWrapper>
    </FullDialog>
  );
};

export default MHZY_EscapteResults;
