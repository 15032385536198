import styled from '@emotion/styled';
import { Stack, Tab, Tabs } from '@mui/material';
import { ThemeButton } from 'lib/common/ThemeButton';
import { AdminStatsRes, CommentRes, GameAudit } from 'openapi';
import { FC, useState } from 'react';
import { CommonUIProps } from 'utils/types';

const PageContainer = styled.div`
  width: 100%;
  min-height: 100dvh;
  background-color: black;
`;

const Header = styled.header`
  padding-top: 35px;
  font-size: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
`;

const TabWrapper = styled.div`
  margin-top: 12px;
  font-size: 24px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ITab = styled(Tab)`
  font-size: 18px;
  border-radius: 8px;
`;

const Table = styled.table`
  margin: auto;
  margin-top: 30px;
  width: 90vw;
  align-items: center;
  text-align: center;
  border: 0;
  color: white;
`;

const TableHead = styled.thead`
  text-align: center;
  color: white;
  font-size: 24px;
`;

const TableRow = styled.tr`
  height: 60px;

  > td {
    vertical-align: top;
  }
`;

const ActionButton = styled(ThemeButton)`
  margin-left: 5px;
  margin-right: 5px;
`;

const ErrorMsgBox = styled.div`
  text-align: center;
  color: red;
  font-size: 24px;
  margin-top: 12px;
`;

const StatContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  background-color: #D4D4D4;
  padding: 20px;
  color: black;
`;

export interface AdminPageProps extends CommonUIProps {
  errorMsg?: string;
  gameList?: Record<string, GameAudit>;
  commentList?: Record<string, CommentRes>;
  onRefreshButtonClicked: () => void;
  onJoinGameButtonClicked: (gameId: string) => void;
  onForceUpdateButtonClicked: (gameId: string) => void;
  onApproveButtonClicked: (commentId: string, approval: boolean) => void;
  stats?: AdminStatsRes;
}

export const AdminPage: FC<AdminPageProps> = function ({
  errorMsg,
  gameList,
  commentList,
  onRefreshButtonClicked,
  onJoinGameButtonClicked,
  onForceUpdateButtonClicked,
  onApproveButtonClicked,
  stats,
  ...props
}) {

  const [activeTab, setActiveTab] = useState<number>(0);

  return (
    <PageContainer {...props}>
      {errorMsg ? <ErrorMsgBox>{errorMsg}</ErrorMsgBox> : <>
        <Header>
          <h1>时空侦探局管理系统</h1>
          <Stack direction="row" spacing={4} style={{ marginBottom: '32px' }}>
            <StatContainer>{`用户数：${stats?.users}`}</StatContainer>
            <StatContainer>{`在线数：${stats?.onlines}`}</StatContainer>
            <StatContainer>{`订阅VIP数：${stats?.subscriptions?.PLAN_C}`}</StatContainer>
            <StatContainer>{`开始游戏数：${Object.values(stats?.games ?? {}).reduce((sum, count) => sum + count, 0)}`}</StatContainer>
            <StatContainer>{`总游戏数：${Object.keys(gameList ?? {}).length}`}</StatContainer>
          </Stack>
        </Header>
        <TabWrapper>
          <Tabs value={activeTab} onChange={(_, newValue: number) => setActiveTab(newValue)} textColor="inherit" indicatorColor="primary" visibleScrollbar>
            <ITab label="房间" />
            <ITab label="评论" />
          </Tabs>
        </TabWrapper>
        <Table>
          {activeTab === 0 && <>
            <TableHead>
              <TableRow>
                <td>案件ID</td>
                <td>剧本</td>
                <td>创建者</td>
                <td>创建时间</td>
                <td>游戏状态</td>
                <td>当前阶段</td>
              </TableRow>
            </TableHead>
            <tbody>
              {Object.entries(gameList ?? {}).map(([gameId, gameAudit]) => (
                <TableRow key={gameId}>
                  <td>{gameId}</td>
                  <td>{gameAudit.storyId}</td>
                  <td>{gameAudit.creator}</td>
                  <td>{gameAudit.createTime && new Date(gameAudit.createTime).toLocaleString()}</td>
                  <td>{gameAudit.status}</td>
                  <td>{gameAudit.stage}</td>
                  <td>
                    <ActionButton width="120px" onClick={() => onJoinGameButtonClicked(gameId)}>进入案件</ActionButton>
                    <ActionButton width="120px" onClick={() => onForceUpdateButtonClicked(gameId)}>强制刷新</ActionButton>
                  </td>
                </TableRow>
              ))}
            </tbody>
          </>}
          {activeTab === 1 && <>
            <TableHead>
              <TableRow>
                <td>评论ID</td>
                <td>用户ID</td>
                <td>创建时间</td>
                <td>评分</td>
                <td style={{ width: '500px'}}>内容</td>
                <td>审核通过</td>
              </TableRow>
            </TableHead>
            <tbody>
              {Object.entries(commentList ?? {}).map(([commentId, comment]) => (
                <TableRow key={commentId}>
                  <td>{commentId}</td>
                  <td>{comment.userId}</td>
                  <td>{comment.createTime && new Date(comment.createTime).toLocaleString()}</td>
                  <td>{comment.rating}</td>
                  <td>{comment.comment}</td>
                  <td>
                    <ActionButton width="120px" onClick={() => onApproveButtonClicked(commentId, !comment.approval)}>{comment.approval ? '取消通过' : '通过'}</ActionButton>
                  </td>
                </TableRow>
              ))}
            </tbody>
          </>}
        </Table>
      </>}
    </PageContainer>
  );
};

export default AdminPage;
