import styled from '@emotion/styled';
import { Dialog, DialogProps, Skeleton } from '@mui/material';
import { CommonIconButton } from 'lib/common/CommonIconButton';
import { FC, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import XHSQRCode from 'assets/XHS.webp';
import { getAssetUrl } from 'utils/utils';

const IDialog = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 40px;
    overflow: hidden;
    border: 3px solid white;
  }
`;

const QRcode = styled.img`
  width: 400px;
`;

const CloseButton = styled(CommonIconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export interface QRCodeDialogProps extends DialogProps {
  platform?: string,
}

export const WechatQRCodeDialog: FC<QRCodeDialogProps> = function ({
  platform,
  ...props
}) {

  // const [wechatQRCodeUri, setWechatQRCodeUri] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // useEffect(() => {
  //   if (!wechatQRCodeUri && platform === 'wechat') {
  //     setIsLoading(true);
  //     clientApi().getQRCode('wechat').then((result) => {
  //       setWechatQRCodeUri(result.data.uri);
  //       setIsLoading(false);
  //     }).catch((error: unknown) => {
  //       console.error(error);
  //     })
  //   }
  // }, [wechatQRCodeUri, platform]);

  const QRCodeUrl = useMemo(() => {
    switch (platform) {
      case 'XHS':
        setIsLoading(false);
        return XHSQRCode;
      case 'wechat':
        setIsLoading(false);
        return getAssetUrl('/wechat_qrcode.jpg');
      default:
        return undefined;
    }
  }, [platform]);

  return (
    <IDialog {...props}>
      {isLoading ? <Skeleton sx={{ bgcolor: '#D4D4D4' }} variant="rounded" width={400} height={618} animation="wave" /> :
        <QRcode src={QRCodeUrl} />}
      <CloseButton icon={<CloseIcon htmlColor="white" />} onClick={() => props.onClose?.({}, 'escapeKeyDown')} />
    </IDialog>
  );
};

export default WechatQRCodeDialog;
