/* tslint:disable */
/* eslint-disable */
/**
 * AOT-Backend
 * Backend for agencyoftime.com
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig as RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AdminStatsRes
 */
export interface AdminStatsRes {
    /**
     * 
     * @type {number}
     * @memberof AdminStatsRes
     */
    'users'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminStatsRes
     */
    'onlines'?: number;
    /**
     * 
     * @type {AdminStatsResSubscriptions}
     * @memberof AdminStatsRes
     */
    'subscriptions'?: AdminStatsResSubscriptions;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AdminStatsRes
     */
    'games'?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface AdminStatsResSubscriptions
 */
export interface AdminStatsResSubscriptions {
    /**
     * 
     * @type {number}
     * @memberof AdminStatsResSubscriptions
     */
    'PLAN_B': number;
    /**
     * 
     * @type {number}
     * @memberof AdminStatsResSubscriptions
     */
    'PLAN_C': number;
}
/**
 * 
 * @export
 * @interface ApproveCommentReq
 */
export interface ApproveCommentReq {
    /**
     * 
     * @type {string}
     * @memberof ApproveCommentReq
     */
    'commentId': string;
    /**
     * 
     * @type {boolean}
     * @memberof ApproveCommentReq
     */
    'approval': boolean;
}
/**
 * 
 * @export
 * @interface CancelSubscriptionRes
 */
export interface CancelSubscriptionRes {
    /**
     * 
     * @type {number}
     * @memberof CancelSubscriptionRes
     */
    'endDate': number;
}
/**
 * 
 * @export
 * @interface CharacterRes
 */
export interface CharacterRes {
    /**
     * 
     * @type {string}
     * @memberof CharacterRes
     */
    'avatar': string;
    /**
     * 
     * @type {string}
     * @memberof CharacterRes
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof CharacterRes
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof CharacterRes
     */
    'intro': string;
    /**
     * 
     * @type {string}
     * @memberof CharacterRes
     */
    'portrait'?: string;
    /**
     * 
     * @type {string}
     * @memberof CharacterRes
     */
    'cover'?: string;
    /**
     * 
     * @type {string}
     * @memberof CharacterRes
     */
    'quote'?: string;
    /**
     * 
     * @type {string}
     * @memberof CharacterRes
     */
    'voice'?: string;
}
/**
 * 
 * @export
 * @interface ChoiceRes
 */
export interface ChoiceRes {
    /**
     * 
     * @type {number}
     * @memberof ChoiceRes
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof ChoiceRes
     */
    'frontUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ChoiceRes
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof ChoiceRes
     */
    'status': string;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof ChoiceRes
     */
    'consents'?: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface ClueMemoryRes
 */
export interface ClueMemoryRes {
    /**
     * 
     * @type {string}
     * @memberof ClueMemoryRes
     */
    'memory': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClueMemoryRes
     */
    'characters': Array<string>;
}
/**
 * 
 * @export
 * @interface ClueRes
 */
export interface ClueRes {
    /**
     * 
     * @type {string}
     * @memberof ClueRes
     */
    'frontUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ClueRes
     */
    'backUrl': string;
    /**
     * 
     * @type {ClueResIsFound}
     * @memberof ClueRes
     */
    'isFound': ClueResIsFound;
    /**
     * 
     * @type {boolean}
     * @memberof ClueRes
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClueRes
     */
    'mustPublish'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClueRes
     */
    'keys'?: Array<string>;
    /**
     * 
     * @type {Array<ClueMemoryRes>}
     * @memberof ClueRes
     */
    'memories'?: Array<ClueMemoryRes>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClueRes
     */
    'choices'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClueRes
     */
    'secrets'?: Array<string>;
}
/**
 * @type ClueResIsFound
 * @export
 */
export type ClueResIsFound = boolean | string;

/**
 * 
 * @export
 * @interface CommentRes
 */
export interface CommentRes {
    /**
     * 
     * @type {string}
     * @memberof CommentRes
     */
    'storyId': string;
    /**
     * 
     * @type {string}
     * @memberof CommentRes
     */
    'userId': string;
    /**
     * 
     * @type {boolean}
     * @memberof CommentRes
     */
    'approval': boolean;
    /**
     * 
     * @type {number}
     * @memberof CommentRes
     */
    'createTime': number;
    /**
     * 
     * @type {number}
     * @memberof CommentRes
     */
    'rating': number;
    /**
     * 
     * @type {string}
     * @memberof CommentRes
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface CreateCheckoutSessionReq
 */
export interface CreateCheckoutSessionReq {
    /**
     * 
     * @type {StripeItem}
     * @memberof CreateCheckoutSessionReq
     */
    'item': StripeItem;
    /**
     * 
     * @type {number}
     * @memberof CreateCheckoutSessionReq
     */
    'quantity': number;
}


/**
 * 
 * @export
 * @interface CreateCheckoutSessionRes
 */
export interface CreateCheckoutSessionRes {
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutSessionRes
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface DiscordLoginReq
 */
export interface DiscordLoginReq {
    /**
     * 
     * @type {string}
     * @memberof DiscordLoginReq
     */
    'discordToken': string;
}
/**
 * 
 * @export
 * @interface DowngradeSubscriptionRes
 */
export interface DowngradeSubscriptionRes {
    /**
     * 
     * @type {number}
     * @memberof DowngradeSubscriptionRes
     */
    'effectiveDate': number;
}
/**
 * 
 * @export
 * @interface EmailLoginReq
 */
export interface EmailLoginReq {
    /**
     * 
     * @type {string}
     * @memberof EmailLoginReq
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof EmailLoginReq
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface GameAudit
 */
export interface GameAudit {
    /**
     * 
     * @type {string}
     * @memberof GameAudit
     */
    'storyId': string;
    /**
     * 
     * @type {string}
     * @memberof GameAudit
     */
    'creator': string;
    /**
     * 
     * @type {number}
     * @memberof GameAudit
     */
    'createTime'?: number;
    /**
     * 
     * @type {string}
     * @memberof GameAudit
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof GameAudit
     */
    'stage': string;
    /**
     * 
     * @type {number}
     * @memberof GameAudit
     */
    'gameTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof GameAudit
     */
    'numberOfPlayers': number;
    /**
     * 
     * @type {number}
     * @memberof GameAudit
     */
    'clueTurn'?: number;
    /**
     * 
     * @type {number}
     * @memberof GameAudit
     */
    'numberOfCluesFound': number;
    /**
     * 
     * @type {GameType}
     * @memberof GameAudit
     */
    'gameType': GameType;
    /**
     * 
     * @type {Array<string>}
     * @memberof GameAudit
     */
    'audiences'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: PlayerRes; }}
     * @memberof GameAudit
     */
    'players'?: { [key: string]: PlayerRes; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: MemoryRes; }; }}
     * @memberof GameAudit
     */
    'memories'?: { [key: string]: { [key: string]: MemoryRes; }; };
    /**
     * 
     * @type {{ [key: string]: RoomRes; }}
     * @memberof GameAudit
     */
    'rooms': { [key: string]: RoomRes; };
    /**
     * 
     * @type {{ [key: string]: ChoiceRes; }}
     * @memberof GameAudit
     */
    'choices'?: { [key: string]: ChoiceRes; };
    /**
     * 
     * @type {{ [key: string]: SecretRes; }}
     * @memberof GameAudit
     */
    'secrets'?: { [key: string]: SecretRes; };
    /**
     * 
     * @type {GameAuditHints}
     * @memberof GameAudit
     */
    'hints'?: GameAuditHints;
}


/**
 * 
 * @export
 * @interface GameAuditHints
 */
export interface GameAuditHints {
    /**
     * 
     * @type {number}
     * @memberof GameAuditHints
     */
    'remaining': number;
    /**
     * 
     * @type {{ [key: string]: HintRes; }}
     * @memberof GameAuditHints
     */
    'categories': { [key: string]: HintRes; };
}
/**
 * 
 * @export
 * @interface GameCreateReq
 */
export interface GameCreateReq {
    /**
     * 
     * @type {string}
     * @memberof GameCreateReq
     */
    'storyId': string;
    /**
     * 
     * @type {boolean}
     * @memberof GameCreateReq
     */
    'isVIPGame'?: boolean;
}
/**
 * 
 * @export
 * @interface GameCreateRes
 */
export interface GameCreateRes {
    /**
     * 
     * @type {string}
     * @memberof GameCreateRes
     */
    'gameId': string;
}
/**
 * 
 * @export
 * @interface GameThumbnail
 */
export interface GameThumbnail {
    /**
     * 
     * @type {string}
     * @memberof GameThumbnail
     */
    'storyId': string;
    /**
     * 
     * @type {number}
     * @memberof GameThumbnail
     */
    'createTime': number;
    /**
     * 
     * @type {string}
     * @memberof GameThumbnail
     */
    'status': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GameType = {
    General: 'GENERAL',
    HostByVip: 'HOST_BY_VIP',
    HostByDm: 'HOST_BY_DM',
    HostByRetailer: 'HOST_BY_RETAILER'
} as const;

export type GameType = typeof GameType[keyof typeof GameType];


/**
 * 
 * @export
 * @interface GoogleLoginReq
 */
export interface GoogleLoginReq {
    /**
     * 
     * @type {string}
     * @memberof GoogleLoginReq
     */
    'googleToken': string;
}
/**
 * 
 * @export
 * @interface HintRes
 */
export interface HintRes {
    /**
     * 
     * @type {boolean}
     * @memberof HintRes
     */
    'reveal': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof HintRes
     */
    'clues'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof HintRes
     */
    'textList': Array<string>;
}
/**
 * 
 * @export
 * @interface MemoryRes
 */
export interface MemoryRes {
    /**
     * 
     * @type {boolean}
     * @memberof MemoryRes
     */
    'isTriggered': boolean;
    /**
     * 
     * @type {string}
     * @memberof MemoryRes
     */
    'frontUrl': string;
    /**
     * 
     * @type {string}
     * @memberof MemoryRes
     */
    'backUrl': string;
    /**
     * 
     * @type {number}
     * @memberof MemoryRes
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof MemoryRes
     */
    'message1': string;
    /**
     * 
     * @type {string}
     * @memberof MemoryRes
     */
    'message2': string;
}
/**
 * 
 * @export
 * @interface PlayerRes
 */
export interface PlayerRes {
    /**
     * 
     * @type {string}
     * @memberof PlayerRes
     */
    'character'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerRes
     */
    'isOnline'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerRes
     */
    'isReady'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerRes
     */
    'hasRead'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PlayerRes
     */
    'hasIntroduced'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlayerRes
     */
    'votedMurderers'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PlayerRes
     */
    'votedClue'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlayerRes
     */
    'privateClues'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PlayerRes
     */
    'clipedClues'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof PlayerRes
     */
    'extraHint'?: string;
    /**
     * 
     * @type {string}
     * @memberof PlayerRes
     */
    'votedHint'?: string;
}
/**
 * 
 * @export
 * @interface ProcessCheckoutSessionReq
 */
export interface ProcessCheckoutSessionReq {
    /**
     * 
     * @type {string}
     * @memberof ProcessCheckoutSessionReq
     */
    'sessionId': string;
}
/**
 * 
 * @export
 * @interface QRCodeRes
 */
export interface QRCodeRes {
    /**
     * 
     * @type {string}
     * @memberof QRCodeRes
     */
    'uri': string;
}
/**
 * 
 * @export
 * @interface RefreshTokenReq
 */
export interface RefreshTokenReq {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenReq
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface RefreshTokenRes
 */
export interface RefreshTokenRes {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenRes
     */
    'accessToken': string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenRes
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface RoomRes
 */
export interface RoomRes {
    /**
     * 
     * @type {boolean}
     * @memberof RoomRes
     */
    'isLocked'?: boolean;
    /**
     * 
     * @type {{ [key: string]: ClueRes; }}
     * @memberof RoomRes
     */
    'clues': { [key: string]: ClueRes; };
}
/**
 * 
 * @export
 * @interface SecretRes
 */
export interface SecretRes {
    /**
     * 
     * @type {string}
     * @memberof SecretRes
     */
    'frontUrl': string;
    /**
     * 
     * @type {string}
     * @memberof SecretRes
     */
    'backUrl': string;
    /**
     * 
     * @type {string}
     * @memberof SecretRes
     */
    'isFound'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SecretRes
     */
    'isPublic': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SecretRes
     */
    'mustPublish'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SecretRes
     */
    'isAvailable': boolean;
    /**
     * 
     * @type {string}
     * @memberof SecretRes
     */
    'condition'?: string;
    /**
     * 
     * @type {string}
     * @memberof SecretRes
     */
    'conditionText'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SecretRes
     */
    'secrets'?: Array<string>;
    /**
     * 
     * @type {Array<ClueMemoryRes>}
     * @memberof SecretRes
     */
    'memories'?: Array<ClueMemoryRes>;
}
/**
 * 
 * @export
 * @interface SendCodeReq
 */
export interface SendCodeReq {
    /**
     * 
     * @type {string}
     * @memberof SendCodeReq
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface StoryCommentRes
 */
export interface StoryCommentRes {
    /**
     * 
     * @type {string}
     * @memberof StoryCommentRes
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof StoryCommentRes
     */
    'avatar': string;
    /**
     * 
     * @type {number}
     * @memberof StoryCommentRes
     */
    'createTime': number;
    /**
     * 
     * @type {number}
     * @memberof StoryCommentRes
     */
    'rating': number;
    /**
     * 
     * @type {string}
     * @memberof StoryCommentRes
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface StoryRes
 */
export interface StoryRes {
    /**
     * 
     * @type {string}
     * @memberof StoryRes
     */
    'storyName': string;
    /**
     * 
     * @type {string}
     * @memberof StoryRes
     */
    'storyDescription': string;
    /**
     * 
     * @type {{ [key: string]: CharacterRes; }}
     * @memberof StoryRes
     */
    'characterList': { [key: string]: CharacterRes; };
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StripeItem = {
    PlanB: 'PLAN_B',
    PlanC: 'PLAN_C',
    GameToken: 'GAME_TOKEN',
    Dm: 'DM',
    Retailer: 'RETAILER'
} as const;

export type StripeItem = typeof StripeItem[keyof typeof StripeItem];


/**
 * 
 * @export
 * @interface SubmitCommentReq
 */
export interface SubmitCommentReq {
    /**
     * 
     * @type {string}
     * @memberof SubmitCommentReq
     */
    'storyId': string;
    /**
     * 
     * @type {number}
     * @memberof SubmitCommentReq
     */
    'rating': number;
    /**
     * 
     * @type {string}
     * @memberof SubmitCommentReq
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface SubscriptionRes
 */
export interface SubscriptionRes {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionRes
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionRes
     */
    'periodEndDate'?: number;
}
/**
 * 
 * @export
 * @interface UpgradeSubscriptionRes
 */
export interface UpgradeSubscriptionRes {
    /**
     * 
     * @type {number}
     * @memberof UpgradeSubscriptionRes
     */
    'nextBillDate': number;
}
/**
 * 
 * @export
 * @interface UserPatchReq
 */
export interface UserPatchReq {
    /**
     * 
     * @type {string}
     * @memberof UserPatchReq
     */
    'nickname'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPatchReq
     */
    'avatar'?: string;
}
/**
 * 
 * @export
 * @interface UserRes
 */
export interface UserRes {
    /**
     * 
     * @type {string}
     * @memberof UserRes
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof UserRes
     */
    'nickname': string;
    /**
     * 
     * @type {string}
     * @memberof UserRes
     */
    'avatar': string;
    /**
     * 
     * @type {string}
     * @memberof UserRes
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRes
     */
    'accessToken': string;
    /**
     * 
     * @type {string}
     * @memberof UserRes
     */
    'refreshToken': string;
    /**
     * 
     * @type {UserRole}
     * @memberof UserRes
     */
    'role': UserRole;
    /**
     * 
     * @type {number}
     * @memberof UserRes
     */
    'gameToken': number;
    /**
     * 
     * @type {number}
     * @memberof UserRes
     */
    'coupon'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserRes
     */
    'newUser'?: boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const UserRole = {
    PlanA: 'PLAN_A',
    PlanB: 'PLAN_B',
    PlanC: 'PLAN_C',
    Dm: 'DM',
    Retailer: 'RETAILER',
    Admin: 'ADMIN'
} as const;

export type UserRole = typeof UserRole[keyof typeof UserRole];


/**
 * 
 * @export
 * @interface UserThumbnail
 */
export interface UserThumbnail {
    /**
     * 
     * @type {string}
     * @memberof UserThumbnail
     */
    'nickname': string;
    /**
     * 
     * @type {string}
     * @memberof UserThumbnail
     */
    'avatar': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Approve Comment
         * @param {ApproveCommentReq} approveCommentReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveComment: async (approveCommentReq: ApproveCommentReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'approveCommentReq' is not null or undefined
            assertParamExists('approveComment', 'approveCommentReq', approveCommentReq)
            const localVarPath = `/comment/approval`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(approveCommentReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/store/subscription/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Checkout Session
         * @param {CreateCheckoutSessionReq} createCheckoutSessionReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCheckoutSession: async (createCheckoutSessionReq: CreateCheckoutSessionReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCheckoutSessionReq' is not null or undefined
            assertParamExists('createCheckoutSession', 'createCheckoutSessionReq', createCheckoutSessionReq)
            const localVarPath = `/store/create-checkout-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCheckoutSessionReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new game
         * @param {GameCreateReq} gameCreateReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGame: async (gameCreateReq: GameCreateReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameCreateReq' is not null or undefined
            assertParamExists('createGame', 'gameCreateReq', gameCreateReq)
            const localVarPath = `/game/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gameCreateReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Downgrade Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downgradeSubscription: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/store/subscription/downgrade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch comments for story
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCommentForStory: async (storyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storyId' is not null or undefined
            assertParamExists('fetchCommentForStory', 'storyId', storyId)
            const localVarPath = `/comment/${storyId}`
                .replace(`{${"storyId"}}`, encodeURIComponent(String(storyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Force Update Game
         * @param {string} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceUpdateGame: async (gameId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            assertParamExists('forceUpdateGame', 'gameId', gameId)
            const localVarPath = `/admin/game/{gameId}/update`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get comment list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Current Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentSubscription: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get game list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGameList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/games`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get QR code of a platform
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQRCode: async (platform: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'platform' is not null or undefined
            assertParamExists('getQRCode', 'platform', platform)
            const localVarPath = `/general/qrcodes/{platform}`
                .replace(`{${"platform"}}`, encodeURIComponent(String(platform)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStats: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get story detail
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoryDetail: async (storyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storyId' is not null or undefined
            assertParamExists('getStoryDetail', 'storyId', storyId)
            const localVarPath = `/game/story/{storyId}`
                .replace(`{${"storyId"}}`, encodeURIComponent(String(storyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Truth For Game
         * @param {string} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTruthForGame: async (gameId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gameId' is not null or undefined
            assertParamExists('getTruthForGame', 'gameId', gameId)
            const localVarPath = `/game/truth/${gameId}`
                .replace(`{${"gameId"}}`, encodeURIComponent(String(gameId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCases: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/cases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user thumbnail by userId
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserThumbnail: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserThumbnail', 'userId', userId)
            const localVarPath = `/user/{userId}/thumbnail`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User login with discord
         * @param {DiscordLoginReq} discordLoginReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithDiscord: async (discordLoginReq: DiscordLoginReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'discordLoginReq' is not null or undefined
            assertParamExists('loginWithDiscord', 'discordLoginReq', discordLoginReq)
            const localVarPath = `/auth/login/discord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discordLoginReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User login with email
         * @param {EmailLoginReq} emailLoginReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithEmail: async (emailLoginReq: EmailLoginReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailLoginReq' is not null or undefined
            assertParamExists('loginWithEmail', 'emailLoginReq', emailLoginReq)
            const localVarPath = `/auth/login/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailLoginReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User login with google
         * @param {GoogleLoginReq} googleLoginReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithGoogle: async (googleLoginReq: GoogleLoginReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'googleLoginReq' is not null or undefined
            assertParamExists('loginWithGoogle', 'googleLoginReq', googleLoginReq)
            const localVarPath = `/auth/login/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleLoginReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User login with token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithToken: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/login/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Process Session
         * @param {ProcessCheckoutSessionReq} processCheckoutSessionReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processCheckoutSession: async (processCheckoutSessionReq: ProcessCheckoutSessionReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'processCheckoutSessionReq' is not null or undefined
            assertParamExists('processCheckoutSession', 'processCheckoutSessionReq', processCheckoutSessionReq)
            const localVarPath = `/store/process-checkout-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processCheckoutSessionReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send Verification Code
         * @param {SendCodeReq} sendCodeReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendVerificationCode: async (sendCodeReq: SendCodeReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendCodeReq' is not null or undefined
            assertParamExists('sendVerificationCode', 'sendCodeReq', sendCodeReq)
            const localVarPath = `/auth/send-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendCodeReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit new comment
         * @param {SubmitCommentReq} submitCommentReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitComment: async (submitCommentReq: SubmitCommentReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submitCommentReq' is not null or undefined
            assertParamExists('submitComment', 'submitCommentReq', submitCommentReq)
            const localVarPath = `/comment/submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitCommentReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uncancel Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uncancelSubscription: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/store/subscription/uncancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user info
         * @param {UserPatchReq} userPatchReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserInfo: async (userPatchReq: UserPatchReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPatchReq' is not null or undefined
            assertParamExists('updateUserInfo', 'userPatchReq', userPatchReq)
            const localVarPath = `/user/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPatchReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upgrade Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upgradeSubscription: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/store/subscription/upgrade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify Refresh Token
         * @param {RefreshTokenReq} refreshTokenReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyRefreshToken: async (refreshTokenReq: RefreshTokenReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshTokenReq' is not null or undefined
            assertParamExists('verifyRefreshToken', 'refreshTokenReq', refreshTokenReq)
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Approve Comment
         * @param {ApproveCommentReq} approveCommentReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveComment(approveCommentReq: ApproveCommentReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveComment(approveCommentReq, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.approveComment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Cancel Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSubscription(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancelSubscriptionRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSubscription(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.cancelSubscription']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create Checkout Session
         * @param {CreateCheckoutSessionReq} createCheckoutSessionReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCheckoutSession(createCheckoutSessionReq: CreateCheckoutSessionReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateCheckoutSessionRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCheckoutSession(createCheckoutSessionReq, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.createCheckoutSession']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create new game
         * @param {GameCreateReq} gameCreateReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGame(gameCreateReq: GameCreateReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GameCreateRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGame(gameCreateReq, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.createGame']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Downgrade Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downgradeSubscription(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DowngradeSubscriptionRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downgradeSubscription(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.downgradeSubscription']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Fetch comments for story
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchCommentForStory(storyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: StoryCommentRes; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchCommentForStory(storyId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.fetchCommentForStory']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Force Update Game
         * @param {string} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forceUpdateGame(gameId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forceUpdateGame(gameId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.forceUpdateGame']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get comment list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: CommentRes; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentList(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getCommentList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Current Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentSubscription(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentSubscription(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getCurrentSubscription']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get game list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGameList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: GameAudit; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGameList(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getGameList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get QR code of a platform
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQRCode(platform: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QRCodeRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQRCode(platform, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getQRCode']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStats(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminStatsRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStats(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getStats']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get story detail
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoryDetail(storyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoryRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoryDetail(storyId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getStoryDetail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Truth For Game
         * @param {string} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTruthForGame(gameId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArrayBuffer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTruthForGame(gameId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getTruthForGame']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get User Cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCases(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: GameThumbnail; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCases(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getUserCases']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get user thumbnail by userId
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserThumbnail(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserThumbnail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserThumbnail(userId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getUserThumbnail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary User login with discord
         * @param {DiscordLoginReq} discordLoginReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginWithDiscord(discordLoginReq: DiscordLoginReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginWithDiscord(discordLoginReq, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.loginWithDiscord']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary User login with email
         * @param {EmailLoginReq} emailLoginReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginWithEmail(emailLoginReq: EmailLoginReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginWithEmail(emailLoginReq, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.loginWithEmail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary User login with google
         * @param {GoogleLoginReq} googleLoginReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginWithGoogle(googleLoginReq: GoogleLoginReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginWithGoogle(googleLoginReq, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.loginWithGoogle']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary User login with token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginWithToken(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginWithToken(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.loginWithToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Process Session
         * @param {ProcessCheckoutSessionReq} processCheckoutSessionReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processCheckoutSession(processCheckoutSessionReq: ProcessCheckoutSessionReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processCheckoutSession(processCheckoutSessionReq, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.processCheckoutSession']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send Verification Code
         * @param {SendCodeReq} sendCodeReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendVerificationCode(sendCodeReq: SendCodeReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendVerificationCode(sendCodeReq, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.sendVerificationCode']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Submit new comment
         * @param {SubmitCommentReq} submitCommentReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitComment(submitCommentReq: SubmitCommentReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitComment(submitCommentReq, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.submitComment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Uncancel Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uncancelSubscription(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uncancelSubscription(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.uncancelSubscription']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update user info
         * @param {UserPatchReq} userPatchReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserInfo(userPatchReq: UserPatchReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserInfo(userPatchReq, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.updateUserInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Upgrade Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upgradeSubscription(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpgradeSubscriptionRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upgradeSubscription(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.upgradeSubscription']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Verify Refresh Token
         * @param {RefreshTokenReq} refreshTokenReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyRefreshToken(refreshTokenReq: RefreshTokenReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshTokenRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyRefreshToken(refreshTokenReq, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.verifyRefreshToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Approve Comment
         * @param {ApproveCommentReq} approveCommentReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveComment(approveCommentReq: ApproveCommentReq, options?: any): AxiosPromise<void> {
            return localVarFp.approveComment(approveCommentReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription(options?: any): AxiosPromise<CancelSubscriptionRes> {
            return localVarFp.cancelSubscription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Checkout Session
         * @param {CreateCheckoutSessionReq} createCheckoutSessionReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCheckoutSession(createCheckoutSessionReq: CreateCheckoutSessionReq, options?: any): AxiosPromise<CreateCheckoutSessionRes> {
            return localVarFp.createCheckoutSession(createCheckoutSessionReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new game
         * @param {GameCreateReq} gameCreateReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGame(gameCreateReq: GameCreateReq, options?: any): AxiosPromise<GameCreateRes> {
            return localVarFp.createGame(gameCreateReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Downgrade Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downgradeSubscription(options?: any): AxiosPromise<DowngradeSubscriptionRes> {
            return localVarFp.downgradeSubscription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch comments for story
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchCommentForStory(storyId: string, options?: any): AxiosPromise<{ [key: string]: StoryCommentRes; }> {
            return localVarFp.fetchCommentForStory(storyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Force Update Game
         * @param {string} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceUpdateGame(gameId: string, options?: any): AxiosPromise<void> {
            return localVarFp.forceUpdateGame(gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get comment list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentList(options?: any): AxiosPromise<{ [key: string]: CommentRes; }> {
            return localVarFp.getCommentList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Current Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentSubscription(options?: any): AxiosPromise<SubscriptionRes> {
            return localVarFp.getCurrentSubscription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get game list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGameList(options?: any): AxiosPromise<{ [key: string]: GameAudit; }> {
            return localVarFp.getGameList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get QR code of a platform
         * @param {string} platform 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQRCode(platform: string, options?: any): AxiosPromise<QRCodeRes> {
            return localVarFp.getQRCode(platform, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStats(options?: any): AxiosPromise<AdminStatsRes> {
            return localVarFp.getStats(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get story detail
         * @param {string} storyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoryDetail(storyId: string, options?: any): AxiosPromise<StoryRes> {
            return localVarFp.getStoryDetail(storyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Truth For Game
         * @param {string} gameId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTruthForGame(gameId: string, options?: any): AxiosPromise<ArrayBuffer> {
            return localVarFp.getTruthForGame(gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User Cases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCases(options?: any): AxiosPromise<{ [key: string]: GameThumbnail; }> {
            return localVarFp.getUserCases(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user thumbnail by userId
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserThumbnail(userId: string, options?: any): AxiosPromise<UserThumbnail> {
            return localVarFp.getUserThumbnail(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User login with discord
         * @param {DiscordLoginReq} discordLoginReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithDiscord(discordLoginReq: DiscordLoginReq, options?: any): AxiosPromise<UserRes> {
            return localVarFp.loginWithDiscord(discordLoginReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User login with email
         * @param {EmailLoginReq} emailLoginReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithEmail(emailLoginReq: EmailLoginReq, options?: any): AxiosPromise<UserRes> {
            return localVarFp.loginWithEmail(emailLoginReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User login with google
         * @param {GoogleLoginReq} googleLoginReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithGoogle(googleLoginReq: GoogleLoginReq, options?: any): AxiosPromise<UserRes> {
            return localVarFp.loginWithGoogle(googleLoginReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User login with token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithToken(options?: any): AxiosPromise<UserRes> {
            return localVarFp.loginWithToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Process Session
         * @param {ProcessCheckoutSessionReq} processCheckoutSessionReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processCheckoutSession(processCheckoutSessionReq: ProcessCheckoutSessionReq, options?: any): AxiosPromise<void> {
            return localVarFp.processCheckoutSession(processCheckoutSessionReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send Verification Code
         * @param {SendCodeReq} sendCodeReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendVerificationCode(sendCodeReq: SendCodeReq, options?: any): AxiosPromise<void> {
            return localVarFp.sendVerificationCode(sendCodeReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit new comment
         * @param {SubmitCommentReq} submitCommentReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitComment(submitCommentReq: SubmitCommentReq, options?: any): AxiosPromise<void> {
            return localVarFp.submitComment(submitCommentReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uncancel Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uncancelSubscription(options?: any): AxiosPromise<void> {
            return localVarFp.uncancelSubscription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user info
         * @param {UserPatchReq} userPatchReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserInfo(userPatchReq: UserPatchReq, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserInfo(userPatchReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upgrade Subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upgradeSubscription(options?: any): AxiosPromise<UpgradeSubscriptionRes> {
            return localVarFp.upgradeSubscription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify Refresh Token
         * @param {RefreshTokenReq} refreshTokenReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyRefreshToken(refreshTokenReq: RefreshTokenReq, options?: any): AxiosPromise<RefreshTokenRes> {
            return localVarFp.verifyRefreshToken(refreshTokenReq, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - interface
 * @export
 * @interface DefaultApi
 */
export interface DefaultApiInterface {
    /**
     * 
     * @summary Approve Comment
     * @param {ApproveCommentReq} approveCommentReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    approveComment(approveCommentReq: ApproveCommentReq, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Cancel Subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    cancelSubscription(options?: RawAxiosRequestConfig): AxiosPromise<CancelSubscriptionRes>;

    /**
     * 
     * @summary Create Checkout Session
     * @param {CreateCheckoutSessionReq} createCheckoutSessionReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createCheckoutSession(createCheckoutSessionReq: CreateCheckoutSessionReq, options?: RawAxiosRequestConfig): AxiosPromise<CreateCheckoutSessionRes>;

    /**
     * 
     * @summary Create new game
     * @param {GameCreateReq} gameCreateReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    createGame(gameCreateReq: GameCreateReq, options?: RawAxiosRequestConfig): AxiosPromise<GameCreateRes>;

    /**
     * 
     * @summary Downgrade Subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    downgradeSubscription(options?: RawAxiosRequestConfig): AxiosPromise<DowngradeSubscriptionRes>;

    /**
     * 
     * @summary Fetch comments for story
     * @param {string} storyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    fetchCommentForStory(storyId: string, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: StoryCommentRes; }>;

    /**
     * 
     * @summary Force Update Game
     * @param {string} gameId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    forceUpdateGame(gameId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Get comment list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getCommentList(options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: CommentRes; }>;

    /**
     * 
     * @summary Get Current Subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getCurrentSubscription(options?: RawAxiosRequestConfig): AxiosPromise<SubscriptionRes>;

    /**
     * 
     * @summary Get game list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getGameList(options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: GameAudit; }>;

    /**
     * 
     * @summary Get QR code of a platform
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getQRCode(platform: string, options?: RawAxiosRequestConfig): AxiosPromise<QRCodeRes>;

    /**
     * 
     * @summary Get Stats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getStats(options?: RawAxiosRequestConfig): AxiosPromise<AdminStatsRes>;

    /**
     * 
     * @summary Get story detail
     * @param {string} storyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getStoryDetail(storyId: string, options?: RawAxiosRequestConfig): AxiosPromise<StoryRes>;

    /**
     * 
     * @summary Get Truth For Game
     * @param {string} gameId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getTruthForGame(gameId: string, options?: RawAxiosRequestConfig): AxiosPromise<ArrayBuffer>;

    /**
     * 
     * @summary Get User Cases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getUserCases(options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: GameThumbnail; }>;

    /**
     * 
     * @summary Get user thumbnail by userId
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getUserThumbnail(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<UserThumbnail>;

    /**
     * 
     * @summary User login with discord
     * @param {DiscordLoginReq} discordLoginReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    loginWithDiscord(discordLoginReq: DiscordLoginReq, options?: RawAxiosRequestConfig): AxiosPromise<UserRes>;

    /**
     * 
     * @summary User login with email
     * @param {EmailLoginReq} emailLoginReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    loginWithEmail(emailLoginReq: EmailLoginReq, options?: RawAxiosRequestConfig): AxiosPromise<UserRes>;

    /**
     * 
     * @summary User login with google
     * @param {GoogleLoginReq} googleLoginReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    loginWithGoogle(googleLoginReq: GoogleLoginReq, options?: RawAxiosRequestConfig): AxiosPromise<UserRes>;

    /**
     * 
     * @summary User login with token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    loginWithToken(options?: RawAxiosRequestConfig): AxiosPromise<UserRes>;

    /**
     * 
     * @summary Process Session
     * @param {ProcessCheckoutSessionReq} processCheckoutSessionReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    processCheckoutSession(processCheckoutSessionReq: ProcessCheckoutSessionReq, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Send Verification Code
     * @param {SendCodeReq} sendCodeReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    sendVerificationCode(sendCodeReq: SendCodeReq, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Submit new comment
     * @param {SubmitCommentReq} submitCommentReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    submitComment(submitCommentReq: SubmitCommentReq, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Uncancel Subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    uncancelSubscription(options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Update user info
     * @param {UserPatchReq} userPatchReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    updateUserInfo(userPatchReq: UserPatchReq, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Upgrade Subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    upgradeSubscription(options?: RawAxiosRequestConfig): AxiosPromise<UpgradeSubscriptionRes>;

    /**
     * 
     * @summary Verify Refresh Token
     * @param {RefreshTokenReq} refreshTokenReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    verifyRefreshToken(refreshTokenReq: RefreshTokenReq, options?: RawAxiosRequestConfig): AxiosPromise<RefreshTokenRes>;

}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI implements DefaultApiInterface {
    /**
     * 
     * @summary Approve Comment
     * @param {ApproveCommentReq} approveCommentReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public approveComment(approveCommentReq: ApproveCommentReq, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).approveComment(approveCommentReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel Subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cancelSubscription(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cancelSubscription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Checkout Session
     * @param {CreateCheckoutSessionReq} createCheckoutSessionReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createCheckoutSession(createCheckoutSessionReq: CreateCheckoutSessionReq, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createCheckoutSession(createCheckoutSessionReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new game
     * @param {GameCreateReq} gameCreateReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createGame(gameCreateReq: GameCreateReq, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createGame(gameCreateReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Downgrade Subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downgradeSubscription(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).downgradeSubscription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch comments for story
     * @param {string} storyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fetchCommentForStory(storyId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fetchCommentForStory(storyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Force Update Game
     * @param {string} gameId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public forceUpdateGame(gameId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).forceUpdateGame(gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get comment list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCommentList(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCommentList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Current Subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCurrentSubscription(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCurrentSubscription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get game list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGameList(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getGameList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get QR code of a platform
     * @param {string} platform 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getQRCode(platform: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getQRCode(platform, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Stats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStats(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getStats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get story detail
     * @param {string} storyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStoryDetail(storyId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getStoryDetail(storyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Truth For Game
     * @param {string} gameId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTruthForGame(gameId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTruthForGame(gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User Cases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserCases(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUserCases(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user thumbnail by userId
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserThumbnail(userId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUserThumbnail(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User login with discord
     * @param {DiscordLoginReq} discordLoginReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public loginWithDiscord(discordLoginReq: DiscordLoginReq, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).loginWithDiscord(discordLoginReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User login with email
     * @param {EmailLoginReq} emailLoginReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public loginWithEmail(emailLoginReq: EmailLoginReq, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).loginWithEmail(emailLoginReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User login with google
     * @param {GoogleLoginReq} googleLoginReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public loginWithGoogle(googleLoginReq: GoogleLoginReq, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).loginWithGoogle(googleLoginReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User login with token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public loginWithToken(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).loginWithToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Process Session
     * @param {ProcessCheckoutSessionReq} processCheckoutSessionReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public processCheckoutSession(processCheckoutSessionReq: ProcessCheckoutSessionReq, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).processCheckoutSession(processCheckoutSessionReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send Verification Code
     * @param {SendCodeReq} sendCodeReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendVerificationCode(sendCodeReq: SendCodeReq, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).sendVerificationCode(sendCodeReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit new comment
     * @param {SubmitCommentReq} submitCommentReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public submitComment(submitCommentReq: SubmitCommentReq, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).submitComment(submitCommentReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uncancel Subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uncancelSubscription(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).uncancelSubscription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user info
     * @param {UserPatchReq} userPatchReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUserInfo(userPatchReq: UserPatchReq, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateUserInfo(userPatchReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upgrade Subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public upgradeSubscription(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).upgradeSubscription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify Refresh Token
     * @param {RefreshTokenReq} refreshTokenReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public verifyRefreshToken(refreshTokenReq: RefreshTokenReq, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).verifyRefreshToken(refreshTokenReq, options).then((request) => request(this.axios, this.basePath));
    }
}



