import styled from '@emotion/styled';
import { DialogProps } from '@mui/material';
import { FullDialog } from 'lib/common/FullDialog';
import { FC, useState } from 'react';
import useSound from 'use-sound';
import { useInterval, useTimeout } from 'utils/hooks';
import { BlackOutKeyframes, SleepyKeyframes } from 'utils/keyframes';
import TypingSound from 'assets/sound/typing.mp3';
import ThunderSound from 'assets/sound/thunder2.mp3';
import GunshotSound from 'assets/sound/revolvershot.mp3';
import VaseFallSound from 'assets/sound/vasefall.mp3';

const TransitionContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  animation: ${SleepyKeyframes} 38.5s linear both;
`;

const TextWrapper = styled.div`
  width: 100vw;
  height: 400px;
  border: white solid;
  border-width: 3px 0 3px 0;
  background-color: #090909;
  color: white;
  font-family: 'STZhongSong';
  text-align: center;
  transition: opacity 1s ease-in-out 5s;
`;

const BlackoutMask = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: black;
  z-index: 1;
  opacity: 0;
  animation: ${BlackOutKeyframes} 28.5s ease-in-out both;
`;

const Title = styled.div`
  font-size: 32px;
  margin-top: 15px;
`;

const Text = styled.div`
  font-size: 40px;
  margin-top: 20px;
  line-height: 2em;
  margin-left: 1em;
`;

export interface YLSX_StageTransitionProps extends DialogProps { }

const YLSX_StageTransition1: FC<YLSX_StageTransitionProps> = function ({
  ...props
}) {

  const text1 = '很快到了晚饭时间，众人在餐桌前入座。';
  const text2 = '闵嘉云邀请大家尝尝剁椒鱼头，而易温却突然站起来，亲自为每个人盛了一碗老鸭汤。';
  const text3 = '在你喝了汤之后，只感到一阵昏昏欲睡……';

  const [animatedText1, setAnimatedText1] = useState<string>('');
  const [animatedText2, setAnimatedText2] = useState<string>('');
  const [animatedText3, setAnimatedText3] = useState<string>('');
  const [hideText, setHideText] = useState<boolean>(false);

  const typingSpeed = 120;
  const linePause = 500;

  const [playTypingSound] = useSound(TypingSound, { volume: 0.3, playbackRate: 1 });
  const [playThunderSound] = useSound(ThunderSound, { volume: 0.5 });
  const [playGunshotSound] = useSound(GunshotSound, { volume: 0.6 });
  const [playVaseFallSound] = useSound(VaseFallSound, { volume: 0.8 });

  useInterval(() => {
    setAnimatedText1(text1.slice(0, animatedText1.length + 1));
  }, props.open ? (animatedText1.length === text1.length ? null : animatedText1.length === 0 ? 2000 : typingSpeed) : null);

  useInterval(() => {
    setAnimatedText2(text2.slice(0, animatedText2.length + 1));
  }, props.open ? (animatedText2.length === text2.length ? null : animatedText2.length === 0 ? 2000 + text1.length * typingSpeed + linePause : typingSpeed) : null);

  useInterval(() => {
    setAnimatedText3(text3.slice(0, animatedText3.length + 1));
  }, props.open ? (animatedText3.length === text3.length ? null : animatedText3.length === 0 ? 2000 + (text1.length + text2.length) * typingSpeed + linePause * 2 : typingSpeed) : null);

  useTimeout(
    () => {
      playTypingSound();
    },
    props.open ? 2000 : null
  );

  useTimeout(
    () => {
      playThunderSound();
    },
    props.open ? 2000 + typingSpeed * (text1.length + text2.length + text3.length) + linePause * 2 + 1000 + 10000 : null
  );

  useTimeout(
    () => {
      playGunshotSound();
      setHideText(true);
    },
    props.open ? 2000 + typingSpeed * (text1.length + text2.length + text3.length) + linePause * 2 + 6000 + 10000 : null
  );

  useTimeout(
    () => {
      playVaseFallSound();
    },
    props.open ? 2000 + typingSpeed * (text1.length + text2.length + text3.length) + linePause * 2 + 9000 + 10000 : null
  );

  return (
    <FullDialog disableEscapeKeyDown {...props}>
      <TransitionContainer style={{ animationDelay: 2000 + typingSpeed * (text1.length + text2.length + text3.length) + linePause * 2 + 500 + 'ms' }} onAnimationEnd={() => props.onClose?.({}, 'escapeKeyDown')}>
        <BlackoutMask style={{ animationDelay: 2000 + typingSpeed * (text1.length + text2.length + text3.length) + linePause * 2 + 7500 + 'ms' }} />
        <TextWrapper hidden={hideText}>
          <Title>
            麓下馆时间
            <br />
            18:50
          </Title>
          <Text>
            {animatedText1}
            <br />
            {animatedText2}
            <br />
            {animatedText3}
            <br />
          </Text>
        </TextWrapper>
      </TransitionContainer>
    </FullDialog>
  );
};

const YLSX_StageTransition2: FC<YLSX_StageTransitionProps> = function ({
  ...props
}) {

  const text1 = '经历了此前的集体调查后，宋笃程的尸体已经开始变得僵硬。';
  const text2 = '大家默默看着时钟，各自回房，却紧接着传来了女人的尖叫声。';
  const text3 = '这时，大家才意识到原来今天的惨案并未结束……';

  const [animatedText1, setAnimatedText1] = useState<string>('');
  const [animatedText2, setAnimatedText2] = useState<string>('');
  const [animatedText3, setAnimatedText3] = useState<string>('');

  const typingSpeed = 120;
  const linePause = 500;

  const [playTypingSound] = useSound(TypingSound, { volume: 0.3, playbackRate: 0.95 });

  useInterval(() => {
    setAnimatedText1(text1.slice(0, animatedText1.length + 1));
  }, props.open ? (animatedText1.length === text1.length ? null : animatedText1.length === 0 ? 2000 : typingSpeed) : null);

  useInterval(() => {
    setAnimatedText2(text2.slice(0, animatedText2.length + 1));
  }, props.open ? (animatedText2.length === text2.length ? null : animatedText2.length === 0 ? 2000 + text1.length * typingSpeed + linePause : typingSpeed) : null);

  useInterval(() => {
    setAnimatedText3(text3.slice(0, animatedText3.length + 1));
  }, props.open ? (animatedText3.length === text3.length ? null : animatedText3.length === 0 ? 2000 + (text1.length + text2.length) * typingSpeed + linePause * 2 : typingSpeed) : null);

  useTimeout(
    () => {
      playTypingSound();
    },
    props.open ? 2000 : null
  );

  return (
    <FullDialog disableEscapeKeyDown {...props}>
      <TextWrapper style={{ opacity: animatedText3 === text3 ? '0' : '1' }} onTransitionEnd={() => props.onClose?.({}, 'escapeKeyDown')}>
        <Title>
          麓下馆时间
          <br />
          20:00
        </Title>
        <Text>
          {animatedText1}
          <br />
          {animatedText2}
          <br />
          {animatedText3}
          <br />
        </Text>
      </TextWrapper>
    </FullDialog>
  );
};

export { YLSX_StageTransition1, YLSX_StageTransition2 };
