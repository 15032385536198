enum RouterPath {
  Home = '/',
  Game = '/game',
  Admin = '/admin',
  PrivacyPolicy = '/privacy-policy',
  TermsAndCondtions = '/terms-and-conditions',
  Recruit = '/recruit',
  FAQ = '/faq',
  Truth = '/truth'
}

export default RouterPath;