import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import { CommonUIProps } from 'utils/types';
import { UserThumbnail } from 'openapi';
import { Stack } from '@mui/material';
import { parseAvatarUrl } from 'utils/utils';
import { ThemeButton } from 'lib/common/ThemeButton';
import CommonAvatar from 'lib/common/CommonAvatar';


const PageContainer = styled.div`
  height: 100dvh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageTitle = styled.div`
  color: white;
  font-size: 48px;
  text-align: center;
  margin-top: 32px;
`;

const RemainingPlayerStack = styled(Stack)`
  margin-top: 24px;
  height: 90px;
`;

const PlayerWrapper = styled(Stack)`
  width: 120px;
`;

const PlayerAvatar = styled(CommonAvatar)`
  height: 60px;
  width: 60px;
`;

const PlayerNickname = styled.div`
  width: 100%;
  font-size: 16px;
  font-family: 微软雅黑;
  text-align: center;
  margin-top: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: white;
`;

const ButtonStack = styled(Stack)`
  margin-top: 12px;
  margin-bottom: 32px;
`;

export interface CharacterPageProps extends CommonUIProps {
  isCreator: boolean;
  currentCharacter?: string;
  isReady?: boolean;
  isAllReady: boolean;
  backgroundUrl?: string;
  storyLayout: ReactNode;
  remainingPlayers: Partial<Record<string, Partial<UserThumbnail>>>;
  onReadyButtonClicked: () => void;
  onNextButtonClicked: () => void;
  showReadyButton: boolean;
}

export const CharacterPage: FC<CharacterPageProps> = function ({
  isCreator,
  currentCharacter,
  isReady,
  isAllReady,
  backgroundUrl,
  storyLayout,
  remainingPlayers,
  onReadyButtonClicked,
  onNextButtonClicked,
  showReadyButton,
  ...props
}) {
  return (
    <PageContainer style={{ backgroundImage: `url(${backgroundUrl})` }} {...props}>
      <PageTitle>角色选择</PageTitle>
      {storyLayout}
      <RemainingPlayerStack direction="row">
        {Object.entries(remainingPlayers).map(([playerId, playerThumbnail]) => (
          <PlayerWrapper key={playerId} direction="column" alignItems="center">
            <PlayerAvatar src={parseAvatarUrl(playerThumbnail?.avatar)} />
            <PlayerNickname>{playerThumbnail?.nickname}</PlayerNickname>
          </PlayerWrapper>
        ))}
      </RemainingPlayerStack>
      <ButtonStack direction="row" alignItems="flex-end" spacing={2}>
        {showReadyButton && <ThemeButton disabled={!currentCharacter} onClick={onReadyButtonClicked}>{isReady ? '取消准备' : '准备完毕'}</ThemeButton>}
        {isCreator && <ThemeButton disabled={!isAllReady} onClick={onNextButtonClicked}>下一步</ThemeButton>}
      </ButtonStack>
    </PageContainer>
  );
};

export default CharacterPage;
