import { useCallback, useContext, useMemo, useState } from "react";
import { createBinder, getAssetUrl } from "utils/utils";
import { WaitingPage, WaitingPageProps } from "./WaitingPage";
import { useNavigate, useParams } from "react-router-dom";
import RouterPath from "router/RouterPath";
import { socket } from "utils/socketConnector";
import { GameContext, StoryContext, UserContext } from "utils/contexts";
import { enqueueSnackbar } from "notistack";
import { InviteLinkDialog } from 'lib/game/components/InviteLinkDialog';

export type WaitingPageBinderManagedProps = 'gameId' | 'user' | 'backgroundUrl' | 'posterUrl' | 'titleUrl' | 'isCreator' | 'numberOfPlayers' | 'storyDescription' | 'onStartGameButtonClicked' | 'onExitGameButtonClicked'
| 'onCopyCaseIdButtonClicked' | 'inviteLinkDialog' | 'onInviteLinkButtonClicked';

export interface WaitingPageBinderProps extends Omit<WaitingPageProps, WaitingPageBinderManagedProps> { }

const useWaitingPageBinder = (props: WaitingPageBinderProps): WaitingPageProps => {

  const { user } = useContext(UserContext);
  const gameAudit = useContext(GameContext);
  const storyDetail = useContext(StoryContext);
  
  const { gameId } = useParams();
  const navigate = useNavigate();

  const [showInviteLinkDialog, setShowInviteLinkDialog] = useState<boolean>(false);

  const onStartGameButtonClicked = () => {
    socket?.emit('startGame', gameId);
  }

  const onExitGameButtonClicked = () => {
    navigate(RouterPath.Home);
  }

  const onCopyCaseIdButtonClicked = useCallback(() => {
    navigator.clipboard?.writeText(gameId ?? '').then(() => enqueueSnackbar('案件编号已复制到剪贴板', { variant: 'success' }))
  }, [gameId]);

  const managedProps: Pick<WaitingPageProps, WaitingPageBinderManagedProps> = {
    gameId,
    user,
    backgroundUrl: getAssetUrl(`/${gameAudit?.storyId}/background.webp`),
    posterUrl: getAssetUrl(`/${gameAudit?.storyId}/poster.webp`),
    titleUrl: getAssetUrl(`/${gameAudit?.storyId}/title.webp`),
    isCreator: gameAudit?.creator === user?.userId,
    numberOfPlayers: gameAudit?.numberOfPlayers ?? 0,
    storyDescription: storyDetail?.storyDescription ?? '',
    onStartGameButtonClicked,
    onExitGameButtonClicked,
    onCopyCaseIdButtonClicked,
    inviteLinkDialog: useMemo(() => <InviteLinkDialog open={showInviteLinkDialog} onClose={() => setShowInviteLinkDialog(false)} />, [showInviteLinkDialog]),
    onInviteLinkButtonClicked: () => setShowInviteLinkDialog(true),
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const WaitingPageBinder = createBinder(WaitingPage, useWaitingPageBinder);

export default WaitingPageBinder;