/* eslint-disable react/jsx-pascal-case */
import styled from '@emotion/styled';
import { LocationButton } from 'lib/game/components/LocationButton';
import { FC, ReactNode, useContext } from 'react';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';
import { GameContext } from 'utils/contexts';
import './firefly.scss';


const MapContainer = styled.div`
  width: 100vw;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const Map = styled.img`
  width: 100%;
`;

const GameStageInfo = styled.div`
  position: absolute;
  top: 40px;
  right: 60px;
  text-align: center;
  font-family: 'STZhongSong';
  font-size: 40px;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  color: white;
`;

const ILocationButton = styled(LocationButton)`
  border-radius: 16px;
`;

const Town = styled(ILocationButton)`
  width: 6.8%;
  height: 4.8%;
  left: 59.3%;
  top: 27.8%;
`;

const GeHome = styled(ILocationButton)`
  width: 3.3%;
  height: 5.7%;
  left: 55.2%;
  top: 23.6%;
`;

const GraveYard = styled(ILocationButton)`
  width: 5.0%;
  height: 4.8%;
  left: 39.2%;
  top: 71.4%;
`;

const DeerForest = styled(ILocationButton)`
  width: 6.8%;
  height: 4.8%;
  left: 31.1%;
  top: 47.7%;
`;

const FishMountain = styled(ILocationButton)`
  width: 6.8%;
  height: 4.8%;
  left: 44.3%;
  top: 56.2%;
`;

const BaoshuTower = styled(ILocationButton)`
  width: 2.7%;
  height: 11.8%;
  left: 46.5%;
  top: 15.4%;
`;

const Tavern = styled(ILocationButton)`
  width: 3.3%;
  height: 5.7%;
  left: 65.9%;
  top: 42.4%;
`;

const Pavilion = styled(ILocationButton)`
  width: 2.7%;
  height: 11.8%;
  left: 17.4%;
  top: 27.6%;
`;

const YuHome = styled(ILocationButton)`
  width: 3.3%;
  height: 5.7%;
  left: 70.4%;
  top: 27.9%;
`;

const ZhangHome = styled(ILocationButton)`
  width: 3.3%;
  height: 5.7%;
  left: 49.7%;
  top: 26.7%;
`;

const TownGate = styled(ILocationButton)`
  width: 2.7%;
  height: 7.8%;
  left: 60.1%;
  top: 69.9%;
`;

const LotusStream = styled(ILocationButton)`
  width: 6.8%;
  height: 4.8%;
  left: 74.3%;
  top: 70.8%;
`;

const getStageInfo = (stage: string): ReactNode => {
  switch (stage) {
    case 'INVESTIGATING1':
    case 'DISCUSSING1':
      return <>
        <span style={{ fontSize: '32px' }}>第一幕</span><br />
        野鹿林惨案<br />
        <span style={{ fontSize: '28px' }}>宝殊镇 9月1日</span>
      </>
    case 'INVESTIGATING2':
    case 'DISCUSSING2':
      return <>
        <span style={{ fontSize: '32px' }}>第二幕</span><br />
        葛月萝之死<br />
        <span style={{ fontSize: '28px' }}>葛家大宅 9月2日</span>
      </>
    case 'INVESTIGATING3':
    case 'DISCUSSING3':
      return <>
        <span style={{ fontSize: '32px' }}>第三幕</span><br />
        旧冢添新骨<br />
        <span style={{ fontSize: '28px' }}>坟场 9月3日</span>
      </>
    case 'INVESTIGATING4':
    case 'VOTING':
      return <>
        <span style={{ fontSize: '32px' }}>第四幕</span><br />
        酒馆再惊魂<br />
        <span style={{ fontSize: '28px' }}>吕家酒馆 9月4日</span>
      </>
    default:
      return null;
  }
}

export interface ZTFR_InvestigatePageProps extends CommonUIProps {
  showMap: boolean;
  gameTime: string;
  numRemainingClues: Record<string, number>;
  locationHasNewClue: string[];
  onLocationButtonClicked: (location: string) => void;
  pickClueDialog: ReactNode;
  showPickClueDialog: boolean;
  setShowPickClueDialog: (_: boolean) => void;
}

export const ZTFR_InvestigatePage: FC<ZTFR_InvestigatePageProps> = function ({
  showMap,
  gameTime,
  numRemainingClues,
  locationHasNewClue,
  onLocationButtonClicked,
  pickClueDialog,
  showPickClueDialog,
  setShowPickClueDialog,
  ...props
}) {

  const gameStage = useContext(GameContext)?.stage ?? '';

  return (
    <div {...props}>
      {showMap &&
        <>
          <MapContainer>
            <div style={{ width: '100%', position: 'relative' }}>
              <Map src={getAssetUrl('/ZTFR/map.webp')} draggable={false} />
              <Town badge={numRemainingClues['宝殊镇']} badgeStyle={{ top: 0, bottom: 0, left: '108%' }} hasNewClue={locationHasNewClue.includes('宝殊镇')} onClick={() => onLocationButtonClicked('宝殊镇')} />

              {['INVESTIGATING2', 'READING3', 'INVESTIGATING3', 'READING4', 'INVESTIGATING4', 'VOTING', 'REVEALING', 'DISCUSSING2', 'DISCUSSING3'].includes(gameStage) &&
                <GeHome badge={numRemainingClues['葛家']} badgeStyle={{ top: '5px', left: '44px' }} hasNewClue={locationHasNewClue.includes('葛家')} onClick={() => onLocationButtonClicked('葛家')} />
              }
              {['INVESTIGATING3', 'READING4', 'INVESTIGATING4', 'VOTING', 'REVEALING', 'DISCUSSING3'].includes(gameStage) &&
                <>
                  <GraveYard badge={numRemainingClues['坟场']} badgeStyle={{ top: 0, bottom: 0, left: '108%' }} hasNewClue={locationHasNewClue.includes('坟场')} onClick={() => onLocationButtonClicked('坟场')} />
                  <DeerForest badge={numRemainingClues['野鹿林']} badgeStyle={{ top: 0, bottom: 0, left: '108%' }} hasNewClue={locationHasNewClue.includes('野鹿林')} onClick={() => onLocationButtonClicked('野鹿林')} />
                  <FishMountain badge={numRemainingClues['鱼头山']} badgeStyle={{ top: 0, bottom: 0, left: '108%' }} hasNewClue={locationHasNewClue.includes('鱼头山')} onClick={() => onLocationButtonClicked('鱼头山')} />
                  <BaoshuTower badge={numRemainingClues['宝殊塔']} badgeStyle={{ left: '2px', right: 0, bottom: '-26%' }} hasNewClue={locationHasNewClue.includes('宝殊塔')} onClick={() => onLocationButtonClicked('宝殊塔')} />
                </>
              }

              {['INVESTIGATING4', 'VOTING', 'REVEALING'].includes(gameStage) &&
                <>
                  <Tavern badge={numRemainingClues['吕家酒馆']} badgeStyle={{ top: '5px', left: '44px' }} hasNewClue={locationHasNewClue.includes('吕家酒馆')} onClick={() => onLocationButtonClicked('吕家酒馆')} />
                  <Pavilion badge={numRemainingClues['山中亭']} badgeStyle={{ left: '2px', right: 0, bottom: '-26%' }} hasNewClue={locationHasNewClue.includes('山中亭')} onClick={() => onLocationButtonClicked('山中亭')} />
                  <YuHome badge={numRemainingClues['于家']} badgeStyle={{ top: '5px', left: '44px' }} hasNewClue={locationHasNewClue.includes('于家')} onClick={() => onLocationButtonClicked('于家')} />
                  <ZhangHome badge={numRemainingClues['张家']} badgeStyle={{ top: '5px', left: '44px' }} hasNewClue={locationHasNewClue.includes('张家')} onClick={() => onLocationButtonClicked('张家')} />
                  <TownGate badge={numRemainingClues['镇口']} badgeStyle={{ left: '2px', right: 0, bottom: '-40%' }} hasNewClue={locationHasNewClue.includes('镇口')} onClick={() => onLocationButtonClicked('镇口')} />
                  <LotusStream badge={numRemainingClues['莲花溪']} badgeStyle={{ top: 0, bottom: 0, left: '-130%' }} hasNewClue={locationHasNewClue.includes('莲花溪')} onClick={() => onLocationButtonClicked('莲花溪')} />
                </>
              }
            </div>
          </MapContainer>
          {['READING3', 'INVESTIGATING3', 'READING4', 'INVESTIGATING4', 'VOTING', 'REVEALING', 'DISCUSSING3'].includes(gameStage) &&
            <div>
              <div className='firefly' />
              <div className='firefly' />
              <div className='firefly' />
              <div className='firefly' />
              <div className='firefly' />
            </div>}
          <GameStageInfo>
            {getStageInfo(gameStage)}
          </GameStageInfo>
        </>
      }
      {pickClueDialog}
    </div>
  );
};

export default ZTFR_InvestigatePage;
