import styled from '@emotion/styled';
import { DialogProps } from '@mui/material';
import { FullDialog } from 'lib/common/FullDialog';
import { FC, useEffect, useState } from 'react';
import useSound from 'use-sound';
import { useInterval, useTimeout } from 'utils/hooks';
import TypingSound from 'assets/sound/typing.mp3';
import { getAssetUrl } from 'utils/utils';

const TextWrapper = styled.div`
  width: 100vw;
  height: 400px;
  border: white solid;
  border-width: 3px 0 3px 0;
  background-color: #090909;
  color: white;
  font-family: 'STZhongSong';
  text-align: center;
  transition: opacity 1s ease-in-out 2s;
`;

const Title = styled.div`
  font-size: 32px;
  margin-top: 15px;
`;

const Text = styled.div`
  font-size: 40px;
  margin-top: 20px;
  line-height: 2em;
  margin-left: 1em;
`;

export interface EXSL_StageTransitionProps extends DialogProps { }

const EXSL_StageTransition1: FC<EXSL_StageTransitionProps> = function ({
  ...props
}) {

  const text1 = '大家都接受了隼的邀请，来到“巴家”用餐。';
  const text2 = '这时，巴府山和鬼印回来了，他们模糊的交谈声从门外传来……';
  const text3 = '隼立即开门出去，打断了两人的谈话。';

  const [animatedText1, setAnimatedText1] = useState<string>('');
  const [animatedText2, setAnimatedText2] = useState<string>('');
  const [animatedText3, setAnimatedText3] = useState<string>('');

  const typingSpeed = 120;
  const linePause = 500;

  const [playTypingSound, { stop }] = useSound(TypingSound, { volume: 0.3, playbackRate: 1 });
  const [playConversation] = useSound(getAssetUrl('/EXSL/transition1.mp3'));

  useInterval(() => {
    setAnimatedText1(text1.slice(0, animatedText1.length + 1));
  }, props.open ? (animatedText1.length === text1.length ? null : animatedText1.length === 0 ? 2000 : typingSpeed) : null);

  useInterval(() => {
    setAnimatedText2(text2.slice(0, animatedText2.length + 1));
  }, props.open ? (animatedText2.length === text2.length ? null : animatedText2.length === 0 ? 2000 + text1.length * typingSpeed + linePause : typingSpeed) : null);

  useInterval(() => {
    if (animatedText3.length === 0) {
      playTypingSound();
    }
    setAnimatedText3(text3.slice(0, animatedText3.length + 1));
  }, props.open ? (animatedText3.length === text3.length ? null : animatedText3.length === 0 ? 2000 + (text1.length + text2.length) * typingSpeed + linePause * 2 + 26000 : typingSpeed) : null);

  useEffect(() => {
    if (animatedText3 === text3) {
      stop();
    }
  }, [animatedText3, stop]);

  useTimeout(
    () => {
      playTypingSound();
    },
    props.open ? 2000 : null
  );

  useTimeout(
    () => {
      stop();
    },
    props.open ? 2000 + typingSpeed * (text1.length + text2.length) + linePause * 2 : null
  );

  useTimeout(
    () => {
      playConversation();
    },
    props.open ? 2000 + typingSpeed * (text1.length + text2.length) + linePause * 2 + 1000 : null
  );

  return (
    <FullDialog disableEscapeKeyDown {...props}>
      <TextWrapper style={{ opacity: animatedText3 === text3 ? '0' : '1' }} onTransitionEnd={() => props.onClose?.({}, 'escapeKeyDown')}>
        <Title>
          “巴康寨”大厅
          <br />
          12:00
        </Title>
        <Text>
          {animatedText1}
          <br />
          {animatedText2}
          <br />
          {animatedText3}
          <br />
        </Text>
      </TextWrapper>
    </FullDialog>
  );
};

const EXSL_StageTransition2: FC<EXSL_StageTransitionProps> = function ({
  ...props
}) {

  const text1 = '大家都回到寨内，看到“大嬷嬷”在陪着小孙儿玩耍，嘴里似乎唱着什么。';
  const text2 = '（歌词大意：中元前后勿下水…溺死要找替死鬼…七月不要树下睡…吊死也要替死鬼…）';
  const text3 = '你虽身处太阳之下，却还是不禁打了个寒颤……';

  const [animatedText1, setAnimatedText1] = useState<string>('');
  const [animatedText2, setAnimatedText2] = useState<string>('');
  const [animatedText3, setAnimatedText3] = useState<string>('');

  const typingSpeed = 120;
  const linePause = 500;

  const [playTypingSound, { stop }] = useSound(TypingSound, { volume: 0.3, playbackRate: 0.95 });
  const [playSong] = useSound(getAssetUrl('/EXSL/transition2.mp3'));

  useInterval(() => {
    setAnimatedText1(text1.slice(0, animatedText1.length + 1));
  }, props.open ? (animatedText1.length === text1.length ? null : animatedText1.length === 0 ? 2000 : typingSpeed) : null);

  useInterval(() => {
    if (animatedText2 === '') {
      setAnimatedText2('（歌词大意：')
    } else {
      setAnimatedText2(text2.slice(0, animatedText2.length + 1));
    }
  }, props.open ? (animatedText2.length === text2.length ? null : animatedText2.length === 0 ? 2000 + text1.length * typingSpeed + linePause + 1000 : 400) : null);

  useInterval(() => {
    if (animatedText3.length === 0) {
      playTypingSound();
    }
    setAnimatedText3(text3.slice(0, animatedText3.length + 1));
  }, props.open ? (animatedText3.length === text3.length ? null : animatedText3.length === 0 ? 2000 + text1.length * typingSpeed + linePause + 16000 : typingSpeed) : null);

  useEffect(() => {
    if (animatedText3 === text3) {
      stop();
    }
  }, [animatedText3, stop]);

  useTimeout(
    () => {
      playTypingSound();
    },
    props.open ? 2000 : null
  );

  useTimeout(
    () => {
      stop();
    },
    props.open ? 2000 + typingSpeed * (text1.length) + linePause : null
  );

  useTimeout(
    () => {
      playSong();
    },
    props.open ? 2000 + typingSpeed * (text1.length) + linePause + 1000 : null
  );

  return (
    <FullDialog disableEscapeKeyDown {...props}>
      <TextWrapper style={{ opacity: animatedText3 === text3 ? '0' : '1' }} onTransitionEnd={() => props.onClose?.({}, 'escapeKeyDown')}>
        <Title>
          “巴康寨”内
          <br />
          16:30
        </Title>
        <Text>
          {animatedText1}
          <br />
          {animatedText2}
          <br />
          {animatedText3}
          <br />
        </Text>
      </TextWrapper>
    </FullDialog>
  );
};

export { EXSL_StageTransition1, EXSL_StageTransition2 };
