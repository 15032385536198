import { useCallback, useContext, useMemo } from 'react';
import { VoteClueDialog, VoteClueDialogProps } from './VoteClueDialog';
import { createBinder } from 'utils/utils';
import { GameContext, UserContext } from 'utils/contexts';
import { socket } from 'utils/socketConnector';
import { useParams } from 'react-router-dom';

export type VoteClueDialogBinderManagedProps = 'playerVotedClues' | 'onClueButtonClicked' | 'onClipButtonClicked' | 'clipedClues';

export interface VoteClueDialogBinderProps extends Omit<VoteClueDialogProps, VoteClueDialogBinderManagedProps> { 
  location?: string;
}

const useVoteClueDialogBinder = (props: VoteClueDialogBinderProps): VoteClueDialogProps => {

  const { user } = useContext(UserContext);
  const gameAudit = useContext(GameContext);
  const { gameId } = useParams();

  const playerVotedClues = useMemo(() => Object.fromEntries(Object.values(gameAudit?.players ?? {}).map((playerInfo) => [playerInfo.character, playerInfo.votedClue])), [gameAudit?.players]);
  const isAudience = useMemo(() => !Object.keys(gameAudit?.players ?? {}).includes(user?.userId ?? ''), [gameAudit?.players, user?.userId]);

  const onClueButtonClicked = useCallback((clue: string) => {
    if (!isAudience && !gameAudit?.rooms?.[props.location ?? '']?.clues[clue].isFound) {
      socket?.emit('playerVoteClue', user?.userId, gameId, gameAudit?.players?.[user?.userId ?? '']?.votedClue === clue ? null : clue, props.location);
    }
  }, [gameAudit?.players, gameAudit?.rooms, gameId, isAudience, props.location, user?.userId]);

  const onClipButtonClicked = useCallback((clue: string, clip?: string) => {
    socket?.emit('playerUpdateClipedClue', user?.userId, gameId, clue, clip);
  }, [gameId, user?.userId]);

  const managedProps: Pick<VoteClueDialogProps, VoteClueDialogBinderManagedProps> = {
    playerVotedClues,
    onClueButtonClicked,
    onClipButtonClicked,
    clipedClues: gameAudit?.players?.[user?.userId ?? '']?.clipedClues,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const VoteClueDialogBinder = createBinder(VoteClueDialog, useVoteClueDialogBinder);

export default VoteClueDialogBinder;
