import styled from '@emotion/styled';
import { DialogProps } from '@mui/material';
import { FullDialog } from 'lib/common/FullDialog';
import { FC, useEffect, useState } from 'react';
import useSound from 'use-sound';
import { useInterval, useTimeout } from 'utils/hooks';
import TypingSound from 'assets/sound/typing.mp3';
import { getAssetUrl } from 'utils/utils';

const TextWrapper = styled.div`
  width: 100vw;
  height: 400px;
  border: white solid;
  border-width: 3px 0 3px 0;
  background-color: #090909;
  color: white;
  font-family: 'STZhongSong';
  text-align: center;
  transition: opacity 1s ease-in-out 2s;
`;

const Title = styled.div`
  font-size: 32px;
  margin-top: 15px;
`;

const Text = styled.div`
  font-size: 36px;
  margin-top: 20px;
  line-height: 2em;
  margin-left: 1em;
`;

export interface YLW_StageTransitionProps extends DialogProps { }

const YLW_StageTransition: FC<YLW_StageTransitionProps> = function ({
  ...props
}) {

  const text1 = '安梧回房后，众人各自散去，却发现老张从餐厅里急匆匆地跑了出来。';
  const text2 = '你们来到餐厅，竟发现安老爷躺在地上，正口吐白沫！他用尽力气，最后艰难地说了些什么……';
  const text3 = '还没说完，老爷就已经断了气！';

  const [animatedText1, setAnimatedText1] = useState<string>('');
  const [animatedText2, setAnimatedText2] = useState<string>('');
  const [animatedText3, setAnimatedText3] = useState<string>('');

  const typingSpeed = 120;
  const linePause = 500;

  const [playTypingSound, { stop }] = useSound(TypingSound, { volume: 0.3, playbackRate: 1 });
  const [playVoice] = useSound(getAssetUrl('/YLW/transition_voice.mp3'));

  useInterval(() => {
    setAnimatedText1(text1.slice(0, animatedText1.length + 1));
  }, props.open ? (animatedText1.length === text1.length ? null : animatedText1.length === 0 ? 2000 : typingSpeed) : null);

  useInterval(() => {
    setAnimatedText2(text2.slice(0, animatedText2.length + 1));
  }, props.open ? (animatedText2.length === text2.length ? null : animatedText2.length === 0 ? 2500 + text1.length * typingSpeed + linePause : typingSpeed) : null);

  useInterval(() => {
    if (animatedText3.length === 0) {
      playTypingSound();
    }
    setAnimatedText3(text3.slice(0, animatedText3.length + 1));
  }, props.open ? (animatedText3.length === text3.length ? null : animatedText3.length === 0 ? 2500 + (text1.length + text2.length) * typingSpeed + linePause * 2 + 7500 : typingSpeed) : null);

  useTimeout(
    () => {
      stop();
    },
    props.open ? 2000 + typingSpeed * (text1.length + text2.length) + linePause * 2 : null
  );

  useTimeout(
    () => {
      playTypingSound();
    },
    props.open ? 1600 : null
  );

  useTimeout(
    () => {
      playVoice();
    },
    props.open ? 2000 + typingSpeed * (text1.length + text2.length) + linePause * 3 + 1000 : null
  );

  useEffect(() => {
    if (animatedText3 === text3) {
      stop();
    }
  }, [animatedText3, stop]);

  return (
    <FullDialog disableEscapeKeyDown {...props}>
      <TextWrapper style={{ opacity: animatedText3.length === text3.length ? '0' : '1' }} onTransitionEnd={() => props.onClose?.({}, 'escapeKeyDown')}>
        <Title>
          “时珞庄”餐厅
          <br />
          10:00
        </Title>
        <Text>
          {animatedText1}
          <br />
          {animatedText2}
          <br />
          {animatedText3}
          <br />
        </Text>
      </TextWrapper>
    </FullDialog>
  );
};

export { YLW_StageTransition };