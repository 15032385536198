import { createBinder, getAssetUrl } from "utils/utils";
import { YLW_InvestigatePage, YLW_InvestigatePageProps } from "./YLW_InvestigatePage";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { GameContext, UserContext, VolumeContext } from "utils/contexts";
import { socket } from "utils/socketConnector";
import PickClueDialogBinder from "lib/game/components/PickClueDialogBinder";
import { YLW_ForbiddenRooms } from "./YLW_StaticData";
import useSound from "use-sound";
import { useParams } from "react-router-dom";
import ExploreDialogBinder from "lib/stories/YLW/YLW_ExploreDialogBinder";

export type YLW_InvestigatePageBinderManagedProps = 'showMap' | 'gameTime' | 'numRemainingClues' | 'locationHasNewClue'
  | 'onLocationButtonClicked' | 'pickClueDialog' | 'isHouseInvestigated' | 'showInviteButton' | 'onInviteButtonClicked'
  | 'showConfirmDialog' | 'handleConfirmDialogClose' | 'onConfirmInvite'
  | 'exploreDialog' | 'onExploreButtonClicked' | 'exploreMusicRef' | 'onExploreMusicEnded'
  | 'showStageTransition' | 'onStageTransitionEnded';

export interface YLW_InvestigatePageBinderProps extends Omit<YLW_InvestigatePageProps, YLW_InvestigatePageBinderManagedProps> {
  showPickClueDialog: boolean;
  setShowPickClueDialog: (_: boolean) => void;
  showExploreDialog: boolean;
  setShowExploreDialog: (_: boolean) => void;
}

const useYLW_InvestigatePageBinder = (props: YLW_InvestigatePageBinderProps): YLW_InvestigatePageProps => {

  const { user } = useContext(UserContext);
  const { gameId } = useParams();
  const volume = useContext(VolumeContext);
  const gameAudit = useContext(GameContext);
  const [newClues, setNewClues] = useState<Record<string, string[]>>({});
  const { showPickClueDialog, setShowPickClueDialog, showExploreDialog, setShowExploreDialog } = props;
  const [currentLocation, setCurrentLocation] = useState<string>();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const exploreMusicRef = useRef<HTMLAudioElement>(null);
  const [hasExploreMusicPlayed, setHasExploreMusicPlayed] = useState<boolean>(false);

  const [showStageTransition, setShowStageTransition] = useState<boolean>(false);

  const [musicPlay, { stop: musicStop }] = useSound(getAssetUrl('/YLW/bgm.mp3'), {
    volume: volume / 100,
    loop: true,
    autoplay: true,
    interrupt: true,
  });

  useEffect(() => {
    return () => musicStop();
  }, [musicStop]);

  const numRemainingClues = useMemo(() =>
    Object.fromEntries(
      Object.entries(gameAudit?.rooms ?? {})
        .map(([room, roomInfo]) => [room, Object.values(roomInfo.clues).reduce(((numClues, clueInfo) => numClues + (clueInfo.isFound ? 0 : 1)), 0)]))
    , [gameAudit?.rooms]);

  const allowToInvestigate = useMemo(() => {
    const forbiddenRoom = YLW_ForbiddenRooms[gameAudit?.players?.[user?.userId ?? '']?.character ?? ''];
    if (forbiddenRoom) {
      if (forbiddenRoom !== currentLocation) {
        return true;
      }
      return Object.keys(gameAudit?.rooms ?? {}).every((room) => {
        if (room === forbiddenRoom) {
          return true;
        }
        return Object.values(gameAudit?.rooms[room]?.clues ?? {}).every((clue) => clue.isFound);
      })
    }
    return true;
  }, [currentLocation, gameAudit?.players, gameAudit?.rooms, user?.userId]);

  useEffect(() => {

    const onClueFound = (clue: string, location: string) => {
      setNewClues((prev) => ({
        ...prev,
        [location]: prev[location] ? [...prev[location], clue] : [clue]
      }));
    }

    const onCluePublished = (clue: string, location: string, character: string) => {
      if (character !== gameAudit?.players?.[user?.userId ?? '']?.character) {
        setNewClues((prev) => ({
          ...prev,
          [location]: prev[location] ? [...prev[location], clue] : [clue]
        }));
      }
    }

    const onStageTransition = (newStage: string) => {
      if (newStage === 'READING2') {
        musicStop();
        setShowStageTransition(true);
      }
    }

    socket?.on('clueFound', onClueFound);
    socket?.on('cluePublished', onCluePublished);
    socket?.on('stageTransition', onStageTransition);

    return () => {
      socket?.off('clueFound', onClueFound);
      socket?.off('cluePublished', onCluePublished);
      socket?.off('stageTransition', onStageTransition);
    }
  }, [gameAudit?.players, musicStop, user?.userId]);

  const onLocationButtonClicked = useCallback((location: string) => {
    setCurrentLocation(location);
    setShowPickClueDialog(true);
  }, [setShowPickClueDialog]);

  const handlePickClueDialogClose = useCallback(() => {
    setShowPickClueDialog(false);
    if (currentLocation) {
      const temp = { ...newClues };
      delete temp[currentLocation];
      setNewClues(temp);
    }
  }, [currentLocation, newClues, setShowPickClueDialog]);

  const handleExploreDialogClose = useCallback(() => {
    setShowExploreDialog(false);
    const temp = { ...newClues };
    delete temp['时珞庄外'];
    setNewClues(temp);
  }, [newClues, setShowExploreDialog]);

  const onConfirmInvite = useCallback(() => {
    setShowConfirmDialog(false);
    if (gameAudit?.players?.[user?.userId ?? ''].character === '吾特') {
      socket?.emit('moveToStage', gameId, 'READING2');
    }
  }, [gameAudit?.players, gameId, user?.userId]);

  const onExploreButtonClicked = useCallback(() => {
    setShowExploreDialog(true)
    if (!hasExploreMusicPlayed) {
      musicStop();
      if (exploreMusicRef && exploreMusicRef.current) {
        exploreMusicRef.current.volume = volume;
        exploreMusicRef.current.play();
      }
      setHasExploreMusicPlayed(true);
    }
  }, [hasExploreMusicPlayed, musicStop, setShowExploreDialog, volume]);

  const onExploreMusicEnded = useCallback(() => {
    musicPlay();
  }, [musicPlay]);

  const onStageTransitionEnded = useCallback((_: Event, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === 'escapeKeyDown') {
      musicPlay();
      setShowStageTransition(false);
    }
  }, [musicPlay]);

  const managedProps: Pick<YLW_InvestigatePageProps, YLW_InvestigatePageBinderManagedProps> = {
    showMap: !!gameAudit?.stage && gameAudit.stage.slice(0, -1) !== 'READING' && gameAudit.stage !== 'INTRODUCING',
    gameTime: ['READING1', 'INTRODUCING', 'INVESTIGATING1'].includes(gameAudit?.stage ?? '') ? '12:15' : '13:15',
    numRemainingClues,
    locationHasNewClue: useMemo(() => Object.keys(newClues), [newClues]),
    onLocationButtonClicked,
    pickClueDialog: useMemo(() => <PickClueDialogBinder location={currentLocation} newClues={newClues[currentLocation ?? '']} open={showPickClueDialog} onClose={handlePickClueDialogClose} allowToInvestigate={allowToInvestigate} />, [allowToInvestigate, currentLocation, handlePickClueDialogClose, newClues, showPickClueDialog]),
    isHouseInvestigated: Object.keys(gameAudit?.rooms ?? {}).every((room) => room === '时珞庄外' || Object.values(gameAudit?.rooms[room]?.clues ?? {}).every((clue) => !!clue.isFound)),
    showInviteButton: gameAudit?.stage === 'INVESTIGATING1' && gameAudit?.players?.[user?.userId ?? '']?.character === '吾特',
    onInviteButtonClicked: () => setShowConfirmDialog(true),
    showConfirmDialog,
    handleConfirmDialogClose: () => setShowConfirmDialog(false),
    onConfirmInvite,
    exploreDialog: useMemo(() => <ExploreDialogBinder newClues={newClues['时珞庄外']} open={showExploreDialog} onClose={handleExploreDialogClose} />, [handleExploreDialogClose, newClues, showExploreDialog]),
    onExploreButtonClicked,
    exploreMusicRef,
    onExploreMusicEnded,
    showStageTransition,
    onStageTransitionEnded,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const YLW_InvestigatePageBinder = createBinder(YLW_InvestigatePage, useYLW_InvestigatePageBinder);

export default YLW_InvestigatePageBinder;