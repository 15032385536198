import styled from '@emotion/styled';
import { FC } from 'react';
import { DialogProps, Stack } from '@mui/material';
import { getAssetUrl } from 'utils/utils';
import { ThemeButton } from 'lib/common/ThemeButton';
import { FullDialog } from 'lib/common/FullDialog';
import { FlipKeyframes } from 'utils/keyframes';
import { ClueRes, SecretRes } from 'openapi';

const ContentWrapper = styled(Stack)`
  width: 100vw;
  height: 100dvh;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
`;

const TitleBox = styled.div`
  width: 100vw;
  padding-top: 30px;
  color: white;
  font-size: 56px;
  font-family: '黑体';
  text-align: center;
`;

const ClueContainer = styled.div`
  width: 100vw;
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`;

const ClueCardContainer = styled.div<{ shouldFlip?: boolean }>`
  width: 33.2vh;
  height: 48vh;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 30px;
  perspective: 1000px;
  position: relative;

  transform-style: preserve-3d;
  animation-duration: 0.8s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-delay: 0.5s;
  animation-name: ${(props) => props.shouldFlip ? FlipKeyframes : ''};
`;

const ClueCard = styled.img`
  width: 33.2vh;
  height: 48vh;
  border: 3px solid white;
  border-radius: 20px;
  backface-visibility: hidden;
`;

const ClueText = styled.div`
  height: 35px;
  margin-top: 12px;
  font-size: 24px;
  color: white;
  font-family: '微软雅黑';
  text-align: center;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.4), 0 6px 20px rgba(0, 0, 0, 0.3);
`;

const Footer = styled.div`
  width: 120px;
  height: 48px;
  padding-bottom: 24px;
`;

const EmptyText = styled.div`
  font-size: 64px;
  color: white;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
`;

export interface PrivateCluesDialogProps extends DialogProps {
  clues?: Record<string, SecretRes | ClueRes | undefined>;
  currentCharacter?: string;
  onPublishButtonClicked: (clue: string) => void;
}

export const PrivateCluesDialog: FC<PrivateCluesDialogProps> = function ({
  clues,
  currentCharacter,
  onPublishButtonClicked,
  ...props
}) {

  return (
    <FullDialog {...props}>
      <ContentWrapper direction="column" alignItems="center">
        {clues ? <>
          <TitleBox>{`您未公开的线索（最多只能隐藏1条）`}</TitleBox>
          <ClueContainer>
            {Object.entries(clues ?? {}).map(([clue, clueInfo]) => (
              <Stack key={clue} direction="column">
                <ClueCardContainer>
                  <ClueCard src={getAssetUrl(clueInfo?.frontUrl)} draggable={false} onContextMenu={(e) => e.preventDefault()} />
                </ClueCardContainer>
                <ClueText>
                  <span
                    style={{ color: '#4EB7FE', textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => onPublishButtonClicked(clue)}
                  >
                    点击此处
                  </span>
                  公开该线索
                </ClueText>
              </Stack>
            ))}
          </ClueContainer>
        </> :
          <EmptyText>
            您没有未公开的线索
          </EmptyText>}
        <Footer>
          {Object.keys(clues ?? {}).length <= 1 && <ThemeButton onClick={() => props.onClose?.({}, "escapeKeyDown")}>返回</ThemeButton>}
        </Footer>
      </ContentWrapper>
    </FullDialog>
  );
};

export default PrivateCluesDialog;
