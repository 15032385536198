import { useCallback, useContext, useMemo, useState } from 'react';
import { HintsDialog, HintsDialogProps } from './HintsDialog';
import { createBinder } from 'utils/utils';
import { GameContext, UserContext } from 'utils/contexts';
import { socket } from 'utils/socketConnector';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

export type HintsDialogBinderManagedProps = 'activeCategory' | 'onCategoryClicked' | 'onReturnButtonClicked' | 'showExtraHintDialog' | 'onConfirmExtraHint' | 'votedHints'
| 'onExtraHintDialogClose' | 'onNotConfirmExtraHint';

export interface HintsDialogBinderProps extends Omit<HintsDialogProps, HintsDialogBinderManagedProps> { }

const useHintsDialogBinder = (props: HintsDialogBinderProps): HintsDialogProps => {

  const [activeCategory, setActiveCategory] = useState<string>();

  const { user } = useContext(UserContext);
  const gameAudit = useContext(GameContext);
  const { gameId } = useParams();
  const isAudience = useMemo(() => !Object.keys(gameAudit?.players ?? {}).includes(user?.userId ?? ''), [gameAudit?.players, user?.userId]);

  const [showExtraHintDialog, setShowExtraHintDialog] = useState<boolean>(false);
  const [selectedHint, setSelectedHint] = useState<string>();

  const votedHints = useMemo(() => {
    const temp: Record<string, string[]> = {};
      Object.values(gameAudit?.players ?? {}).forEach((player) => {
        if (player.votedHint) {
          temp[player.votedHint] = [...(temp[player.votedHint] ?? []), player.character ?? ''];
        }
      })
    return temp;
  }, [gameAudit?.players]);

  const onCategoryClicked = useCallback((category: string) => {
    if (gameAudit?.hints?.categories[category].reveal) {
      setActiveCategory(category);
    } else {
      if (isAudience) {
        return;
      }

      if (user?.role !== 'PLAN_A' && !gameAudit?.players?.[user?.userId ?? ''].extraHint) {
        setShowExtraHintDialog(true);
        setSelectedHint(category);
      } else {
        if (gameAudit?.hints?.remaining) {
          socket?.emit('playerVoteHint', user?.userId, gameId, category);
        } else {
          enqueueSnackbar('提示次数已经用完了哦！', { variant: 'error' });
        }
      }
    }
  }, [gameAudit?.hints?.categories, gameAudit?.hints?.remaining, gameAudit?.players, gameId, isAudience, user?.role, user?.userId]);

  const onConfirmExtraHint = useCallback(() => {
    if (selectedHint) {
      socket?.emit('playerUseExtraHint', user?.userId, gameId, selectedHint);
    }
    setShowExtraHintDialog(false);
  }, [selectedHint, gameId, user?.userId]);

  const onNotConfirmExtraHint = useCallback(() => {
    if (selectedHint) {
      socket?.emit('playerVoteHint', user?.userId, gameId, selectedHint);
    }
    setShowExtraHintDialog(false);
  }, [gameId, selectedHint, user?.userId]);

  const onReturnButtonClicked = useCallback(() => {
    setActiveCategory(undefined);
  }, []);

  const managedProps: Pick<HintsDialogProps, HintsDialogBinderManagedProps> = {
    activeCategory,
    onCategoryClicked,
    onReturnButtonClicked,
    showExtraHintDialog,
    onExtraHintDialogClose: () => setShowExtraHintDialog(false),
    onConfirmExtraHint,
    onNotConfirmExtraHint,
    votedHints,
  }
  return {
    ...props,
    ...managedProps,
  };
};

export const HintsDialogBinder = createBinder(HintsDialog, useHintsDialogBinder);

export default HintsDialogBinder;
