/* eslint-disable react/jsx-pascal-case */
import { useContext, useMemo, useState } from "react";
import { createBinder } from "utils/utils";
import { EpiloguePage, EpiloguePageProps } from "./EpiloguePage";
import { GameContext, PlayersContext } from "utils/contexts";
import NDYY_EpiloguePage from "lib/stories/NDYY/NDYY_EpiloguePage";
import YLSX_EpiloguePage from "lib/stories/YLSX/YLSX_EpiloguePage";
import SSQW_EpiloguePage from "lib/stories/SSQW/SSQW_EpiloguePage";
import { useTimeout } from "utils/hooks";
import ZTFR_EpiloguePage from "lib/stories/ZTFR/ZTFR_EpiloguePage";
import EXSL_EpiloguePage from "lib/stories/EXSL/EXSL_EpiloguePage";
import YLW_EpiloguePage from "lib/stories/YLW/YLW_EpiloguePage";
import MHZY_EpiloguePage from "lib/stories/MHZY/MHZY_EpiloguePage";
import WQYF_EpiloguePage from "lib/stories/WQYF/WQYF_EpiloguePage";
import XLJ_EpiloguePage from "lib/stories/XLJ/XLJ_EpiloguePage";
import YSZM_EpiloguePage from "lib/stories/YSZM/YSZM_EpiloguePage";

export type EpiloguePageBinderManagedProps = 'videoId' | 'storyEpilogue' | 'playerCharacterMap' | 'marqueeTime' | 'showReturnButton';

export interface EpiloguePageBinderProps extends Omit<EpiloguePageProps, EpiloguePageBinderManagedProps> {

}

const useEpiloguePageBinder = (props: EpiloguePageBinderProps): EpiloguePageProps => {

  const gameAudit = useContext(GameContext);
  const players = useContext(PlayersContext);

  const [showReturnButton, setShowReturnButton] = useState<boolean>(false);

  const videoId = useMemo(() => {
    switch (gameAudit?.storyId) {
      case 'NDYY':
        return 'fLqpj8jZB10';
      case 'YLSX':
        return 'mJAGAaXvHO0';
      case 'SSQW':
        return 'bQU878bb-tY';
      case 'ZTFR':
        return 'pM_Qx34WFxo';
      case 'EXSL':
        return '5OnOTH2MkU0';
      case 'YLW':
        return 'VEU1uibh1g0';
      case 'MHZY':
        return 'GQPdUs6W36Y';
      case 'WQYF':
        return 'bH1G-amBUrQ';
      case 'XLJ':
        return 'waPiKRdQA8c';
      case 'YSZM':
        return 'EYjcNxuVmok';
      default:
        return '';
    }
  }, [gameAudit?.storyId]);

  const storyEpilogue = useMemo(() => {
    switch (gameAudit?.storyId) {
      case 'NDYY':
        return <NDYY_EpiloguePage />;
      case 'YLSX':
        return <YLSX_EpiloguePage />;
      case 'SSQW':
        return <SSQW_EpiloguePage />;
      case 'ZTFR':
        return <ZTFR_EpiloguePage />;
      case 'EXSL':
        return <EXSL_EpiloguePage />;
      case 'YLW':
        return <YLW_EpiloguePage />;
      case 'MHZY':
        return <MHZY_EpiloguePage />;
      case 'WQYF':
        return <WQYF_EpiloguePage />;
      case 'XLJ':
        return <XLJ_EpiloguePage />;
      case 'YSZM':
        return <YSZM_EpiloguePage />;
      default:
        return null;
    }
  }, [gameAudit?.storyId]);

  const marqueeTime = useMemo(() => {
    switch (gameAudit?.storyId) {
      case 'NDYY':
        return 68;
      case 'YLSX':
        return 52;
      case 'SSQW':
        return 52;
      case 'ZTFR':
        return 70;
      case 'EXSL':
        return 56;
      case 'YLW':
        return 54;
      case 'MHZY':
        return 50;
      case 'WQYF':
        return 52;
      case 'XLJ':
        return 72;
      case 'YSZM':
        return 52;
      default:
        return 0;
    }
  }, [gameAudit?.storyId]);

  useTimeout(() => {
    setShowReturnButton(true);
  }, (marqueeTime + 2) * 1000);

  const playerCharacterMap = useMemo(() => Object.fromEntries(Object.entries(gameAudit?.players ?? {}).map(([playerId, player]) => [players[playerId]?.nickname, player.character ?? ''])), [gameAudit?.players, players]);

  const managedProps: Pick<EpiloguePageProps, EpiloguePageBinderManagedProps> = {
    videoId,
    storyEpilogue,
    playerCharacterMap,
    marqueeTime,
    showReturnButton,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const EpiloguePageBinder = createBinder(EpiloguePage, useEpiloguePageBinder);

export default EpiloguePageBinder;