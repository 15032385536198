import styled from '@emotion/styled';
import { Stack, Divider, Tooltip, Badge } from '@mui/material';
import { FC, ReactNode, useEffect } from 'react';
import { CommonUIProps } from 'utils/types';
import HomeIcon from '@mui/icons-material/Home';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import { CommonIconButton } from 'lib/common/CommonIconButton';
import ConfirmDialog from 'lib/common/ConfirmDialog';
import RateReviewIcon from '@mui/icons-material/RateReview';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

const ToolbarContainer = styled(Stack)`
  height: 48px;
  padding-left: 12px;
  padding-right: 12px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  background-color: #274472;
  position: fixed;
  bottom: 0;
  left: 48px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const ButtonDivier = styled(Divider)`
  height: 32px;
  background-color: #EFEFEFAA;
  margin-left: 8px;
  margin-right: 8px;
`;

export interface GameToolbarProps extends CommonUIProps {
  showConfirmDialog: boolean;
  onConfirmDialogClose: () => void;
  onConfirmButtonClicked: () => void;
  onHomeButtonClicked: () => void;
  onStickerButtonClicked: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClueClipButtonClicked: () => void;
  onFeedbackButtonClicked: () => void;
  onGuidanceButtonClicked: () => void;
  onHintsButtonClicked: () => void;
  stickerPopover: ReactNode;
  newHints: boolean;
}

export const GameToolbar: FC<GameToolbarProps> = function ({
  showConfirmDialog,
  onConfirmDialogClose,
  onConfirmButtonClicked,
  onHomeButtonClicked,
  onStickerButtonClicked,
  onClueClipButtonClicked,
  onFeedbackButtonClicked,
  onGuidanceButtonClicked,
  onHintsButtonClicked,
  stickerPopover,
  newHints,
  ...props
}) {

  useEffect(() => {
    console.log(newHints);
  }, [newHints]);

  return (
    <>
      <ToolbarContainer direction="row" alignItems="center" {...props}>
        <CommonIconButton icon={<HomeIcon htmlColor="white" />} onClick={onHomeButtonClicked} />
        <ButtonDivier orientation="vertical" />
        <Tooltip title="动态表情" placement="top">
          <span>
            <CommonIconButton icon={<EmojiEmotionsIcon htmlColor="white" />} onClick={onStickerButtonClicked} />
          </span>
        </Tooltip>
        <Tooltip title="线索夹" placement="top">
          <span>
            <CommonIconButton icon={<FolderSpecialIcon htmlColor="white" />} onClick={onClueClipButtonClicked} />
          </span>
        </Tooltip>
        <Tooltip title="游戏提示" placement="top">
          <span>
            <Badge invisible={!newHints} color="error" variant="dot" overlap="circular">
              <CommonIconButton icon={<TipsAndUpdatesIcon htmlColor={newHints ? "yellow" : "white"} />} onClick={onHintsButtonClicked} />
            </Badge>
          </span>
        </Tooltip>
        <Tooltip title="剧本评测" placement="top">
          <span>
            <CommonIconButton icon={<RateReviewIcon htmlColor="white" />} onClick={onFeedbackButtonClicked} />
          </span>
        </Tooltip>
        <Tooltip title="帮助" placement="top">
          <span>
            <CommonIconButton icon={<HelpCenterIcon htmlColor="white" />} onClick={onGuidanceButtonClicked} />
          </span>
        </Tooltip>
      </ToolbarContainer>
      <ConfirmDialog title="确认返回主页吗？" open={showConfirmDialog} onClose={onConfirmDialogClose} onConfirmButtonClicked={onConfirmButtonClicked} />
      {stickerPopover}
    </>
  );
};

export default GameToolbar;
