import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { PlayerWrapper } from 'lib/game/components/PlayerWrapper';
import { CharacterRes } from 'openapi';
import { FC, useContext, useState } from 'react';
import { GameContext, PlayersContext } from 'utils/contexts';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';

const ContentWrapper = styled(Stack)`
  flex: 1 1 auto;
`;

const CharacterCover = styled.img`
  height: 72dvh;
  -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  transform-origin: center;
  transform: perspective(800px) rotateY(5deg);
  border: 5px solid white;
`;

const CharacterGrid = styled.div`
  display: grid;
  grid-template-columns: 200px 200px 200px 200px;
  grid-template-rows: 400px 400px;
`;

const CharacterContainer = styled.div`
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: STFangSong;
`;

const CharacterAvatar = styled.img`
  height: 120px;
  width: 120px;
  border-radius: 60px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 4px 6px #5885AF;
  }
`;

const CharacterTitle = styled.div`
  font-size: 28px;
  margin-top: 12px;
  color: #C9C9C9;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.4), 0 6px 20px rgba(0, 0, 0, 0.6);
`;

const CharacterName = styled.div`
  font-size: 36px;
  margin-top: 8px;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.4), 0 6px 20px rgba(0, 0, 0, 0.6);
  font-family: STZhongSong;
`;

const PlayerContainer = styled.div`
  height: 120px;
`;

export interface YLSX_CharacterPageProps extends CommonUIProps {
  characterList: Record<string, CharacterRes>;
  onCharacterClicked: (character: string) => void;
}

export const YLSX_CharacterPage: FC<YLSX_CharacterPageProps> = function ({
  characterList,
  onCharacterClicked,
}) {

  const gameAudit = useContext(GameContext);
  const players = useContext(PlayersContext);

  const [activeCharacter, setActiveCharacter] = useState<string>('闵嘉雪');

  return (
    <ContentWrapper direction="row" alignItems="center" spacing={48}>
      <CharacterCover src={getAssetUrl(characterList[activeCharacter]?.cover)} />
      <CharacterGrid>
        {Object.entries(characterList ?? {}).map(([character, characterInfo]) => {
          const playerId = Object.keys(gameAudit?.players ?? {}).find((id) => gameAudit?.players?.[id].character === character);
          const playerThumbnail = players[playerId ?? ''];
          return (
            <CharacterContainer
              key={character}
              style={{ gridRow: characterInfo.index > 3 ? 2 : 1, gridColumn: (characterInfo.index - 1) % 3 + 1 + (characterInfo.index > 3 ? 1 : 0) }}
              onMouseEnter={() => setActiveCharacter(character)}
              onClick={() => onCharacterClicked(character)}
            >
              <PlayerContainer>
                {playerThumbnail && <PlayerWrapper player={playerThumbnail} cover={gameAudit?.players?.[playerId ?? ''].isReady ? 'ready' : undefined} />}
              </PlayerContainer>
              <CharacterAvatar src={getAssetUrl(characterInfo.avatar)} />
              <CharacterTitle>{`“${characterInfo.title}”`}</CharacterTitle>
              <CharacterName>{character}</CharacterName>
            </CharacterContainer>
          )
        }
        )}
      </CharacterGrid>
    </ContentWrapper>
  );
};

export default YLSX_CharacterPage;
