import { createBinder, getAssetUrl } from "utils/utils";
import { RoseFlower, SSQW_InvestigatePage, SSQW_InvestigatePageProps } from "./SSQW_InvestigatePage";
import { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { GameContext, UserContext, VolumeContext } from "utils/contexts";
import { socket } from "utils/socketConnector";
import { useParams } from "react-router-dom";
import PickClueDialogBinder from "lib/game/components/PickClueDialogBinder";
import { SSQW_ForbiddenRooms } from "./SSQW_StaticData";
import useSound from "use-sound";
import { useRandomInterval } from "utils/hooks";
import BirdFlap from 'assets/sound/bird_flap.mp3';

export type SSQW_InvestigatePageBinderManagedProps = 'showMap' | 'gameTime' | 'numRemainingClues' | 'locationHasNewClue'
  | 'onLocationButtonClicked' | 'pickClueDialog' | 'lockWobbles' | 'lockedRooms' | 'onLockAnimationEnd' | 'showRoseRoom'
  | 'showConfirmDialog' | 'handleConfirmDialogClose' | 'onConfirmEnterRoseRoom' | 'showStageTransition' | 'onStageTransitionEnd'
  | 'roseRoomEntered' | 'flower' | 'bgm2MusicRef' | 'onBgm2MusicEnded' | 'roseRoomVoted';

export interface SSQW_InvestigatePageBinderProps extends Omit<SSQW_InvestigatePageProps, SSQW_InvestigatePageBinderManagedProps> {
  showPickClueDialog: boolean;
  setShowPickClueDialog: (_: boolean) => void;
}

const useSSQW_InvestigatePageBinder = (props: SSQW_InvestigatePageBinderProps): SSQW_InvestigatePageProps => {

  const { user } = useContext(UserContext);
  const volume = useContext(VolumeContext);
  const gameAudit = useContext(GameContext);
  const { gameId } = useParams();
  const [newClues, setNewClues] = useState<Record<string, string[]>>({});
  const { showPickClueDialog, setShowPickClueDialog } = props;
  const [currentLocation, setCurrentLocation] = useState<string>();
  const [lockWobbles, setLockWobbles] = useState<Record<string, boolean>>({ 上锁房间: false, 上锁礼堂: false, 上锁书库: false, 蔷薇馆外: false, 蔷薇图案的门: false });
  const lockedRooms: Record<string, boolean> = useMemo(() => ({
    上锁房间: !gameAudit?.rooms['男仆房']?.clues['clue07']?.isPublic,
    上锁礼堂: !gameAudit?.rooms['男仆房']?.clues['clue07']?.isPublic,
    上锁书库: !gameAudit?.rooms['男仆房']?.clues['clue07']?.isPublic,
    蔷薇馆外: !gameAudit?.rooms['男仆房']?.clues['clue07']?.isPublic,
    蔷薇房间: !gameAudit?.rooms['上锁礼堂']?.clues['clue29']?.isPublic,
  }), [gameAudit?.rooms]);
  const [showStageTransition, setShowStageTransition] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const [playBirdFlapSound] = useSound(BirdFlap);

  const [musicPlay, { stop: musicStop }] = useSound(getAssetUrl('/SSQW/bgm.mp3'), {
    volume: volume / 100,
    loop: true,
    autoplay: true,
    interrupt: true
  });

  const bgm2MusicRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    return () => musicStop();
  }, [musicStop]);

  const [flower, setFlower] = useState<ReactNode>();

  const handleFlowerClicked = useCallback(() => {
    setFlower(undefined);
  },[]);

  useRandomInterval(() => {
    if (!flower) {
      const width = Math.floor(Math.random() * 100) + 60;
      const animation = Math.floor(Math.random() * 5);
      const animationDuration = Math.floor(Math.random() * 5) + 10;
      setFlower(<RoseFlower width={width} animation={animation} animationDuration={animationDuration} handleTransitionEnd={handleFlowerClicked} />);
    }
  }, 60 * 1000 * 2, 60 * 1000 * 5);

  const numRemainingClues = useMemo(() =>
    Object.fromEntries(
      Object.entries(gameAudit?.rooms ?? {})
        .map(([room, roomInfo]) => [room, Object.values(roomInfo.clues).reduce(((numClues, clueInfo) => numClues + (clueInfo.isFound ? 0 : 1)), 0)]))
    , [gameAudit?.rooms]);

  const allowToInvestigate = useMemo(() => {
    if (gameAudit?.stage === 'INVESTIGATING1') {
      return true;
    }
    const forbiddenRoom = SSQW_ForbiddenRooms[gameAudit?.players?.[user?.userId ?? '']?.character ?? ''];
    if (forbiddenRoom) {
      if (forbiddenRoom !== currentLocation) {
        return true;
      }
      return Object.keys(gameAudit?.rooms ?? {}).every((room) => {
        if (room === forbiddenRoom) {
          return true;
        }
        return Object.values(gameAudit?.rooms[room]?.clues ?? {}).every((clue) => clue.isFound);
      })
    }
    return true;
  }, [currentLocation, gameAudit?.players, gameAudit?.rooms, gameAudit?.stage, user?.userId]);

  const unavailableClues = useMemo(() => gameAudit?.stage === 'INVESTIGATING1' ? ['clue11', 'clue12', 'clue04', 'clue05', 'clue06'] : undefined, [gameAudit?.stage]);

  useEffect(() => {

    const onClueFound = (clue: string, location: string) => {
      setNewClues((prev) => ({
        ...prev,
        [location]: prev[location] ? [...prev[location], clue] : [clue]
      }));
    }

    const onCluePublished = (clue: string, location: string, character: string) => {
      if (character !== gameAudit?.players?.[user?.userId ?? '']?.character) {
        setNewClues((prev) => ({
          ...prev,
          [location]: prev[location] ? [...prev[location], clue] : [clue]
        }));
      }
    }

    const onStageTransition = (newStage: string) => {
      if (newStage === 'READING2') {
        setShowStageTransition(true);
        musicStop();
        
      }
      if (newStage === 'INVESTIGATING1') {
        playBirdFlapSound();
      }
    }

    socket?.on('clueFound', onClueFound);
    socket?.on('cluePublished', onCluePublished);
    socket?.on('stageTransition', onStageTransition);

    return () => {
      socket?.off('clueFound', onClueFound);
      socket?.off('cluePublished', onCluePublished);
      socket?.off('stageTransition', onStageTransition);
    }
  }, [gameAudit?.players, musicStop, playBirdFlapSound, user?.userId]);

  const onLocationButtonClicked = useCallback((location: string) => {
    if (lockedRooms[location]) {
      setLockWobbles((prev) => ({ ...prev, [location]: true }));
    } else if (location === '蔷薇房间') {
      if (gameAudit?.players?.[user?.userId ?? '']?.votedClue !== 'rose') {
        setShowConfirmDialog(true);
      }
    } else {
      setCurrentLocation(location);
      setShowPickClueDialog(true);
    }
  }, [gameAudit?.players, lockedRooms, setShowPickClueDialog, user?.userId]);

  const handlePickClueDialogClose = useCallback(() => {
    setShowPickClueDialog(false);
    if (currentLocation) {
      const temp = { ...newClues };
      delete temp[currentLocation];
      setNewClues(temp);
    }
  }, [currentLocation, newClues, setShowPickClueDialog]);

  const onConfirmEnterRoseRoom = useCallback(() => {
    socket?.emit('SSQW:enterRoseRoom', gameId, user?.userId);
    setShowConfirmDialog(false);
  }, [gameId, user?.userId]);

  const handleStageTransitionEnd = useCallback((_: Event, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === 'escapeKeyDown') {
      setShowStageTransition(false);
      bgm2MusicRef.current?.play();
    }
  }, []);

  const onBgm2MusicEnded = useCallback(() => {
    musicPlay();
  }, [musicPlay]);

  const roseRoomVoted = useMemo(() => Object.values(gameAudit?.players ?? {}).filter((player) => player.votedClue === 'rose').map((player) => player.character ?? ''), [gameAudit?.players]);


  const managedProps: Pick<SSQW_InvestigatePageProps, SSQW_InvestigatePageBinderManagedProps> = {
    showMap: !!gameAudit?.stage && gameAudit.stage.slice(0, -1) !== 'READING' && gameAudit.stage !== 'INTRODUCING',
    gameTime: ['READING1', 'INTRODUCING', 'INVESTIGATING1'].includes(gameAudit?.stage ?? '') ? '12:15' : '13:15',
    numRemainingClues,
    locationHasNewClue: useMemo(() => Object.keys(newClues), [newClues]),
    onLocationButtonClicked,
    pickClueDialog: useMemo(() => <PickClueDialogBinder location={currentLocation} newClues={newClues[currentLocation ?? '']} open={showPickClueDialog} onClose={handlePickClueDialogClose} allowToInvestigate={allowToInvestigate} unavailableClues={unavailableClues} sleepAnimation={unavailableClues} />, [allowToInvestigate, currentLocation, handlePickClueDialogClose, newClues, showPickClueDialog, unavailableClues]),
    lockWobbles,
    lockedRooms,
    onLockAnimationEnd: (location: string) => setLockWobbles((prev) => ({ ...prev, [location]: false })),
    showRoseRoom: !!gameAudit?.rooms['水池旁']?.clues['clue14']?.isPublic,
    showConfirmDialog,
    handleConfirmDialogClose: () => setShowConfirmDialog(false),
    onConfirmEnterRoseRoom,
    showStageTransition,
    onStageTransitionEnd: handleStageTransitionEnd,
    roseRoomEntered: ['READING2', 'INVESTIGATING2', 'VOTING', 'REVEALING'].includes(gameAudit?.stage ?? ''),
    flower,
    bgm2MusicRef,
    onBgm2MusicEnded,
    roseRoomVoted,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const SSQW_InvestigatePageBinder = createBinder(SSQW_InvestigatePage, useSSQW_InvestigatePageBinder);

export default SSQW_InvestigatePageBinder;