import styled from '@emotion/styled';
import { FC, ReactNode, useContext } from 'react';
import { CommonUIProps } from 'utils/types';
import { UserInfo } from 'utils/localStorage';
import { ThemeButton } from 'lib/common/ThemeButton';
import { Avatar, Stack } from '@mui/material';
import { parseAvatarUrl } from 'utils/utils';
import { GameContext, PlayersContext } from 'utils/contexts';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import { CommonIconButton } from 'lib/common/CommonIconButton';


const PageContainer = styled(Stack)`
  height: 100dvh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
`;

const Poster = styled.img`
  width: 20vw;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
  margin-left: 8%;
`;

const Title = styled.img`
  height: 100vh;
  margin-right: 4%;
`;

const StoryDetailWrapper = styled(Stack)`
  width: 50vw;
  color: white;
  font-family: STFangSong;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
`;

const IntroHeader = styled.div`
  font-size: 54px;
  text-align: center;
`;

const StoryIntro = styled.div`
  margin-top: 45px;
  font-size: 26px;
  line-height: 200%;
  text-indent: 2em;
  text-align: justify;
`;

const PlayerWrapper = styled(Stack)`
  width: 120px;
`;

const PlayerAvatar = styled(Avatar)`
  height: 60px;
  width: 60px;
  background-color: #C3C3C3;
`;

const PlayerNickname = styled.div`
  width: 100%;
  font-size: 16px;
  font-family: 微软雅黑;
  text-align: center;
  margin-top: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ButtonStack = styled(Stack)`
  margin-top: 32px;
`;

const RoomNumberWrapper = styled.div`
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 26px;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export interface WaitingPageProps extends CommonUIProps {
  gameId?: string;
  user?: UserInfo;
  backgroundUrl?: string;
  posterUrl?: string;
  titleUrl?: string;
  isCreator: boolean;
  numberOfPlayers: number;
  storyDescription: string;
  onStartGameButtonClicked: () => void;
  onExitGameButtonClicked: () => void;
  onCopyCaseIdButtonClicked: () => void;
  inviteLinkDialog: ReactNode;
  onInviteLinkButtonClicked: () => void;
}

export const WaitingPage: FC<WaitingPageProps> = function ({
  gameId,
  user,
  backgroundUrl,
  posterUrl,
  titleUrl,
  isCreator,
  numberOfPlayers,
  storyDescription,
  onStartGameButtonClicked,
  onExitGameButtonClicked,
  onCopyCaseIdButtonClicked,
  inviteLinkDialog,
  onInviteLinkButtonClicked,
  ...props
}) {

  const gameAudit = useContext(GameContext);
  const players = useContext(PlayersContext);
  const DM = gameAudit?.gameType === 'HOST_BY_DM' ? gameAudit.creator : undefined;

  return (
    <PageContainer direction="row" alignItems="center" justifyContent="space-between" style={{ backgroundImage: `url(${backgroundUrl})` }} {...props}>
      <Poster src={posterUrl} />
      <StoryDetailWrapper direction="column" alignItems="center">
        <IntroHeader>
          故事背景
          <br />
          <span style={{ fontSize: '32px' }}>民国三年（1914年）</span>
        </IntroHeader>
        <StoryIntro dangerouslySetInnerHTML={{ __html: storyDescription }} />
        <Stack direction="row">
          {DM &&
            <PlayerWrapper direction="column" alignItems="center" style={{ marginRight: '80px' }}>
              <PlayerAvatar src={parseAvatarUrl(players[DM]?.avatar)} />
              <PlayerNickname>DM：{players[DM]?.nickname}</PlayerNickname>
            </PlayerWrapper>
          }
          {Object.keys(gameAudit?.players ?? {}).map((playerId) => (
            <PlayerWrapper key={playerId} direction="column" alignItems="center">
              <PlayerAvatar src={parseAvatarUrl(players[playerId]?.avatar)} />
              <PlayerNickname>{players[playerId]?.nickname}</PlayerNickname>
            </PlayerWrapper>
          ))}
          {[...Array(numberOfPlayers - Object.keys(gameAudit?.players ?? {}).length)].map((_, index) => (
            <PlayerWrapper key={`empty${index}`} direction="column" alignItems="center">
              <PlayerAvatar />
              <PlayerNickname>等待中</PlayerNickname>
            </PlayerWrapper>
          ))}
        </Stack>
        <ButtonStack direction="row" spacing={2}>
          <ThemeButton onClick={onExitGameButtonClicked}>退出房间</ThemeButton>
          {isCreator ? <ThemeButton disabled={numberOfPlayers !== Object.keys(gameAudit?.players ?? {}).length} onClick={onStartGameButtonClicked}>开始案件</ThemeButton> : null}
        </ButtonStack>
      </StoryDetailWrapper>
      <Title src={titleUrl} />
      <RoomNumberWrapper>
        案件编号：{gameId}
        <Stack direction="row">
          <CommonIconButton icon={<ContentCopyIcon htmlColor="white" />} onClick={onCopyCaseIdButtonClicked} />
          <CommonIconButton icon={<ShareIcon htmlColor="white" />} onClick={onInviteLinkButtonClicked} />
        </Stack>
      </RoomNumberWrapper>
      {inviteLinkDialog}
    </PageContainer>
  );
};

export default WaitingPage;
