/* eslint-disable react/jsx-pascal-case */
import styled from '@emotion/styled';
import { ThemeButton } from 'lib/common/ThemeButton';
import { LocationButton } from 'lib/game/components/LocationButton';
import GameLock from 'lib/game/components/GameLock';
import { FC, ReactNode } from 'react';
import { CommonUIProps } from 'utils/types';
import { getAssetUrl } from 'utils/utils';
import { YLSX_StageTransition1, YLSX_StageTransition2 } from './YLSX_StageTransition';
import ConfirmDialog from 'lib/common/ConfirmDialog';

const Map = styled.div`
  width: 50vw;
  height: 100dvh;
  position: absolute;
  top: 0;
  left: 28%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
    margin-top: 12vh;
    margin-bottom: 12vh;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d4aa70;
    border-radius: 100px;
    border: 4px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #ac926d;
  }
`;

const Floor3 = styled.div`
  margin-top: 12vh;
  width: 100%;
  position: relative;
`;

const Floor3Map = styled.img`
  width: 100%;
`;

const Floor3Mask = styled.div`
  height: 94.2%;
  width: 89.5%;
  position: absolute;
  top: 1.2%;
  left: 0.5%;
  background-color: rgba(0, 0, 0, 0.65);
  border: 6px solid transparent;
  cursor: pointer;

  &:hover {
    border-color: #fc575a;
  }
`;

const Floor2 = styled.div`
  margin-top: 60px;
  width: 100%;
  position: relative;
`;

const Floor2Map = styled.img`
  width: 100%;
`;

const Floor1 = styled.div`
  margin-top: 60px;
  width: 100%;
  position: relative;
`;

const Floor1Map = styled.img`
  width: 100%;
`;

const FrontYardMask = styled.div`
  height: 39.8%;
  width: 89.5%;
  position: absolute;
  bottom: 1.4%;
  left: 0.5%;
  background-color: rgba(0, 0, 0, 0.65);
  border: 6px solid transparent;
  cursor: pointer;

  &:hover {
    border-color: #fc575a;
  }
`;

const Basement = styled.div`
  margin-top: 60px;
  margin-bottom: 12vh;
  width: 100%;
  position: relative;
`;

const BasementMap = styled.img`
  width: 100%;
`;

const BasementMask = styled.div`
  height: 90%;
  width: 89.6%;
  position: absolute;
  top: 1.5%;
  left: 0.4%;
  background-color: rgba(0, 0, 0, 0.65);
  border: 6px solid transparent;
  cursor: pointer;

  &:hover {
    border-color: #fc575a;
  }
`;

const MapInfoWrapper = styled.div`
  position: absolute;
  top: 8vh;
  right: 6vh;
  color: white;
  font-size: 2vh;
  width: 26vh;
  font-family: 'STZhongSong';
`;

const MapInfo = styled.img`
  width: 26vh;
  margin-left: 10px;
  margin-bottom: 10px;
`;

const RestButton = styled(ThemeButton)`
  background-color: #FF6060;
  position: absolute;
  height: 36px;
  right: 34.5%;
  bottom: 7.5%;

  &:hover {
    background-color: #FF6060AA;
  }
`;

const DiningRoom = styled(LocationButton)`
  width: 31.2%;
  height: 34.7%;
  right: 9.3%;
  top: 2.1%;
`;

const DNRoom = styled(LocationButton)`
  width: 12.9%;
  height: 34%;
  left: 19%;
  top: 2.6%;
`;

const StorageRoom = styled(LocationButton)`
  width: 17.6%;
  height: 34.5%;
  left: 0.8%;
  top: 2.2%;
`;

const GuestRoom2 = styled(LocationButton)`
  width: 17.7%;
  height: 34.8%;
  right: 9.2%;
  bottom: 2.8%;
`;

const JXRoom = styled(LocationButton)`
  width: 21.8%;
  height: 34.1%;
  right: 27.5%;
  bottom: 3.4%;
`;

const RecreationRoom = styled(LocationButton)`
  width: 31.6%;
  height: 34.9%;
  left: 9.3%;
  bottom: 2.8%;
`;

const LivingRoom = styled(LocationButton)`
  width: 31.1%;
  height: 20.1%;
  right: 9.3%;
  top: 1.4%;
`;

const GuestRoom1 = styled(LocationButton)`
  width: 13.0%;
  height: 19.9%;
  left: 18.9%;
  top: 1.7%;
`;

const MaidRoom = styled(LocationButton)`
  width: 17.7%;
  height: 20.2%;
  left: 0.7%;
  top: 1.4%;
`;

const Kitchen = styled(LocationButton)`
  width: 31.1%;
  height: 20.1%;
  right: 9.3%;
  top: 35.3%;
`;

const FootmanRoom = styled(LocationButton)`
  width: 22.6%;
  height: 20.1%;
  left: 9.3%;
  top: 35.3%;
`;

const GuestRoom4 = styled(LocationButton)`
  width: 17.7%;
  height: 34.8%;
  right: 9.2%;
  top: 2.1%;
`;

const GuestRoom3 = styled(LocationButton)`
  width: 12.9%;
  height: 34.3%;
  right: 27.5%;
  top: 2.4%;
`;

const Balcony = styled(LocationButton)`
  width: 25.6%;
  height: 20.2%;
  right: 41%;
  top: 2.6%;
`;

const MasterRoom = styled(LocationButton)`
  width: 30.9%;
  height: 34.6%;
  left: 0.9%;
  top: 2.1%;
`;

const StudyRoom = styled(LocationButton)`
  width: 31.2%;
  height: 34.7%;
  right: 9.2%;
  bottom: 2.9%;
`;

const Pharmacy = styled(LocationButton)`
  width: 22.6%;
  height: 35.0%;
  left: 9.3%;
  bottom: 2.6%;
`;

const GardenerRoom = styled(LocationButton)`
  width: 7.8%;
  height: 26.0%;
  left: 5.5%;
  bottom: 8.8%;
`;

const FlowerBed = styled(LocationButton)`
  width: 26.6%;
  height: 13.4%;
  left: 32.1%;
  bottom: 15.2%;
`;

const WindowOutside = styled(LocationButton)`
  width: 4.3%;
  height: 13.0%;
  right: 31.4%;
  bottom: 28.8%;
`;

const HallwayCorner = styled(LocationButton)`
  width: 6%;
  height: 36%;
  right: 20.0%;
  top: 2.4%;
`;

const Room1 = styled(LocationButton)`
  width: 22.1%;
  height: 45.6%;
  left: 0.5%;
  top: 2%;
`;

const Room2 = styled(LocationButton)`
  width: 26%;
  height: 45.2%;
  left: 23.2%;
  top: 2%;
`;

const Room3 = styled(LocationButton)`
  width: 22.8%;
  height: 45.2%;
  left: 49.8%;
  top: 2%;
`;

export interface YLSX_InvestigatePageProps extends CommonUIProps {
  showMap?: boolean;
  showRestButton: boolean;
  onRestButtonClicked: () => void;
  isFloor3Locked: boolean;
  isFrontYardLocked: boolean;
  isBasementLocked: boolean;
  numRemainingClues: Record<string, number>;
  locationHasNewClue: string[];
  showLocationButtons: boolean;
  pickClueDialog: ReactNode;
  onLocationButtonClicked: (location: string) => void;
  lockWobbles: Record<string, boolean>;
  lockedRooms: Record<string, boolean>;
  onLockAnimationEnd: (location: string) => void;
  onLocationMaskClicked: (location: string) => void;
  showPickClueDialog: boolean;
  setShowPickClueDialog: (_: boolean) => void;
  showStageTransition1: boolean;
  showStageTransition2: boolean;
  onStageTransitionEnd: (_: Event, reason: "backdropClick" | "escapeKeyDown") => void;
  showConfirmDialog: boolean;
  handleConfirmDialogClose: () => void;
  onConfirmRest: () => void;
}

export const YLSX_InvestigatePage: FC<YLSX_InvestigatePageProps> = function ({
  showMap,
  showRestButton,
  onRestButtonClicked,
  isFloor3Locked,
  isFrontYardLocked,
  isBasementLocked,
  numRemainingClues,
  locationHasNewClue,
  showLocationButtons,
  pickClueDialog,
  onLocationButtonClicked,
  lockWobbles,
  lockedRooms,
  onLockAnimationEnd,
  onLocationMaskClicked,
  showPickClueDialog,
  setShowPickClueDialog,
  showStageTransition1,
  showStageTransition2,
  onStageTransitionEnd,
  showConfirmDialog,
  handleConfirmDialogClose,
  onConfirmRest,
  ...props
}) {
  return (
    <div {...props}>
      {showMap &&
        <>
          <Map>
            <Floor3>
              <Floor3Map src={getAssetUrl('/YLSX/floor3.webp')} />
              {showLocationButtons && <>
                {lockedRooms['三楼'] ? <Floor3Mask onClick={() => onLocationMaskClicked('三楼')} /> : <>
                  <GuestRoom4 badge={numRemainingClues['客房四']} badgeStyle={{ bottom: '12px', left: '6px' }} hasNewClue={locationHasNewClue.includes('客房四')} onClick={() => onLocationButtonClicked('客房四')} />
                  <GuestRoom3 badge={numRemainingClues['客房三']} badgeStyle={{ bottom: '12px', left: '6px' }} hasNewClue={locationHasNewClue.includes('客房三')} onClick={() => onLocationButtonClicked('客房三')} />
                  <Balcony badge={numRemainingClues['阳台']} badgeStyle={{ bottom: '38%', left: '45%' }} hasNewClue={locationHasNewClue.includes('阳台')} onClick={() => onLocationButtonClicked('阳台')} />
                  <MasterRoom badge={numRemainingClues['主人房']} badgeStyle={{ bottom: '12px' }} hasNewClue={locationHasNewClue.includes('主人房')} onClick={() => onLocationButtonClicked('主人房')} />
                  <StudyRoom badge={numRemainingClues['书房']} badgeStyle={{ bottom: '12px', left: '12px' }} hasNewClue={locationHasNewClue.includes('书房')} onClick={() => onLocationButtonClicked('书房')} />
                  <Pharmacy badge={numRemainingClues['药品房']} badgeStyle={{ bottom: '12px' }} hasNewClue={locationHasNewClue.includes('药品房')} onClick={() => onLocationButtonClicked('药品房')} />
                </>}
              </>}
            </Floor3>
            <Floor2>
              <Floor2Map src={getAssetUrl('/YLSX/floor2.webp')} />
              {showRestButton && <RestButton width="100px" onClick={onRestButtonClicked}>回房休息</RestButton>}
              {showLocationButtons && <>
                <DiningRoom badge={numRemainingClues['餐厅']} badgeStyle={{ bottom: '10px', left: '6px' }} hasNewClue={locationHasNewClue.includes('餐厅')} onClick={() => onLocationButtonClicked('餐厅')} />
                <DNRoom badge={numRemainingClues['黛妮房']} badgeStyle={{ bottom: '10px' }} hasNewClue={locationHasNewClue.includes('黛妮房')} onClick={() => onLocationButtonClicked('黛妮房')} />
                <StorageRoom badge={numRemainingClues['杂物房']} badgeStyle={{ bottom: '10px' }} hasNewClue={locationHasNewClue.includes('杂物房')} onClick={() => onLocationButtonClicked('杂物房')} />
                <GuestRoom2 badge={numRemainingClues['客房二']} badgeStyle={{ bottom: '10px', left: '6px' }} hasNewClue={locationHasNewClue.includes('客房二')} onClick={() => onLocationButtonClicked('客房二')} />
                <JXRoom badge={numRemainingClues['嘉雪房']} badgeStyle={{ bottom: '10px', left: '6px' }} hasNewClue={locationHasNewClue.includes('嘉雪房')} onClick={() => onLocationButtonClicked('嘉雪房')} />
                <RecreationRoom badge={numRemainingClues['娱乐室']} badgeStyle={{ bottom: '10px' }} hasNewClue={locationHasNewClue.includes('娱乐室')} onClick={() => onLocationButtonClicked('娱乐室')} />
                {lockedRooms['三楼'] && <GameLock wobble={lockWobbles['三楼']} style={{ left: '44.3%', bottom: '45%' }} onAnimationEnd={() => onLockAnimationEnd('三楼')} />}
              </>}
            </Floor2>
            <Floor1>
              <Floor1Map src={getAssetUrl('/YLSX/floor1.webp')} />
              {showLocationButtons && <>
                <LivingRoom badge={numRemainingClues['客厅']} badgeStyle={{ bottom: '10px', left: '6px' }} hasNewClue={locationHasNewClue.includes('客厅')} onClick={() => onLocationButtonClicked('客厅')} />
                <GuestRoom1 badge={numRemainingClues['客房一']} badgeStyle={{ bottom: '10px' }} hasNewClue={locationHasNewClue.includes('客房一')} onClick={() => onLocationButtonClicked('客房一')} />
                <Kitchen badge={numRemainingClues['厨房']} badgeStyle={{ bottom: '25px', left: '6px' }} hasNewClue={locationHasNewClue.includes('厨房')} onClick={() => onLocationButtonClicked('厨房')} />
                <MaidRoom badge={numRemainingClues['女仆房']} badgeStyle={{ bottom: '10px' }} hasNewClue={locationHasNewClue.includes('女仆房')} onClick={() => onLocationButtonClicked('女仆房')} />
                <FootmanRoom badge={numRemainingClues['男仆房']} badgeStyle={{ bottom: '25px', left: '6px' }} hasNewClue={locationHasNewClue.includes('男仆房')} onClick={() => onLocationButtonClicked('男仆房')} />
                {lockedRooms['前院'] ? <>
                  <FrontYardMask onClick={() => onLocationMaskClicked('前院')} />
                  <GameLock wobble={lockWobbles['正门']} style={{ left: '44.3%', bottom: '41%' }} onAnimationEnd={() => onLockAnimationEnd('正门')} />
                  <GameLock wobble={lockWobbles['侧门']} style={{ right: '14.25%', bottom: '41%' }} onAnimationEnd={() => onLockAnimationEnd('侧门')} />
                </> : <>
                  <GardenerRoom badge={numRemainingClues['园丁房']} badgeStyle={{ bottom: '8px' }} hasNewClue={locationHasNewClue.includes('园丁房')} onClick={() => onLocationButtonClicked('园丁房')} />
                  <FlowerBed badge={numRemainingClues['花圃']} badgeStyle={{ bottom: '37.5%', left: '50%' }} hasNewClue={locationHasNewClue.includes('花圃')} onClick={() => onLocationButtonClicked('花圃')} />
                  <WindowOutside badge={numRemainingClues['窗外']} badgeStyle={{ top: '25%' }} hasNewClue={locationHasNewClue.includes('窗外')} onClick={() => onLocationButtonClicked('窗外')} />
                </>}
                {lockedRooms['地下室'] && <GameLock wobble={lockWobbles['地下室']} style={{ right: '26%', top: '26.5%' }} onAnimationEnd={() => onLockAnimationEnd('地下室')} />}
              </>}
            </Floor1>
            <Basement>
              <BasementMap src={getAssetUrl('/YLSX/basement.webp')} />
              {showLocationButtons && <>
                {lockedRooms['地下室'] ? <BasementMask onClick={() => onLocationMaskClicked('地下室')} /> : <>
                  <HallwayCorner badge={numRemainingClues['走廊拐角']} badgeStyle={{ bottom: '25%' }} hasNewClue={locationHasNewClue.includes('走廊拐角')} onClick={() => onLocationButtonClicked('走廊拐角')} />
                  <Room1 badge={numRemainingClues['房间一']} badgeStyle={{ bottom: '30%', left: '65%' }} hasNewClue={locationHasNewClue.includes('房间一')} onClick={() => onLocationButtonClicked('房间一')} />
                  <Room2 badge={numRemainingClues['房间二']} badgeStyle={{ bottom: '30%', left: '55%' }} hasNewClue={locationHasNewClue.includes('房间二')} onClick={() => onLocationButtonClicked('房间二')} />
                  <Room3 badge={numRemainingClues['房间三']} badgeStyle={{ bottom: '30%', left: '65%' }} hasNewClue={locationHasNewClue.includes('房间三')} onClick={() => onLocationButtonClicked('房间三')} />
                </>}
              </>}
            </Basement>
          </Map>
          <MapInfoWrapper>
            <MapInfo src={getAssetUrl('/YLSX/mapinfo.webp')} />
            <p>1、只有1层的窗外有防备窃贼进出的铁栏杆。</p>
            <p>2、每层楼梯中间都有一道用来推“餐车”上下的楼梯。</p>
          </MapInfoWrapper>
        </>
      }
      {pickClueDialog}
      <YLSX_StageTransition1 open={showStageTransition1} onClose={onStageTransitionEnd} />
      <YLSX_StageTransition2 open={showStageTransition2} onClose={onStageTransitionEnd} />
      <ConfirmDialog open={showConfirmDialog} onClose={handleConfirmDialogClose} title="确定回房休息吗？" content="在你回房休息后，游戏会进入下一阶段。" onConfirmButtonClicked={onConfirmRest} />
    </div>
  );
};

export default YLSX_InvestigatePage;
