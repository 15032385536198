import styled from '@emotion/styled';
import { FC } from 'react';
import { DialogProps, Grid, Stack } from '@mui/material';
import { FullDialog } from 'lib/common/FullDialog';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import { CommonIconButton } from 'lib/common/CommonIconButton';
import { ClueRes, SecretRes } from 'openapi';
import { ClipColor, Clips } from './ClueClipDialogBinder';
import { getAssetUrl } from 'utils/utils';
import DeleteIcon from '@mui/icons-material/Delete';

const ContentWrapper = styled(Stack)`
  margin: auto;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const ClipContainer = styled.div<{ orientaiton: 'vertical' | 'horizontal' }>`
  height: 72vh;
  width: ${(props) => props.orientaiton === 'vertical' ? '60vh' : '80vh'};
  background-color: #ffe6a7;
  border-radius: 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 1;
`;

const ClipScroller = styled(Grid)`
  width: 100%;
  height: calc(100% + 32px);
  padding-top: 32px;
  padding-bottom: 32px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
    margin-top: 64px;
    margin-bottom: 32px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d4aa70;
    border-radius: 100px;
    border: 4px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #ac926d;
  }
`;

const FilterWrapper = styled(Stack)`
  margin-top: 20px;
`;

const Filter = styled.div<{ active: boolean }>`
  width: 72px;
  height: 60px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: ${(props) => props.active ? '#ffe6a7' : '#bb9457'};
  z-index: ${(props) => props.active ? '2' : '1'};
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Star = styled(StarIcon)`
  font-size: 42px;
  margin-left: 16px;
`;

const CloseButton = styled(CommonIconButton)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const NoCluesText = styled.div`
  width: 100%;
  height: 100%;
  font-size: 48px;
  color: #A4A4A4AA;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ClueCardContainer = styled.div<{ orientaiton: 'vertical' | 'horizontal' }>`
  border-radius: 20px;
  position: relative;
  width: ${(props) => props.orientaiton === 'vertical' ? 'calc(24vh + 6px)' : 'calc(30vh + 6px)'};
  height: ${(props) => props.orientaiton === 'vertical' ? '34.5vh' : '19.7vh'};
  margin-left: auto;
  margin-right: auto;
`;

const ClueCard = styled.img<{ orientaiton: 'vertical' | 'horizontal' }>`
  width: ${(props) => props.orientaiton === 'vertical' ? '24vh' : '30vh'};
  border-radius: 20px;
  border: 3px solid white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const ClueCardMask = styled.div<{ orientaiton: 'vertical' | 'horizontal' }>`
  width: ${(props) => props.orientaiton === 'vertical' ? '24vh' : '30vh'};
  height: 100%;
  position: absolute;
  left: 3px;
  top: 3px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  z-index: 1;

  > div {
    display: none;
  }
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.4);

    > div {
      display: flex;
    }
  }
`;

const ClueButtonStack = styled(Stack)`
  border-radius: 16px;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.8);
`;

const ClueLocationText = styled.div`
  margin-top: 8px;
  font-size: 24px;
  font-weight: bold;
`;

export interface ClueClipDialogProps extends DialogProps {
  clueOrientation: 'horizontal' | 'vertical';
  activeClip: string;
  onClipClicked: (clip: string) => void;
  clues?: Record<string, Partial<SecretRes | ClueRes> & { location?: string } | undefined>;
  onRemoveClipedClueButtonClicked: (clue: string) => void;
}

export const ClueClipDialog: FC<ClueClipDialogProps> = function ({
  clueOrientation,
  activeClip,
  onClipClicked,
  clues,
  onRemoveClipedClueButtonClicked,
  ...props
}) {
  return (
    <FullDialog {...props}>
      <ContentWrapper direction="row">
        <FilterWrapper direction="column" spacing={1}>
          {Clips.map((clip) =>
            <Filter key={clip} active={activeClip === clip} onClick={() => onClipClicked(clip)}>
              <Star htmlColor={ClipColor[clip]} />
            </Filter>
          )}
        </FilterWrapper>
        <ClipContainer orientaiton={clueOrientation}>
          <CloseButton icon={<CloseIcon />} onClick={() => props.onClose?.({}, 'escapeKeyDown')} />
          <ClipScroller container rowSpacing={4}>
            {clues ? <>
              {Object.entries(clues).map(([clue, clueInfo]) =>
                <Grid key={clue} item xs={6} style={{ textAlign: "center" }} justifySelf="center" >
                  <ClueCardContainer orientaiton={clueOrientation}>
                    <ClueCard src={getAssetUrl(clueInfo?.frontUrl)} orientaiton={clueOrientation} />
                    <ClueCardMask orientaiton={clueOrientation}>
                      <ClueButtonStack direction="row">
                        <CommonIconButton icon={<DeleteIcon htmlColor="white" />} onClick={() => onRemoveClipedClueButtonClicked(clue)} />
                      </ClueButtonStack>
                    </ClueCardMask>
                  </ClueCardContainer>
                  <ClueLocationText>{clue.slice(0, 6) === 'secret' ? '秘密线索' : clueInfo?.location}</ClueLocationText>
                </Grid>)}
            </> : <NoCluesText>
              这个分类目前没有线索哦
            </NoCluesText>}
          </ClipScroller>
        </ClipContainer>
      </ContentWrapper>
    </FullDialog>
  );
};

export default ClueClipDialog;
