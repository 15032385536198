import styled from '@emotion/styled';
import { FC, ReactNode, useState } from 'react';
import { CommonUIProps } from 'utils/types';
import YouTube from 'react-youtube';
import { ThemeButton } from 'lib/common/ThemeButton';
import { Stack } from '@mui/material';
import { getAssetUrl } from 'utils/utils';
import Logo from 'assets/logo1024_white.png';
import { MarqueeKeyframes } from 'utils/keyframes';

const PageContainer = styled.div`
  height: 100dvh;
  width: 100vw;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const VideoBackground = styled.div`
  height: 100dvh;
  width: 100vw;
  background-color: black;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

const YouTubeVideo = styled(YouTube)`
  width: 100vw;
  pointer-events: none;
`;

const ReturnButton = styled(ThemeButton)`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 40px;
`;

const Marquee = styled.div`
  margin-left: 50vw;
  width: 40vw;
  height: 100vh;
  color: white;
  font-size: 36px;
  animation: ${MarqueeKeyframes} linear forwards;
`;

const Cast = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  line-height: 250%;
  font-weight: normal;
`;

const StudioLogo = styled.img`
  width: 20vw;
`;

const AOTLogo = styled.img`
  width: 20vw;
`;

const LastWord = styled.div`
  margin-top: 5vh;
  line-height: 200%;
  display: flex;
  align-items: center;
  text-align: center;
`;


export interface EpiloguePageProps extends CommonUIProps {
  videoId: string;
  storyEpilogue: ReactNode;
  playerCharacterMap: Record<string, string>;
  onReturnButtonClicked: () => void;
  marqueeTime: number;
  showReturnButton: boolean;
}

export const EpiloguePage: FC<EpiloguePageProps> = function ({
  videoId,
  storyEpilogue,
  playerCharacterMap,
  onReturnButtonClicked,
  marqueeTime,
  showReturnButton,
  ...props
}) {

  const [hideVideo, setHideVideo] = useState<boolean>(false);

  return (
    <PageContainer {...props}>
      <VideoBackground>
        <YouTubeVideo videoId={videoId} style={{ opacity: hideVideo ? '0' : '1' }} opts={{ height: window.innerWidth / 16 * 9, width: window.innerWidth, playerVars: { autoplay: 1, controls: 0, mute: 1 } }} onEnd={() => setHideVideo(true)} />
      </VideoBackground>
      {storyEpilogue}
      <Marquee style={{ animationDuration: `${marqueeTime}s` }}>
        <Stack direction="column" alignItems="center">
          特别出演
          <Cast>
            {Object.entries(playerCharacterMap).map(([player, character]) => (
              <Stack key={character} direction="row" justifyContent="space-between" spacing={4}>
                <span>{character}</span>
                <span>{player}</span>
              </Stack>
            ))}
          </Cast>
          <div style={{ marginTop: '40vh' }}>剧本工作室</div>
          <StudioLogo src={getAssetUrl('/NDYY/ZLY_logo.webp')} />
          <div style={{ marginTop: '40vh' }}>
            后期设计与开发
          </div>
          <AOTLogo src={Logo} />
          <LastWord>
            感谢您的支持
            <br />
            欢迎您与好友一同体验其他剧本！
            <br />
          </LastWord>
        </Stack>
      </Marquee>
      {showReturnButton && <ReturnButton width="120px" onClick={onReturnButtonClicked}>返回复盘</ReturnButton>}
    </PageContainer>
  );
};

export default EpiloguePage;
