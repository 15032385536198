import styled from '@emotion/styled';
import { FC } from 'react';
import { Button, ButtonProps } from '@mui/material';

const IButton = styled(Button)<ThemeButtonProps>`
  width: ${(props) => props.width ?? '100%'};
  height: ${(props) => props.height ?? '48px'};
  border-radius: 8px;
  pointer-events: auto;

  color: white;
  text-align: center;
  font-size: 18px;
  line-height: 20px;
  font-style: normal;
  white-space: nowrap;

  &.MuiButton-root {
    text-transform: none;
  }

  &.Mui-disabled {
    background-color: #274472AA;
    color: #9B9B9B;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export interface ThemeButtonProps extends ButtonProps {
  loading?: boolean;
  width?: string;
  height?: string;
}

export const ThemeButton: FC<ThemeButtonProps> = function ({
  loading,
  children,
  ...props
}) {
  return (
    <IButton variant="contained" {...props}>
      <ButtonWrapper style={{opacity: loading ? 0 : 1}}>{children}</ButtonWrapper>
    </IButton>
  );
};
