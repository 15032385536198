import Sticker1 from 'assets/stickers/1.gif';
import Sticker2 from 'assets/stickers/2.gif';
import Sticker3 from 'assets/stickers/3.gif';
import Sticker4 from 'assets/stickers/4.gif';
import Sticker5 from 'assets/stickers/5.gif';
import Sticker6 from 'assets/stickers/6.gif';
import Sticker7 from 'assets/stickers/7.gif';
import Sticker8 from 'assets/stickers/8.gif';
import Sticker9 from 'assets/stickers/9.gif';
import Sticker10 from 'assets/stickers/10.gif';
import Sticker11 from 'assets/stickers/11.gif';
import Sticker12 from 'assets/stickers/12.gif';
import Sticker13 from 'assets/stickers/13.gif';
import Sticker14 from 'assets/stickers/14.gif';
import Sticker15 from 'assets/stickers/15.gif';

export const Stickers: Record<string, string> = {
  sticker1: Sticker1,
  sticker2: Sticker2,
  sticker3: Sticker3,
  sticker4: Sticker4,
  sticker5: Sticker5,
  sticker6: Sticker6,
  sticker7: Sticker7,
  sticker8: Sticker8,
  sticker9: Sticker9,
  sticker10: Sticker10,
  sticker11: Sticker11,
  sticker12: Sticker12,
  sticker13: Sticker13,
  sticker14: Sticker14,
  sticker15: Sticker15,
}