/* eslint-disable react/jsx-pascal-case */
import { createBinder, getCharacterColors } from "utils/utils";
import { InvestigatePage, InvestigatePageProps } from "./InvestigatePage";
import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GameContext, StoryContext, UserContext, VolumeContext } from "utils/contexts";
import NDYY_InvestigatePageBinder from "lib/stories/NDYY/NDYY_InvestigatePageBinder";
import { NDYY_Scripts, NDYY_TruthSummary, NDYY_VoteQuestions, getNDYYFooterText, getNDYYInvestigatePageBackgroundUrl } from "lib/stories/NDYY/NDYY_StaticData";
import VoteDialogBinder from "./components/VoteDialogBinder";
import ScriptDialogBinder from "./components/ScriptDialogBinder";
import MemoryDialogBinder from "./components/MemoryDialogBinder";
import ChoiceDialogBinder from "./components/ChoiceDialogBinder";
import { socket } from "utils/socketConnector";
import TruthSummary from "./components/TruthSummary";
import { useParams } from "react-router-dom";
import { CharacterRes } from "openapi";
import { YLSX_Scripts, YLSX_TruthSummary, YLSX_VoteQuestions, getYLSXFooterText, getYLSXInvestigatePageBackgroundUrl } from "lib/stories/YLSX/YLSX_StaticData";
import YLSX_InvestigatePageBinder from "lib/stories/YLSX/YLSX_InvestigatePageBinder";
import { useInterval } from "utils/hooks";
import SecretDialogBinder from "./components/SecretDialogBinder";
import PrivateCluesDialogBinder from "./components/PrivateCluesDialogBinder";
import { useSnackbar } from "notistack";
import GameToolbarBinder from "./components/GameToolbarBinder";
import { SSQW_Scripts, SSQW_TruthSummary, SSQW_VoteQuestions, getSSQWFooterText, getSSQWInvestigatePageBackgroundUrl } from "lib/stories/SSQW/SSQW_StaticData";
import SSQW_InvestigatePageBinder from "lib/stories/SSQW/SSQW_InvestigatePageBinder";
import { ZTFR_Scripts, ZTFR_TruthSummary, ZTFR_VoteQuestions, getZTFRFooterText, getZTFRInvestigatePageBackgroundUrl } from "lib/stories/ZTFR/ZTFR_StaticData";
import ZTFR_InvestigatePageBinder from "lib/stories/ZTFR/ZTFR_InvestigatePageBinder";
import FeedbackDialogBinder from "./components/FeedbackDialogBinder";
import { EXSL_Scripts, EXSL_TruthSummary, EXSL_VoteQuestions, getEXSLFooterText, getEXSLInvestigatePageBackgroundUrl } from "lib/stories/EXSL/EXSL_StaticData";
import EXSL_InvestigatePageBinder from "lib/stories/EXSL/EXSL_InvestigatePageBinder";
import ClueClipDialogBinder from "./components/ClueClipDialogBinder";
import { YLW_Scripts, YLW_TruthSummary, YLW_VoteQuestions, getYLWFooterText, getYLWInvestigatePageBackgroundUrl } from "lib/stories/YLW/YLW_StaticData";
import YLW_InvestigatePageBinder from "lib/stories/YLW/YLW_InvestigatePageBinder";
import GuidanceDialogBinder from "./components/GuidanceDialogBinder";
import { MHZY_Scripts, MHZY_TruthSummary, MHZY_VoteQuestions, getMHZYFooterText, getMHZYInvestigatePageBackgroundUrl } from "lib/stories/MHZY/MHZY_StaticData";
import MHZY_InvestigatePageBinder from "lib/stories/MHZY/MHZY_InvestigatePageBinder";
import { SerializedStyles } from "@emotion/react";
import HintsDialogBinder from "./components/HintsDialogBinder";
import { WQYF_Scripts, WQYF_TruthSummary, WQYF_VoteQuestions, getWQYFFooterText, getWQYFInvestigatePageBackgroundUrl } from "lib/stories/WQYF/WQYF_StaticData";
import WQYF_InvestigatePageBinder from "lib/stories/WQYF/WQYF_InvesitgatePageBinder";
import XLJ_InvestigatePageBinder from "lib/stories/XLJ/XLJ_InvestigatePageBinder";
import { XLJ_Scripts, XLJ_TruthSummary, XLJ_VoteQuestions, getXLJFooterText, getXLJInvestigatePageBackgroundUrl } from "lib/stories/XLJ/XLJ_StaticData";
import { getYSZMFooterText, getYSZMInvestigatePageBackgroundUrl, YSZM_Scripts, YSZM_TruthSummary, YSZM_VoteQuestions } from "lib/stories/YSZM/YSZM_StaticData";
import YSZM_InvestigatePageBinder from "lib/stories/YSZM/YSZM_InvestigatePageBinder";
import SubscriptionDialogBinder from "lib/home/SubscriptionDialogBinder";

export type InvestigatePageBinderManagedProps = 'user' | 'playerAudit' | 'backgroundUrl' | 'playerCharacterMap'
  | 'characters' | 'characterColors' | 'chatBubbleMessages' | 'showChatBubble' | 'handleChatBubbleClose'
  | 'storyLayout' | 'volume' | 'handleVolumeChange'
  | 'showVoteButton' | 'hasVoted' | 'voteDialog' | 'onVoteButtonClicked' | 'scriptDialog' | 'onScriptButtonClicked'
  | 'showMemoryButton' | 'memoryDialog' | 'onMemoryButtonClicked' | 'showChoiceButton' | 'choiceDialog' | 'onChoiceButtonClicked'
  | 'newMemories' | 'newChoices' | 'hasNewScripts' | 'showTruthButton' | 'animateTruthButton' | 'onTruthButtonClicked' | 'truthSummary'
  | 'isAudience' | 'footerText'
  | 'introducingTurn' | 'onFinishIntroducingButtonClicked' | 'clueTurn'
  | 'showSecretButton' | 'secretDialog' | 'newSecrets' | 'onSecretButtonClicked'
  | 'privateCluesDialog' | 'onPrivateCluesButtonClicked' | 'showEndDiscussionButton' | 'onEndDiscussionButtonClicked'
  | 'showConfirmDialog' | 'handleConfirmDialogClose' | 'onConfirmEndDiscussion'
  | 'toolbar' | 'currentCharacter' | 'numberOfPlayers' | 'newCharacters' | 'changeCharacters'
  | 'feedbackDialog' | 'clueClipDialog' | 'guidanceDialog' | 'hintsDialog'
  | 'onSkipClueTurnButtonClicked'
  | 'backgroundAnimation' | 'onBackgroundAnimationEnd'
  | 'donateDialog';

export interface InvestigatePageBinderProps extends Omit<InvestigatePageProps, InvestigatePageBinderManagedProps> { }

const useInvestigatePageBinder = (props: InvestigatePageBinderProps): InvestigatePageProps => {

  const { user } = useContext(UserContext);
  const gameAudit = useContext(GameContext);
  const storyDetail = useContext(StoryContext);
  const { gameId } = useParams();

  const [volume, setVolume] = useState<number>(50);
  const [showVoteDialog, setShowVoteDialog] = useState<boolean>(false);
  const [showScriptDialog, setShowScriptDialog] = useState<boolean>(false);
  const [showMemoryDialog, setShowMemoryDialog] = useState<boolean>(false);
  const [showChoiceDialog, setShowChoiceDialog] = useState<boolean>(false);
  const [showSecretDialog, setShowSecretDialog] = useState<boolean>(false);
  const [showPickClueDialog, setShowPickClueDialog] = useState<boolean>(false);
  const [showPrivateCluesDialog, setShowPrivateCluesDialog] = useState<boolean>(false);
  const [showExploreDialog, setShowExploreDialog] = useState<boolean>(false);

  const isDialogShowing = showVoteDialog || showScriptDialog || showMemoryDialog || showChoiceDialog || showSecretDialog || showPickClueDialog || showPrivateCluesDialog || showExploreDialog;

  const [newMemories, setNewMemories] = useState<string[]>([]);
  const [currentScriptPage, setCurrentScriptPage] = useState<number>(0);
  const [hasNewScripts, setHasNewScripts] = useState<boolean>(false);
  const [newChoices, setNewChoices] = useState<string[]>([]);
  const [animateTruthButton, setAnimateTruthButton] = useState<boolean>(false);
  const [newSecrets, setNewSecrets] = useState<string[]>([]);
  const [showTruthSummary, setShowTruthSummary] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const [changeCharacters, setChangeCharacters] = useState<string[]>();

  const [showFeedbackDialog, setShowFeedbackDialog] = useState<boolean>(false);
  const [showClueClipDialog, setShowClueClipDialog] = useState<boolean>(false);
  const [showGuidanceDialog, setShowGuidanceDialog] = useState<boolean>(false);
  const [showHintsDialog, setShowHintsDialog] = useState<boolean>(false);
  const [showDonateDialog, setShowDonateDialog] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const isAudience = useMemo(() => !Object.keys(gameAudit?.players ?? {}).includes(user?.userId ?? ''), [gameAudit?.players, user?.userId]);

  const backgroundUrlArray = useMemo(() => {
    switch (gameAudit?.storyId) {
      case 'NDYY':
        return getNDYYInvestigatePageBackgroundUrl(gameAudit?.stage);
      case 'YLSX':
        return getYLSXInvestigatePageBackgroundUrl(gameAudit?.stage);
      case 'SSQW':
        return getSSQWInvestigatePageBackgroundUrl(gameAudit?.stage);
      case 'ZTFR':
        return getZTFRInvestigatePageBackgroundUrl(gameAudit?.stage);
      case 'EXSL':
        return getEXSLInvestigatePageBackgroundUrl(gameAudit?.stage);
      case 'YLW':
        return getYLWInvestigatePageBackgroundUrl(gameAudit?.stage);
      case 'MHZY':
        return getMHZYInvestigatePageBackgroundUrl(gameAudit?.stage);
      case 'WQYF':
        return getWQYFInvestigatePageBackgroundUrl(gameAudit?.stage);
      case 'XLJ':
        return getXLJInvestigatePageBackgroundUrl(gameAudit?.stage);
      case 'YSZM':
        return getYSZMInvestigatePageBackgroundUrl(gameAudit?.stage);
      default:
        return undefined;
    }
  }, [gameAudit?.stage, gameAudit?.storyId]);

  const [backgroundIndex, setBackgroundIndex] = useState<number>(0);
  const [controlledBackground, setControlledBackground] = useState<string>();
  const [backgroundAnimation, setBackgroundAnimation] = useState<SerializedStyles>();

  useInterval(() => {
    setBackgroundIndex((backgroundIndex + 1) % (backgroundUrlArray?.length ?? 1));
  }, (backgroundUrlArray && (backgroundUrlArray.length > 1)) ? 60000 : null);

  const currentCharacter = gameAudit?.players?.[user?.userId ?? '']?.character ?? '';
  const playerCharacterMap = useMemo(() => Object.fromEntries(Object.entries(gameAudit?.players ?? {}).map(([playerId, player]) => [playerId, player.character ?? ''])), [gameAudit?.players]);
  const characterOrder = useMemo(() => Object.keys(storyDetail?.characterList ?? {}).sort((characterA, characterB) => storyDetail!.characterList[characterA]?.index - storyDetail!.characterList[characterB]?.index), [storyDetail]);
  const charactersSorted = useMemo(() => Object.fromEntries(characterOrder.map((character) => [character, storyDetail?.characterList[character] as CharacterRes])), [characterOrder, storyDetail?.characterList]);
  const newCharacters: Record<string, string | undefined> = useMemo(() => {
    switch (gameAudit?.storyId) {
      case 'ZTFR':
        switch (gameAudit?.stage) {
          case 'READING2':
          case 'INVESTIGATING2':
          case 'DISCUSSING2':
            return { 葛月萝: '葛月曼', 薛达财: '薛玉帆', 吕松年: '吕陆升' };
          case 'READING3':
          case 'INVESTIGATING3':
          case 'DISCUSSING3':
            return { 葛月萝: '葛月曼', 薛达财: '薛玉帆', 吕松年: '吕陆升', 于彦诚: '于尚勇' };
          case 'READING4':
          case 'INVESTIGATING4':
          case 'VOTING':
          case 'REVEALING':
            return { 葛月萝: '葛月曼', 薛达财: '薛玉帆', 吕松年: '吕陆升', 于彦诚: '于尚勇', 张太太: '稚筠' };
          default:
            return {};
        }
      case 'MHZY':
        if (['READING2', 'INVESTIGATING2', 'DISCUSSING2', 'READING3', 'INVESTIGATING3', 'VOTING', 'ESCAPING', 'REVEALING'].includes(gameAudit?.stage ?? '')) {
          return { 王赶海: '潜水员' };
        } else {
          return {};
        }
      case 'XLJ':
        if (['READING2', 'INVESTIGATING2', 'DISCUSSING2', 'READING3', 'INVESTIGATING3', 'VOTING', 'ESCAPING', 'REVEALING'].includes(gameAudit?.stage ?? '')) {
          return { 昭然: '祀女' };
        }
        return {};
      default:
        return {};
    }
  }, [gameAudit?.stage, gameAudit?.storyId]);
  const introducingTurn = useMemo(() => {
    if (gameAudit?.stage === 'INTRODUCING') {
      return characterOrder.find((character) => !Object.values(gameAudit?.players ?? {}).find((player) => player.character === character)?.hasIntroduced);
    }
  }, [characterOrder, gameAudit?.players, gameAudit?.stage]);

  const [chatBubbleMessages, setChatBubbleMessages] = useState<Record<string, { message?: string, delay?: number }>>(Object.fromEntries(Object.keys(charactersSorted).map((character) => [character, {}])));
  const [showChatBubble, setShowChatBubble] = useState<Record<string, boolean>>(Object.fromEntries(Object.keys(charactersSorted).map((character) => [character, false])));

  const handleChatBubbleClose = useCallback((character: string) => {
    setShowChatBubble((prev) => ({
      ...prev,
      [character]: false
    }))
  }, []);

  const [newHints, setNewHints] = useState<boolean>(false);

  useEffect(() => {

    const onChatMessageReceived = (character: string, message?: string, delay?: number) => {
      setChatBubbleMessages((prev) => ({
        ...prev,
        [character]: { message, delay }
      }))
      setShowChatBubble((prev) => ({
        ...prev,
        [character]: true
      }))
    }

    const onStickerReceived = (character: string, stickerId: string) => {
      setChatBubbleMessages((prev) => ({
        ...prev,
        [character]: {
          message: `/s ${stickerId}`,
          delay: 5000,
        }
      }))
      setShowChatBubble((prev) => ({
        ...prev,
        [character]: true
      }))
    }

    const onMemoryTriggered = (character: string, memory: string) => {
      if (character === gameAudit?.players?.[user?.userId ?? '']?.character) {
        setNewMemories((prev) => [...prev, memory]);
      }
      setChatBubbleMessages((prev) => ({
        ...prev,
        [character]: {
          message: `/m ${gameAudit?.memories?.[character]?.[memory]?.message1}-${memory}-${gameAudit?.memories?.[character]?.[memory]?.message2}`,
          delay: 8000,
        }
      }));
      setShowChatBubble((prev) => ({
        ...prev,
        [character]: true
      }))
    }

    const onChoiceTriggered = (choice: string) => {
      setNewChoices((prev) => [...prev, choice]);
    }

    const onCluePublished = (_: string, location: string, character: string) => {
      enqueueSnackbar(`${character}公开了一条${location}的线索`);
    }

    const onSecretFound = (secret: string) => {
      setNewSecrets((prev) => [...prev, secret]);
    }

    const onSecretPublished = (secret: string, character: string) => {
      enqueueSnackbar(`${character}公开了一条秘密线索`);
      if (character !== gameAudit?.players?.[user?.userId ?? '']?.character) {
        setNewSecrets((prev) => [...prev, secret]);
      }
    }

    const onStageTransition = (newStage: string) => {
      if (newStage.slice(0, -1) === 'INVESTIGATING' || newStage === 'INTRODUCING' || newStage === 'GREETING') {
        //隐藏“我读完了”聊天气泡
        setChatBubbleMessages(Object.fromEntries(Object.keys(charactersSorted).map((character) => [character, { message: chatBubbleMessages[character].message, delay: 0 }])));
      }
      //嗜睡蔷薇剧本进入第二幕隐藏聊天气泡
      if (gameAudit?.storyId === 'SSQW' && newStage === 'READING2') {
        setChatBubbleMessages(Object.fromEntries(Object.keys(charactersSorted).map((character) => [character, { message: chatBubbleMessages[character].message, delay: 0 }])));
      }
      if (gameAudit?.storyId === 'WQYF' && newStage === 'READING3') {
        setChatBubbleMessages(Object.fromEntries(Object.keys(charactersSorted).map((character) => [character, { message: chatBubbleMessages[character].message, delay: 0 }])));
      }
      if (newStage.slice(0, -1) === 'READING' && !isAudience) {
        setHasNewScripts(true);
        switch (gameAudit?.storyId) {
          case 'NDYY':
            setCurrentScriptPage(8);
            break;
          case 'YLSX':
            setCurrentScriptPage(newStage === 'READING2' ? 5 : 8)
            break;
          case 'SSQW':
            setCurrentScriptPage(9);
            break;
          case 'ZTFR':
            if (newStage === 'READING2') {
              setChangeCharacters(['葛月萝', '薛达财', '吕松年']);
              setCurrentScriptPage(3);
            } else if (newStage === 'READING3') {
              setChangeCharacters(['于彦诚']);
              setCurrentScriptPage(6);
            } else {
              setChangeCharacters(['张太太']);
              setCurrentScriptPage(9);
            }
            break;
          case 'EXSL':
            setCurrentScriptPage(newStage === 'READING2' ? 7 : 10);
            break;
          case 'YLW':
            setCurrentScriptPage(8);
            break;
          case 'MHZY':
            if (newStage === 'READING2') {
              setChangeCharacters(['王赶海']);
              setCurrentScriptPage(4);
            } else {
              setCurrentScriptPage(11);
            }
            break;
          case 'WQYF':
            setCurrentScriptPage(newStage === 'READING2' ? 6 : 9);
            break;
          case 'XLJ':
            if (newStage === 'READING2') {
              setChangeCharacters(['昭然']);
              setCurrentScriptPage(4);
            } else {
              setCurrentScriptPage(10);
            }
            break;
          case 'YSZM':
            setCurrentScriptPage(newStage === 'READING2' ? 6 : 9);
            break;
          default:
            break;
        }
      }

      if (newStage === 'VOTING') {
        setNewHints(true);
      }

      if (newStage === 'ESCAPING') {
        setChatBubbleMessages(Object.fromEntries(Object.keys(charactersSorted).map((character) => [character, { message: chatBubbleMessages[character].message, delay: 0 }])));
      }

      if (newStage === 'REVEALING') {
        setChatBubbleMessages(Object.fromEntries(Object.keys(charactersSorted).map((character) => [character, { message: chatBubbleMessages[character].message, delay: 0 }])));
        setAnimateTruthButton(true);
      }
    }

    const onHintRevealed = (_: string) => {
      setNewHints(true);
    }

    socket?.on('chatMessageReceived', onChatMessageReceived);
    socket?.on('memoryTriggered', onMemoryTriggered);
    socket?.on('choiceTriggered', onChoiceTriggered);
    socket?.on('stageTransition', onStageTransition);
    socket?.on('cluePublished', onCluePublished);
    socket?.on('secretFound', onSecretFound);
    socket?.on('secretPublished', onSecretPublished);
    socket?.on('stickerReceived', onStickerReceived);
    socket?.on('hintRevealed', onHintRevealed);

    return () => {
      socket?.off('chatMessageReceived', onChatMessageReceived);
      socket?.off('memoryTriggered', onMemoryTriggered);
      socket?.off('choiceTriggered', onChoiceTriggered);
      socket?.off('stageTransition', onStageTransition);
      socket?.off('cluePublished', onCluePublished);
      socket?.off('secretFound', onSecretFound);
      socket?.off('secretPublished', onSecretPublished);
      socket?.off('stickerReceived', onStickerReceived);
      socket?.off('hintRevealed', onHintRevealed);
    }
  }, [charactersSorted, chatBubbleMessages, enqueueSnackbar, gameAudit?.memories, gameAudit?.players, gameAudit?.storyId, isAudience, user?.userId]);

  useEffect(() => {
    if ((gameAudit?.players?.[user?.userId ?? '']?.privateClues?.length ?? 0) > 1 && !isDialogShowing) {
      setShowPrivateCluesDialog(true);
    }
  }, [gameAudit?.players, isDialogShowing, user?.userId]);

  const renderStoryLayout = useMemo(() => {
    switch (gameAudit?.storyId) {
      case 'NDYY':
        return <NDYY_InvestigatePageBinder />;
      case 'YLSX':
        return <YLSX_InvestigatePageBinder showPickClueDialog={showPickClueDialog} setShowPickClueDialog={setShowPickClueDialog} />;
      case 'SSQW':
        return <SSQW_InvestigatePageBinder showPickClueDialog={showPickClueDialog} setShowPickClueDialog={setShowPickClueDialog} />;
      case 'ZTFR':
        return <ZTFR_InvestigatePageBinder showPickClueDialog={showPickClueDialog} setShowPickClueDialog={setShowPickClueDialog} />;
      case 'EXSL':
        return <EXSL_InvestigatePageBinder showPickClueDialog={showPickClueDialog} setShowPickClueDialog={setShowPickClueDialog} />;
      case 'YLW':
        return <YLW_InvestigatePageBinder showPickClueDialog={showPickClueDialog} setShowPickClueDialog={setShowPickClueDialog} showExploreDialog={showExploreDialog} setShowExploreDialog={setShowExploreDialog} />;
      case 'MHZY':
        return <MHZY_InvestigatePageBinder showPickClueDialog={showPickClueDialog} setShowPickClueDialog={setShowPickClueDialog} setControllerBackground={setControlledBackground} setBackgroundAnimation={setBackgroundAnimation} />;
      case 'WQYF':
        return <WQYF_InvestigatePageBinder showPickClueDialog={showPickClueDialog} setShowPickClueDialog={setShowPickClueDialog} />;
      case 'XLJ':
        return <XLJ_InvestigatePageBinder showPickClueDialog={showPickClueDialog} setShowPickClueDialog={setShowPickClueDialog} />
      case 'YSZM':
        return <YSZM_InvestigatePageBinder showPickClueDialog={showPickClueDialog} setShowPickClueDialog={setShowPickClueDialog} />;
      default:
        return null;
    }
  }, [gameAudit?.storyId, showExploreDialog, showPickClueDialog]);

  const voteQuestions = useMemo(() => {
    switch (gameAudit?.storyId) {
      case 'NDYY':
        return NDYY_VoteQuestions;
      case 'YLSX':
        return YLSX_VoteQuestions;
      case 'SSQW':
        return SSQW_VoteQuestions;
      case 'ZTFR':
        return ZTFR_VoteQuestions;
      case 'EXSL':
        return EXSL_VoteQuestions;
      case 'YLW':
        return YLW_VoteQuestions;
      case 'MHZY':
        return MHZY_VoteQuestions;
      case 'WQYF':
        return WQYF_VoteQuestions;
      case 'XLJ':
        return XLJ_VoteQuestions;
      case 'YSZM':
        return YSZM_VoteQuestions;
      default:
        return [];
    }
  }, [gameAudit?.storyId]);

  const scriptUrl = useMemo(() => {
    switch (gameAudit?.storyId) {
      case 'NDYY':
        return NDYY_Scripts[currentCharacter];
      case 'YLSX':
        return YLSX_Scripts[currentCharacter];
      case 'SSQW':
        return SSQW_Scripts[currentCharacter];
      case 'ZTFR':
        return ZTFR_Scripts[currentCharacter];
      case 'EXSL':
        return EXSL_Scripts[currentCharacter];
      case 'YLW':
        return YLW_Scripts[currentCharacter];
      case 'MHZY':
        return MHZY_Scripts[currentCharacter];
      case 'WQYF':
        return WQYF_Scripts[currentCharacter];
      case 'XLJ':
        return XLJ_Scripts[currentCharacter];
      case 'YSZM':
        return YSZM_Scripts[currentCharacter];
      default:
        return undefined;
    }
  }, [currentCharacter, gameAudit?.storyId]);

  const showMemoryButton = useMemo(() => {
    if (gameAudit?.stage && !['INTRODUCING', 'READING1'].includes(gameAudit?.stage)) {
      switch (gameAudit?.storyId) {
        case 'NDYY':
        case 'SSQW':
        case 'WQYF':
          return true;
        case 'YLW':
        case 'MHZY':
        case 'XLJ':
          return ['INVESTIGATING2', 'DISCUSSING2', 'INVESTIGATING3', 'VOTING', 'REVEALING'].includes(gameAudit?.stage ?? '');
        case 'ZTFR':
          return gameAudit.players?.[user?.userId ?? '']?.character === '乐婉';
        default:
          return false;
      }
    }
    return false;
  }, [gameAudit?.players, gameAudit?.stage, gameAudit?.storyId, user?.userId]);

  const truthSummary = useMemo(() => {
    switch (gameAudit?.storyId) {
      case 'NDYY':
        return NDYY_TruthSummary;
      case 'YLSX':
        return YLSX_TruthSummary;
      case 'SSQW':
        return SSQW_TruthSummary;
      case 'ZTFR':
        return ZTFR_TruthSummary;
      case 'EXSL':
        return EXSL_TruthSummary;
      case 'YLW':
        return YLW_TruthSummary;
      case 'MHZY':
        return MHZY_TruthSummary;
      case 'WQYF':
        return WQYF_TruthSummary;
      case 'XLJ':
        return XLJ_TruthSummary;
      case 'YSZM':
        return YSZM_TruthSummary;
      default:
        return {};
    }
  }, [gameAudit?.storyId]);

  const clueTurn = useMemo(() => {
    let turn = 0;
    switch (gameAudit?.storyId) {
      case 'YLSX':
      case 'EXSL':
      case 'YLW':
      case 'MHZY':
        turn = ['INVESTIGATING2', 'INVESTIGATING3'].includes(gameAudit?.stage) ? (gameAudit.clueTurn ?? 0) : 0;
        break;
      case 'SSQW':
        turn = ['INVESTIGATING1', 'INVESTIGATING2'].includes(gameAudit?.stage) ? (gameAudit.clueTurn ?? 0) : 0;
        break;
      case 'ZTFR':
        turn = ['INVESTIGATING1', 'INVESTIGATING2', 'INVESTIGATING3', 'INVESTIGATING4'].includes(gameAudit?.stage) ? (gameAudit.clueTurn ?? 0) : 0;
        break;
      case 'XLJ':
      case 'WQYF':
      case 'YSZM':
        turn = ['INVESTIGATING1', 'INVESTIGATING2', 'INVESTIGATING3'].includes(gameAudit?.stage) ? (gameAudit.clueTurn ?? 0) : 0;
        break;
      default:
        break;
    }
    return Object.keys(charactersSorted).find((character) => charactersSorted[character].index === turn);
  }, [charactersSorted, gameAudit?.clueTurn, gameAudit?.stage, gameAudit?.storyId]);

  const footerText = useMemo(() => {
    switch (gameAudit?.storyId) {
      case 'NDYY':
        return getNDYYFooterText(gameAudit?.stage);
      case 'YLSX':
        return getYLSXFooterText(gameAudit?.stage);
      case 'SSQW':
        return getSSQWFooterText(gameAudit?.stage);
      case 'ZTFR':
        return getZTFRFooterText(gameAudit?.stage);
      case 'EXSL':
        return getEXSLFooterText(gameAudit?.stage);
      case 'YLW':
        return getYLWFooterText(gameAudit?.stage);
      case 'MHZY':
        return getMHZYFooterText(gameAudit?.stage);
      case 'WQYF':
        return getWQYFFooterText(gameAudit?.stage);
      case 'XLJ':
        return getXLJFooterText(gameAudit?.stage);
      case 'YSZM':
        return getYSZMFooterText(gameAudit?.stage);
      default:
        return undefined;
    }
  }, [gameAudit?.stage, gameAudit?.storyId]);

  const showSecretButton = useMemo(() => {
    switch (gameAudit?.storyId) {
      case 'YLSX':
      case 'EXSL':
      case 'YLW':
      case 'WQYF':
      case 'YSZM':
        return !!gameAudit?.stage && ['INVESTIGATING2', 'DISCUSSING2', 'INVESTIGATING3', "VOTING", 'REVEALING'].includes(gameAudit?.stage);
      default:
        return false;
    }
  }, [gameAudit?.stage, gameAudit?.storyId]);

  const handleScrtipDialogClose = useCallback(() => {
    setShowScriptDialog(false);
    setHasNewScripts(false);
  }, []);

  const handleMemoryDialogClose = useCallback(() => {
    setShowMemoryDialog(false);
    setNewMemories([]);
  }, []);

  const handleChoiceDialogClose = useCallback(() => {
    setShowChoiceDialog(false);
    setNewChoices([]);
  }, []);

  const handleSecretDialogClose = useCallback(() => {
    setShowSecretDialog(false);
    setNewSecrets([]);
  }, []);

  const handleTruthSummaryClosed = useCallback(() => {
    setShowTruthSummary(false)
    if (animateTruthButton) {
      setAnimateTruthButton(false);
      setShowFeedbackDialog(true);
    }
  }, [animateTruthButton]);

  const onFinishIntroducingButtonClicked = useCallback(() => {
    if (gameAudit?.players?.[user?.userId ?? '']?.character === introducingTurn) {
      socket?.emit('playerFinishIntroducing', user?.userId, gameId);
    }
  }, [gameAudit?.players, gameId, introducingTurn, user?.userId]);

  const onConfirmEndDiscussion = useCallback(() => {
    setShowConfirmDialog(false);
    socket?.emit('endDiscussion', gameId);
  }, [gameId]);

  const onSkipClueTurnButtonClicked = useCallback(() => {
    if (user?.userId && gameId) {
      socket?.emit('playerSkipClueTurn', user?.userId, gameId);
    }
  }, [gameId, user?.userId]);

  const onHintsDialogClosed = useCallback(() => {
    setShowHintsDialog(false);
    setNewHints(false);
  }, []);

  const managedProps: Pick<InvestigatePageProps, InvestigatePageBinderManagedProps> = {
    user,
    playerAudit: gameAudit?.players,
    backgroundUrl: controlledBackground ?? backgroundUrlArray?.[backgroundIndex] ?? backgroundUrlArray?.[0],
    playerCharacterMap,
    characters: charactersSorted,
    characterColors: getCharacterColors(gameAudit?.storyId),
    chatBubbleMessages,
    showChatBubble,
    handleChatBubbleClose,
    storyLayout:
      <VolumeContext.Provider value={volume}>
        {renderStoryLayout}
      </VolumeContext.Provider>,
    volume,
    handleVolumeChange: (_, newValue) => setVolume(newValue as number),
    showVoteButton: gameAudit?.stage === 'VOTING' && !isAudience,
    hasVoted: !!gameAudit?.players?.[user?.userId ?? '']?.votedMurderers,
    voteDialog: useMemo(() => <VoteDialogBinder questions={voteQuestions} characterList={charactersSorted} open={showVoteDialog} onClose={() => setShowVoteDialog(false)} />, [charactersSorted, showVoteDialog, voteQuestions]),
    onVoteButtonClicked: () => setShowVoteDialog(true),
    scriptDialog: useMemo(() =>
      <ScriptDialogBinder
        scriptUrl={scriptUrl}
        currentPage={currentScriptPage}
        onPageChange={(_: ChangeEvent<unknown>, page: number) => setCurrentScriptPage(page - 1)}
        onLeftButtonClicked={() => setCurrentScriptPage(currentScriptPage - 1)}
        onRightButtonClicked={() => setCurrentScriptPage(currentScriptPage + 1)}
        open={showScriptDialog}
        onClose={handleScrtipDialogClose}
      />
      , [currentScriptPage, handleScrtipDialogClose, scriptUrl, showScriptDialog]),
    onScriptButtonClicked: () => setShowScriptDialog(true),
    showMemoryButton: showMemoryButton && !isAudience,
    memoryDialog: useMemo(() => <MemoryDialogBinder cardOrientation={gameAudit?.storyId === 'NDYY' ? 'horizontal' : 'vertical'} newMemories={newMemories} open={showMemoryDialog} onClose={handleMemoryDialogClose} />, [gameAudit?.storyId, handleMemoryDialogClose, newMemories, showMemoryDialog]),
    onMemoryButtonClicked: () => setShowMemoryDialog(true),
    showChoiceButton: !!gameAudit?.stage && gameAudit?.storyId === 'NDYY' && !['INTRODUCING', 'READING1'].includes(gameAudit?.stage),
    choiceDialog: useMemo(() => <ChoiceDialogBinder newChoices={newChoices} open={showChoiceDialog} onClose={handleChoiceDialogClose} />, [handleChoiceDialogClose, newChoices, showChoiceDialog]),
    onChoiceButtonClicked: () => setShowChoiceDialog(true),
    newMemories,
    newChoices,
    hasNewScripts,
    showTruthButton: gameAudit?.stage === 'REVEALING',
    animateTruthButton,
    onTruthButtonClicked: () => setShowTruthSummary(true),
    truthSummary: showTruthSummary && <TruthSummary questions={voteQuestions} truth={truthSummary} onDonateButtonClicked={() => setShowDonateDialog(true)} onCloseButtonClicked={handleTruthSummaryClosed} />,
    isAudience,
    footerText,
    introducingTurn,
    onFinishIntroducingButtonClicked,
    clueTurn,
    showSecretButton,
    secretDialog: useMemo(() => <SecretDialogBinder newSecrets={newSecrets} open={showSecretDialog} onClose={handleSecretDialogClose} />, [handleSecretDialogClose, newSecrets, showSecretDialog]),
    newSecrets,
    onSecretButtonClicked: () => setShowSecretDialog(true),
    privateCluesDialog: useMemo(() => <PrivateCluesDialogBinder open={showPrivateCluesDialog} onClose={() => setShowPrivateCluesDialog(false)} />, [showPrivateCluesDialog]),
    onPrivateCluesButtonClicked: () => setShowPrivateCluesDialog(true),
    showEndDiscussionButton: gameAudit?.creator === user?.userId && gameAudit?.stage.slice(0, -1) === 'DISCUSSING',
    onEndDiscussionButtonClicked: () => setShowConfirmDialog(true),
    showConfirmDialog,
    handleConfirmDialogClose: () => setShowConfirmDialog(false),
    onConfirmEndDiscussion,
    toolbar: useMemo(() => <GameToolbarBinder newHints={newHints} onClueClipButtonClicked={() => setShowClueClipDialog(true)} onFeedbackButtonClicked={() => setShowFeedbackDialog(true)} onGuidanceButtonClicked={() => setShowGuidanceDialog(true)} onHintsButtonClicked={() => setShowHintsDialog(true)} />, [newHints]),
    currentCharacter: newCharacters[currentCharacter] ?? currentCharacter,
    numberOfPlayers: gameAudit?.numberOfPlayers ?? 0,
    newCharacters,
    changeCharacters,
    feedbackDialog: useMemo(() => <FeedbackDialogBinder open={showFeedbackDialog} onClose={() => setShowFeedbackDialog(false)} />, [showFeedbackDialog]),
    clueClipDialog: useMemo(() => <ClueClipDialogBinder open={showClueClipDialog} onClose={() => setShowClueClipDialog(false)} />, [showClueClipDialog]),
    guidanceDialog: useMemo(() => <GuidanceDialogBinder open={showGuidanceDialog} onClose={() => setShowGuidanceDialog(false)} />, [showGuidanceDialog]),
    onSkipClueTurnButtonClicked,
    backgroundAnimation,
    onBackgroundAnimationEnd: () => setBackgroundAnimation(undefined),
    hintsDialog: useMemo(() => <HintsDialogBinder open={showHintsDialog} onClose={onHintsDialogClosed} />, [onHintsDialogClosed, showHintsDialog]),
    donateDialog: useMemo(() => <SubscriptionDialogBinder open={showDonateDialog} onClose={() => setShowDonateDialog(false)}/>, [showDonateDialog]),
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const InvestigatePageBinder = createBinder(InvestigatePage, useInvestigatePageBinder);

export default InvestigatePageBinder;