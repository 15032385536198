import styled from '@emotion/styled';
import { FC, useEffect, useMemo, useState } from 'react';
import { DialogProps, Stack } from '@mui/material';
import { getAssetUrl } from 'utils/utils';
import { ThemeButton } from 'lib/common/ThemeButton';
import { FullDialog } from 'lib/common/FullDialog';
import { FlipBackKeyframes, FlipKeyframes } from 'utils/keyframes';
import { SecretRes } from 'openapi';
import FlipSound from 'assets/sound/flip.mp3';
import useSound from 'use-sound';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { ClipBadge, ClipTooltip } from './PickClueDialog';
import { CommonIconButton } from 'lib/common/CommonIconButton';
import { ClipColor, Clips } from './ClueClipDialogBinder';
import StarIcon from '@mui/icons-material/Star';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';

const ContentWrapper = styled(Stack)`
  width: 100vw;
  height: 100dvh;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
`;

const TitleBox = styled.div`
  width: 100vw;
  padding-top: 30px;
  color: white;
  font-size: 56px;
  font-family: '黑体';
  text-align: center;
`;

const ClueContainer = styled.div`
  width: 80vw;
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-bottom: 10vh;
`;

const FlipButtonWrapper = styled.div`
  height: 40px;
`;

const FlipButton = styled(CommonIconButton)`
  width: 40px;
  transition: transform 0.8s ease-in-out;
`;

const ClueCardContainer = styled.div<{ shouldFlip?: boolean }>`
  width: calc(30vh + 6px);
  height: calc(43.18vh + 6px);
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 8px;
  perspective: 1000px;
  position: relative;
`;

const FlipAnimationWrapper = styled.div<{ flip?: boolean, animate?: boolean }>`
  transform-style: preserve-3d;
  animation-duration: 0.8s;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-name: ${(props) => props.animate === true ? FlipKeyframes : (props.animate === false ? FlipBackKeyframes : null)};
  transform: rotateY(${props => props.flip ? '180deg' : '0deg'});
  perspective: 1000px;
`;

const ClueCard = styled.img<{ clickable?: boolean, isFound?: boolean, isPublic?: boolean }>`
  width: 30vh;
  height: 43.18vh;
  border: 3px solid white;
  border-radius: 20px;
  backface-visibility: hidden;
  cursor: ${(props) => (props.isFound || !props.clickable) ? 'auto' : 'pointer'};
  filter: ${(props) => props.isPublic ? 'brightness(1.0)' : ((props.isFound || !props.clickable) ? 'brightness(0.7)' : 'brightness(0.9)')};

  &:hover {
    filter: ${(props) => props.isPublic ? 'brightness(1.0)' : ((props.isFound || !props.clickable) ? 'brightness(0.7)' : 'brightness(1.1)')};
  }
`;

const FlipCard = styled(ClueCard)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const FlipCardFront = styled(ClueCard)`
  transform: rotateY(-180deg);
`;

const ClueText = styled(Stack)`
  height: 35px;
  margin-top: 12px;
  font-size: 24px;
  color: white;
  font-family: '微软雅黑';
  text-align: center;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.4), 0 6px 20px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
`;

const Footer = styled.div`
  width: 120px;
  padding-bottom: 24px;
`;

export interface SecretDialogProps extends DialogProps {
  privateAvailableSecrets?: string[];
  conditionSatisfied: Record<string, boolean>;
  secrets?: Record<string, SecretRes>;
  currentCharacter?: string;
  newSecrets?: string[];
  onSecretButtonClicked: (secret: string) => void;
  onPublishButtonClicked: (secret: string) => void;
  onClipButtonClicked: (clue: string, clip?: string) => void;
  clipedClues?: Record<string, string>;
}

export const SecretDialog: FC<SecretDialogProps> = function ({
  privateAvailableSecrets,
  conditionSatisfied,
  secrets,
  currentCharacter,
  newSecrets,
  onSecretButtonClicked,
  onPublishButtonClicked,
  onClipButtonClicked,
  clipedClues,
  ...props
}) {

  const [playFlipSound] = useSound(FlipSound, { volume: 0.5 });
  const [hideActionButton, setHideActionButton] = useState<Record<string, boolean>>({});

  const [flipAnimation, setFlipAnimation] = useState<Record<string, boolean>>({});

  const clueState = useMemo(() => {
    return Object.fromEntries(Object.entries(secrets ?? {}).map(([clue, clueInfo]) => [clue, newSecrets?.includes(clue) ? false : (clueInfo.isPublic || clueInfo.isFound === currentCharacter)]));
  }, [secrets, currentCharacter, newSecrets]);

  useEffect(() => {
    if (props.open && newSecrets) {
      setFlipAnimation(Object.fromEntries(newSecrets.map((clue) => [clue, false])));
    }
  }, [newSecrets, props.open]);

  useEffect(() => {
    if (!props.open) {
      setHideActionButton({});
      setFlipAnimation({});
    }
  }, [props.open])

  return (
    <FullDialog {...props}>
      <ContentWrapper direction="column" alignItems="center">
        <TitleBox>秘密线索（满足特定条件后方可调查）</TitleBox>
        <ClueContainer>
          {Object.entries(secrets ?? {}).map(([secret, secretInfo]) => (
            <Stack key={secret} direction="column" alignItems="center">
              <FlipButtonWrapper>
                {(secretInfo.isPublic || secretInfo.isFound === currentCharacter) &&
                  <FlipButton style={{ transform: flipAnimation[secret] ? 'rotate(0deg)' : 'rotate(180deg)' }}
                    icon={<FlipCameraAndroidIcon htmlColor="white" />}
                    onClick={() => setFlipAnimation((prev) => ({ ...prev, [secret]: (flipAnimation[secret] === undefined) ? true : !flipAnimation[secret] }))}
                  />}
              </FlipButtonWrapper>
              <ClueCardContainer>

                <FlipAnimationWrapper flip={clueState[secret]} animate={flipAnimation[secret]} onAnimationStart={() => {playFlipSound();setHideActionButton((prev) => ({ ...prev, [secret]: true })) }} onAnimationEnd={() => { if (flipAnimation[secret] === false) setHideActionButton((prev) => ({ ...prev, [secret]: false })) }}>
                  <FlipCardFront src={getAssetUrl(secretInfo.frontUrl)} isFound isPublic clickable={false} draggable={false} onContextMenu={(e) => e.preventDefault()} />
                  <FlipCard src={getAssetUrl(secretInfo.backUrl)} isFound={!!secretInfo.isFound} isPublic={secretInfo.isPublic || secretInfo.isFound === currentCharacter} clickable={conditionSatisfied[secret]} draggable={false} onClick={() => onSecretButtonClicked(secret)} onContextMenu={(e) => e.preventDefault()} />
                </FlipAnimationWrapper>

                {!hideActionButton[secret] && (secretInfo.isPublic || secretInfo.isFound === currentCharacter) &&
                  <ClipTooltip arrow placement="top" title={
                    <Stack direction="row" spacing={1}>
                      {Clips.map((clip) =>
                        <CommonIconButton key={clip} icon={<StarIcon fontSize="large" htmlColor={ClipColor[clip]} />} onClick={() => onClipButtonClicked(secret, clip)} />
                      )}
                      <CommonIconButton icon={<NotInterestedIcon fontSize="large" htmlColor="red" />} onClick={() => onClipButtonClicked(secret)} />
                    </Stack>}>
                    <ClipBadge>
                      <StarIcon htmlColor={ClipColor[clipedClues?.[secret] ?? '']} />
                    </ClipBadge>
                  </ClipTooltip>}
              </ClueCardContainer>

              <ClueText direction="row" alignItems="center" justifyContent="center">
                {secretInfo.isFound
                  ? <>
                    {secretInfo.isPublic ? <>由<span style={{ color: '#FFC96B' }}>{secretInfo.isFound}</span>公开</>
                      : <>
                        {secretInfo.isFound === currentCharacter ? <>
                          <span
                            style={{ color: '#4EB7FE', textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => onPublishButtonClicked(secret)}
                          >
                            点击此处
                          </span>
                          公开该线索
                        </> : `已被${secretInfo.isFound}调查`}
                      </>}
                  </>
                  : <>
                    {secretInfo.isAvailable || privateAvailableSecrets?.includes(secret) ? <>
                      {secretInfo.condition ?
                        <>
                          {`发现了“${secretInfo.conditionText}”`}
                          {conditionSatisfied[secret] ? <CheckIcon htmlColor="#50C878" fontSize="large" /> : <CloseIcon htmlColor="#d32f2f" fontSize="large" />}
                        </>
                        : '可以调查'}
                    </> : '调查条件：未知'}
                  </>}
              </ClueText>
            </Stack>
          ))}
        </ClueContainer>
        <Footer>
          <ThemeButton onClick={() => props.onClose?.({}, "escapeKeyDown")}>返回</ThemeButton>
        </Footer>
      </ContentWrapper>
    </FullDialog>
  );
};

export default SecretDialog;
