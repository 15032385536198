import styled from '@emotion/styled';
import { FC } from 'react';
import { AvatarProps, DialogProps, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeButton } from 'lib/common/ThemeButton';
import CommonAvatar from 'lib/common/CommonAvatar';
import { getAssetUrl } from 'utils/utils';
import { CommonIconButton } from 'lib/common/CommonIconButton';
import { FullDialog } from 'lib/common/FullDialog';
import ConfirmDialog from 'lib/common/ConfirmDialog';
import { CharacterRes } from 'openapi';

const ContentWrapper = styled(Stack)`
  margin: auto;
  margin-top: 110px;
  margin-bottom: 80px;
  border-radius: 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const LeftPageContainer = styled(Stack)`
  height: 60dvh;
  width: 25vw;
  background-color: #9f9274;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  color: white;
  position: relative;
`;

const QuestionTextWrapper = styled.div`
  font-size: 48px;
  text-align: center;
  margin-left: 36px;
  margin-right: 36px;
`;

const PageNumber = styled.div`
  margin-top: 30px;
  font-size: 36px;
`;

const PageButton = styled(ThemeButton)`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 5vh;
`;

const RightPageContainer = styled(Stack)`
  height: 60dvh;
  width: 25vw;
  background-color: #fffae6;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  position: relative;
`;

const CloseButton = styled(CommonIconButton)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const CharacterGridContainer = styled.div`
  grid-template-columns: 110px 110px 110px 110px 110px;
  grid-template-rows: 180px 180px 180px;
  display: grid;
  align-items: center;
`;

const CharacterGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CharacterAvatar = styled(({ isActive, ...props }: { isActive: boolean } & AvatarProps) => (
  <CommonAvatar {...props} />
))`
  width: 80px;
  height: 80px;
  cursor: pointer;
  box-shadow: ${(props) => props.isActive ? '0 0 4px 6px #5885AF' : 'none'};
`;

const CharacterName = styled.div`
  font-size: 22px;
  height: 28px;
  margin-top: 5px;
  text-align: center;
  font-family: 'STZhongSong';
`;

const SubmitButton = styled(ThemeButton)`
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
  &.Mui-disabled {
    background-color: #d32f2fAA;
  }
`;

export interface VoteDialogProps extends DialogProps {
  currentPage: number;
  votedMurderers: Array<string | null>;
  questions: string[];
  isAllQuestionVoted: boolean;
  onPrevPageButtonClicked: () => void;
  onNextPageButtonClicked: () => void;
  onSelectMurderer: (murderer: string) => void;
  onSubmitButtonClicked: () => void;
  showConfirmDialog: boolean;
  handleConfirmDialogClose: () => void;
  onConfirmVote: () => void;
  characterList: Record<string, CharacterRes>;
}

export const VoteDialog: FC<VoteDialogProps> = function ({
  currentPage,
  votedMurderers,
  questions,
  isAllQuestionVoted,
  onPrevPageButtonClicked,
  onNextPageButtonClicked,
  onSelectMurderer,
  onSubmitButtonClicked,
  showConfirmDialog,
  handleConfirmDialogClose,
  onConfirmVote,
  characterList,
  ...props
}) {

  const getGridStyle = (index: number) => {
    switch (Object.keys(characterList ?? {}).length) {
      case 5:
        switch (index) {
          case 0:
            return { gridColumn: 2, gridRow: 1 }
          case 1:
            return { gridColumn: 4, gridRow: 1 };
          case 2:
            return { gridColumn: 1, gridRow: 2 };
          case 3:
            return { gridColumn: 3, gridRow: 3 };
          case 4:
            return { gridColumn: 5, gridRow: 2 };
          default:
            return null;
        }
      case 6:
        switch (index) {
          case 0:
            return { gridColumn: 2, gridRow: 1 };
          case 1:
            return { gridColumn: 4, gridRow: 1 };
          case 2:
            return { gridColumn: 1, gridRow: 2 };
          case 3:
            return { gridColumn: 5, gridRow: 2 };
          case 4:
            return { gridColumn: 2, gridRow: 3 };
          case 5:
            return { gridColumn: 4, gridRow: 3 };
          default:
            return null;
        }
      default:
        return null;
    }
  };

  return (
    <FullDialog {...props}>
      <ContentWrapper direction="row">
        <LeftPageContainer direction="column" alignItems="center" justifyContent="center">
          <QuestionTextWrapper>
            {questions[currentPage] ?? null}
          </QuestionTextWrapper>
          <PageNumber>{`${currentPage + 1}/${questions.length}`}</PageNumber>
          {currentPage > 0 ? <PageButton width="120px" onClick={onPrevPageButtonClicked}>上一页</PageButton> : null}
        </LeftPageContainer>
        <RightPageContainer direction="column" alignItems="center" justifyContent="center">
          <CloseButton icon={<CloseIcon />} onClick={() => props.onClose?.({}, 'escapeKeyDown')} />
          <CharacterGridContainer>
            {Object.entries(characterList ?? {}).map(([character, characterInfo], index) => (
              <CharacterGrid key={character} style={{ ...getGridStyle(index) }}>
                <CharacterAvatar src={getAssetUrl(characterInfo.avatar)} isActive={character === votedMurderers[currentPage]} onClick={() => onSelectMurderer(character)} />
                <CharacterName>{character}</CharacterName>
              </CharacterGrid>
            ))}
          </CharacterGridContainer>
          {currentPage + 1 < questions.length ? <PageButton width="120px" onClick={onNextPageButtonClicked}>下一页</PageButton> : null}
        </RightPageContainer>
      </ContentWrapper>
      <SubmitButton color="error" width="120px" disabled={!isAllQuestionVoted} onClick={onSubmitButtonClicked}>提交</SubmitButton>
      <ConfirmDialog open={showConfirmDialog} onClose={handleConfirmDialogClose} title="确定提交投票吗？" content="提交之后，你将无法更改。" onConfirmButtonClicked={onConfirmVote} />
    </FullDialog>
  );
};

export default VoteDialog;
