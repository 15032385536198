import { createBinder, getAssetUrl } from "utils/utils";
import { XLJ_InvestigatePage, XLJ_InvestigatePageProps } from "./XLJ_InvestigatePage";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { GameContext, UserContext, VolumeContext } from "utils/contexts";
import { socket } from "utils/socketConnector";
import PickClueDialogBinder from "lib/game/components/PickClueDialogBinder";
import useSound from "use-sound";
import { useParams } from "react-router-dom";

export type XLJ_InvestigatePageBinderManagedProps = 'showMap' | 'gameTime' | 'numRemainingClues' | 'locationHasNewClue'
  | 'onLocationButtonClicked' | 'pickClueDialog' | 'showInviteButton' | 'onInviteButtonClicked'
  | 'showConfirmDialog' | 'handleConfirmDialogClose' | 'onConfirmInvite';

export interface XLJ_InvestigatePageBinderProps extends Omit<XLJ_InvestigatePageProps, XLJ_InvestigatePageBinderManagedProps> {
  showPickClueDialog: boolean;
  setShowPickClueDialog: (_: boolean) => void;
}

const useXLJ_InvestigatePageBinder = (props: XLJ_InvestigatePageBinderProps): XLJ_InvestigatePageProps => {

  const { user } = useContext(UserContext);
  const { gameId } = useParams();
  const volume = useContext(VolumeContext);
  const gameAudit = useContext(GameContext);
  const [newClues, setNewClues] = useState<Record<string, string[]>>({});
  const { showPickClueDialog, setShowPickClueDialog } = props;
  const [currentLocation, setCurrentLocation] = useState<string>();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);


  const [, { stop: musicStop }] = useSound(getAssetUrl('/XLJ/bgm.mp3'), {
    volume: volume / 100,
    loop: true,
    autoplay: true,
    interrupt: true,
  });

  useEffect(() => {
    return () => musicStop();
  }, [musicStop]);

  const newCharacters = useMemo(() => {
    switch (gameAudit?.stage) {
      case 'READING2':
      case 'INVESTIGATING2':
      case 'DISCUSSING2':
      case 'READING3':
      case 'INVESTIGATING3':
      case 'VOTING':
      case 'REVEALING':
        return { 昭然: '祀女' };
      default:
        return {};
    }
  }, [gameAudit?.stage]);

  const numRemainingClues = useMemo(() =>
    Object.fromEntries(
      Object.entries(gameAudit?.rooms ?? {})
        .map(([room, roomInfo]) => [room, Object.values(roomInfo.clues).reduce(((numClues, clueInfo) => numClues + (clueInfo.isFound ? 0 : 1)), 0)]))
    , [gameAudit?.rooms]);

  const unavailableClues = useMemo(() => [
    ...(gameAudit?.stage !== 'INVESTIGATING3' ? ['clue10', 'clue11', 'clue12', 'clue13', 'clue14', 'clue27', 'clue28', 'clue29', 'clue30'] : []),
    ...(!gameAudit?.rooms['祭楼二层']?.clues['clue28'].isPublic ? ['clue15', 'clue16', 'clue17'] : []),

  ], [gameAudit?.rooms, gameAudit?.stage]);

  const mistClues = useMemo(() => !gameAudit?.rooms['祭楼二层']?.clues['clue28'].isPublic ? ['clue15', 'clue16', 'clue17'] : undefined, [gameAudit?.rooms]);

  useEffect(() => {

    const onClueFound = (clue: string, location: string) => {
      setNewClues((prev) => ({
        ...prev,
        [location]: prev[location] ? [...prev[location], clue] : [clue]
      }));
    }

    const onCluePublished = (clue: string, location: string, character: string) => {
      if (character !== gameAudit?.players?.[user?.userId ?? '']?.character) {
        setNewClues((prev) => ({
          ...prev,
          [location]: prev[location] ? [...prev[location], clue] : [clue]
        }));
      }
    }

    const onStageTransition = (newStage: string) => {

    }

    socket?.on('clueFound', onClueFound);
    socket?.on('cluePublished', onCluePublished);
    socket?.on('stageTransition', onStageTransition);

    return () => {
      socket?.off('clueFound', onClueFound);
      socket?.off('cluePublished', onCluePublished);
      socket?.off('stageTransition', onStageTransition);
    }
  }, [gameAudit?.players, musicStop, user?.userId]);

  const onLocationButtonClicked = useCallback((location: string) => {
    setCurrentLocation(location);
    setShowPickClueDialog(true);
  }, [setShowPickClueDialog]);

  const handlePickClueDialogClose = useCallback(() => {
    setShowPickClueDialog(false);
    if (currentLocation) {
      const temp = { ...newClues };
      delete temp[currentLocation];
      setNewClues(temp);
    }
  }, [currentLocation, newClues, setShowPickClueDialog]);

  const onConfirmInvite = useCallback(() => {
    setShowConfirmDialog(false);
    if (gameAudit?.players?.[user?.userId ?? ''].character === '过岭') {
      socket?.emit('moveToStage', gameId, 'INVESTIGATING1');
    }
  }, [gameAudit?.players, gameId, user?.userId]);

  const managedProps: Pick<XLJ_InvestigatePageProps, XLJ_InvestigatePageBinderManagedProps> = {
    showMap: !!gameAudit?.stage && gameAudit.stage.slice(0, -1) !== 'READING' && gameAudit.stage !== 'INTRODUCING',
    gameTime: ['READING1', 'INTRODUCING', 'INVESTIGATING1'].includes(gameAudit?.stage ?? '') ? '12:15' : '13:15',
    numRemainingClues,
    locationHasNewClue: useMemo(() => Object.keys(newClues), [newClues]),
    onLocationButtonClicked,
    pickClueDialog: useMemo(() => <PickClueDialogBinder location={currentLocation} newClues={newClues[currentLocation ?? '']} newCharacters={newCharacters} open={showPickClueDialog} onClose={handlePickClueDialogClose} allowToInvestigate unavailableClues={unavailableClues} mistCover={mistClues} />, [currentLocation, handlePickClueDialogClose, mistClues, newCharacters, newClues, showPickClueDialog, unavailableClues]),
    showInviteButton: gameAudit?.stage === 'GREETING' && gameAudit?.players?.[user?.userId ?? '']?.character === '过岭',
    onInviteButtonClicked: () => setShowConfirmDialog(true),
    showConfirmDialog,
    handleConfirmDialogClose: () => setShowConfirmDialog(false),
    onConfirmInvite,
  };

  return {
    ...props,
    ...managedProps,
  };
};

export const XLJ_InvestigatePageBinder = createBinder(XLJ_InvestigatePage, useXLJ_InvestigatePageBinder);

export default XLJ_InvestigatePageBinder;